import React, { useEffect, useState } from "react";
import "./style.scss";
import { useSelector } from "react-redux";
import SelectOptionInstantSearch from "components/FormElements/SelectOptionInstantSearch";

let brandLimit = 15;
function BrandModelOptionsNew({
  brandValue = "",
  modelValue = "",
  brandName = "brand",
  modelName = "model",
  showErrorBrand,
  errorBrand,
  showErrorModel,
  errorModel,
  handleChangeBrand,
  handleChangeModel,
  brandLabel = "*Brand",
  modelLabel = "*Model",
  isModalOpen = true,
}) {
  const brandInformation = useSelector((state) => state.danisikBrands);
  const modelInformation = useSelector((state) => state.danisikModels);
  const user = useSelector((state) => state.danisikUser?.user);
  const [brandOptions, setBrandOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [brandCurrentPage, setBrandCurrentPage] = useState(1);
  let brandPageCount = brandInformation.brands.length / brandLimit;
  const [brandSearchText, setBrandSearchText] = useState("");
  const [modelCurrentPage, setModelCurrentPage] = useState(1);
  const [modelPageCount, setModelPageCount] = useState(1);
  const [modelSearchText, setModelSearchText] = useState("");

  useEffect(() => {
    setBrandOptions(brandInformation.brands.slice(0, brandLimit));
    setBrandCurrentPage(1);
  }, [brandInformation.brands]);

  const handleScrollBottomBrand = () => {
    if (brandPageCount > brandCurrentPage) {
      setBrandCurrentPage(brandCurrentPage + 1);
    }
  };
  const handleScrollBottomModel = () => {
    if (modelPageCount > modelCurrentPage) {
      setModelCurrentPage(modelCurrentPage + 1);
    }
  };

  useEffect(() => {
    if (brandCurrentPage > 1) {
      let newBrands = brandInformation.brands
        .filter((brand) =>
          brand.name.toLowerCase().includes(brandSearchText.toLowerCase())
        )
        .slice(0, brandCurrentPage * brandLimit);
      setBrandOptions(newBrands);
    }
  }, [brandCurrentPage]);
  useEffect(() => {
    if (
      isModalOpen &&
      user &&
      (user?.role === "admin" || user?.role === "store")
    ) {
      let newBrands = brandInformation.brands
        .filter((brand) =>
          brand.name.toLowerCase().includes(brandSearchText.toLowerCase())
        )
        .slice(0, brandLimit);
      setBrandOptions(newBrands);
      setBrandCurrentPage(1);
    }
  }, [brandSearchText, isModalOpen]);

  useEffect(() => {
    if (
      isModalOpen &&
      user &&
      (user?.role === "admin" || user?.role === "store") &&
      brandValue
    ) {
      let newModels = modelInformation.models[brandValue._id];
      if (newModels) {
        newModels = newModels
          .filter((model) =>
            model.name.toLowerCase().includes(modelSearchText.toLowerCase())
          )
          .slice(0, brandLimit);
      }

      setModelOptions(newModels);
      setModelCurrentPage(1);
    }
  }, [modelSearchText, brandValue]);

  useEffect(() => {
    if (
      brandValue?._id &&
      user &&
      (user?.role === "admin" || user?.role === "store")
    ) {
      setModelOptions(modelInformation.models[brandValue._id]);
    }
  }, [brandValue]);

  const preChangeBrand = (e) => {
    handleChangeBrand(e);
    if (modelValue?._id) {
      handleChangeModel({
        target: { name: modelName, value: { _id: "", name: "" } },
      });
    }
  };

  const handleChangeFilterBrandInput = (val) => {
    setBrandSearchText(val);
  };
  const handleChangeFilterModelInput = (val) => {
    setModelSearchText(val);
  };

  return (
    <>
      <SelectOptionInstantSearch
        options={brandOptions}
        label={brandLabel}
        name={brandName}
        handleChange={preChangeBrand}
        value={brandValue}
        showError={showErrorBrand}
        error={errorBrand}
        handleScrollBottom={handleScrollBottomBrand}
        filterInput={brandSearchText}
        handleChangeFilterInput={handleChangeFilterBrandInput}
        disabled={!(user?.role === "admin" || user?.role === "store")}
      />
      <SelectOptionInstantSearch
        options={modelOptions}
        label={modelLabel}
        name={modelName}
        handleChange={handleChangeModel}
        value={modelValue}
        showError={showErrorModel}
        error={errorModel}
        handleScrollBottom={handleScrollBottomModel}
        filterInput={modelSearchText}
        handleChangeFilterInput={handleChangeFilterModelInput}
        disabled={!(user?.role === "admin" || user?.role === "store")}
      />
    </>
  );
}

export default BrandModelOptionsNew;
