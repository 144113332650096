import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import ModalHeader from "components/Modals/ModalHeader";
import SearchInput from "components/Search/SearchInput";
import Add from "assets/icons/Add";
import EditPencil from "assets/icons/EditPencil";
import BrandAdd from "../BrandAdd";
import { truncate } from "utils/stringFunctions";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";

let brandLimit = 15;
function BrandList({ isModalOpen = false, setIsModalOpen }) {
  const brandInformation = useSelector((state) => state.danisikBrands);
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [addBrandModalOpen, setAddBrandModalOpen] = useState(false);
  const [addBrandModalInfo, setAddBrandModalInfo] = useState({
    _id: 0,
    name: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [brandCurrentPage, setBrandCurrentPage] = useState(1);
  let brandPageCount = brandInformation.brands.length / brandLimit;
  const [brandSearchText, setBrandSearchText] = useState("");
  const [brandSearchTextForSearch, setBrandSearchTextForSearch] = useState("");

  useEffect(() => {
    setBrands(brandInformation.brands.slice(0, brandLimit));
    setBrandCurrentPage(1);
  }, [brandInformation.brands]);

  const handleScrollBottomBrand = () => {
    if (brandPageCount > brandCurrentPage) {
      setBrandCurrentPage(brandCurrentPage + 1);
    }
  };
  const handleScrollDropdown = (e) => {
    const isScrollAtTheBottom =
      e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 1;
    if (isScrollAtTheBottom) {
      handleScrollBottomBrand();
    }
  };

  useEffect(() => {
    if (brandCurrentPage > 1) {
      setBrands(
        brandInformation.brands.slice(0, brandCurrentPage * brandLimit)
      );
    }
  }, [brandCurrentPage]);
  useEffect(() => {
    let newBrands = brandInformation.brands
      .filter((brand) =>
        brand.name.toLowerCase().includes(brandSearchText.toLowerCase())
      )
      .slice(0, brandLimit);
    setBrands(newBrands);
    setBrandCurrentPage(1);
  }, [brandSearchTextForSearch]);

  useEffect(() => {
    setBrandSearchTextForSearch(brandSearchText);
  }, [brandSearchText]);

  return (
    <div
      className={`brand-list-modal-container modal-container settings-modals ${
        isModalOpen ? "open" : ""
      }`}
    >
      <>
        <div className={`modal brand-list-modal`}>
          <ModalHeader
            title={languageTexts[danisikLanguage].brand}
            setIsModalOpen={setIsModalOpen}
          />
          <div className="modal-content settings-modal-content">
            <div className="modal-body-header">
              <div className="search-input-container">
                <SearchInput
                  onChange={(e) => setBrandSearchText(e.target.value)}
                  value={brandSearchText}
                />
              </div>
              <div
                className="add-button-container"
                onClick={() => {
                  setAddBrandModalInfo({
                    name: "",
                    _id: 0,
                  });
                  setAddBrandModalOpen(true);
                }}
              >
                <Add />
              </div>
            </div>
            <div
              className="settings-modal-body"
              onScroll={handleScrollDropdown}
            >
              <div className="item-container">
                {brands.map((brand) => (
                  <div className="item" key={brand._id}>
                    <span title={brand.name}>{truncate(brand.name, 30)}</span>
                    <div
                      className="edit-icon-container"
                      onClick={() => {
                        setAddBrandModalInfo({
                          name: brand.name,
                          _id: brand._id,
                        });
                        setAddBrandModalOpen(true);
                      }}
                    >
                      <EditPencil />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            onClick={() => setIsModalOpen(false)}
          ></div>
        </div>
        <BrandAdd
          setIsModalOpen={setAddBrandModalOpen}
          isModalOpen={addBrandModalOpen}
          addBrandModalInfo={addBrandModalInfo}
        />
      </>
    </div>
  );
}

export default BrandList;
