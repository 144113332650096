import FormButton from "components/Buttons/FormButton";
import Input from "components/FormElements/Input";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "./style.scss";
import CurrencyInput from "components/FormElements/CurrencyInput";
import DeviceValidationSchema from "./DeviceValidationSchema";
import CustomAlert from "lib/customAlert.js";
import DragAndDrop from "components/DragAndDrop";
import AddCircle from "assets/icons/AddCircle";
import BrandModelOptions from "components/BrandModelOptions";
import CategoryOptions from "components/CategoryOptions";
import AddAccessuars from "components/Modals/AddAccessuars";
import DeleteBin2 from "assets/icons/DeleteBin2";
import EditPencil from "assets/icons/EditPencil";
import { statusInfos } from "constants/statusInfos";
import ProductService from "services/product/productService";
import { toast } from "react-toastify";
import InputWithQrCodeNew from "components/FormElements/InputWithQrCodeNew";
import languageTexts from "constants/languageTexts";
import { useDispatch, useSelector } from "react-redux";
import DotmedCategoryOptions from "components/DotmedCategoryOptions";
import { getDevices } from "redux/product/device/deviceActions";
import BrandModelOptionsNew from "components/BrandModelOptionsNew";
import DotmedCategoryOptionsNew from "components/DotmedCategoryOptionsNew";
import AccessoryService from "services/product/device/accessoryService";

const brandOptions = [
  { name: "Siemens", value: "_45132154" },
  { name: "X-Ray", value: "_45132155" },
  { name: "Bosch", value: "_45132154" },
  { name: "Arcelik", value: "_45132154" },
  { name: "Siemens", value: "_45132154" },
  { name: "X-Ray", value: "_45132155" },
  { name: "Bosch", value: "_45132154" },
  { name: "Arcelik", value: "_45132154" },
  { name: "Siemens", value: "_45132154" },
  { name: "X-Ray", value: "_45132155" },
  { name: "Bosch", value: "_45132154" },
];

const getFormattedDeviceInfoConvert = (
  deviceInfos,
  accessuars,
  accessuarModalInfoFromConvert
) => {
  let formData = new FormData();
  formData.append("serialNumber", deviceInfos.serialNumber);
  formData.append("brandId", deviceInfos.brand?._id);
  formData.append("modelId", deviceInfos.model?._id);
  formData.append("categoryId", deviceInfos.category?._id);
  formData.append("dotmedCategoryId", deviceInfos.dotmedCategory?._id);
  formData.append("productionYear", deviceInfos.productionYear);
  formData.append("supplyInstitution", deviceInfos.supplyInstitution);
  formData.append(
    "salePrice[value]",
    deviceInfos.salePrice?.value ? deviceInfos.salePrice.value : null
  );
  formData.append("salePrice[currency]", deviceInfos.salePrice?.currency);
  formData.append(
    "shippingCost[value]",
    deviceInfos.shippingCost?.value ? deviceInfos.shippingCost.value : null
  );
  formData.append("shippingCost[currency]", deviceInfos.shippingCost?.currency);
  formData.append(
    "taxCost[value]",
    deviceInfos.taxCost?.value ? deviceInfos.taxCost.value : null
  );
  formData.append("taxCost[currency]", deviceInfos.taxCost?.currency);
  formData.append(
    "otherCost[value]",
    deviceInfos.otherCost?.value ? deviceInfos.otherCost.value : null
  );
  formData.append("otherCost[currency]", deviceInfos.otherCost?.currency);
  for (let i = 0; i < accessuars.length; i++) {
    formData.append(
      `accessories[${i}][serialNumber]`,
      accessuars[i].serialNumber
    );
    formData.append(`accessories[${i}][brandId]`, accessuars[i].brandId?._id);
    formData.append(`accessories[${i}][modelId]`, accessuars[i].modelId?._id);
    formData.append(`accessories[${i}][definition]`, accessuars[i].definition);
    formData.append(`accessories[${i}][status]`, accessuars[i].status);
    for (let j = 0; j < accessuars[i].images.length; j++) {
      formData.append(`accessories[${i}][images]`, accessuars[i].images[j]);
    }
    formData.append("device", true);
    formData.append("part", false);
  }
  for (let i = 0; i < accessuarModalInfoFromConvert.images.length; i++) {
    formData.append("images", accessuarModalInfoFromConvert.images[i]);
  }
  return formData;
};
const getFormattedDeviceInfo = (deviceInfos, accessuars) => {
  let formData = new FormData();
  formData.append("serialNumber", deviceInfos.serialNumber);
  formData.append("brandId", deviceInfos.brand?._id);
  formData.append("modelId", deviceInfos.model?._id);
  formData.append("categoryId", deviceInfos.category?._id);
  formData.append("dotmedCategoryId", deviceInfos.dotmedCategory?._id);
  formData.append("productionYear", deviceInfos.productionYear);
  formData.append("supplyInstitution", deviceInfos.supplyInstitution);
  formData.append(
    "salePrice[value]",
    deviceInfos.salePrice?.value ? deviceInfos.salePrice.value : null
  );
  formData.append("salePrice[currency]", deviceInfos.salePrice?.currency);
  formData.append(
    "shippingCost[value]",
    deviceInfos.shippingCost?.value ? deviceInfos.shippingCost.value : null
  );
  formData.append("shippingCost[currency]", deviceInfos.shippingCost?.currency);
  formData.append(
    "taxCost[value]",
    deviceInfos.taxCost?.value ? deviceInfos.taxCost.value : null
  );
  formData.append("taxCost[currency]", deviceInfos.taxCost?.currency);
  formData.append(
    "otherCost[value]",
    deviceInfos.otherCost?.value ? deviceInfos.otherCost.value : null
  );
  formData.append("otherCost[currency]", deviceInfos.otherCost?.currency);
  for (let i = 0; i < accessuars.length; i++) {
    formData.append(
      `accessories[${i}][serialNumber]`,
      accessuars[i].serialNumber
    );
    formData.append(`accessories[${i}][brandId]`, accessuars[i].brandId?._id);
    formData.append(`accessories[${i}][modelId]`, accessuars[i].modelId?._id);
    formData.append(`accessories[${i}][definition]`, accessuars[i].definition);
    formData.append(`accessories[${i}][status]`, accessuars[i].status);
    for (let j = 0; j < accessuars[i].images.length; j++) {
      formData.append(`accessories[${i}][images]`, accessuars[i].images[j]);
    }
  }
  return formData;
};
const productService = new ProductService();
function Device({ accessuarModalInfoFromConvert, isOpenedFromConvert }) {
  const dispatch = useDispatch();
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [loading, setIsLoading] = useState(false);
  const [addAccessuarModalOpen, setAddAccessuarModalOpen] = useState(false);
  const [accessuarModalInfo, setAccessuarModalInfo] = useState({
    _id: 0,
    serialNumber: "",
    status: {
      name: "",
      _id: "",
    },
    definition: "",
    images: [],
    brand: {
      name: "",
      _id: "",
    },
    model: {
      name: "",
      _id: "",
    },
  });
  const [accessuars, setAccessuars] = useState([]);
  const formik = useFormik({
    initialValues: {
      serialNumber: accessuarModalInfoFromConvert?.serialNumber || "",
      brand: accessuarModalInfoFromConvert?.brand || {
        name: "",
        _id: "",
      },
      model: accessuarModalInfoFromConvert?.model || {
        name: "",
        _id: "",
      },
      productionYear: "",
      category: {
        name: "",
        _id: "",
      },
      dotmedCategory: {
        name: "",
        _id: "",
      },
      supplyInstitution: "",
      salePrice: {
        currency: "EUR",
        value: "",
      },
      shippingCost: {
        currency: "EUR",
        value: "",
      },

      taxCost: {
        currency: "EUR",
        value: "",
      },
      otherCost: {
        currency: "EUR",
        value: "",
      },
    },
    validationSchema: DeviceValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].areYouSureToAddNewDevice
      );
      if (result) {
        //setIsLoading(true);

        setIsLoading(true);
        if (isOpenedFromConvert) {
          const accessoryService = new AccessoryService();
          let data = getFormattedDeviceInfoConvert(
            values,
            accessuars,
            accessuarModalInfoFromConvert
          );
          accessoryService
            .convertAccessuar(accessuarModalInfoFromConvert?._id, data)
            .then((res) => {
              formik.resetForm();
              setAccessuars([]);
              toast.success(languageTexts[danisikLanguage].deviceCreated);
              dispatch(getDevices());
            })
            .finally(() => setIsLoading(false));
        } else {
          let data = getFormattedDeviceInfo(values, accessuars);
          productService
            .createDevice(data)
            .then((res) => {
              formik.resetForm();
              setAccessuars([]);
              toast.success(languageTexts[danisikLanguage].deviceCreated);
              dispatch(getDevices());
            })
            .finally(() => setIsLoading(false));
        }
      }
    },
    enableReinitialize: true,
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value);
  };
  const handleChangeCurrency = (e) => {
    formik.setFieldValue(`${e.name}.currency`, e.value);
  };
  const handleChangeCurrencyInputValue = (e) => {
    formik.setFieldValue(`${e.name}.value`, e.value);
  };

  const handleDeleteAccessuar = async (index) => {
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].areYouSureToAddNewDevice
    );
    if (result) {
      const newAccessuars = [
        ...accessuars.slice(0, index),
        ...accessuars.slice(index + 1),
      ];
      setAccessuars(newAccessuars);
    }
  };

  return (
    <div className="add-device-container">
      <form onSubmit={formik.handleSubmit} className="flow-content">
        <InputWithQrCodeNew
          label={"*" + languageTexts[danisikLanguage].serialNumber}
          value={formik.values.serialNumber}
          handleChange={handleChange}
          name="serialNumber"
          showError={
            formik.touched.serialNumber && Boolean(formik.errors.serialNumber)
          }
          error={formik.touched.serialNumber && formik.errors.serialNumber}
        />

        <BrandModelOptionsNew
          brandLabel={languageTexts[danisikLanguage].brand}
          modelLabel={languageTexts[danisikLanguage].model}
          brandValue={formik.values.brand}
          modelValue={formik.values.model}
          showErrorBrand={
            formik.touched.brand?._id && Boolean(formik.errors.brand?._id)
          }
          errorBrand={formik.touched.brand?._id && formik.errors.brand?._id}
          showErrorModel={
            formik.touched.model?._id && Boolean(formik.errors.model?._id)
          }
          errorModel={formik.touched.model?._id && formik.errors.model?._id}
          handleChangeBrand={handleChange}
          handleChangeModel={handleChange}
        />
        <Input
          type="number"
          max={new Date().getFullYear()}
          label={languageTexts[danisikLanguage].productionYear}
          value={formik.values.productionYear}
          handleChange={handleChange}
          name="productionYear"
          showError={
            formik.touched.productionYear &&
            Boolean(formik.errors.productionYear)
          }
          error={formik.touched.productionYear && formik.errors.productionYear}
        />
        <DotmedCategoryOptionsNew
          options={brandOptions}
          label={"*" + languageTexts[danisikLanguage].category + "Dotmed"}
          name="dotmedCategory"
          handleChange={handleChange}
          value={formik.values.dotmedCategory}
          showError={
            formik.touched.dotmedCategory?._id &&
            Boolean(formik.errors.dotmedCategory?._id)
          }
          error={
            formik.touched.dotmedCategory?._id &&
            formik.errors.dotmedCategory?._id
          }
        />
        <Input
          label={languageTexts[danisikLanguage].supplyingInstitution}
          value={formik.values.supplyInstitution}
          handleChange={handleChange}
          name="supplyInstitution"
          showError={
            formik.touched.supplyInstitution &&
            Boolean(formik.errors.supplyInstitution)
          }
          error={
            formik.touched.supplyInstitution && formik.errors.supplyInstitution
          }
        />
        <CurrencyInput
          label={languageTexts[danisikLanguage].purchasePrice}
          value={formik.values.salePrice.value}
          selectedCurrency={formik.values.salePrice.currency}
          handleChangeCurrency={handleChangeCurrency}
          handleChange={handleChangeCurrencyInputValue}
          name="salePrice"
          showError={
            formik.touched.salePrice?.value &&
            Boolean(formik.errors.salePrice?.value)
          }
          error={
            formik.touched.salePrice?.value && formik.errors.salePrice?.value
          }
        />
        <CurrencyInput
          label={languageTexts[danisikLanguage].shippingExpenses}
          value={formik.values.shippingCost.value}
          selectedCurrency={formik.values.shippingCost.currency}
          handleChangeCurrency={handleChangeCurrency}
          handleChange={handleChangeCurrencyInputValue}
          name="shippingCost"
          showError={
            formik.touched.shippingCost?.value &&
            Boolean(formik.errors.shippingCost?.value)
          }
          error={
            formik.touched.shippingCost?.value &&
            formik.errors.shippingCost?.value
          }
        />

        <CurrencyInput
          label={languageTexts[danisikLanguage].taxExpenses}
          value={formik.values.taxCost.value}
          selectedCurrency={formik.values.taxCost.currency}
          handleChangeCurrency={handleChangeCurrency}
          handleChange={handleChangeCurrencyInputValue}
          name="taxCost"
          showError={
            formik.touched.taxCost?.value &&
            Boolean(formik.errors.taxCost?.value)
          }
          error={formik.touched.taxCost?.value && formik.errors.taxCost?.value}
        />
        <CurrencyInput
          label={languageTexts[danisikLanguage].otherExpenses}
          value={formik.values.otherCost.value}
          selectedCurrency={formik.values.otherCost.currency}
          handleChangeCurrency={handleChangeCurrency}
          handleChange={handleChangeCurrencyInputValue}
          name="otherCost"
          showError={
            formik.touched.otherCost?.value &&
            Boolean(formik.errors.otherCost?.value)
          }
          error={
            formik.touched.otherCost?.value && formik.errors.otherCost?.value
          }
        />

        <div className="accessuar-information-container flow-content">
          <div className="accessuar-information-header">
            <h3>{languageTexts[danisikLanguage].accessuaryInformations}</h3>
            <div
              className="add-svg-container"
              onClick={() => {
                setAccessuarModalInfo({
                  _id: 0,
                  serialNumber: "",
                  status: "",
                  definition: "",
                  images: [],
                  brand: {
                    name: "",
                    _id: "",
                  },
                  model: {
                    name: "",
                    _id: "",
                  },
                });
                setAddAccessuarModalOpen(true);
              }}
            >
              <AddCircle />
            </div>
          </div>
          <div className="accessuar-information-body">
            {accessuars.map((accessuar, index) => (
              <div
                className="accessuar-information-item flow-content"
                key={index}
              >
                <DragAndDrop disabled={true} images={accessuar.images} />
                <Input
                  label={"*" + languageTexts[danisikLanguage].serialNumber}
                  value={accessuar.serialNumber}
                  disabled={true}
                />
                <Input
                  label={"*" + languageTexts[danisikLanguage].brand}
                  value={accessuar.brandId?.name}
                  disabled={true}
                />
                <Input
                  label={"*" + languageTexts[danisikLanguage].model}
                  value={accessuar.modelId?.name}
                  disabled={true}
                />
                <Input
                  label={languageTexts[danisikLanguage].status}
                  value={statusInfos[accessuar.status]}
                  disabled={true}
                />
                <Input
                  label={languageTexts[danisikLanguage].accessuarDefinition}
                  value={accessuar.definition}
                  handleChange={handleChange}
                  disabled={true}
                />
                <div className="button-container">
                  <button
                    className="delete"
                    type="button"
                    onClick={() => handleDeleteAccessuar(index)}
                  >
                    <DeleteBin2 width="2rem" height="2rem" />
                    {languageTexts[danisikLanguage].delete}
                  </button>
                  <button
                    className="edit"
                    type="button"
                    onClick={() => {
                      setAccessuarModalInfo({
                        _id: accessuar._id,
                        index,
                        serialNumber: accessuar.serialNumber,
                        status: accessuar.status,
                        definition: accessuar.definition,
                        images: accessuar.images,
                        brand: accessuar.brandId,
                        model: accessuar.modelId,
                      });
                      setAddAccessuarModalOpen(true);
                    }}
                  >
                    <EditPencil width="1.8rem" height="1.8rem" />
                    {languageTexts[danisikLanguage].edit}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <FormButton
          title={languageTexts[danisikLanguage].save}
          isLoading={loading}
        />
      </form>
      <AddAccessuars
        setIsModalOpen={setAddAccessuarModalOpen}
        isModalOpen={addAccessuarModalOpen}
        accessuarModalInfo={accessuarModalInfo}
        setAccessuarModalInfo={setAccessuarModalInfo}
        accessuars={accessuars}
        setAccessuars={setAccessuars}
      />
    </div>
  );
}

export default Device;
