import api from "lib/api";

export default class PdfService {
  serviceName = "pdf";
  downloadPdf(data) {
    return api.post(this.serviceName + "/deviceDynamic", data);
  }
  getStockPdf() {
    return api.post(`${this.serviceName}/productList`, null, {
      responseType: "blob",
    });
  }
  downloadPdfDashboard(deviceId) {
    return api.post(
      this.serviceName + "/dynamicPdfAllInformations",
      { id: deviceId },
      {
        responseType: "blob",
      }
    );
  }
}
