import React from "react";

function MoreVertical({
  fill = "var(--clr-neutral-100)",
  width = "2.4rem",
  height = "2.4rem",
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M12 3c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 14c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-7c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
        fill={fill}
      />
    </svg>
  );
}

export default MoreVertical;
