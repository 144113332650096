import FactoryService from "services/factoryService";

export default class ECommerceService extends FactoryService {
  constructor() {
    super("eCommerce");
  }
  getECommerceByDeviceId(id) {
    return this.api.get(
      `/${this.serviceName}/getAllECommercesWithDeviceId/${id}`
    );
  }
  listInDotmed(id, deviceId) {
    return this.api.put(`/${this.serviceName}/dotmed/${id}`, {
      deviceId,
      dotmed: { price: null },
    });
  }
  listInShopify(id, deviceId) {
    return this.api.put(`/${this.serviceName}/shopify/${id}`, {
      deviceId,
      shopify: { price: null },
    });
  }
  listInEbay(id, deviceId) {
    return this.api.put(`/${this.serviceName}/ebay/${id}`, {
      deviceId,
      ebay: { price: null },
    });
  }

  deleteFromDotmed(id, deviceId) {
    return this.api.put(
      `/${this.serviceName}/dotmed/deleteECommerceDotmed/${id}`,
      { deviceId }
    );
  }
  updateDotmed(id, deviceId) {
    return this.api.put(
      `/${this.serviceName}/dotmed/updateECommerceDotmed/${id}`,
      { deviceId }
    );
  }

  deleteFromShopify(id, deviceId) {
    return this.api.put(
      `/${this.serviceName}/shopify/deleteECommerceShopify/${id}`,
      { deviceId }
    );
  }
  updateShopify(id, deviceId) {
    return this.api.put(
      `/${this.serviceName}/shopify/updateECommerceShopify/${id}`,
      { deviceId }
    );
  }
}
