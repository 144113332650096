import { useFormik } from "formik";
import CustomAlert from "lib/customAlert.js";
import React from "react";
import userValidationSchema from "./userValidationSchema";
import ModalHeader from "components/Modals/ModalHeader";
import Input from "components/FormElements/Input";
import FormButton from "components/Buttons/FormButton";
import { toast } from "react-toastify";
import DeleteButton from "components/Buttons/DeleteButton";
import UserService from "services/userService";
import SelectOption from "components/FormElements/SelectOption";
import { ROLES, ROLES_OPTIONS } from "constants/userRoles";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
import LoadingPage from "components/LoadingPage";
import { useState } from "react";
import UserChangePassword from "../UserChangePassword";
import PrimaryButton from "components/Buttons/PrimaryButton";

function UserAdd({
  isModalOpen = false,
  setIsModalOpen,
  addUserModalInfo = {
    _id: 0,
    name: "",
    email: "",
    roleId: "",
  },
  getAllUsers,
}) {
  const [loading, setLoading] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [userId, setUserId] = useState(0);
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: addUserModalInfo._id,
      name: addUserModalInfo.name,
      email: addUserModalInfo.email,
      role: {
        name: ROLES[addUserModalInfo.roleId],
        _id: addUserModalInfo.roleId,
      },
    },
    validationSchema: userValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        let userService = new UserService();
        setLoading(true);
        if (values._id) {
          userService
            .updateUser(values._id, {
              name: values.name,
              email: values.email,
              role: values.role._id,
            })
            .then(() => {
              toast.success(languageTexts[danisikLanguage].succesfullyUpdated);
              getAllUsers();
              setIsModalOpen(false);
            })
            .finally(() => setLoading(false));
        } else {
          userService
            .createUser({
              name: values.name,
              email: values.email,
              role: values.role._id,
            })
            .then(() => {
              toast.success(languageTexts[danisikLanguage].succesfullyAdded);
              getAllUsers();
              setIsModalOpen(false);
            })
            .finally(() => setLoading(false));
        }
      }
    },
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value);
  };
  const handleDelete = async () => {
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      "Delete User",
      "Are you sure to delete this user?"
    );
    if (result) {
      let userService = new UserService();
      setLoading(true);
      userService
        .deleteUser(formik.values._id)
        .then(() => {
          toast.success(languageTexts[danisikLanguage].succesfullyDeleted);
          getAllUsers();
          setIsModalOpen(false);
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <div
      className={`add-user-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      {loading ? (
        <LoadingPage />
      ) : (
        <div className={`modal add-user-modal`}>
          <ModalHeader
            title={`${
              addUserModalInfo._id
                ? languageTexts[danisikLanguage].edit
                : languageTexts[danisikLanguage].add
            }`}
            setIsModalOpen={setIsModalOpen}
          />
          <div className="modal-content">
            <form onSubmit={formik.handleSubmit} className="flow-content">
              <Input
                label={"User Name"}
                value={formik.values.name}
                handleChange={handleChange}
                name="name"
                showError={formik.touched.name && Boolean(formik.errors.name)}
                error={formik.touched.name && formik.errors.name}
              />
              <Input
                label={"User Email"}
                value={formik.values.email}
                handleChange={handleChange}
                name="email"
                showError={formik.touched.email && Boolean(formik.errors.email)}
                error={formik.touched.email && formik.errors.email}
              />
              <SelectOption
                label="User Role"
                name="role"
                value={formik.values.role}
                options={ROLES_OPTIONS}
                handleChange={handleChange}
                error={formik.touched.role?._id && formik.errors.role?._id}
                showError={
                  formik.touched.role?._id && Boolean(formik.errors.role?._id)
                }
              />
              <FormButton title={languageTexts[danisikLanguage].save} />
              {formik.values._id ? (
                <DeleteButton onClick={() => handleDelete()} />
              ) : null}
              <PrimaryButton
                title="Change Password"
                type="button"
                onClick={() => {
                  setIsChangePasswordModalOpen(true);
                }}
                style={{
                  color: "var(--clr-neutral-100)",
                  backgroundColor: "var(--clr-blue)",
                }}
              />
            </form>
          </div>
          <div
            className="modal-overlay"
            onClick={() => setIsModalOpen(false)}
          ></div>
          <UserChangePassword
            id={formik.values._id}
            isModalOpen={isChangePasswordModalOpen}
            setIsModalOpen={setIsChangePasswordModalOpen}
          />
        </div>
      )}
    </div>
  );
}

export default UserAdd;
