import React from "react";

function NextSkipRight({
  fill = "var(--clr-neutral-200)",
  width = "2.4rem",
  height = "2.4rem",
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={fill}
      {...props}
    >
      <path d="M17 6 17 18H15L15 6 17 6ZM13 12 7 18V6L13 12Z"></path>
    </svg>
  );
}

export default NextSkipRight;
