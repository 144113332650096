import UserList from "components/Modals/Admin/User/UserList";
import languageTexts from "constants/languageTexts";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

function UserManagementItem() {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const wrapperRef = useRef(null);

  return (
    <div className="setting-item-container" ref={wrapperRef}>
      <div className="setting-item" onClick={() => setIsUserModalOpen(true)}>
        <span>{languageTexts[danisikLanguage].userManagement}</span>
      </div>
      <UserList
        setIsModalOpen={setIsUserModalOpen}
        isModalOpen={isUserModalOpen}
      />
    </div>
  );
}

export default UserManagementItem;
