import React, { useEffect, useState } from "react";
import "./style.scss";
import ModalHeader from "../ModalHeader";
import Tabs from "./Tabs/TabHeader";
import PartTab from "./Tabs/Part";
import PurchaseTab from "./Tabs/Purchase";
import StorageTab from "./Tabs/Storage";
import ECommerceTab from "./Tabs/ECommerce";
import * as partTabs from "constants/partTabs";
import DeviceTab from "./Tabs/Device";
import PartService from "services/product/part/part";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";
function Part({
  isModalOpen = false,
  setIsModalOpen,
  partId,
  partActiveStatus,
  partName,
  stockNumber,
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const user = useSelector((state) => state.danisikUser?.user);
  const hasPermissionToEdit =
    user &&
    (user.role === "admin" || user.role === "buyer" || user.role === "store");
  const tabs = {
    [partTabs.PURCHASE]: <PurchaseTab partId={partId} />,
    [partTabs.PART]: <PartTab partId={partId} />,
    [partTabs.STORAGE]: <StorageTab partId={partId} />,
    [partTabs.DEVICE]: <DeviceTab partId={partId} />,
    [partTabs.ECOMMERCE]: <ECommerceTab partId={partId} />,
  };
  const [activeTab, setActiveTab] = useState(partTabs.PURCHASE);
  const [stockNumberNew, setStockNumberNew] = useState(stockNumber);
  const [partNameNew, setPartNameNew] = useState(partName);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    // setTimeout(() => {
    //   setPartId("");
    // }, 300);
  };

  useEffect(() => {
    if (!partName && partId) {
      let partService = new PartService();
      partService.getById(partId).then((result) => {
        setPartNameNew(result.data.data.partName + result.data.data.partNumber);
        setStockNumberNew(result.data.data.stockNumber);
      });
    }
  }, [partName, partId]);

  useEffect(() => {
    let body = document.querySelector("body");
    body.classList.add("open-scroll");
    body.classList.add("open-scroll");
    if (isModalOpen) {
      body.classList.add("open-scroll");
    } else {
      body.classList.remove("open-scroll");
    }
  }, [isModalOpen]);

  return (
    <div
      className={`device-modal-container modal-container ${
        isModalOpen ? "open" : ""
      } ${!hasPermissionToEdit ? "pointer-events-none" : ""}`}
    >
      <div className={`modal device-modal `}>
        <ModalHeader
          title={partName ? partName : partNameNew}
          setIsModalOpen={setIsModalOpen}
          subTitle={`${languageTexts[danisikLanguage].stockNumber}: ${
            stockNumber || stockNumberNew
          }`}
        />
        <div className="modal-content no-padding">
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            partId={partId}
            partActiveStatus={partActiveStatus}
          />
          {tabs[activeTab]}
        </div>
        <div className="modal-overlay" onClick={handleCloseModal}></div>
      </div>
    </div>
  );
}

export default Part;
