import React, { useEffect, useState } from "react";

import "./style.scss";
import ArrowDown from "assets/icons/ArrowDown";
import { truncate } from "utils/stringFunctions";
import ArrowUp from "assets/icons/ArrowUp";
import DeviceModal from "components/Modals/Device";
import img1 from "assets/images/no-image-available.png";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";

//types : "device-status-working","broke","unknown"

function Table({
  filterOptions,
  deviceArr = [],
  sortBy,
  setSortBy,
  getDeviceWithFilters,
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [deviceId, setDeviceId] = useState("");
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [stockNumber, setStockNumber] = useState("");
  const handleSortBy = (sortByParam) => {
    if (sortBy?.name === sortByParam && sortBy?.sortType === "asc") {
      setSortBy({ name: sortByParam, sortType: "desc" });
    } else if (sortBy?.name === sortByParam && sortBy?.sortType === "desc") {
      setSortBy({ name: sortByParam, sortType: "asc" });
    } else {
      setSortBy({ name: sortByParam, sortType: "asc" });
    }
  };

  const handleDeviceModal = (id, deviceName, stockNumber) => {
    setDeviceId(id);
    setDeviceName(deviceName);
    setStockNumber(stockNumber);
  };
  useEffect(() => {
    if (deviceId) {
      setIsDeviceModalOpen(true);
    }
  }, [deviceId]);

  return (
    <div className="device-table-inactive">
      <div className="table-header-container">
        <div className="table-header">
          <div className="table-img-header table-header-item"></div>
          <div
            className="table-serial-number-header table-header-item"
            onClick={() => handleSortBy("serialNumber")}
          >
            <span>{languageTexts[danisikLanguage].serialNumber}</span>
            {sortBy?.name === "serialNumber" && sortBy?.sortType === "asc" ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
          <div
            className="table-stock-number-header table-header-item"
            onClick={() => handleSortBy("stockNumber")}
          >
            <span>{languageTexts[danisikLanguage].stockNumber}</span>
            {sortBy?.name === "stockNumber" && sortBy?.sortType === "asc" ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
          <div
            className="table-brand-header table-header-item"
            onClick={() => handleSortBy("brand")}
          >
            <span>{languageTexts[danisikLanguage].brand}</span>
            {sortBy?.name === "brand" && sortBy?.sortType === "asc" ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
          <div
            className="table-model-header table-header-item"
            onClick={() => handleSortBy("model")}
          >
            <span>{languageTexts[danisikLanguage].model}</span>
            {sortBy?.name === "model" && sortBy?.sortType === "asc" ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
          <div
            className="table-year-header table-header-item"
            onClick={() => handleSortBy("year")}
          >
            <span>{languageTexts[danisikLanguage].year}</span>
            {sortBy?.name === "year" && sortBy?.sortType === "asc" ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
          <div
            className="table-category-header table-header-item"
            onClick={() => handleSortBy("category")}
          >
            <span>{languageTexts[danisikLanguage].category}</span>
            {sortBy?.name === "category" && sortBy?.sortType === "asc" ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
          <div
            className="table-status-header table-header-item"
            onClick={() => handleSortBy("status")}
          >
            <span>{languageTexts[danisikLanguage].status}</span>
            {sortBy?.name === "status" && sortBy?.sortType === "asc" ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
        </div>
      </div>

      <div className="table-content-container">
        {deviceArr.map((device, index) => (
          <div
            className="table-content"
            key={index}
            onClick={() =>
              handleDeviceModal(
                device._id,
                `${device.brandId?.name} ${device.modelId?.name}`,
                device.stockNumber
              )
            }
          >
            <div className="table-img-content table-content-item">
              <img
                src={device.images[0]?.url ? device.images[0].url : img1}
                alt={`${device.brandId?.name} ${device.modelId?.name}`}
              />
            </div>
            <div
              className="table-serial-number-content table-content-item"
              title={device.serialNumber}
            >
              <span className="table-content-title">
                {languageTexts[danisikLanguage].serialNumber}:{" "}
              </span>
              <span> {truncate(device.serialNumber, 15)}</span>
            </div>
            <div
              className="table-stock-number-content table-content-item"
              title={device.stockNumber}
            >
              <span className="table-content-title">
                {languageTexts[danisikLanguage].stockNumber}:{" "}
              </span>
              <span>{truncate(device.stockNumber, 15)}</span>
            </div>
            <div
              className="table-brand-content table-content-item"
              title={device.brandId?.name}
            >
              <span>{truncate(device.brandId?.name)}</span>
            </div>
            <div
              className="table-model-content table-content-item"
              title={device.modelId?.name}
            >
              <span>{truncate(device.modelId?.name)}</span>
            </div>
            <div className="table-year-content table-content-item">
              <span className="table-content-title">
                {languageTexts[danisikLanguage].year}:{" "}
              </span>
              <span>{device.productionYear}</span>
            </div>
            <div
              className="table-category-content table-content-item"
              title={device.categoryId?.name}
            >
              <span className="table-content-title">
                {languageTexts[danisikLanguage].category}: :{" "}
              </span>
              <span>{truncate(device.categoryId?.name)}</span>
            </div>
            <div className="table-status-content table-content-item">
              <div
                className={`device-status-${device?.deviceStatus?.toLowerCase()}`}
              ></div>
              <span>
                <span>{device.deviceStatus}</span>
              </span>
            </div>
            <div className="table-brand-model-content table-content-item">
              <span>
                {device.brandId?.name} {device.modelId?.name}
              </span>
            </div>
          </div>
        ))}
      </div>
      {deviceId ? (
        <DeviceModal
          deviceId={deviceId}
          setDeviceId={setDeviceId}
          handleDeviceId={setDeviceId}
          isModalOpen={isDeviceModalOpen}
          setIsModalOpen={setIsDeviceModalOpen}
          deviceName={deviceName}
          getDeviceWithFilters={getDeviceWithFilters}
          stockNumber={stockNumber}
        />
      ) : null}
    </div>
  );
}

export default Table;
