import ArrowDown from "assets/icons/ArrowDown";
import ErrorMessages from "components/ErrorMessages";
import SearchInput from "components/Search/SearchInput";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.scss";
import { debounce } from "utils/debounce";

function SelectOption({
  showError = false,
  error = "",
  label = "",
  name = "",
  value,
  handleChange,
  disabled = false,
  options = [],
  handleScrollBottom = () => {},
  filterInput = "",
  handleChangeFilterInput = () => {},
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [filterInputToShow, setFilterInputToShow] = useState(filterInput);

  const handleCloseOptions = useCallback(() => {
    setShowOptions(false);
  }, []);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, handleCloseOptions);
  // function filterFunction() {
  //   for (let i = 0; i < options.length; i++) {
  //     setFilteredOptions(
  //       options.filter((val) => {
  //         return val.name.toUpperCase().includes(filterInput.toUpperCase());
  //       })
  //     );
  //   }
  // }
  const handleSelectedVal = (val) => {
    handleChange({ target: { name: name, value: val } });
    setShowOptions(false);
  };

  const handleOpenOptions = () => {
    if (!disabled) {
      setShowOptions(!showOptions);
    }
  };
  const handleScrollDropdown = (e) => {
    const isScrollAtTheBottom =
      e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 3;
    if (isScrollAtTheBottom) {
      handleScrollBottom();
    }
  };
  const handleScrollDropdownDebounce = debounce((e) => {
    handleScrollDropdown(e);
  }, 350);

  const handleChangeFilterInputDebounce = useCallback(
    debounce((val) => {
      handleChangeFilterInput(val);
    }, 350),
    [handleChangeFilterInput]
  );

  useEffect(() => {
    handleChangeFilterInputDebounce(filterInputToShow);
  }, [filterInputToShow, handleChangeFilterInputDebounce]);

  return (
    <div className="form-select-option" ref={wrapperRef}>
      <button
        className="dropbtn"
        onClick={() => handleOpenOptions()}
        type="button"
      >
        <span className={`label-text ${value?.name ? "label-small" : ""}`}>
          {label}
        </span>
        {value?.name && <span className="selected-value">{value.name}</span>}

        <div className="more-icon-container">
          <ArrowDown width="19" height="19" />
        </div>
      </button>
      <div
        className={`dropdown-content ${showOptions ? "show" : ""}`}
        onScroll={handleScrollDropdownDebounce}
      >
        <SearchInput
          type="text"
          value={filterInputToShow}
          onChange={(e) => setFilterInputToShow(e.target.value)}
        />
        <span onClick={() => handleSelectedVal({ name: "Select", value: "" })}>
          Select
        </span>
        {options.map((option, index) => (
          <span key={index} onClick={() => handleSelectedVal(option)}>
            {option.name}
          </span>
        ))}
      </div>
      {showError && error.length ? <ErrorMessages error={error} /> : null}
    </div>
  );
}

export default SelectOption;
