import React, { useState } from "react";
import "./style.scss";
import Input from "components/FormElements/Input";
import ButtonWithIcon from "components/Buttons/ButtonWithIcon";
import Search from "assets/icons/Search";
import { statusInfos } from "constants/statusInfos";
import PrimaryButton from "components/Buttons/PrimaryButton";
import AddNewPart from "./AddNewPart";
import PartService from "services/product/part/part";
import LoadingPage from "components/LoadingPage";
import PartModal from "components/Modals/Part";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";

const partService = new PartService();
function Part({ accessuarModalInfoFromConvert, isOpenedFromConvert }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [partNumber, setPartNumber] = useState("");
  const [searched, setSearched] = useState(false);
  const [addNewPart, setAddNewPart] = useState(false);
  const [parts, setParts] = useState([]);
  const [selectedPart, setSelectedPart] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isPartModalOpen, setIsPartModalOpen] = useState(false);

  const handleChangePartNumber = (e) => {
    setPartNumber(e.target.value);
  };
  const handleSearchParts = () => {
    if (partNumber) {
      setLoading(true);
      partService
        .findByPartNumber(partNumber)
        .then((res) => {
          setParts(res.data.data);
          setSearched(true);
        })
        .finally(() => setLoading(false));
    }
  };
  const handleReset = () => {
    setSearched(false);
    setParts([]);
    setPartNumber("");
  };
  const handleAddNew = () => {
    setAddNewPart(true);
  };

  const handleSelectPart = (part) => {
    setSelectedPart(part);
    setIsPartModalOpen(true);
  };

  return loading ? (
    <LoadingPage />
  ) : addNewPart ? (
    <AddNewPart
      setAddNewPart={setAddNewPart}
      partNumber={partNumber}
      accessuarModalInfoFromConvert={accessuarModalInfoFromConvert}
      isOpenedFromConvert={isOpenedFromConvert}
    />
  ) : (
    <div className="add-part-container">
      <div className="flow-content">
        <Input
          label={languageTexts[danisikLanguage].partNumber}
          value={partNumber}
          handleChange={handleChangePartNumber}
          name="partNumber"
        />
        {searched ? (
          <div className="flow-content">
            <span className="parts-found-count">
              {parts.length} {languageTexts[danisikLanguage].partsFound}
            </span>
            {parts.map((part) => (
              <div
                key={part._id}
                className="part-info"
                onClick={() => handleSelectPart(part)}
              >
                <span>PN: {part.partNumber}</span>
                <span>
                  {languageTexts[danisikLanguage].status}:{" "}
                  {statusInfos[part.status]}
                </span>
                <span>
                  {languageTexts[danisikLanguage].year}: {part.productionYear}
                </span>
              </div>
            ))}
            <PrimaryButton
              title={languageTexts[danisikLanguage].add}
              onClick={handleAddNew}
              backgroundColor="var(--clr-blue)"
            />
            <PrimaryButton
              title={languageTexts[danisikLanguage].reset}
              onClick={handleReset}
              backgroundColor="var(--clr-blue)"
            />
          </div>
        ) : (
          <ButtonWithIcon
            title={languageTexts[danisikLanguage].search}
            icon={<Search />}
            onClick={handleSearchParts}
          />
        )}
      </div>
      {selectedPart ? (
        <PartModal
          partId={selectedPart._id}
          isModalOpen={isPartModalOpen}
          setIsModalOpen={setIsPartModalOpen}
          partName={selectedPart.name}
        />
      ) : null}
    </div>
  );
}

export default Part;
