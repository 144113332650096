import * as Yup from "yup";
export default Yup.object().shape({
  password: Yup.string("Password")
    .required("Password is required")
    .min(8, "Password length should be at least 8 characters"),
  passwordConfirm: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});
