import DeviceTableInactive from "components/DeviceTableInactive";
import React from "react";

function InactiveDevices() {
  return (
    <div>
      <DeviceTableInactive />
    </div>
  );
}

export default InactiveDevices;
