import React, { useState } from "react";
import "./style.scss";
import ModalHeader from "../ModalHeader";
import Device from "./Device";
import Part from "./Part";
import * as constants from "constants/constants";
import DeviceIcon from "assets/icons/Device";
import PartIcon from "assets/icons/Parts";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function AddProduct({
  isModalOpen = false,
  setIsModalOpen,
  isOpenedFromConvert = false,
  accessuarModalInfo,
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [selectedTab, setSelectedTab] = useState(null);
  const handleSelectedTab = (tab) => {
    setSelectedTab(tab);
  };
  useEffect(() => {
    if (isModalOpen) {
      setSelectedTab(selectedTab ? selectedTab : constants.DEVICE);
    }
  }, [isModalOpen]);

  useEffect(() => {
    let body = document.querySelector("body");
    body.classList.add("open-scroll");
    body.classList.add("open-scroll");
    if (isModalOpen) {
      body.classList.add("open-scroll");
    } else {
      body.classList.remove("open-scroll");
    }
  }, [isModalOpen]);

  const handleCloseModal = () => {};

  return (
    <div
      className={`add-product-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal add-product-modal`}>
        <ModalHeader
          title={languageTexts[danisikLanguage].add}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content">
          <div className="tab">
            <div
              className={`tab-item ${
                selectedTab === constants.DEVICE ? "active-tab" : ""
              }`}
              onClick={() => handleSelectedTab(constants.DEVICE)}
            >
              <DeviceIcon />
              <span>{languageTexts[danisikLanguage].deviceInfos}</span>
            </div>
            <div
              className={`tab-item ${
                selectedTab === constants.PART ? "active-tab" : ""
              }`}
              onClick={() => handleSelectedTab(constants.PART)}
            >
              <PartIcon />
              <span>Part</span>
            </div>
          </div>
          {selectedTab === null ? null : selectedTab === constants.DEVICE ? (
            <Device
              isModalOpen={isModalOpen}
              key={isOpenedFromConvert}
              accessuarModalInfoFromConvert={accessuarModalInfo}
              isOpenedFromConvert={isOpenedFromConvert}
            />
          ) : (
            <Part
              isModalOpen={isModalOpen}
              key={isOpenedFromConvert}
              accessuarModalInfoFromConvert={accessuarModalInfo}
              isOpenedFromConvert={isOpenedFromConvert}
            />
          )}
        </div>
      </div>
      <div
        className="modal-overlay"
        onClick={() => setIsModalOpen(false)}
      ></div>
    </div>
  );
}

export default AddProduct;
