import { ThemeActionTypes } from "./themeActionTypes";
import * as constants from "constants/constants";

const INITIAL_STATE = {
  theme: localStorage.getItem("danisikTheme") || constants.DARK_THEME,
};

const themeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ThemeActionTypes.TOGGLE_THEME:
      let themeToSet =
        state.theme === constants.DARK_THEME
          ? constants.LIGHT_THEME
          : constants.DARK_THEME;
      localStorage.setItem("danisikTheme", themeToSet);
      return {
        theme: themeToSet,
      };
    default:
      return state;
  }
};

export default themeReducer;
