import * as Yup from "yup";
export default Yup.object().shape({
  serialNumber: Yup.string("").required("Serial number is required"),
  status: Yup.object().shape({
    _id: Yup.string("").required("Status is required"),
  }),
  brand: Yup.object().shape({
    _id: Yup.string("").required("Brand is required"),
  }),
  model: Yup.object().shape({
    _id: Yup.string("").required("Model is required"),
  }),
});
