import React from "react";

function Save({
  fill = "var(--clr-neutral-200)",
  width = "2.4rem",
  height = "2.4rem",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={fill}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M18 21v-8H6v8H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h13l4 4v13a1 1 0 0 1-1 1h-2zm-2 0H8v-6h8v6z" />
    </svg>
  );
}

export default Save;
