import Printer from "assets/icons/Printer";
import LoadingPage from "components/LoadingPage";
import languageTexts from "constants/languageTexts";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import PartService from "services/product/part/part";
import { generateXmlDymoPart } from "utils/dymo/partDymo";

function PartDymo({ partId }) {
  const [isLoading, setIsLoading] = useState(false);
  const danisikLanguage = useSelector((state) => state.danisikLanguage);

  const dymo = window.dymo;
  const printLabel = () => {
    let partService = new PartService();
    setIsLoading(true);
    partService
      .getById(partId)
      .then((res) => {
        let partInfosData = res.data.data;
        let printers = dymo.label?.framework?.getPrinters();
        if (!printers?.length) {
          alert("No DYMO printers are installed. Install DYMO printers.");
          return;
        }
        let selectedPrinter = printers[0];
        let xml = generateXmlDymoPart(
          partInfosData.partNumber,
          partInfosData.partName,
          partInfosData.stockNumber,
          `${window.location.origin}/parts/?label=${partId}`,
          partInfosData.serialNumber,
          partInfosData.productionYear
        );
        dymo.label.framework.openLabelXml(xml).print(selectedPrinter.name);
      })
      .finally(() => setIsLoading(false));
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <button
      className="print-label"
      onClick={printLabel}
      style={{ margin: 0, gap: "0.2rem", padding: 0 }}
    >
      <Printer />
      {languageTexts[danisikLanguage].printLabel}
    </button>
  );
}

export default PartDymo;
