export default class CustomAlert {
  alert = function (message = "", title = "") {
    let customAlert = document.getElementById("custom-alert");
    customAlert.style.display = "block";

    document.getElementById("dialogboxhead").style.display = "block";

    document.getElementById("dialogboxbody").innerHTML = message;
    document.getElementById("dialogboxhead").innerHTML = title;

    let dialogboxCancelButton = document.getElementById(
      "dialogbox-cancel-button"
    );
    let dialogboxOkButton = document.getElementById("dialogbox-ok-button");

    return new Promise((acc) => {
      function okClicked() {
        dialogboxOkButton.removeEventListener("click", okClicked);
        customAlert.style.display = "none";
        acc(true);
      }
      function cancelClicked() {
        dialogboxCancelButton.removeEventListener("click", cancelClicked);
        customAlert.style.display = "none";
        acc(false);
      }
      dialogboxOkButton.addEventListener("click", okClicked);
      dialogboxCancelButton.addEventListener("click", cancelClicked);
    });
  };
}
