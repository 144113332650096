import React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function DeviceRedirect() {
  const navigate = useNavigate();
  const { deviceId } = useParams();
  useEffect(() => {
    navigate(`/devices/?label=${deviceId}`);
  }, []);

  return <div></div>;
}

export default DeviceRedirect;
