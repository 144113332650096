import React from "react";
import "./style.scss";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import DownloadFile from "assets/icons/DownloadFile";
import Close from "assets/icons/Close";
import SwiperCustomButtons from "./swiperCustomButtons";
import { useEffect } from "react";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";

function SwiperComponent({
  files,
  swiperModalOpen,
  setSwiperModalOpen,
  initialSlide,
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  function downloadImage(url) {
    let newUrl = url + "?t=" + new Date().getTime();
    saveAs(newUrl, url);
  }

  const handleCloseModal = () => {
    setSwiperModalOpen(false);
  };
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();

        // 👇️ your logic here
        handleCloseModal();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return swiperModalOpen ? (
    <div className="swiper-container">
      <Swiper
        pagination={true}
        modules={[Pagination]}
        className="mySwiper"
        initialSlide={initialSlide <= files.length ? initialSlide : 0}
      >
        {files.map((file, index) => (
          <SwiperSlide key={index}>
            <div className="img-container">
              <div className="close-btn" onClick={handleCloseModal}>
                <Close width="3.6rem" height="3.6rem" />
              </div>
              <SwiperCustomButtons />

              <img src={file?.url || file?.preview} />
              <div
                className="download-btn"
                onClick={() => downloadImage(file?.url || file?.preview)}
              >
                <DownloadFile />
                {languageTexts[danisikLanguage].download}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-overlay" onClick={handleCloseModal}></div>
    </div>
  ) : null;
}

export default SwiperComponent;
