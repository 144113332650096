import * as Yup from "yup";
export default Yup.object().shape({
  name: Yup.string("Name").required("required"),
  email: Yup.string("Email").email("Enter valid email").required("Email is required"),
  password: Yup.string("Password")
    .required("Password is required")
    .min(8, "Password length should be at least 8 characters")
    .matches(/.*[0-9].*/, "Password should contain at least one number")
    .matches(/(?=.*[a-z])/, "Password should contain at least one lower case")
    .matches(/(?=.*[A-Z])/, "Password should contain at least one upper case")
    .matches(
      /(?=.*?[#+?!@$%^&*-])/,
      "Password should contain at least one special character"
    ),
  passwordConfirm: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});
