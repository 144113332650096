import FormButton from "components/Buttons/FormButton";
import Input from "components/FormElements/Input";
import { useFormik } from "formik";
import CustomAlert from "lib/customAlert.js";
import React from "react";
import ModalHeader from "../ModalHeader";
import "./style.scss";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
import storageValidationSchema from "./storageValidationSchema";

function AddStorage({
  isModalOpen = false,
  setIsModalOpen,
  partId,
  storageModalInfo = {
    storageDefinition: "",
    shelfNumber: "",
    boxNumber: "",
    _id: 0,
  },
  setStorageModalInfo,
  storages,
  setStorages,
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      storageDefinition: storageModalInfo.storageDefinition,
      shelfNumber: storageModalInfo.shelfNumber,
      boxNumber: storageModalInfo.boxNumber,
    },
    validationSchema: storageValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        if (storageModalInfo.index + 1) {
          storages[storageModalInfo.index] = values;
          setStorages(storages);
        } else {
          storages.push(values);
          setStorages(storages);
        }
        setIsModalOpen(false);
        setStorageModalInfo({ _id: 0, title: "", description: "" });
      }
    },
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value);
  };
  return (
    <div
      className={`add-storage-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal add-storage-modal`}>
        <ModalHeader
          title={`${
            storageModalInfo._id
              ? languageTexts[danisikLanguage].edit
              : languageTexts[danisikLanguage].add
          }`}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content">
          <form
            onSubmit={formik.handleSubmit}
            className="flow-content tab-device-form"
          >
            <Input
              label={languageTexts[danisikLanguage].storageDefinition}
              value={formik.values.storageDefinition}
              handleChange={handleChange}
              name="storageDefinition"
              showError={
                formik.touched.storageDefinition &&
                Boolean(formik.errors.storageDefinition)
              }
              error={
                formik.touched.storageDefinition &&
                formik.errors.storageDefinition
              }
            />
            <Input
              label={languageTexts[danisikLanguage].shelfNumber}
              value={formik.values.shelfNumber}
              handleChange={handleChange}
              name="shelfNumber"
              type="number"
              showError={
                formik.touched.shelfNumber && Boolean(formik.errors.shelfNumber)
              }
              error={formik.touched.shelfNumber && formik.errors.shelfNumber}
            />
            <Input
              label={languageTexts[danisikLanguage].boxNumber}
              value={formik.values.boxNumber}
              handleChange={handleChange}
              name="boxNumber"
              type="number"
              showError={
                formik.touched.boxNumber && Boolean(formik.errors.boxNumber)
              }
              error={formik.touched.boxNumber && formik.errors.boxNumber}
            />
            <FormButton title={languageTexts[danisikLanguage].save} />
          </form>
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
    </div>
  );
}

export default AddStorage;
