import CustomAlert from "components/CustomAlert";
import LoadingPage from "components/LoadingPage";
import ThemeProvider from "components/Theme/ThemeProvider";
import DefaultLayout from "Layouts/DefaultLayout";
import LoginLayout from "Layouts/LoginLayout";
import Login from "pages/Auth/Login";
import Register from "pages/Auth/Register";
import Dashboard from "pages/Dashboard";
import Devices from "pages/Devices";
import GetStock from "pages/GetStock";
import InactiveParts from "pages/InactiveParts";
import InactiveDevices from "pages/InactviceDevices";
import NotFound from "pages/NotFound";
import Parts from "pages/Parts";
import ProductDetail from "pages/ProductDetail";
import DeviceRedirect from "pages/Redirect/Device";
import Settings from "pages/Settings";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setBrands } from "redux/product/brand/brandActions";
import { setCategories } from "redux/product/category/categoryActions";
import { setModels } from "redux/product/model/modelActions";
import { signIn } from "redux/user/userActions";
import UserService from "services/userService";
import { AddToHomeScreen } from "react-pwa-add-to-homescreen";

const isTokenValid = (token) => {
  if (
    token !== "null" &&
    token !== null &&
    token !== undefined &&
    token !== "" &&
    token.length > 0 &&
    token !== "undefined"
  ) {
    return true;
  }
};
function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.danisikUser?.user);
  const [loading, setLoading] = useState(true);
  let token = localStorage.getItem("danisik-token");
  const [pathName, setPathName] = useState(null);
  const location = useLocation();
  const { pathname, search } = location;
  useEffect(() => {
    const fullPath = `${pathname}${search}`;
    setPathName(fullPath);
  }, []);

  useEffect(() => {
    if (!user) {
      if (isTokenValid(token)) {
        let userService = new UserService();
        setLoading(true);
        userService
          .getCurrentUser()
          .then((res) => {
            dispatch(signIn(res.data.data.user, token));
          })
          .finally(() => {
            setLoading(false);
            navigate(pathName);
          });
      } else {
        if (
          pathName &&
          !pathName.startsWith("/product-detail") &&
          !pathName.startsWith("/register") &&
          !pathName.startsWith("/login")
        ) {
          dispatch(signIn(null));
          navigate("/login");
        }
      }
    }
  }, [user, dispatch, navigate, pathName]);

  useEffect(() => {
    if (user) {
      dispatch(setBrands(user));
      dispatch(setCategories(user));
      dispatch(setModels(user));
    }
  }, [user]);

  return (
    <ThemeProvider>
      <Routes>
        {token ? (
          <Route element={<DefaultLayout />}>
            <Route
              path="/"
              element={
                user ? (
                  user.role === "admin" ? (
                    <Dashboard />
                  ) : (
                    <Devices />
                  )
                ) : (
                  <Devices />
                )
              }
            />
            <Route path="/devices" element={<Devices />} />
            <Route path="/devices/:deviceId" element={<Devices />} />
            <Route path="/inactives" element={<InactiveDevices />} />
            <Route path="/inactive-parts" element={<InactiveParts />} />
            <Route path="/parts" element={<Parts />} />
            <Route path="/parts/:partId" element={<Parts />} />
            <Route path="/get-stock" element={<GetStock />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/buy/:deviceId" element={<DeviceRedirect />} />
            <Route
              path="*"
              element={loading ? <LoadingPage /> : <NotFound />}
            />
          </Route>
        ) : (
          <Route element={<LoginLayout />}>
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
            </>
            <Route path="*" element={<Devices />} />
          </Route>
        )}
        <Route
          path="/product-detail/:productType/:id"
          element={<ProductDetail />}
        />
      </Routes>
      <AddToHomeScreen translate={{ chromiumInstall: "Install Danisik App" }} />
      <CustomAlert />
      <ToastContainer />
    </ThemeProvider>
  );
}
/**/

export default App;
