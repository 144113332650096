import DeviceTable from "components/DeviceTable";
import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

function Devices() {
  const { deviceId } = useParams();

  return (
    <div>
      <DeviceTable deviceId={deviceId} />
    </div>
  );
}

export default Devices;
