import React from "react";

function PdfSheet({
  fill = "var(--clr-neutral-200)",
  width = "2.4rem",
  height = "2.4rem",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.4673e-05 6L6.00303 0H16.998C17.55 0 18 0.455 18 0.992V19.008C17.9998 19.2712 17.895 19.5235 17.7088 19.7095C17.5226 19.8955 17.2702 20 17.007 20H0.993025C0.861702 19.9991 0.731845 19.9723 0.61087 19.9212C0.489895 19.8701 0.38017 19.7957 0.287961 19.7022C0.195752 19.6087 0.122864 19.4979 0.0734597 19.3762C0.0240555 19.2545 -0.000897804 19.1243 2.4673e-05 18.993V6ZM7.00002 1.5L1.50002 7H7.00002V1.5Z"
        fill="#DEDEDE"
      />
    </svg>
  );
}

export default PdfSheet;
