import { BrandActionTypes } from "./brandActionTypes";

const INITIAL_STATE = {
  brands: [],
  brandLoading: false,
  brandError: null,
};

const brandReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BrandActionTypes.SET_BRANDS:
      return {
        ...state,
        brandLoading: true,
      };
    case BrandActionTypes.SET_BRANDS_SUCCESSFUL:
      return {
        ...state,
        brands: action.payload,
        brandLoading: false,
      };
    case BrandActionTypes.SET_BRANDS_ERROR:
      return {
        ...state,
        brandError: action.payload,
        brandLoading: false,
      };
    default:
      return state;
  }
};

export default brandReducer;
