export function generateXmlDymoDevice(
  link,
  stockNumber,
  brand,
  model,
  productionYear
) {
  var labelXml = `<?xml version="1.0" encoding="utf-8"?>
    <DesktopLabel Version="1">
    <DYMOLabel Version="3">
      <Description>DYMO Label</Description>
      <Orientation>Landscape</Orientation>
      <LabelName>Addresss0722370</LabelName>
      <InitialLength>0</InitialLength>
      <BorderStyle>SolidLine</BorderStyle>
      <DYMORect>
        <DYMOPoint>
          <X>0.23</X>
          <Y>0.06</Y>
        </DYMOPoint>
        <Size>
          <Width>3.21</Width>
          <Height>0.9966666</Height>
        </Size>
      </DYMORect>
      <BorderColor>
        <SolidColorBrush>
          <Color A="1" R="0" G="0" B="0"></Color>
        </SolidColorBrush>
      </BorderColor>
      <BorderThickness>1</BorderThickness>
      <Show_Border>False</Show_Border>
      <DynamicLayoutManager>
        <RotationBehavior>ClearObjects</RotationBehavior>
        <LabelObjects>
          <QRCodeObject>
            <Name>IQRCodeObject0</Name>
            <Brushes>
              <BackgroundBrush>
                <SolidColorBrush>
                  <Color A="1" R="1" G="1" B="1"></Color>
                </SolidColorBrush>
              </BackgroundBrush>
              <BorderBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BorderBrush>
              <StrokeBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </StrokeBrush>
              <FillBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </FillBrush>
            </Brushes>
            <Rotation>Rotation0</Rotation>
            <OutlineThickness>1</OutlineThickness>
            <IsOutlined>False</IsOutlined>
            <BorderStyle>SolidLine</BorderStyle>
            <Margin>
              <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
            </Margin>
            <BarcodeFormat>QRCode</BarcodeFormat>
            <Data>
              <DataString>${link}</DataString>
            </Data>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <Size>AutoFit</Size>
            <EQRCodeType>QRCodeText</EQRCodeType>
            <TextDataHolder>
              <Value>${link}</Value>
            </TextDataHolder>
            <ObjectLayout>
              <DYMOPoint>
                <X>0.24</X>
                <Y>0.07</Y>
              </DYMOPoint>
              <Size>
                <Width>0.801254</Width>
                <Height>0.9387515</Height>
              </Size>
            </ObjectLayout>
          </QRCodeObject>
          <TextObject>
            <Name>ITextObject0</Name>
            <Brushes>
              <BackgroundBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BackgroundBrush>
              <BorderBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BorderBrush>
              <StrokeBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </StrokeBrush>
              <FillBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </FillBrush>
            </Brushes>
            <Rotation>Rotation0</Rotation>
            <OutlineThickness>1</OutlineThickness>
            <IsOutlined>False</IsOutlined>
            <BorderStyle>SolidLine</BorderStyle>
            <Margin>
              <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
            </Margin>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <FitMode>None</FitMode>
            <IsVertical>False</IsVertical>
            <FormattedText>
              <FitMode>None</FitMode>
              <HorizontalAlignment>Center</HorizontalAlignment>
              <VerticalAlignment>Middle</VerticalAlignment>
              <IsVertical>False</IsVertical>
              <LineTextSpan>
                <TextSpan>
                  <Text>Manufacturer: ${brand}</Text>
                  <FontInfo>
                    <FontName>Segoe UI</FontName>
                    <FontSize>10</FontSize>
                    <IsBold>False</IsBold>
                    <IsItalic>False</IsItalic>
                    <IsUnderline>False</IsUnderline>
                    <FontBrush>
                      <SolidColorBrush>
                        <Color A="1" R="0" G="0" B="0"></Color>
                      </SolidColorBrush>
                    </FontBrush>
                  </FontInfo>
                </TextSpan>
              </LineTextSpan>
            </FormattedText>
            <ObjectLayout>
              <DYMOPoint>
                <X>1.105417</X>
                <Y>0.06</Y>
              </DYMOPoint>
              <Size>
                <Width>2.334584</Width>
                <Height>0.2583344</Height>
              </Size>
            </ObjectLayout>
          </TextObject>
          <TextObject>
            <Name>ITextObject1</Name>
            <Brushes>
              <BackgroundBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BackgroundBrush>
              <BorderBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BorderBrush>
              <StrokeBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </StrokeBrush>
              <FillBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </FillBrush>
            </Brushes>
            <Rotation>Rotation0</Rotation>
            <OutlineThickness>1</OutlineThickness>
            <IsOutlined>False</IsOutlined>
            <BorderStyle>SolidLine</BorderStyle>
            <Margin>
              <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
            </Margin>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <FitMode>None</FitMode>
            <IsVertical>False</IsVertical>
            <FormattedText>
              <FitMode>None</FitMode>
              <HorizontalAlignment>Center</HorizontalAlignment>
              <VerticalAlignment>Middle</VerticalAlignment>
              <IsVertical>False</IsVertical>
              <LineTextSpan>
                <TextSpan>
                  <Text>Model: </Text>
                  <FontInfo>
                    <FontName>Segoe UI</FontName>
                    <FontSize>10</FontSize>
                    <IsBold>False</IsBold>
                    <IsItalic>False</IsItalic>
                    <IsUnderline>False</IsUnderline>
                    <FontBrush>
                      <SolidColorBrush>
                        <Color A="1" R="0" G="0" B="0"></Color>
                      </SolidColorBrush>
                    </FontBrush>
                  </FontInfo>
                </TextSpan>
                <TextSpan>
                  <Text>${model}</Text>
                  <FontInfo>
                    <FontName>Segoe UI</FontName>
                    <FontSize>10</FontSize>
                    <IsBold>False</IsBold>
                    <IsItalic>False</IsItalic>
                    <IsUnderline>False</IsUnderline>
                    <FontBrush>
                      <SolidColorBrush>
                        <Color A="1" R="0" G="0" B="0"></Color>
                      </SolidColorBrush>
                    </FontBrush>
                  </FontInfo>
                </TextSpan>
              </LineTextSpan>
            </FormattedText>
            <ObjectLayout>
              <DYMOPoint>
                <X>1.126268</X>
                <Y>0.3035421</Y>
              </DYMOPoint>
              <Size>
                <Width>2.31375</Width>
                <Height>0.2358336</Height>
              </Size>
            </ObjectLayout>
          </TextObject>
          <TextObject>
            <Name>ITextObject2</Name>
            <Brushes>
              <BackgroundBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BackgroundBrush>
              <BorderBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BorderBrush>
              <StrokeBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </StrokeBrush>
              <FillBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </FillBrush>
            </Brushes>
            <Rotation>Rotation0</Rotation>
            <OutlineThickness>1</OutlineThickness>
            <IsOutlined>False</IsOutlined>
            <BorderStyle>SolidLine</BorderStyle>
            <Margin>
              <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
            </Margin>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <FitMode>None</FitMode>
            <IsVertical>False</IsVertical>
            <FormattedText>
              <FitMode>None</FitMode>
              <HorizontalAlignment>Center</HorizontalAlignment>
              <VerticalAlignment>Middle</VerticalAlignment>
              <IsVertical>False</IsVertical>
              <LineTextSpan>
                <TextSpan>
                  <Text>YOM: ${productionYear}</Text>
                  <FontInfo>
                    <FontName>Segoe UI</FontName>
                    <FontSize>10</FontSize>
                    <IsBold>False</IsBold>
                    <IsItalic>False</IsItalic>
                    <IsUnderline>False</IsUnderline>
                    <FontBrush>
                      <SolidColorBrush>
                        <Color A="1" R="0" G="0" B="0"></Color>
                      </SolidColorBrush>
                    </FontBrush>
                  </FontInfo>
                </TextSpan>
              </LineTextSpan>
            </FormattedText>
            <ObjectLayout>
              <DYMOPoint>
                <X>1.167505</X>
                <Y>0.5393758</Y>
              </DYMOPoint>
              <Size>
                <Width>2.272514</Width>
                <Height>0.2291664</Height>
              </Size>
            </ObjectLayout>
          </TextObject>
          <TextObject>
            <Name>ITextObject3</Name>
            <Brushes>
              <BackgroundBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BackgroundBrush>
              <BorderBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BorderBrush>
              <StrokeBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </StrokeBrush>
              <FillBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </FillBrush>
            </Brushes>
            <Rotation>Rotation0</Rotation>
            <OutlineThickness>1</OutlineThickness>
            <IsOutlined>False</IsOutlined>
            <BorderStyle>SolidLine</BorderStyle>
            <Margin>
              <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
            </Margin>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <FitMode>None</FitMode>
            <IsVertical>False</IsVertical>
            <FormattedText>
              <FitMode>None</FitMode>
              <HorizontalAlignment>Center</HorizontalAlignment>
              <VerticalAlignment>Middle</VerticalAlignment>
              <IsVertical>False</IsVertical>
              <LineTextSpan>
                <TextSpan>
                  <Text>SKU:</Text>
                  <FontInfo>
                    <FontName>Segoe UI</FontName>
                    <FontSize>10</FontSize>
                    <IsBold>False</IsBold>
                    <IsItalic>False</IsItalic>
                    <IsUnderline>False</IsUnderline>
                    <FontBrush>
                      <SolidColorBrush>
                        <Color A="1" R="0" G="0" B="0"></Color>
                      </SolidColorBrush>
                    </FontBrush>
                  </FontInfo>
                </TextSpan>
                <TextSpan>
                  <Text> </Text>
                  <FontInfo>
                    <FontName>Segoe UI</FontName>
                    <FontSize>10</FontSize>
                    <IsBold>False</IsBold>
                    <IsItalic>False</IsItalic>
                    <IsUnderline>False</IsUnderline>
                    <FontBrush>
                      <SolidColorBrush>
                        <Color A="1" R="0" G="0" B="0"></Color>
                      </SolidColorBrush>
                    </FontBrush>
                  </FontInfo>
                </TextSpan>
                <TextSpan>
                  <Text>${stockNumber}</Text>
                  <FontInfo>
                    <FontName>Segoe UI</FontName>
                    <FontSize>10</FontSize>
                    <IsBold>False</IsBold>
                    <IsItalic>False</IsItalic>
                    <IsUnderline>False</IsUnderline>
                    <FontBrush>
                      <SolidColorBrush>
                        <Color A="1" R="0" G="0" B="0"></Color>
                      </SolidColorBrush>
                    </FontBrush>
                  </FontInfo>
                </TextSpan>
              </LineTextSpan>
            </FormattedText>
            <ObjectLayout>
              <DYMOPoint>
                <X>1.136679</X>
                <Y>0.7637504</Y>
              </DYMOPoint>
              <Size>
                <Width>2.303356</Width>
                <Height>0.2339592</Height>
              </Size>
            </ObjectLayout>
          </TextObject>
        </LabelObjects>
      </DynamicLayoutManager>
    </DYMOLabel>
    <LabelApplication>Blank</LabelApplication>
    <DataTable>
      <Columns></Columns>
      <Rows></Rows>
    </DataTable>
  </DesktopLabel>`;
  return labelXml;
}
