//"x-ray-tube", "electronic-card","workstation","monitor","other"
export const partTypes = {
  "x-ray-tube": "X-Ray Tube",
  "electronic-card": "Electronic Card",
  workstation: "Workstation",
  monitor: "Monitor",
  other: "Other",
};

export const partTypeOptions = [
  { name: "X-Ray Tube", _id: "x-ray-tube" },
  { name: "Electronic Card", _id: "electronic-card" },
  { name: "Workstation", _id: "workstation" },
  { name: "Monitor", _id: "monitor" },
  { name: "Other", _id: "other" },
];
