import FormButton from "components/Buttons/FormButton";
import Input from "components/FormElements/Input";
import { useFormik } from "formik";
import CustomAlert from "lib/customAlert.js";
import React from "react";
import ModalHeader from "../ModalHeader";
import "./style.scss";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
import deviceLocationValidationSchema from "./deviceLocationValidationSchema";

function AddDeviceLocation({
  isModalOpen = false,
  setIsModalOpen,
  deviceLocationModalInfo = {
    _id: 0,
    title: "",
    description: "",
  },
  setDeviceLocationModalInfo,
  deviceLocations,
  setDeviceLocations,
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: deviceLocationModalInfo._id || 0,
      title: deviceLocationModalInfo.title,
      description: deviceLocationModalInfo.description,
    },
    validationSchema: deviceLocationValidationSchema,
    onSubmit: async (values) => {
      let formattedValues = {
        _id: values._id,
        title: values.title,
        description: values.description,
      };
      if (deviceLocationModalInfo.index + 1) {
        deviceLocations[deviceLocationModalInfo.index] = formattedValues;
        setDeviceLocations(deviceLocations);
      } else {
        deviceLocations.push(formattedValues);
        setDeviceLocations(deviceLocations);
      }
      setIsModalOpen(false);
      setDeviceLocationModalInfo({
        _id: 0,
        title: "",
        description: "",
      });
    },
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value);
  };
  return (
    <div
      className={`add-device-location-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal add-device-location-modal`}>
        <ModalHeader
          title={`${
            deviceLocationModalInfo.index + 1
              ? languageTexts[danisikLanguage].edit
              : languageTexts[danisikLanguage].add
          }`}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content">
          <form
            onSubmit={formik.handleSubmit}
            className="flow-content tab-device-form"
          >
            <Input
              label={languageTexts[danisikLanguage].title}
              value={formik.values.title}
              handleChange={handleChange}
              name="title"
              showError={formik.touched.title && Boolean(formik.errors.title)}
              error={formik.touched.title && formik.errors.title}
            />
            <Input
              label={languageTexts[danisikLanguage].description}
              value={formik.values.description}
              handleChange={handleChange}
              name="description"
              showError={
                formik.touched.description && Boolean(formik.errors.description)
              }
              error={formik.touched.description && formik.errors.description}
            />

            <FormButton title={languageTexts[danisikLanguage].save} />
          </form>
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
    </div>
  );
}

export default AddDeviceLocation;
