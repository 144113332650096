import FactoryService from "services/factoryService";

export default class PartStorageService extends FactoryService {
  constructor() {
    super("partStorage");
  }
  getPartStorageByPartId(partId){
    return this.api.get(`${this.serviceName}/getAllPartStorageWithPartId/${partId}`)
  }
}
