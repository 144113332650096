import FormButton from "components/Buttons/FormButton";
import Input from "components/FormElements/Input";
import { useFormik } from "formik";
import CustomAlert from "lib/customAlert.js";
import React, { useState } from "react";
import "./style.scss";
import addDamageValidationSchema from "./addDamageValidationSchema";
import DragAndDrop from "components/DragAndDrop";
import ModalHeader from "components/Modals/ModalHeader";
import DamageService from "services/product/device/damageService";
import { toast } from "react-toastify";
import LoadingPage from "components/LoadingPage";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";
import DragAndDropNew from "components/DragAndDropNew";

const damageService = new DamageService();
function AddDamage({ deviceId, isModalOpen, setIsModalOpen, getDamages }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      images: [],
      description: "",
    },
    validationSchema: addDamageValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        let formData = new FormData();
        formData.append("description", formik.values.description);
        formData.append("deviceId", deviceId);
        for (let i = 0; i < formik.values.images.length; i++) {
          formData.append("images", formik.values.images[i]);
        }
        setIsLoading(true);
        damageService
          .addDamage(formData)
          .then((res) => {
            toast.success(languageTexts[danisikLanguage].succesfullyAdded);
            getDamages();
            setIsModalOpen(false);
          })
          .finally(() => setIsLoading(false));
      }
    },
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value);
  };
  const handleUpdateFile = (images) => {
    let newImages = [...formik.values.images, ...images];
    formik.setFieldValue("images", newImages);
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <div
      className={`add-damage-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal add-damage-modal`}>
        <ModalHeader
          title={languageTexts[danisikLanguage].add}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content">
          <form
            onSubmit={formik.handleSubmit}
            className="flow-content tab-device-form"
          >
            <DragAndDropNew
              images={formik.values.images}
              setImages={(images) => {
                formik.setFieldValue("images", images);
              }}
            />
            <Input
              label={languageTexts[danisikLanguage].description}
              value={formik.values.description}
              handleChange={handleChange}
              name="description"
              showError={
                formik.touched.description && Boolean(formik.errors.description)
              }
              error={formik.touched.description && formik.errors.description}
            />
            <FormButton title={languageTexts[danisikLanguage].save} />
          </form>
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
    </div>
  );
}

export default AddDamage;
