import React from "react";
import "./style.scss";

function PreviewPDF({ pdfFile }) {
  return (
    <object data={pdfFile} type="application/pdf" className="pdf-preview">
      <p>Preview not supported. Please download the PDF.</p>
    </object>
  );
}
export default PreviewPDF;
