import React from "react";
import "./style.scss";
import Add from "assets/icons/Add";

function AddButton({ title = "Add", onClick, ...props }) {
  return (
    <button className="add-button" type="button" onClick={onClick} {...props}>
      <Add width="2rem" height="2rem" />
      {title}
    </button>
  );
}

export default AddButton;
