import React from "react";
import "./style.scss";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
function LoadingPage() {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  return (
    <div className="loading-page">
      <div className="loading-page-box">
        <div className="animated-circles">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="loading-text">
          <span>{languageTexts[danisikLanguage].loading}...</span>
        </div>
      </div>
      <div className="loading-page-overlay"></div>
    </div>
  );
}

export default LoadingPage;
