import React from "react";
import "./style.scss";
import Loader from "components/Loader";

function PrimaryButton({
  title = "",
  backgroundColor = "",
  onClick,
  loading = false,
  ...props
}) {
  return (
    <button
      className="primary-button"
      style={{ backgroundColor: backgroundColor }}
      onClick={onClick}
      disabled={loading}
      {...props}
    >
      {loading ? <Loader /> : title}
    </button>
  );
}

export default PrimaryButton;
