import FactoryService from "services/factoryService";

export default class PackagingService extends FactoryService {
  constructor() {
    super("packaging");
  }
  getAllPackagingWithDeviceId(deviceId) {
    return this.api.get(
      `/${this.serviceName}/getallPackagingWithDeviceId/${deviceId}`
    );
  }
}
