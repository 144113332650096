import ArrowRight from "assets/icons/ArrowRight";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import React, { useRef, useState } from "react";
import "./style.scss";
import BrandList from "components/Modals/Admin/Brand/BrandList";
import CategoryList from "components/Modals/Admin/CategoryModal/CategoryList";
import PackagingOptionsList from "components/Modals/Admin/PackagingOptionsModal/PackagingOptionsList";
import ModelList from "components/Modals/Admin/ModelModal/ModelList";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";

function AddAndEditFieldsItem() {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isBrandModalOpen, setIsBrandModalOpen] = useState(false);
  const [isModelModalOpen, setIsModelModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isPackagingOptionModalOpen, setIsPackagingOptionModalOpen] =
    useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, function () {
    setIsDropdownOpen(false);
  });
  const handleOpenModal = (setModal) => {
    setModal(true);
    setIsDropdownOpen(false);
  };
  return (
    <div className="setting-item-container" ref={wrapperRef}>
      <div className="setting-item" onClick={() => setIsDropdownOpen(true)}>
        <span>{languageTexts[danisikLanguage].addAndEditFields}</span>
        <ArrowRight />
      </div>
      <div className={`setting-item-dropdown ${isDropdownOpen ? "open" : ""}`}>
        <span
          className="dropdown-item"
          onClick={() => handleOpenModal(setIsBrandModalOpen)}
        >
          {languageTexts[danisikLanguage].brand}
        </span>
        <span
          className="dropdown-item"
          onClick={() => handleOpenModal(setIsModelModalOpen)}
        >
          {languageTexts[danisikLanguage].model}
        </span>
        <span
          className="dropdown-item"
          onClick={() => handleOpenModal(setIsCategoryModalOpen)}
        >
          {languageTexts[danisikLanguage].category}
        </span>
        <span
          className="dropdown-item"
          onClick={() => handleOpenModal(setIsPackagingOptionModalOpen)}
        >
          {languageTexts[danisikLanguage].packagingTypeList}
        </span>
      </div>
      <BrandList
        setIsModalOpen={setIsBrandModalOpen}
        isModalOpen={isBrandModalOpen}
      />
      <CategoryList
        setIsModalOpen={setIsCategoryModalOpen}
        isModalOpen={isCategoryModalOpen}
      />
      <PackagingOptionsList
        setIsModalOpen={setIsPackagingOptionModalOpen}
        isModalOpen={isPackagingOptionModalOpen}
      />
      <ModelList
        setIsModalOpen={setIsModelModalOpen}
        isModalOpen={isModelModalOpen}
      />
    </div>
  );
}

export default AddAndEditFieldsItem;
