import FactoryService from "services/factoryService";

export default class DashboardService extends FactoryService {
  constructor() {
    super("dashboard");
  }
  getTotalPrices() {
    return this.api.get(`${this.serviceName}/totalPrice`);
  }
  getTopModels() {
    return this.api.get(`${this.serviceName}/topfivemodel`);
  }
  getWeeklyModelDistribution() {
    return this.api.get(`${this.serviceName}/weeklyModelDistribution`);
  }
  getYearlyModelDistribution() {
    return this.api.get(`${this.serviceName}/yearlyModelDistribution`);
  }
  getMonthlyModelDistribution() {
    return this.api.get(`${this.serviceName}/monthlyModelDistribution`);
  }
  getUsersDistribution() {
    return this.api.get(`${this.serviceName}/usersDistribution`);
  }
  getECommercesSalesCount() {
    return this.api.get(`${this.serviceName}/ECommercesSalesCount`);
  }
}
