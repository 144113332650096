export function generateXmlDymoPart(
  partNumber,
  definition,
  stockNumber,
  link,
  serialNumber,
  productionYear
) {
  definition = escapedString(definition);
  var labelXml = `<?xml version="1.0" encoding="utf-8"?>
  <DesktopLabel Version="1">
    <DYMOLabel Version="3">
      <Description>DYMO Label</Description>
      <Orientation>Landscape</Orientation>
      <LabelName>Addresss0722370</LabelName>
      <InitialLength>0</InitialLength>
      <BorderStyle>SolidLine</BorderStyle>
      <DYMORect>
        <DYMOPoint>
          <X>0.23</X>
          <Y>0.06</Y>
        </DYMOPoint>
        <Size>
          <Width>3.21</Width>
          <Height>0.9966666</Height>
        </Size>
      </DYMORect>
      <BorderColor>
        <SolidColorBrush>
          <Color A="1" R="0" G="0" B="0"></Color>
        </SolidColorBrush>
      </BorderColor>
      <BorderThickness>1</BorderThickness>
      <Show_Border>False</Show_Border>
      <DynamicLayoutManager>
        <RotationBehavior>ClearObjects</RotationBehavior>
        <LabelObjects>
          <TextObject>
            <Name>ITextObject0</Name>
            <Brushes>
              <BackgroundBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BackgroundBrush>
              <BorderBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BorderBrush>
              <StrokeBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </StrokeBrush>
              <FillBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </FillBrush>
            </Brushes>
            <Rotation>Rotation0</Rotation>
            <OutlineThickness>1</OutlineThickness>
            <IsOutlined>False</IsOutlined>
            <BorderStyle>SolidLine</BorderStyle>
            <Margin>
              <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
            </Margin>
            <HorizontalAlignment>Left</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <FitMode>AlwaysFit</FitMode>
            <IsVertical>False</IsVertical>
            <FormattedText>
              <FitMode>AlwaysFit</FitMode>
              <HorizontalAlignment>Left</HorizontalAlignment>
              <VerticalAlignment>Middle</VerticalAlignment>
              <IsVertical>False</IsVertical>
              <LineTextSpan>
                <TextSpan>
                  <Text>${definition || ""}</Text>
                  <FontInfo>
                    <FontName>Segoe UI</FontName>
                    <FontSize>15.3</FontSize>
                    <IsBold>False</IsBold>
                    <IsItalic>False</IsItalic>
                    <IsUnderline>False</IsUnderline>
                    <FontBrush>
                      <SolidColorBrush>
                        <Color A="1" R="0" G="0" B="0"></Color>
                      </SolidColorBrush>
                    </FontBrush>
                  </FontInfo>
                </TextSpan>
              </LineTextSpan>
            </FormattedText>
            <ObjectLayout>
              <DYMOPoint>
                <X>0.23</X>
                <Y>0.3696908</Y>
              </DYMOPoint>
              <Size>
                <Width>2.372739</Width>
                <Height>0.4861462</Height>
              </Size>
            </ObjectLayout>
          </TextObject>
          <TextObject>
            <Name>ITextObject3</Name>
            <Brushes>
              <BackgroundBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BackgroundBrush>
              <BorderBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BorderBrush>
              <StrokeBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </StrokeBrush>
              <FillBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </FillBrush>
            </Brushes>
            <Rotation>Rotation0</Rotation>
            <OutlineThickness>1</OutlineThickness>
            <IsOutlined>False</IsOutlined>
            <BorderStyle>SolidLine</BorderStyle>
            <Margin>
              <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
            </Margin>
            <HorizontalAlignment>Left</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <FitMode>AlwaysFit</FitMode>
            <IsVertical>False</IsVertical>
            <FormattedText>
              <FitMode>AlwaysFit</FitMode>
              <HorizontalAlignment>Left</HorizontalAlignment>
              <VerticalAlignment>Middle</VerticalAlignment>
              <IsVertical>False</IsVertical>
              <LineTextSpan>
                <TextSpan>
                  <Text>SKU: ${stockNumber || ""}</Text>
                  <FontInfo>
                    <FontName>Segoe UI</FontName>
                    <FontSize>10.2</FontSize>
                    <IsBold>False</IsBold>
                    <IsItalic>False</IsItalic>
                    <IsUnderline>False</IsUnderline>
                    <FontBrush>
                      <SolidColorBrush>
                        <Color A="1" R="0" G="0" B="0"></Color>
                      </SolidColorBrush>
                    </FontBrush>
                  </FontInfo>
                </TextSpan>
              </LineTextSpan>
            </FormattedText>
            <ObjectLayout>
              <DYMOPoint>
                <X>0.24</X>
                <Y>0.8627096</Y>
              </DYMOPoint>
              <Size>
                <Width>1.877914</Width>
                <Height>0.1931241</Height>
              </Size>
            </ObjectLayout>
          </TextObject>
          <TextObject>
            <Name>ITextObject4</Name>
            <Brushes>
              <BackgroundBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BackgroundBrush>
              <BorderBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BorderBrush>
              <StrokeBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </StrokeBrush>
              <FillBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </FillBrush>
            </Brushes>
            <Rotation>Rotation0</Rotation>
            <OutlineThickness>1</OutlineThickness>
            <IsOutlined>False</IsOutlined>
            <BorderStyle>SolidLine</BorderStyle>
            <Margin>
              <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
            </Margin>
            <HorizontalAlignment>Left</HorizontalAlignment>
            <VerticalAlignment>Top</VerticalAlignment>
            <FitMode>AlwaysFit</FitMode>
            <IsVertical>False</IsVertical>
            <FormattedText>
              <FitMode>AlwaysFit</FitMode>
              <HorizontalAlignment>Left</HorizontalAlignment>
              <VerticalAlignment>Top</VerticalAlignment>
              <IsVertical>False</IsVertical>
              <LineTextSpan>
                <TextSpan>
                  <Text>Part Number: ${partNumber || ""}</Text>
                  <FontInfo>
                    <FontName>Segoe UI</FontName>
                    <FontSize>8.4</FontSize>
                    <IsBold>False</IsBold>
                    <IsItalic>False</IsItalic>
                    <IsUnderline>False</IsUnderline>
                    <FontBrush>
                      <SolidColorBrush>
                        <Color A="1" R="0" G="0" B="0"></Color>
                      </SolidColorBrush>
                    </FontBrush>
                  </FontInfo>
                </TextSpan>
              </LineTextSpan>
            </FormattedText>
            <ObjectLayout>
              <DYMOPoint>
                <X>0.24</X>
                <Y>0.06000004</Y>
              </DYMOPoint>
              <Size>
                <Width>2.313751</Width>
                <Height>0.1568789</Height>
              </Size>
            </ObjectLayout>
          </TextObject>
          <QRCodeObject>
            <Name>IQRCodeObject0</Name>
            <Brushes>
              <BackgroundBrush>
                <SolidColorBrush>
                  <Color A="1" R="1" G="1" B="1"></Color>
                </SolidColorBrush>
              </BackgroundBrush>
              <BorderBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BorderBrush>
              <StrokeBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </StrokeBrush>
              <FillBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </FillBrush>
            </Brushes>
            <Rotation>Rotation0</Rotation>
            <OutlineThickness>1</OutlineThickness>
            <IsOutlined>False</IsOutlined>
            <BorderStyle>SolidLine</BorderStyle>
            <Margin>
              <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
            </Margin>
            <BarcodeFormat>QRCode</BarcodeFormat>
            <Data>
              <DataString>${link || "https://resaledesk.de"}</DataString>
            </Data>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <Size>AutoFit</Size>
            <EQRCodeType>QRCodeText</EQRCodeType>
            <TextDataHolder>
              <Value>${link || "https://resaledesk.de"}</Value>
            </TextDataHolder>
            <ObjectLayout>
              <DYMOPoint>
                <X>2.553751</X>
                <Y>0.1350005</Y>
              </DYMOPoint>
              <Size>
                <Width>0.8862521</Width>
                <Height>0.6233329</Height>
              </Size>
            </ObjectLayout>
          </QRCodeObject>
          <TextObject>
            <Name>ITextObject44</Name>
            <Brushes>
              <BackgroundBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BackgroundBrush>
              <BorderBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BorderBrush>
              <StrokeBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </StrokeBrush>
              <FillBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </FillBrush>
            </Brushes>
            <Rotation>Rotation0</Rotation>
            <OutlineThickness>1</OutlineThickness>
            <IsOutlined>False</IsOutlined>
            <BorderStyle>SolidLine</BorderStyle>
            <Margin>
              <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
            </Margin>
            <HorizontalAlignment>Left</HorizontalAlignment>
            <VerticalAlignment>Top</VerticalAlignment>
            <FitMode>AlwaysFit</FitMode>
            <IsVertical>False</IsVertical>
            <FormattedText>
              <FitMode>AlwaysFit</FitMode>
              <HorizontalAlignment>Left</HorizontalAlignment>
              <VerticalAlignment>Top</VerticalAlignment>
              <IsVertical>False</IsVertical>
              <LineTextSpan>
                <TextSpan>
                  <Text>Serial number: ${serialNumber || ""}</Text>
                  <FontInfo>
                    <FontName>Segoe UI</FontName>
                    <FontSize>8.3</FontSize>
                    <IsBold>False</IsBold>
                    <IsItalic>False</IsItalic>
                    <IsUnderline>False</IsUnderline>
                    <FontBrush>
                      <SolidColorBrush>
                        <Color A="1" R="0" G="0" B="0"></Color>
                      </SolidColorBrush>
                    </FontBrush>
                  </FontInfo>
                </TextSpan>
              </LineTextSpan>
            </FormattedText>
            <ObjectLayout>
              <DYMOPoint>
                <X>0.23</X>
                <Y>0.2481291</Y>
              </DYMOPoint>
              <Size>
                <Width>2.323547</Width>
                <Height>0.1545838</Height>
              </Size>
            </ObjectLayout>
          </TextObject>
          <TextObject>
            <Name>ITextObject45</Name>
            <Brushes>
              <BackgroundBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BackgroundBrush>
              <BorderBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BorderBrush>
              <StrokeBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </StrokeBrush>
              <FillBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </FillBrush>
            </Brushes>
            <Rotation>Rotation0</Rotation>
            <OutlineThickness>1</OutlineThickness>
            <IsOutlined>False</IsOutlined>
            <BorderStyle>SolidLine</BorderStyle>
            <Margin>
              <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
            </Margin>
            <HorizontalAlignment>Left</HorizontalAlignment>
            <VerticalAlignment>Top</VerticalAlignment>
            <FitMode>AlwaysFit</FitMode>
            <IsVertical>False</IsVertical>
            <FormattedText>
              <FitMode>AlwaysFit</FitMode>
              <HorizontalAlignment>Left</HorizontalAlignment>
              <VerticalAlignment>Top</VerticalAlignment>
              <IsVertical>False</IsVertical>
              <LineTextSpan>
                <TextSpan>
                  <Text>YOM: ${productionYear || ""}</Text>
                  <FontInfo>
                    <FontName>Segoe UI</FontName>
                    <FontSize>9.5</FontSize>
                    <IsBold>False</IsBold>
                    <IsItalic>False</IsItalic>
                    <IsUnderline>False</IsUnderline>
                    <FontBrush>
                      <SolidColorBrush>
                        <Color A="1" R="0" G="0" B="0"></Color>
                      </SolidColorBrush>
                    </FontBrush>
                  </FontInfo>
                </TextSpan>
              </LineTextSpan>
            </FormattedText>
            <ObjectLayout>
              <DYMOPoint>
                <X>2.659599</X>
                <Y>0.8662537</Y>
              </DYMOPoint>
              <Size>
                <Width>0.7804238</Width>
                <Height>0.1808318</Height>
              </Size>
            </ObjectLayout>
          </TextObject>
        </LabelObjects>
      </DynamicLayoutManager>
    </DYMOLabel>
    <LabelApplication>Blank</LabelApplication>
    <DataTable>
      <Columns></Columns>
      <Rows></Rows>
    </DataTable>
  </DesktopLabel>`;
  return labelXml;
}

function escapedString(str) {
  return str.replace(/[&<>"']/g, function (match) {
    switch (match) {
      case "&":
        return "&amp;";
      case "<":
        return "&lt;";
      case ">":
        return "&gt;";
      case '"':
        return "&quot;";
      case "'":
        return "&apos;";
    }
  });
}
