import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import ModalHeader from "components/Modals/ModalHeader";
import SearchInput from "components/Search/SearchInput";
import Add from "assets/icons/Add";
import EditPencil from "assets/icons/EditPencil";
import ModelAdd from "../ModelAdd";
import ModelService from "services/admin/modelService";
import LoadingPage from "components/LoadingPage";
import { debounce } from "utils/debounce";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";

const modelLimit = 15;
function ModelList({ isModalOpen = false, setIsModalOpen }) {
  const user = useSelector((state) => state.danisikUser?.user);
  const modelInformation = useSelector((state) => state.danisikModels);
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [addModelModalOpen, setAddModelModalOpen] = useState(false);
  const [addModelModalInfo, setAddModelModalInfo] = useState({
    _id: 0,
    name: "",
    brandId: {
      _id: "",
      name: "",
    },
  });
  const [models, setModels] = useState([]);
  const [modelCurrentPage, setModelCurrentPage] = useState(1);
  const [modelSearchText, setModelSearchText] = useState("");
  const [modelSearchTextForSearch, setModelSearchTextForSearch] = useState("");

  let modelPageCount = modelInformation.modelsNotFormatted.length / modelLimit;

  useEffect(() => {
    setModels(modelInformation.modelsNotFormatted.slice(0, modelLimit));
    setModelCurrentPage(1);
  }, [modelInformation.modelsNotFormatted]);

  const handleScrollBottomModel = () => {
    if (modelPageCount > modelCurrentPage) {
      setModelCurrentPage(modelCurrentPage + 1);
    }
  };
  const handleScrollDropdown = (e) => {
    const isScrollAtTheBottom =
      e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 1;
    if (isScrollAtTheBottom) {
      handleScrollBottomModel();
    }
  };

  useEffect(() => {
    if (modelCurrentPage > 1) {
      setModels(
        modelInformation.modelsNotFormatted.slice(
          0,
          modelCurrentPage * modelLimit
        )
      );
    }
  }, [modelCurrentPage]);
  useEffect(() => {
    if (
      isModalOpen &&
      user &&
      (user?.role === "admin" || user?.role === "store")
    ) {
      let newModels = modelInformation.modelsNotFormatted
        .filter((model) =>
          model.name
            .toLowerCase()
            .includes(modelSearchTextForSearch.toLowerCase())
        )
        .slice(0, modelLimit);
      setModels(newModels);
      setModelCurrentPage(1);
    }
  }, [modelSearchTextForSearch]);

  const handleChangeFilterInput = (e) => {
    setModelSearchTextForSearch(e);
  };

  useEffect(() => {
    handleChangeFilterInput(modelSearchText);
  }, [modelSearchText]);

  return (
    <div
      className={`model-list-modal-container modal-container settings-modals ${
        isModalOpen ? "open" : ""
      }`}
    >
      (
      <>
        <div className={`modal model-list-modal`}>
          <ModalHeader
            title={languageTexts[danisikLanguage].model}
            setIsModalOpen={setIsModalOpen}
          />
          <div className="modal-content settings-modal-content">
            <div className="modal-body-header">
              <div className="search-input-container">
                <SearchInput
                  onChange={(e) => setModelSearchText(e.target.value)}
                  value={modelSearchText}
                />
              </div>
              <div
                className="add-button-container"
                onClick={() => {
                  setAddModelModalInfo({
                    name: "",
                    _id: 0,
                    brandId: {
                      _id: "",
                      name: "",
                    },
                  });
                  setAddModelModalOpen(true);
                }}
              >
                <Add />
              </div>
            </div>
            <div
              className="settings-modal-body"
              onScroll={handleScrollDropdown}
            >
              <div className="item-container">
                {models.map((model) => (
                  <div className="item" key={model._id}>
                    <span>{model.name}</span>
                    <div
                      className="edit-icon-container"
                      onClick={() => {
                        setAddModelModalInfo({
                          name: model.name,
                          _id: model._id,
                          brandId: model.brandId,
                        });
                        setAddModelModalOpen(true);
                      }}
                    >
                      <EditPencil />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className="modal-overlay"
            onClick={() => setIsModalOpen(false)}
          ></div>
        </div>
        <ModelAdd
          setIsModalOpen={setAddModelModalOpen}
          isModalOpen={addModelModalOpen}
          addModelModalInfo={addModelModalInfo}
        />
      </>
      )
    </div>
  );
}

export default ModelList;
