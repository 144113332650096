import React from "react";

function Pound({ width = "2rem", height = "2rem", fill = "none" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM7 11V13H6V15H14V13H9V11H12V9H9V8C8.99976 7.67611 9.10436 7.36084 9.29816 7.10133C9.49197 6.84182 9.76457 6.65201 10.0752 6.56029C10.3858 6.46856 10.7178 6.47984 11.0215 6.59245C11.3252 6.70505 11.5843 6.91293 11.76 7.185L13.746 6.689C13.4412 5.9363 12.8842 5.31297 12.1703 4.92576C11.4565 4.53854 10.6303 4.41152 9.83312 4.56644C9.03595 4.72136 8.31743 5.14858 7.80059 5.77496C7.28374 6.40134 7.00073 7.18792 7 8V9H6V11H7Z"
        fill="#DEDEDE"
      />
    </svg>
  );
}

export default Pound;
