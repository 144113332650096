export const isValidNumber = (num) => {
  return (
    !isNaN(num) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(num))
  ); // ...and ensure strings of whitespace fail
};
export const currencyFormat = (num) => {
  if (isValidNumber(num)) {
    return num
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      .replace(/,/g, "#")
      .replace(/\./g, ",")
      .replace(/#/g, ".");
  }
  return null;
};
