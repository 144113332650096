import React, { useEffect, useState } from "react";
import "./style.scss";
import SelectOption from "components/FormElements/SelectOption";
import CategoryService from "services/admin/category";
import { useSelector } from "react-redux";

const categoryService = new CategoryService();
function DotmedCategoryOptions({
  label = "*Category",
  name = "category",
  handleChange = () => {},
  value = "",
  showError,
  error,
  isModalOpen = true,
}) {
  const user = useSelector((state) => state.danisikUser?.user);
  const [categories, setCategories] = useState([]);
  const [categoryCurrentPage, setCategoryCurrentPage] = useState(1);
  const [categoryPageCount, setCategoryPageCount] = useState(1);
  const [categorySearchText, setCategorySearchText] = useState("");

  const handleScrollBottom = () => {
    if (categoryPageCount > categoryCurrentPage) {
      setCategoryCurrentPage(categoryCurrentPage + 1);
    }
  };
  useEffect(() => {
    if (
      isModalOpen &&
      user &&
      (user?.role === "admin" || user?.role === "store")
    ) {
      categoryService
        .getDotmedCategory(`?limit=15&sort=name&page=${categoryCurrentPage}`)
        .then((res) => {
          setCategories(res.data.data);
          setCategoryPageCount(res.data.totalPage ? res.data.totalPage : 1);
        });
    }
  }, [isModalOpen]);
  useEffect(() => {
    if (categoryCurrentPage > 1) {
      categoryService
        .getDotmedCategory(`?limit=15&sort=name&page=${categoryCurrentPage}`)
        .then((res) => {
          let newCategories = [...categories, ...res.data.data];
          setCategories(newCategories);
        });
    }
  }, [categoryCurrentPage]);
  useEffect(() => {
    if (
      isModalOpen &&
      user &&
      (user?.role === "admin" || user?.role === "store")
    ) {
      categoryService
        .getDotmedCategory(`?limit=15&sort=name&search=${categorySearchText}`)
        .then((res) => {
          setCategories(res.data.data);
          setCategoryCurrentPage(1);
        });
    }
  }, [categorySearchText, isModalOpen]);

  const handleChangeFilterCategoryInput = (val) => {
    setCategorySearchText(val);
  };
  return (
    <SelectOption
      options={categories}
      label={label}
      name={name}
      handleChange={handleChange}
      value={value}
      showError={showError}
      error={error}
      handleScrollBottom={handleScrollBottom}
      filterInput={categorySearchText}
      handleChangeFilterInput={handleChangeFilterCategoryInput}
      disabled={!(user?.role === "admin" || user?.role === "store")}
    />
  );
}

export default DotmedCategoryOptions;
