import GraphicalInfos from "components/Dashboard/GraphicalInfos";
import NumericStatistics from "components/Dashboard/NumericStatistics";
import RecentOperation from "components/Dashboard/RecentOperation";
import React from "react";

function Dashboard() {
  return (
    <div className="flow-content">
      <NumericStatistics />
      <RecentOperation />
      <GraphicalInfos />
    </div>
  );
}

export default Dashboard;
