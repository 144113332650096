import { CategoryActionTypes } from "./categoryActionTypes";

const INITIAL_STATE = {
  categories: [],
  categoryLoading: false,
  categoryError: null,
};

const categoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CategoryActionTypes.SET_CATEGORIES:
      return {
        ...state,
        categoryLoading: true,
      };
    case CategoryActionTypes.SET_CATEGORIES_SUCCESSFUL:
      return {
        ...state,
        categories: action.payload,
        categoryLoading: false,
      };
    case CategoryActionTypes.SET_CATEGORIES_ERROR:
      return {
        ...state,
        categoryError: action.payload,
        categoryLoading: false,
      };
    default:
      return state;
  }
};

export default categoryReducer;
