import Device from "assets/icons/Device";
import Euro from "assets/icons/Euro";
import HomeGear from "assets/icons/HomeGear";
import Keyboard from "assets/icons/Keyboard";
import MoreVertical from "assets/icons/MoreVertical";
import Packaging from "assets/icons/Packaging";
import Tools from "assets/icons/Tools";
import React, { useCallback, useRef, useState } from "react";
import "./style.scss";
import * as deviceTabs from "constants/deviceTabs";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import ArrowRight2 from "assets/icons/ArrowRight2";
import DeviceInfoPdf from "../../DeviceInfoPdf";
import DeviceService from "services/product/device/deviceService";
import { toast } from "react-toastify";
import LoadingPage from "components/LoadingPage";
import { useEffect } from "react";
import languageTexts from "constants/languageTexts";
import { useDispatch, useSelector } from "react-redux";
import { truncate } from "utils/stringFunctions";
import CustomAlert from "lib/customAlert";
import DeviceDymo from "components/Dymo/DeviceDymo";
import { Link } from "react-router-dom";
import { getDevices } from "redux/product/device/deviceActions";

let mouseDown = false;
let startX, scrollLeft;
const deviceService = new DeviceService();
function Tab({
  setActiveTab,
  activeTab,
  deviceId,
  deviceActiveStatus,
  setIsModalOpen,
  isModalOpen,
}) {
  const dispatch = useDispatch();
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [isLoading, setIsLoading] = useState(false);
  const tabContainerRef = useRef(null);
  const [moreOptionsVisible, setMoreOptionsVisible] = useState(false);
  const [pdfOptionsVisible, setPdfOptionsVisible] = useState(false);

  const [pdfInfoDownloadModalOpen, setPdfInfoDownloadModalOpen] =
    useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const handleCloseOptions = useCallback(() => {
    setMoreOptionsVisible(false);
    setPdfOptionsVisible(false);
  }, []);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, handleCloseOptions);

  const [completionStatusInfo, setCompletionStatusInfo] = useState(null);
  useEffect(() => {
    deviceService.completionStatus(deviceId).then((res) => {
      setCompletionStatusInfo(res.data?.data[0]);
    });
  }, [deviceId]);

  let startDragging = function (e) {
    tabContainerRef.current.style.cursor = "grabbing";
    mouseDown = true;
    startX = e.pageX - tabContainerRef.current.offsetLeft;
    scrollLeft = tabContainerRef.current.scrollLeft;
    setIsDragging(true);
  };
  let stopDragging = function () {
    mouseDown = false;
    tabContainerRef.current.style.cursor = "grab";
    setIsDragging(false);
  };
  const handleMouseMoveImgContainer = (e) => {
    e.preventDefault();
    if (!mouseDown) {
      return;
    }
    const x = e.pageX - tabContainerRef.current.offsetLeft;
    const scroll = x - startX;
    tabContainerRef.current.scrollLeft = scrollLeft - scroll;
  };
  const handleActiveTab = (tab) => {
    if (!isDragging) {
      setActiveTab(tab);
    }
  };

  const handleDeviceInfoPdf = () => {
    setPdfOptionsVisible(false);
    setPdfInfoDownloadModalOpen(true);
  };

  const handleChangeActiveStatus = () => {
    setIsLoading(true);
    deviceService
      .changeActiveStatus(deviceId)
      .then(() => {
        toast.success(languageTexts[danisikLanguage].operationSuccessful);
        dispatch(getDevices());
      })
      .finally(() => setIsLoading(false));
  };
  const handleDeleteDevice = async () => {
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].OK
    );
    if (result) {
      setIsLoading(true);
      deviceService
        .delete(deviceId)
        .then(() => {
          toast.success(languageTexts[danisikLanguage].succesfullyDeleted);
          dispatch(getDevices());
          setIsModalOpen(false);
        })
        .finally(() => setIsLoading(false));
    }
  };
  return !isModalOpen ? null : isLoading ? (
    <LoadingPage />
  ) : (
    <div className="device-modal-tab">
      <div
        className="tab-items"
        ref={tabContainerRef}
        onMouseMove={handleMouseMoveImgContainer}
        onMouseDown={startDragging}
        onMouseUp={stopDragging}
        onMouseLeave={stopDragging}
      >
        <div
          className={`tab-item ${
            !completionStatusInfo?.device ? "not-completed" : ""
          } ${activeTab === deviceTabs.DEVICE ? "active" : ""}`}
          onClick={() => handleActiveTab(deviceTabs.DEVICE)}
        >
          <div className="svg-icon-container">
            <Device />
          </div>

          <span title={languageTexts[danisikLanguage].deviceInfos}>
            {truncate(languageTexts[danisikLanguage].deviceInfos, 10)}
          </span>
        </div>
        <div
          className={`tab-item ${
            !completionStatusInfo?.buy ? "not-completed" : ""
          } ${activeTab === deviceTabs.PURCHASE ? "active" : ""}`}
          onClick={() => setActiveTab(deviceTabs.PURCHASE)}
        >
          <div className="svg-icon-container">
            <Euro />
          </div>

          <span title={languageTexts[danisikLanguage].buy}>
            {truncate(languageTexts[danisikLanguage].buy, 10)}
          </span>
        </div>
        <div
          className={`tab-item ${
            activeTab === deviceTabs.ACCESSORIES ? "active" : ""
          }`}
          onClick={() => handleActiveTab(deviceTabs.ACCESSORIES)}
        >
          <div className="svg-icon-container">
            <Keyboard />
          </div>

          <span title={"Accessories"}>{truncate("Accessories", 10)}</span>
        </div>
        <div
          className={`tab-item ${
            !completionStatusInfo?.storage ? "not-completed" : ""
          } ${activeTab === deviceTabs.STORAGE ? "active" : ""}`}
          onClick={() => handleActiveTab(deviceTabs.STORAGE)}
        >
          <div className="svg-icon-container">
            <HomeGear />
          </div>

          <span>{truncate(languageTexts[danisikLanguage].storeInfo, 10)}</span>
        </div>
        <div
          className={`tab-item ${
            activeTab === deviceTabs.PACKAGING ? "active" : ""
          }`}
          onClick={() => handleActiveTab(deviceTabs.PACKAGING)}
        >
          <div className="svg-icon-container">
            <Packaging />
          </div>

          <span title={languageTexts[danisikLanguage].packaging}>
            {truncate(languageTexts[danisikLanguage].packaging, 10)}
          </span>
        </div>
        <div
          className={`tab-item ${
            activeTab === deviceTabs.DAMAGE ? "active" : ""
          }`}
          onClick={() => handleActiveTab(deviceTabs.DAMAGE)}
        >
          <div className="svg-icon-container">
            <Tools />
          </div>

          <span title={languageTexts[danisikLanguage].damage}>
            {truncate(languageTexts[danisikLanguage].damage, 10)}
          </span>
        </div>
        <div
          className={`tab-item ${
            activeTab === deviceTabs.ECOMMERCE ? "active" : ""
          }`}
          onClick={() => handleActiveTab(deviceTabs.ECOMMERCE)}
        >
          <div className="svg-icon-container">
            <Euro />
          </div>

          <span title="E-Commerce">{truncate("E-Commerce", 10)} </span>
        </div>
      </div>
      <div className="more-options">
        <div className="more-icon-contanier" ref={wrapperRef}>
          <MoreVertical
            onClick={() => {
              setPdfOptionsVisible(false);
              setMoreOptionsVisible(true);
            }}
          />
          <div className={`options ${moreOptionsVisible ? "visible" : ""}`}>
            <span onClick={handleChangeActiveStatus}>
              {deviceActiveStatus !== null
                ? deviceActiveStatus
                  ? languageTexts[danisikLanguage].makeInactive
                  : languageTexts[danisikLanguage].makeActive
                : languageTexts[danisikLanguage].toggleActive}
            </span>
            <Link
              to={`/product-detail/device/${deviceId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {languageTexts[danisikLanguage].deviceAd}
            </Link>
            <span
              className="flex-between"
              style={{ width: "100%" }}
              onClick={() => {
                setPdfOptionsVisible(true);
                setMoreOptionsVisible(false);
              }}
            >
              PDF
              <ArrowRight2 />
            </span>
            <DeviceDymo deviceId={deviceId} />
            <span className="red-text" onClick={handleDeleteDevice}>
              {languageTexts[danisikLanguage].delete}
            </span>
          </div>
          <div className={`options ${pdfOptionsVisible ? "visible" : ""}`}>
            <span onClick={handleDeviceInfoPdf}>
              {languageTexts[danisikLanguage].deviceInfoPDF}
            </span>
          </div>
        </div>
      </div>
      <DeviceInfoPdf
        setIsModalOpen={setPdfInfoDownloadModalOpen}
        isModalOpen={pdfInfoDownloadModalOpen}
        deviceId={deviceId}
      />
    </div>
  );
}

export default Tab;
