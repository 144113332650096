import React from "react";

function ArrowRightCircle({
  fill = "var(--clr-neutral-200)",
  width = "3.1rem",
  height = "3.1rem",
  ...props
}) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 31 31"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.5003 14.0416V9.66663L21.3337 15.5L15.5003 21.3333V16.9583H9.66699V14.0416H15.5003ZM15.5003 0.916626C23.5503 0.916626 30.0837 7.44996 30.0837 15.5C30.0837 23.55 23.5503 30.0833 15.5003 30.0833C7.45033 30.0833 0.916992 23.55 0.916992 15.5C0.916992 7.44996 7.45033 0.916626 15.5003 0.916626ZM15.5003 27.1666C21.9462 27.1666 27.167 21.9458 27.167 15.5C27.167 9.05413 21.9462 3.83329 15.5003 3.83329C9.05449 3.83329 3.83366 9.05413 3.83366 15.5C3.83366 21.9458 9.05449 27.1666 15.5003 27.1666Z" />
    </svg>
  );
}

export default ArrowRightCircle;
