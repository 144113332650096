import React, { useEffect, useState } from "react";
import ModalHeader from "../ModalHeader";
import "./style.scss";
import RecentOperationService from "services/admin/recentOperationService";
import LoadingPage from "components/LoadingPage";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";

const recentOperationService = new RecentOperationService();
function Notifications({ isModalOpen = false, setIsModalOpen }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (isModalOpen) {
      setIsLoading(true);
      recentOperationService
        .getNotifications()
        .then((res) => {
          setNotifications(res.data.data.recentOperations);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isModalOpen]);

  useEffect(() => {
    let body = document.querySelector("body");
    body.classList.add("open-scroll");
    body.classList.add("open-scroll");
    if (isModalOpen) {
      body.classList.add("open-scroll");
    } else {
      body.classList.remove("open-scroll");
    }
  }, [isModalOpen]);

  return isLoading ? (
    <LoadingPage />
  ) : (
    <div
      className={`notifications-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal notifications-modal`}>
        <ModalHeader
          title={languageTexts[danisikLanguage].notifications}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content">
          {notifications.map((notification, index) => (
            <div
              className={`notification-content ${
                !notification.isRead ? "not-read" : ""
              }`}
              key={index}
            >
              <h5 className="notification-title">{notification.title}</h5>
              <span className="notification-text">
                {notification.description}
              </span>
            </div>
          ))}
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
    </div>
  );
}

export default Notifications;
