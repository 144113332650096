import React, { useEffect, useState } from "react";
import "./style.scss";
import ModalHeader from "../ModalHeader";
import Tabs from "./Tabs/TabHeader";
import DeviceTab from "./Tabs/Device";
import PurchaseTab from "./Tabs/Purchase";
import AccessoriesTab from "./Tabs/Accessories";
import StorageTab from "./Tabs/Storage";
import PackagingTab from "./Tabs/Packaging";
import DamageTab from "./Tabs/Damage";
import ECommerceTab from "./Tabs/ECommerce";
import * as deviceTabs from "constants/deviceTabs";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import DeviceService from "services/product/device/deviceService";
import languageTexts from "constants/languageTexts";

function Device({
  isModalOpen = false,
  setIsModalOpen,
  deviceId,
  setDeviceId,
  deviceName,
  deviceActiveStatus,
  stockNumber,
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [deviceNameForLabel, setDeviceNameForLabel] = useState("");
  const [stockNumberForLabel, setStockNumberForLabel] = useState("");
  const user = useSelector((state) => state.danisikUser?.user);
  const getDevice = useCallback(() => {
    let deviceService = new DeviceService();
    deviceService.getById(deviceId).then((res) => {
      const deviceInfos = res.data.data;
      setDeviceNameForLabel(
        deviceInfos.brandId?.name + deviceInfos.modelId?.name
      );
      setStockNumberForLabel(deviceInfos.stockNumber);
    });
  }, [deviceId]);
  const hasPermissionToEdit =
    user &&
    (user.role === "admin" || user.role === "buyer" || user.role === "store");
  const tabs = {
    [deviceTabs.PURCHASE]: <PurchaseTab deviceId={deviceId} />,
    [deviceTabs.DEVICE]: <DeviceTab deviceId={deviceId} />,
    [deviceTabs.ACCESSORIES]: <AccessoriesTab deviceId={deviceId} />,
    [deviceTabs.STORAGE]: <StorageTab deviceId={deviceId} />,
    [deviceTabs.PACKAGING]: <PackagingTab deviceId={deviceId} />,
    [deviceTabs.DAMAGE]: <DamageTab deviceId={deviceId} />,
    [deviceTabs.ECOMMERCE]: <ECommerceTab deviceId={deviceId} />,
  };
  const [activeTab, setActiveTab] = useState(deviceTabs.DEVICE);

  const handleCloseModal = () => {
    // setTimeout(() => {
    //   setDeviceId(0);
    // }, 300);
    setIsModalOpen(false);
  };

  useEffect(() => {
    getDevice();
  }, [getDevice, user]);

  useEffect(() => {
    let body = document.querySelector("body");
    body.classList.add("open-scroll");
    body.classList.add("open-scroll");
    if (isModalOpen) {
      body.classList.add("open-scroll");
    } else {
      body.classList.remove("open-scroll");
    }
  }, [isModalOpen]);

  return (
    <div
      className={`device-modal-container modal-container ${
        isModalOpen ? "open" : ""
      } ${!hasPermissionToEdit ? "pointer-events-none" : ""}`}
    >
      <div className={`modal device-modal `}>
        <ModalHeader
          title={deviceName || deviceNameForLabel}
          setIsModalOpen={setIsModalOpen}
          subTitle={`${languageTexts[danisikLanguage].stockNumber}: ${
            stockNumber || stockNumberForLabel
          }`}
        />
        <div className="modal-content no-padding">
          <Tabs
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            deviceId={deviceId}
            deviceActiveStatus={deviceActiveStatus}
          />
          {tabs[activeTab]}
        </div>
        <div className="modal-overlay" onClick={handleCloseModal}></div>
      </div>
    </div>
  );
}

export default Device;
