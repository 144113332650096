import FormButton from "components/Buttons/FormButton";
import Input from "components/FormElements/Input";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import ModalHeader from "../ModalHeader";
import "./style.scss";
import BrandModelOptions from "components/BrandModelOptions";
import CategoryOptions from "components/CategoryOptions";
import languageTexts from "constants/languageTexts";
import { useDispatch, useSelector } from "react-redux";
import DotmedCategoryOptions from "components/DotmedCategoryOptions";
import BrandModelOptionsNew from "components/BrandModelOptionsNew";
import DotmedCategoryOptionsNew from "components/DotmedCategoryOptionsNew";

function Filter({
  isModalOpen = false,
  setIsModalOpen,
  setFilterOptions,
  filterOptions,
}) {
  const dispatch = useDispatch();
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      brand: {
        name: filterOptions.brand.name,
        _id: filterOptions.brand._id,
      },
      model: {
        name: filterOptions.model.name,
        _id: filterOptions.model._id,
      },
      category: {
        name: filterOptions.category.name,
        _id: filterOptions.category._id,
      },
      productionYear: "",
    },
    onSubmit: (values) => {
      dispatch(setFilterOptions(values));
    },
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    formik.setFieldValue(name, value);
  };
  useEffect(() => {
    setIsModalOpen(false);
  }, [filterOptions, setIsModalOpen]);

  return (
    <div
      className={`filter-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal filter-modal `}>
        <ModalHeader
          title={languageTexts[danisikLanguage].filter}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content ">
          <form className="flow-content" onSubmit={formik.handleSubmit}>
            <BrandModelOptionsNew
              isModalOpen={isModalOpen}
              brandLabel={languageTexts[danisikLanguage].brand}
              modelLabel={languageTexts[danisikLanguage].model}
              brandValue={formik.values.brand}
              modelValue={formik.values.model}
              showErrorBrand={
                formik.touched.brand?._id && Boolean(formik.errors.brand?._id)
              }
              errorBrand={formik.touched.brand?._id && formik.errors.brand?._id}
              showErrorModel={
                formik.touched.model?._id && Boolean(formik.errors.model?._id)
              }
              errorModel={formik.touched.model?._id && formik.errors.model?._id}
              handleChangeBrand={handleChange}
              handleChangeModel={handleChange}
            />

            <DotmedCategoryOptionsNew
              isModalOpen={isModalOpen}
              label={"*" + languageTexts[danisikLanguage].category}
              name="category"
              handleChange={handleChange}
              value={formik.values.category}
              showError={
                formik.touched.category?._id &&
                Boolean(formik.errors.category?._id)
              }
              error={
                formik.touched.category?._id && formik.errors.category?._id
              }
            />
            <Input
              type="number"
              max={new Date().getFullYear()}
              label={languageTexts[danisikLanguage].productionYear}
              value={formik.values.productionYear}
              handleChange={handleChange}
              name="productionYear"
              showError={
                formik.touched.productionYear &&
                Boolean(formik.errors.productionYear)
              }
              error={
                formik.touched.productionYear && formik.errors.productionYear
              }
            />
            <FormButton title={languageTexts[danisikLanguage].save} />
          </form>
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
    </div>
  );
}

export default Filter;
