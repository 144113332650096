import React, { useEffect, useState } from "react";

import "./style.scss";
import ArrowDown from "assets/icons/ArrowDown";
import { truncate } from "utils/stringFunctions";
import ArrowUp from "assets/icons/ArrowUp";
import img1 from "assets/images/no-image-available.png";
import PartModal from "components/Modals/Part";
import languageTexts from "constants/languageTexts";
import { useDispatch, useSelector } from "react-redux";
import { statusInfos } from "constants/statusInfos";
import { setSortBy } from "redux/product/part/partActions";
import { partTypes } from "constants/partTypes";

//types : "device-status-working","broke","unknown"

function Table({
  filterOptions,
  partArr = [],
  sortBy,
  partIdParam,
  getPartsWithFilters,
  labelParam,
}) {
  const dispatch = useDispatch();
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [partId, setPartId] = useState("");
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
  const [partActiveStatus, setPartActiveStatus] = useState(null);
  const [partName, setPartName] = useState("");
  const [stockNumber, setStockNumber] = useState("");
  const handleSortBy = (sortByParam) => {
    if (sortBy?.name === sortByParam && sortBy?.sortType === "asc") {
      dispatch(setSortBy({ name: sortByParam, sortType: "desc" }));
    } else if (sortBy?.name === sortByParam && sortBy?.sortType === "desc") {
      dispatch(setSortBy({ name: sortByParam, sortType: "asc" }));
    } else {
      dispatch(setSortBy({ name: sortByParam, sortType: "asc" }));
    }
  };

  const handleDeviceModal = (id, partName, active, stockNumber) => {
    setPartId(id);
    setPartActiveStatus(active);
    setPartName(partName);
    setIsDeviceModalOpen(true);
    setStockNumber(stockNumber);
  };
  useEffect(() => {
    if (labelParam) {
      setPartId(labelParam);
      setIsDeviceModalOpen(true);
    }
  }, [labelParam]);

  useEffect(() => {
    if (partIdParam) {
      setPartId(partId);
    }
  }, [partIdParam]);

  return (
    <div className="part-table">
      <div className="table-header-container">
        <div className="table-header">
          <div className="table-img-header table-header-item"></div>
          <div
            className="table-quantity-header table-header-item"
            onClick={() => handleSortBy("quantity")}
          >
            <span>{languageTexts[danisikLanguage].quantity}</span>
            {sortBy?.name === "quantity" && sortBy?.sortType === "asc" ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
          <div
            className="table-part-number-header table-header-item"
            onClick={() => handleSortBy("partNumber")}
          >
            <span>{languageTexts[danisikLanguage].partNumber}</span>
            {sortBy?.name === "partNumber" && sortBy?.sortType === "asc" ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
          <div
            className="table-part-name-header table-header-item"
            onClick={() => handleSortBy("partName")}
          >
            <span>{languageTexts[danisikLanguage].name}</span>
            {sortBy?.name === "partName" && sortBy?.sortType === "asc" ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
          <div
            className="table-brand-header table-header-item"
            onClick={() => handleSortBy("brandId.name")}
          >
            <span>{languageTexts[danisikLanguage].brand}</span>
            {sortBy?.name === "brandId.name" && sortBy?.sortType === "asc" ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
          <div
            className="table-model-header table-header-item"
            onClick={() => handleSortBy("modelId.name")}
          >
            <span>{languageTexts[danisikLanguage].model}</span>
            {sortBy?.name === "modelId.name" && sortBy?.sortType === "asc" ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
          <div
            className="table-year-header table-header-item"
            onClick={() => handleSortBy("year")}
          >
            <span>{languageTexts[danisikLanguage].year}</span>
            {sortBy?.name === "year" && sortBy?.sortType === "asc" ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
          <div
            className="table-listing-id-header table-header-item"
            onClick={() => handleSortBy("listingId")}
          >
            <span>{languageTexts[danisikLanguage].listingIds}</span>
            {sortBy?.name === "listingId" && sortBy?.sortType === "asc" ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
          <div
            className="table-part-type-header table-header-item"
            onClick={() => handleSortBy("partType")}
          >
            <span>{languageTexts[danisikLanguage].type}</span>
            {sortBy?.name === "partType" && sortBy?.sortType === "asc" ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>

          <div
            className="table-status-header table-header-item"
            onClick={() => handleSortBy("status")}
          >
            <span>{languageTexts[danisikLanguage].status}</span>
            {sortBy?.name === "status" && sortBy?.sortType === "asc" ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
        </div>
      </div>

      <div className="table-content-container">
        {partArr.map((part, index) => (
          <div
            className="table-content"
            key={index}
            onClick={() =>
              handleDeviceModal(
                part._id,
                `${part.partName} ${part.partNumber}`,
                part.active,
                part.stockNumber
              )
            }
          >
            <div className="table-img-content table-content-item">
              <img
                src={part.images[0]?.url ? part.images[0].url : img1}
                alt={`${part.brandId?.name} ${part.modelId?.name}`}
              />
            </div>
            <div
              className="table-quantity-content table-content-item"
              title={part.quantity}
            >
              <span className="table-content-title">
                {languageTexts[danisikLanguage].quantity}:{" "}
              </span>
              <span> x{truncate(part.quantity, 15)}</span>
            </div>
            <div
              className="table-part-number-content table-content-item"
              title={part.partNumber}
            >
              <span className="table-content-title">
                {languageTexts[danisikLanguage].partNumber}:{" "}
              </span>
              <span>{truncate(part.partNumber, 15)}</span>
            </div>
            <div
              className="table-part-name-content table-content-item"
              title={part.partName}
            >
              <span className="table-content-title">
                {languageTexts[danisikLanguage].partName}:{" "}
              </span>
              <span>{truncate(part.partName, 25)}</span>
            </div>
            <div
              className="table-brand-content table-content-item"
              title={part.brandId?.name}
            >
              <span>{truncate(part.brandId?.name)}</span>
            </div>
            <div
              className="table-model-content table-content-item"
              title={part.modelId?.name}
            >
              <span>{truncate(part.modelId?.name)}</span>
            </div>
            <div className="table-year-content table-content-item">
              <span className="table-content-title">
                {languageTexts[danisikLanguage].year}:{" "}
              </span>
              <span>{part.productionYear}</span>
            </div>
            <div
              className="table-listing-id-content table-content-item"
              title={part.listingId}
            >
              <span className="table-content-title">
                {languageTexts[danisikLanguage].listingId}:{" "}
              </span>
              <span>{truncate(part.listingId)}</span>
            </div>
            <div
              className="table-part-type-content table-content-item"
              title={partTypes[part.partType]}
            >
              <span className="table-content-title">
                {languageTexts[danisikLanguage].partType}:{" "}
              </span>
              <span>{truncate(partTypes[part.partType])}</span>
            </div>
            <div className="table-status-content table-content-item">
              <div className={`device-status-${part?.status}`}></div>
              <span>
                <span>{statusInfos[part.status]}</span>
              </span>
            </div>
            <div className="table-brand-model-content table-content-item">
              <span>
                {languageTexts[danisikLanguage].deviceName}:{" "}
                {part.brandId?.name} {part.modelId?.name}
              </span>
            </div>
          </div>
        ))}
      </div>
      {partId ? (
        <PartModal
          partId={partId}
          setPartId={setPartId}
          handleDeviceId={setPartId}
          isModalOpen={isDeviceModalOpen}
          setIsModalOpen={setIsDeviceModalOpen}
          partName={partName}
          partActiveStatus={partActiveStatus}
          stockNumber={stockNumber}
        />
      ) : null}
    </div>
  );
}

export default Table;
