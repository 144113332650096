import FactoryService from "services/factoryService";

export default class RecentOperationService extends FactoryService {
  constructor() {
    super("recentOperations");
  }
  getNotifications() {
    return this.api.get(`${this.serviceName}/readRecentOperations`);
  }
  isThereUnreadNotification() {
    return this.api.get(`${this.serviceName}/notificationEndpoint`);
  }
}
