import { PartActionTypes } from "./partActionTypes";

const INITIAL_STATE = {
  parts: [],
  partError: null,
  partInformationLoading: false,
  searchText: "",
  page: 1,
  totalPage: 1,
  totalCount: null,
  limit: 50,
  filterOptions: {
    brand: {
      name: "",
      _id: "",
    },
    model: {
      name: "",
      _id: "",
    },
    category: {
      name: "",
      _id: "",
    },
    productionYear: "",
  },
  sortBy: "",
};

const partReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PartActionTypes.GET_PARTS:
      return {
        ...state,
        partError: null,
        partInformationLoading: true,
      };
    case PartActionTypes.GET_PARTS_SUCCESS:
      return {
        ...state,
        parts: action.payload.data,
        totalPage: action.payload.totalPage,
        totalCount: action.payload.totalCount,
        partError: null,
        partInformationLoading: false,
      };
    case PartActionTypes.GET_PARTS_ERROR:
      return {
        ...state,
        parts: [],
        totalPage: 1,
        totalCount: null,
        partError: action.payload,
        partInformationLoading: false,
      };
    case PartActionTypes.SET_FILTER_OPTIONS_PART:
      return {
        ...state,
        page: 1,
        filterOptions: { ...action.payload },
      };
    case PartActionTypes.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case PartActionTypes.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };
    case PartActionTypes.SET_SORT_BY:
      return {
        ...state,
        sortBy: action.payload,
      };
    default:
      return state;
  }
};

export default partReducer;
