import HeaderAndSidePanel from "components/HeaderAndSidePanel";
import React from "react";
import { Outlet } from "react-router-dom";

function DefaultLayout() {
  return (
    <>
      <HeaderAndSidePanel />
      <div className="content">
        <Outlet />
      </div>
    </>
  );
}

export default DefaultLayout;
