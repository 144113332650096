import React from "react";
import "./style.scss";

function ErrorMessages({ error = "" }) {
  return (
    <div className="error-message-container">
      <span>* {error}</span>
    </div>
  );
}

export default ErrorMessages;
