import * as Yup from "yup";
export default Yup.object().shape({
  title: Yup.string("Site Name").required("required"),
  salePrice: Yup.object().shape({
    value: Yup.number("Enter Sale Price")
      .min(1, "Value must be greater than 0")
      .required("Required"),
    currency: Yup.string()
      .oneOf(["EUR", "USD", "GBP", "TRY"])
      .required("Please select valid currency"),
  }),
});
