import * as Yup from "yup";
export default Yup.object().shape({
  packagingType: Yup.object().shape({
    _id: Yup.string().required("Packaging type is required"),
  }),
  physicalInfos: Yup.object().shape({
    width: Yup.number().min(1, "Number must be greater than 0"),
    height: Yup.number().min(1, "Number must be greater than 0"),
    size: Yup.number().min(1, "Number must be greater than 0"),
    weight: Yup.number().min(1, "Number must be greater than 0"),
  }),
  packagingCost: Yup.object().shape({
    value: Yup.number().min(1, "Number must be greater than 0"),
    currency: Yup.string()
      .oneOf(["EUR", "USD", "GBP", "TRY"])
      .required("Please select valid currency"),
  }),
});
