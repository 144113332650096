import React from "react";

function ArrowUp({
  fill = "rgb(255,255,255)",
  width = "2.4rem",
  height = "2.4rem",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={fill}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 8l6 6H6z" />
    </svg>
  );
}

export default ArrowUp;
