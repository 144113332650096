import FormButton from "components/Buttons/FormButton";
import DragAndDrop from "components/DragAndDrop";
import Input from "components/FormElements/Input";
import SelectOption from "components/FormElements/SelectOption";
import { useFormik } from "formik";
import CustomAlert from "lib/customAlert.js";
import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import partValidationSchema from "./partValidationSchema";
import { statusInfoOptions, statusInfos } from "constants/statusInfos";
import PartService from "services/product/part/part";
import LoadingPage from "components/LoadingPage";
import BrandModelOptions from "components/BrandModelOptions";
import CategoryOptions from "components/CategoryOptions";
import { partTypeOptions, partTypes } from "constants/partTypes";
import { toast } from "react-toastify";
import languageTexts from "constants/languageTexts";
import { useDispatch, useSelector } from "react-redux";
import DotmedCategoryOptions from "components/DotmedCategoryOptions";
import TextArea from "components/FormElements/TextArea";
import DragAndDropNew from "components/DragAndDropNew";
import { getParts } from "redux/product/part/partActions";
import BrandModelOptionsNew from "components/BrandModelOptionsNew";
import DotmedCategoryOptionsNew from "components/DotmedCategoryOptionsNew";
import AddReferencePricePart from "components/Modals/AddReferencePricePart";
import DeleteBin2 from "assets/icons/DeleteBin2";
import AddCircle from "assets/icons/AddCircle";
import EditPencil from "assets/icons/EditPencil";
import CurrencyInput from "components/FormElements/CurrencyInput";
import ReactQuillWrapper from "components/FormElements/ReactQuillWrapper";

const handleFormatPart = (partInfos, referencePrices = []) => {
  let formData = new FormData();
  formData.append("price[currency]", partInfos.price?.currency);
  formData.append("price[value]", partInfos.price?.value);
  formData.append("quantity", partInfos.quantity);
  formData.append("partNumber", partInfos.partNumber);
  formData.append("partName", partInfos.partName);
  formData.append("detailInfo", partInfos.detailInformation);
  formData.append("serialNumber", partInfos.serialNumber);
  formData.append("partType", partInfos.partType?._id);
  formData.append("brandId", partInfos.brand?._id);
  formData.append("modelId", partInfos.model?._id);
  formData.append("categoryId", partInfos.category?._id);
  formData.append("productionYear", partInfos.productionYear);
  formData.append("status", partInfos.status?._id);
  formData.append("physicalInfos[width]", partInfos.physicalInfos?.width);
  formData.append("physicalInfos[height]", partInfos.physicalInfos?.height);
  formData.append("physicalInfos[size]", partInfos.physicalInfos?.size);
  formData.append("physicalInfos[weight]", partInfos.physicalInfos?.weight);
  formData.append("price", partInfos.price);
  formData.append("saleDescriptionDotmed", partInfos.saleDescriptionDotmed);
  formData.append("saleDescriptionShopify", partInfos.saleDescriptionShopify);
  partInfos.images.forEach((image) => {
    formData.append("images", image);
  });
  referencePrices.forEach((referencePrice, index) => {
    formData.append(
      `referencePrices[${index}][salePrice][currency]`,
      referencePrice.salePrice?.currency
    );
    formData.append(
      `referencePrices[${index}][salePrice][value]`,
      referencePrice.salePrice?.value
    );
    formData.append(`referencePrices[${index}][title]`, referencePrice.title);
  });
  partInfos.technicalAndManufacturerImages.forEach((image) => {
    formData.append("technicalAndManufacturerImages", image);
  });
  return formData;
};

const partService = new PartService();
function Part({ partId }) {
  const dispatch = useDispatch();
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [partInfos, setPartInfos] = useState(null);
  const [loading, setLoading] = useState(false);

  const [referencePrices, setReferencePrices] = useState([]);
  const [referencePriceModalInfo, setReferencePriceModalInfo] = useState({
    _id: 0,
    title: "",
    description: "",
  });
  const [addReferencePriceModalOpen, setAddReferencePriceModalOpen] =
    useState(false);

  const getPart = useCallback(() => {
    setLoading(true);
    partService
      .getById(partId)
      .then((res) => {
        let partInfosData = res.data.data;
        formik.setFieldValue("images", partInfosData.images);
        formik.setFieldValue(
          "technicalAndManufacturerImages",
          partInfosData.technicalAndManufacturerImages
        );
        setPartInfos(partInfosData);
        setReferencePrices(res.data.data.referencePrices);
      })
      .finally(() => setLoading(false));
  }, [partId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: partInfos?._id || 0,
      quantity: partInfos?.quantity || "",
      partNumber: partInfos?.partNumber || "",
      partName: partInfos?.partName || "",
      detailInformation: partInfos?.detailInfo || "",
      serialNumber: partInfos?.serialNumber || "",
      saleDescriptionShopify: partInfos?.saleDescriptionShopify || "",
      saleDescriptionDotmed: partInfos?.saleDescriptionDotmed || "",
      partType: {
        name: partTypes[partInfos?.partType] || "",
        _id: partInfos?.partType || "",
      },
      brand: partInfos?.brandId || {
        name: "",
        _id: "",
      },
      model: partInfos?.modelId || {
        name: "",
        _id: "",
      },
      category: partInfos?.categoryId || {
        name: "",
        _id: "",
      },
      productionYear: partInfos?.productionYear || "",
      status: {
        name: statusInfos[partInfos?.status] || "",
        _id: partInfos?.status || "",
      },
      physicalInfos: {
        width: partInfos?.physicalInfos?.width || "",
        height: partInfos?.physicalInfos?.height || "",
        size: partInfos?.physicalInfos?.size || "",
        weight: partInfos?.physicalInfos?.weight || "",
      },
      images: partInfos?.images || [],
      technicalAndManufacturerImages:
        partInfos?.technicalAndManufacturerImages || [],
      price: partInfos?.price || {
        currency: "EUR",
        value: "",
      },
    },
    validationSchema: partValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        setLoading(true);
        partService
          .update(values._id, handleFormatPart(values, referencePrices))
          .then(() => {
            toast.success(languageTexts[danisikLanguage].succesfullyUpdated);
            getPart();
            dispatch(getParts());
          })
          .finally(() => setLoading(false));
      }
    },
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value);
  };

  useEffect(() => {
    getPart();
  }, [getPart]);

  const handleUpdatePartImage = (images) => {
    let newImages = [...formik.values.images, ...images];
    formik.setFieldValue("images", newImages);
  };
  const handleDeletePartImage = (publicId) => {
    partService
      .handleDeletePartImg(formik.values._id, publicId)
      .then((res) => {
        toast.success(languageTexts[danisikLanguage].succesfullyDeleted);
        getPart();
      })
      .finally(() => setLoading(false));
  };
  const handleDeleteTechnicalAndManufacturerImage = (publicId) => {
    partService
      .handleDeleteTechnicalAndManufacturerImg(formik.values._id, publicId)
      .then((res) => {
        toast.success(languageTexts[danisikLanguage].succesfullyDeleted);
        getPart();
      })
      .finally(() => setLoading(false));
  };
  const handleUpdateTechnicalAndManufacturerImage = (images) => {
    let newImages = [...formik.values.images, ...images];
    formik.setFieldValue("technicalAndManufacturerImages", newImages);
  };

  const handleOrderImages = (images, modelId) => {
    partService
      .orderImages({ modelId: modelId, images: images })
      .then((res) => {
        toast.success(
          languageTexts[danisikLanguage].imagesAreOrderedSuccessfully
        );
      });
  };
  const handleOrderTechnicalAndManufacturerImages = (images, modelId) => {
    partService
      .orderImagesTechnicalAndManufucturerImages({
        modelId: modelId,
        images: images,
      })
      .then((res) => {
        toast.success(
          languageTexts[danisikLanguage].imagesAreOrderedSuccessfully
        );
      });
  };
  const handleDeleteImageFormik = (file) => {
    let newFiles = JSON.parse(JSON.stringify(formik.values.images));
    newFiles = newFiles.filter((img) => img._id !== file._id);
    formik.setFieldValue("images", newFiles);
  };
  const handleDeleteImageFormikTechnical = (file) => {
    let newFiles = JSON.parse(JSON.stringify(formik.values.images));
    newFiles = newFiles.filter((img) => img._id !== file._id);
    formik.setFieldValue("technicalAndManufacturerImages", newFiles);
  };

  const handleDeleteReferencePrice = async (index) => {
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].OK
    );
    if (result) {
      const newReferencePrice = [
        ...referencePrices.slice(0, index),
        ...referencePrices.slice(index + 1),
      ];
      setReferencePrices(newReferencePrice);
    }
  };

  const handleChangeCurrency = (e) => {
    formik.setFieldValue(`${e.name}.currency`, e.value);
  };
  const handleChangeCurrencyInputValue = (e) => {
    formik.setFieldValue(`${e.name}.value`, e.value);
  };

  return loading ? (
    <LoadingPage />
  ) : (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="flow-content tab-part-form"
      >
        <DragAndDropNew
          images={formik.values.images}
          setImages={(images) => {
            formik.setFieldValue("images", images);
          }}
          // handleUpdateFile={handleUpdateFile}
          handleDeleteImageFormik={handleDeleteImageFormik}
          handleDeleteImage={handleDeletePartImage}
          handleOrder={handleOrderImages}
          modelId={formik.values._id}
        />

        <div className="modal-form-padding flow-content">
          <Input
            label={languageTexts[danisikLanguage].quantity}
            value={formik.values.quantity}
            handleChange={handleChange}
            name="quantity"
            showError={
              formik.touched.quantity && Boolean(formik.errors.quantity)
            }
            error={formik.touched.quantity && formik.errors.quantity}
          />
          <Input
            label={languageTexts[danisikLanguage].partNumber}
            value={formik.values.partNumber}
            handleChange={handleChange}
            name="partNumber"
            showError={
              formik.touched.partNumber && Boolean(formik.errors.partNumber)
            }
            error={formik.touched.partNumber && formik.errors.partNumber}
          />
          <Input
            label={languageTexts[danisikLanguage].partName}
            value={formik.values.partName}
            handleChange={handleChange}
            name="partName"
            showError={
              formik.touched.partName && Boolean(formik.errors.partName)
            }
            error={formik.touched.partName && formik.errors.partName}
          />
          <Input
            label={languageTexts[danisikLanguage].detailInformation}
            value={formik.values.detailInformation}
            handleChange={handleChange}
            name="detailInformation"
            showError={
              formik.touched.detailInformation &&
              Boolean(formik.errors.detailInformation)
            }
            error={
              formik.touched.detailInformation &&
              formik.errors.detailInformation
            }
          />
          <Input
            label={languageTexts[danisikLanguage].serialNumber}
            value={formik.values.serialNumber}
            handleChange={handleChange}
            name="serialNumber"
            showError={
              formik.touched.serialNumber && Boolean(formik.errors.serialNumber)
            }
            error={formik.touched.serialNumber && formik.errors.serialNumber}
          />
          <BrandModelOptionsNew
            brandLabel={languageTexts[danisikLanguage].brand}
            modelLabel={languageTexts[danisikLanguage].model}
            brandValue={formik.values.brand}
            modelValue={formik.values.model}
            showErrorBrand={
              formik.touched.brand?._id && Boolean(formik.errors.brand?._id)
            }
            errorBrand={formik.touched.brand?._id && formik.errors.brand?._id}
            showErrorModel={
              formik.touched.model?._id && Boolean(formik.errors.model?._id)
            }
            errorModel={formik.touched.model?._id && formik.errors.model?._id}
            handleChangeBrand={handleChange}
            handleChangeModel={handleChange}
          />
          <DotmedCategoryOptionsNew
            label={"*" + languageTexts[danisikLanguage].category}
            name="category"
            handleChange={handleChange}
            value={formik.values.category}
            showError={
              formik.touched.category?._id &&
              Boolean(formik.errors.category?._id)
            }
            error={formik.touched.category?._id && formik.errors.category?._id}
          />
          <Input
            type="number"
            label={languageTexts[danisikLanguage].productionYear}
            value={formik.values.productionYear}
            handleChange={handleChange}
            name="productionYear"
            showError={
              formik.touched.productionYear &&
              Boolean(formik.errors.productionYear)
            }
            error={
              formik.touched.productionYear && formik.errors.productionYear
            }
          />
          <SelectOption
            options={statusInfoOptions}
            label={languageTexts[danisikLanguage].status}
            name="status"
            handleChange={handleChange}
            value={formik.values.status}
            showError={
              formik.touched.status?._id && Boolean(formik.errors.status?._id)
            }
            error={formik.touched.status?._id && formik.errors.status?._id}
          />
          <SelectOption
            options={partTypeOptions}
            label={languageTexts[danisikLanguage].partType}
            name="partType"
            handleChange={handleChange}
            value={formik.values.partType}
            showError={
              formik.touched.partType?._id &&
              Boolean(formik.errors.partType?._id)
            }
            error={formik.touched.partType?._id && formik.errors.partType?._id}
          />
        </div>
        <div className="flow-content">
          <h3 className="section-title">
            {languageTexts[danisikLanguage].technicalAndManufacturerImages}
          </h3>
          <DragAndDropNew
            images={formik.values.technicalAndManufacturerImages}
            setImages={(images) => {
              formik.setFieldValue("technicalAndManufacturerImages", images);
            }}
            // handleUpdateFile={handleUpdateFile}
            handleDeleteImageFormik={handleDeleteImageFormikTechnical}
            handleDeleteImage={handleDeleteTechnicalAndManufacturerImage}
            handleOrder={handleOrderTechnicalAndManufacturerImages}
            modelId={formik.values._id}
          />
        </div>

        <div className="modal-form-padding flow-content">
          <h3 className="section-title">
            {languageTexts[danisikLanguage].physicalInfos}
          </h3>
          <Input
            type="number"
            label={languageTexts[danisikLanguage].width}
            value={formik.values.physicalInfos?.width}
            handleChange={handleChange}
            name="physicalInfos.width"
            showError={
              formik.touched.physicalInfos?.width &&
              Boolean(formik.errors.physicalInfos?.width)
            }
            error={
              formik.touched.physicalInfos?.width &&
              formik.errors.physicalInfos?.width
            }
          />
          <Input
            type="number"
            label={languageTexts[danisikLanguage].height}
            value={formik.values.physicalInfos?.height}
            handleChange={handleChange}
            name="physicalInfos.height"
            showError={
              formik.touched.physicalInfos?.height &&
              Boolean(formik.errors.physicalInfos?.height)
            }
            error={
              formik.touched.physicalInfos?.height &&
              formik.errors.physicalInfos?.height
            }
          />
          <Input
            type="number"
            label={languageTexts[danisikLanguage].size}
            value={formik.values.physicalInfos?.size}
            handleChange={handleChange}
            name="physicalInfos.size"
            showError={
              formik.touched.physicalInfos?.size &&
              Boolean(formik.errors.physicalInfos?.size)
            }
            error={
              formik.touched.physicalInfos?.size &&
              formik.errors.physicalInfos?.size
            }
          />
          <Input
            type="number"
            label={languageTexts[danisikLanguage].weight}
            value={formik.values.physicalInfos?.weight}
            handleChange={handleChange}
            name="physicalInfos.weight"
            showError={
              formik.touched.physicalInfos?.weight &&
              Boolean(formik.errors.physicalInfos?.weight)
            }
            error={
              formik.touched.physicalInfos?.weight &&
              formik.errors.physicalInfos?.weight
            }
          />
          <CurrencyInput
            label={languageTexts[danisikLanguage].salePrice}
            value={formik.values.price.value}
            selectedCurrency={formik.values.price.currency}
            handleChangeCurrency={handleChangeCurrency}
            handleChange={handleChangeCurrencyInputValue}
            name="price"
            showError={
              formik.touched.price?.value && Boolean(formik.errors.price?.value)
            }
            error={formik.touched.price?.value && formik.errors.price?.value}
          />
          <div className="technical-details flow-content">
            <div className="technical-details-header">
              <h3 className="section-title">
                {languageTexts[danisikLanguage].referencePrices}
              </h3>
              <div
                className="add-icon"
                onClick={() => {
                  setReferencePriceModalInfo({
                    title: "",
                    salePrice: {
                      currency: "EUR",
                      value: "",
                    },
                    _id: 0,
                  });
                  setAddReferencePriceModalOpen(true);
                }}
              >
                <AddCircle />
              </div>
            </div>
            {referencePrices.map((value, index) => (
              <div className="technical-detail flow-content" key={index}>
                <Input
                  label={languageTexts[danisikLanguage].title}
                  value={value.title}
                  disabled={true}
                />
                <CurrencyInput
                  label={languageTexts[danisikLanguage].salePrice}
                  value={value.salePrice.value}
                  selectedCurrency={value.salePrice.currency}
                  disabled={true}
                />
                <div className="button-container">
                  <button
                    className="delete"
                    type="button"
                    onClick={() => handleDeleteReferencePrice(index)}
                  >
                    <DeleteBin2 width="2rem" height="2rem" />
                    {languageTexts[danisikLanguage].delete}
                  </button>
                  <button
                    className="edit"
                    type="button"
                    onClick={() => {
                      setReferencePriceModalInfo({
                        title: value.title,
                        salePrice: value.salePrice,
                        _id: value._id,
                        index: index,
                      });
                      setAddReferencePriceModalOpen(true);
                    }}
                  >
                    <EditPencil width="1.8rem" height="1.8rem" />
                    {languageTexts[danisikLanguage].edit}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="modal-form-padding">
          <ReactQuillWrapper
            value={formik.values.saleDescriptionDotmed}
            label={languageTexts[danisikLanguage].saleDescriptionDotmed}
            name="saleDescriptionDotmed"
            handleChange={handleChange}
          />
        </div>
        <div className="modal-form-padding">
          <ReactQuillWrapper
            value={formik.values.saleDescriptionShopify}
            label={languageTexts[danisikLanguage].saleDescriptionShopify}
            name="saleDescriptionShopify"
            handleChange={handleChange}
          />
        </div>

        <FormButton title={languageTexts[danisikLanguage].save} />
      </form>
      <AddReferencePricePart
        setIsModalOpen={setAddReferencePriceModalOpen}
        isModalOpen={addReferencePriceModalOpen}
        referencePriceModalInfo={referencePriceModalInfo}
        setReferencePriceModalInfo={setReferencePriceModalInfo}
        referencePrices={referencePrices}
        setReferencePrices={setReferencePrices}
      />
    </div>
  );
}

export default Part;
