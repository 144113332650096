import React, { useState } from "react";
import "./style.scss";
import Header from "./Header";
import SidePanel from "./SidePanel";

function HeaderAndSidePanel() {
  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  return (
    <div className="header-and-side-panel">
      <Header setSidePanelOpen={setSidePanelOpen} />
      <SidePanel
        sidePanelOpen={sidePanelOpen}
        setSidePanelOpen={setSidePanelOpen}
      />
    </div>
  );
}

export default HeaderAndSidePanel;
