import FactoryService from "services/factoryService";

export default class ECommerceService extends FactoryService {
  constructor() {
    super("partECommerce");
  }
  getECommerceByPartId(partId) {
    return this.api.get(
      `/${this.serviceName}/getallPartECommerceWithPartId/${partId}`
    );
  }
  listInDotmed(id, partId) {
    return this.api.put(`/${this.serviceName}/dotmed/${id}`, {
      partId,
      dotmed: { price: null },
    });
  }
  listInShopify(id, partId, price) {
    return this.api.put(`/${this.serviceName}/shopify/${id}`, {
      partId,
      shopify: { price },
    });
  }
  listInEbay(id, partId) {
    return this.api.put(`/${this.serviceName}/ebay/${id}`, {
      partId,
      ebay: { price: null },
    });
  }

  deleteFromDotmed(id, partId) {
    return this.api.put(
      `/${this.serviceName}/dotmed/deletePartECommerceDotmed/${id}`,
      { partId }
    );
  }
  updateDotmed(id, partId) {
    return this.api.put(
      `/${this.serviceName}/dotmed/updatePartECommerceDotmed/${id}`,
      { partId }
    );
  }

  deleteFromShopify(id, partId) {
    return this.api.put(
      `/${this.serviceName}/shopify/deletePartECommerceShopify/${id}`,
      { partId }
    );
  }
  updateShopify(id, partId, price) {
    return this.api.put(
      `/${this.serviceName}/shopify/updatePartECommerceShopify/${id}`,
      {
        partId,
        shopify: { price },
      }
    );
  }
}
