import DefaultInformations from "components/Modals/Admin/DefaultInformations";
import languageTexts from "constants/languageTexts";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

function DefaultInformationsItem() {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [isDefaultInformationsModalOpen, setIsDefaultInformationsModalOpen] =
    useState(false);
  const wrapperRef = useRef(null);
  return (
    <div className="setting-item-container" ref={wrapperRef}>
      <div
        className="setting-item"
        onClick={() => setIsDefaultInformationsModalOpen(true)}
      >
        <span>{languageTexts[danisikLanguage].defaultInformations}</span>
      </div>
      <DefaultInformations
        setIsModalOpen={setIsDefaultInformationsModalOpen}
        isModalOpen={isDefaultInformationsModalOpen}
      />
    </div>
  );
}

export default DefaultInformationsItem;
