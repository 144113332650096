import React from "react";
import "./style.scss";
import DragAndDropNew from "components/DragAndDropNew";
import Input from "components/FormElements/Input";
import ModalHeader from "../ModalHeader";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";
import { statusInfos } from "constants/statusInfos";
import { uuidv4 } from "utils/generateUUID";

function AccessoryForAdvertise({ accessories, handleCloseModal, isModalOpen }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  return (
    <div
      className={`add-accessuar-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal add-accessuar-modal`}>
        <ModalHeader
          title={languageTexts[danisikLanguage].accessuaryInformations}
          setIsModalOpen={handleCloseModal}
        />
        <div className="modal-content">
          <div className="accessories-body flow-content">
            {accessories.map((accessory) => (
              <div className="accessories-item" key={uuidv4()}>
                <DragAndDropNew
                  images={accessory.images}
                  handleOrder={() => {}}
                  modelId={accessory._id}
                  disabled={true}
                />
                <div
                  className="accessories-info flow-content"
                  style={{ marginTop: "2rem" }}
                >
                  <Input
                    label={languageTexts[danisikLanguage].serialNumber}
                    value={accessory.serialNumber}
                    disabled={true}
                  />
                  <Input
                    label={languageTexts[danisikLanguage].brand}
                    value={accessory.brandId?.name}
                    disabled={true}
                  />
                  <Input
                    label={languageTexts[danisikLanguage].model}
                    value={accessory.modelId?.name}
                    disabled={true}
                  />

                  <Input
                    label={languageTexts[danisikLanguage].status}
                    value={statusInfos[accessory.status]}
                    disabled={true}
                  />
                  <Input
                    label={languageTexts[danisikLanguage].accessuarDefinition}
                    value={accessory.definition}
                    disabled={true}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="modal-overlay" onClick={handleCloseModal}></div>
    </div>
  );
}

export default AccessoryForAdvertise;
