import * as Yup from "yup";
export default Yup.object().shape({
  serialNumber: Yup.string(),
  brand: Yup.object().shape({
    _id: Yup.string("Enter Part Type").required("Part Type is required"),
  }),
  model: Yup.object().shape({
    _id: Yup.string("Enter Part Type").required("Part Type is required"),
  }),
  category: Yup.object().shape({
    _id: Yup.string("Enter Part Type").required("Part Type is required"),
  }),
  productionYear: Yup.string().required("Required"),
});
