import React from "react";
import "./style.scss";
import CameraLine from "assets/icons/CameraLine";
import QrcodePlugin from "components/QrcodePlugin";
import { useState } from "react";
import ErrorMessages from "components/ErrorMessages";
import HTML5QrCode from "components/HTML5QrCode";
import { useEffect } from "react";
import { useCallback } from "react";
import Close from "assets/icons/Close";

function InputWithQrCodeNew({
  label = "",
  value = "",
  name = "",
  handleChange,
  type = "text",
  error = "",
  showError = false,
  ...props
}) {
  const [focused, setFocused] = useState(false);
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const handleChangeQr = (data) => {
    if (data) {
      const e = { target: { value: data, name: name } };
      handleChange(e);
      handleCloseCamera();
    }
  };
  const handleCloseCamera = useCallback(() => {
    document.getElementById("html5-qrcode-button-camera-stop")?.click();
    setIsCameraVisible(false);
  }, []);

  const handleOpenCamera = useCallback(() => {
    setIsCameraVisible(true);
    document.getElementById("html5-qrcode-button-camera-start")?.click();
  }, []);
  useEffect(() => {
    handleCloseCamera();
  }, [handleCloseCamera]);

  useEffect(() => {
    if (isCameraVisible) {
      setTimeout(() => {
        document
          .getElementById("html5-qrcode-button-camera-permission")
          ?.click();
      }, 100);
    }
  }, [isCameraVisible]);

  return (
    <div className="input-with-qr-code-container">
      <label className="input-with-qr-code-new form-input">
        <span
          className={`label-text ${
            value.length || focused ? "label-small" : ""
          }`}
        >
          {label}
        </span>
        <input
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={value}
          name={name}
          onChange={handleChange}
          type={type}
          {...props}
        />
        <div className="icon-container" onClick={handleOpenCamera}>
          <CameraLine width="3rem" height="3rem" />
        </div>
      </label>

      {isCameraVisible ? (
        <>
          <HTML5QrCode
            fps={10}
            qrbox={250}
            disableFlip={false}
            qrCodeSuccessCallback={handleChangeQr}
            handleClose={handleCloseCamera}
          />
          <div className="close-icon" onClick={handleCloseCamera}>
            <Close width="5rem" height="5rem" />
          </div>
        </>
      ) : null}

      {showError && error.length ? <ErrorMessages error={error} /> : null}
    </div>
  );
}

export default InputWithQrCodeNew;
