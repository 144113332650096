import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL + "/api/v1/";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 100000,
});
export const axiosInstanceWithoutInterceptor = axios.create({
  baseURL: BASE_URL,
  timeout: 100000,
});
const nonTokenUrlList = [
  "admin/login",
  "users/signup",
  "users/login",
  "product-detail",
];
axiosInstance.defaults.headers.common["Content-Type"] = "application/json";
async function injectToken(config) {
  const nonTokenUrl = nonTokenUrlList.some(
    (url) => !url.startsWith(config.url)
  );
  const token = localStorage.getItem("danisik-token");

  if (nonTokenUrl || token) {
    config.headers.Authorization = `Bearer ${token}`;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    console.log("görüntüleme yetkiniz yok");
  }

  return config;
}

axiosInstance.interceptors.request.use(injectToken, (err) =>
  Promise.reject(err)
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("danisik-token");
      window.location.replace("/login");
    } else if (error.response.status === 403) {
      //window.location.replace("/");
      toast.error("No Permission");
    } else if (error.response.status.toString().startsWith("5")) {
      toast.error("Unexpected error occured");
    } else {
      toast.error(error.response?.data?.message || "Unhandled error occured");
    }
    return Promise.reject(error.response ? error.response : error);
  }
);

export default axiosInstance;
