import * as Yup from "yup";
export default Yup.object().shape({
  quantity: Yup.number().required("Quantity is required"),
  partNumber: Yup.string().required("Required"),
  partName: Yup.string().required("Part name is required"),
  detailInformation: Yup.string(),
  partType: Yup.object().shape({
    _id: Yup.string().required("Part Type is required"),
  }),

  brand: Yup.object().shape({
    _id: Yup.string().required("Brand is required"),
  }),
  model: Yup.object().shape({
    _id: Yup.string().required("Model is required"),
  }),
  category: Yup.object().shape({
    _id: Yup.string().required("Category is required"),
  }),
  productionYear: Yup.number()
    .min(1800, "Please enter valid year")
    .max(new Date().getFullYear() + 1, "Please enter valid year"),
  status: Yup.object().shape({
    _id: Yup.string("Enter Status").required("Status is required"),
  }),
  // physicalInfos: Yup.object().shape({
  //   width: Yup.number()
  //     .min(1, "Value should be greater than 0")
  //     .required("Width is required"),
  //   height: Yup.number()
  //     .min(1, "Value should be greater than 0")
  //     .required("Height is required"),
  //   size: Yup.number()
  //     .min(1, "Value should be greater than 0")
  //     .required("Size is required"),
  //   weight: Yup.number()
  //     .min(1, "Value should be greater than 0")
  //     .required("Weight is required"),
  // }),
});
