import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import AddCircle from "assets/icons/AddCircle";
import DragAndDrop from "components/DragAndDrop";
import Printer from "assets/icons/Printer";
import Input from "components/FormElements/Input";
import DeleteBin2 from "assets/icons/DeleteBin2";
import EditPencil from "assets/icons/EditPencil";
import AccessoryService from "services/product/device/accessoryService";
import LoadingPage from "components/LoadingPage";
import { toast } from "react-toastify";
import AddAccessuars from "components/Modals/AddAccessuars";
import CustomAlert from "lib/customAlert";
import { statusInfos } from "constants/statusInfos";
import FormButton from "components/Buttons/FormButton";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
import AccessuarDymo from "components/Dymo/AccessuarDymo";
import { uuidv4 } from "utils/generateUUID";
import DragAndDropNew from "components/DragAndDropNew";

const formatAccessuarForUpdate = (accessuar, deviceId) => {
  let formData = new FormData();
  formData.append("serialNumber", accessuar.serialNumber);
  formData.append("modelId", accessuar.modelId?._id);
  formData.append("brandId", accessuar.brandId?._id);
  formData.append("status", accessuar.status);
  formData.append("definition", accessuar.definition);
  formData.append("deviceId", deviceId);
  if (accessuar.images && accessuar.images.length) {
    for (let i = 0; i < accessuar.images.length; i++) {
      formData.append("images", accessuar.images[i]);
    }
  }
  return formData;
};
const formatAccessuar = (accessuar, deviceId) => {
  let formData = new FormData();
  formData.append("accessories[0][serialNumber]", accessuar.serialNumber);
  formData.append("accessories[0][modelId]", accessuar.modelId?._id);
  formData.append("accessories[0][brandId]", accessuar.brandId?._id);
  formData.append("accessories[0][status]", accessuar.status);
  formData.append("accessories[0][definition]", accessuar.definition);
  formData.append("deviceId", deviceId);
  if (accessuar.images && accessuar.images.length) {
    for (let i = 0; i < accessuar.images.length; i++) {
      formData.append(`accessories[${i}][images]`, accessuar.images[i]);
    }
  }

  return formData;
};
const accessoryService = new AccessoryService();
function Accessories({ deviceId }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [loading, setLoading] = useState(false);
  const [accessories, setAccessories] = useState([]);
  const [addAccessuarModalOpen, setAddAccessuarModalOpen] = useState(false);
  const [accessuarModalInfo, setAccessuarModalInfo] = useState({
    _id: 0,
    serialNumber: "",
    status: "",
    definition: "",
    images: [],
    brand: {
      name: "",
      _id: "",
    },
    model: {
      name: "",
      _id: "",
    },
    deviceId: deviceId,
  });
  const getAccessories = useCallback(() => {
    setLoading(true);
    accessoryService
      .getAllByDeviceId(deviceId)
      .then((res) => {
        setAccessories(res.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [deviceId]);

  useEffect(() => {
    getAccessories();
  }, [getAccessories]);

  const handleOrder = (images, modelId) => {
    accessoryService
      .orderImages({ modelId: modelId, images: images })
      .then((res) => {
        toast.success(
          languageTexts[danisikLanguage].imagesAreOrderedSuccessfully
        );
      });
  };

  const handleDeleteAccessuar = async (index) => {
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].OK
    );
    if (result) {
      if (accessories[index]._id) {
        setLoading(true);
        accessoryService
          .delete(accessories[index]._id)
          .then((res) => {
            toast.success(languageTexts[danisikLanguage].succesfullyDeleted);
            getAccessories();
          })
          .finally(() => setLoading(false));
      } else {
        const newAccessuars = [
          ...accessories.slice(0, index),
          ...accessories.slice(index + 1),
        ];
        setAccessories(newAccessuars);
      }
    }
  };

  const updateAccessories = () => {
    // if (accessories.length) {
    let accessoriesReqArr = [];
    accessories.map((accessory) => {
      if (accessory._id) {
        accessoriesReqArr.push(
          accessoryService.update(
            accessory._id,
            formatAccessuarForUpdate(accessory, deviceId)
          )
        );
      } else {
        accessoriesReqArr.push(
          accessoryService.addFormData(formatAccessuar(accessory, deviceId))
        );
      }
    });
    setLoading(true);
    Promise.all(accessoriesReqArr)
      .then((res) => {
        toast.success(languageTexts[danisikLanguage].operationSuccessful);
      })
      .finally(() => setLoading(false));
    // } else {
    //   toast.error(languageTexts[danisikLanguage].minAccessuarNeededError);
    // }
  };

  return loading ? (
    <LoadingPage />
  ) : (
    <div className="accessories-container flow-content">
      <div className="accessories-header">
        <h3 className="section-title">
          {languageTexts[danisikLanguage].accessuaryInformations}
        </h3>
        <div
          className="add-circle-container"
          onClick={() => {
            setAccessuarModalInfo({
              _id: 0,
              serialNumber: "",
              status: "",
              definition: "",
              images: [],
              brand: {
                name: "",
                _id: "",
              },
              model: {
                name: "",
                _id: "",
              },
              deviceId: deviceId,
              stockNumber: "",
            });
            setAddAccessuarModalOpen(true);
          }}
        >
          <AddCircle />
        </div>
      </div>
      <div className="accessories-body flow-content">
        {accessories.map((accessory, index) => (
          <div className="accessories-item" key={uuidv4()}>
            <DragAndDropNew
              images={accessory.images}
              handleOrder={handleOrder}
              modelId={accessory._id}
              disabled={true}
            />
            <AccessuarDymo
              definition={accessory.definition}
              stockNumber={accessory.stockNumber}
              brand={accessory.brandId?.name}
              model={accessory.modelId?.name}
              productionYear={accessory.productionYear}
              link={`${window.location.origin}/devices/?label=${deviceId}`}
            />
            <div className="accessories-info flow-content">
              <Input
                label={languageTexts[danisikLanguage].serialNumber}
                value={accessory.serialNumber}
                disabled={true}
              />
              <Input
                label={languageTexts[danisikLanguage].brand}
                value={accessory.brandId?.name}
                disabled={true}
              />
              <Input
                label={languageTexts[danisikLanguage].model}
                value={accessory.modelId?.name}
                disabled={true}
              />
              {accessory.oldModel ? (
                <Input
                  label={"Old Model (Deprecated)"}
                  value={accessory.oldModel}
                  disabled={true}
                />
              ) : null}

              <Input
                label={languageTexts[danisikLanguage].status}
                value={statusInfos[accessory.status]}
                disabled={true}
              />
              <Input
                label={languageTexts[danisikLanguage].accessuarDefinition}
                value={accessory.definition}
                disabled={true}
              />
            </div>
            <div className="button-container">
              <button
                className="delete"
                type="button"
                onClick={() => handleDeleteAccessuar(index)}
              >
                <DeleteBin2 width="2rem" height="2rem" />
                {languageTexts[danisikLanguage].delete}
              </button>
              <button
                className="edit"
                type="button"
                onClick={() => {
                  setAccessuarModalInfo({
                    _id: accessory._id,
                    index,
                    serialNumber: accessory.serialNumber,
                    status: accessory.status,
                    definition: accessory.definition,
                    images: accessory.images,
                    brand: accessory.brandId,
                    model: accessory.modelId,
                    deviceId: deviceId,
                    stockNumber: accessory.stockNumber,
                  });
                  setAddAccessuarModalOpen(true);
                }}
              >
                <EditPencil width="1.8rem" height="1.8rem" />
                {languageTexts[danisikLanguage].edit}
              </button>
            </div>
          </div>
        ))}
      </div>
      <FormButton
        title={languageTexts[danisikLanguage].save}
        onClick={updateAccessories}
      />
      <AddAccessuars
        setIsModalOpen={setAddAccessuarModalOpen}
        isModalOpen={addAccessuarModalOpen}
        accessuarModalInfo={accessuarModalInfo}
        setAccessuarModalInfo={setAccessuarModalInfo}
        accessuars={accessories}
        setAccessuars={setAccessories}
        getAccessories={getAccessories}
      />
    </div>
  );
}

export default Accessories;
