import FormButton from "components/Buttons/FormButton";
import Input from "components/FormElements/Input";
import { useFormik } from "formik";
import CustomAlert from "lib/customAlert.js";
import React from "react";
import ModalHeader from "../ModalHeader";
import "./style.scss";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
import addReferencePricePartValidationSchema from "./addReferencePricePartValidationSchema";
import CurrencyInput from "components/FormElements/CurrencyInput";

function AddReferencePricePart({
  isModalOpen = false,
  setIsModalOpen,
  referencePriceModalInfo = {
    _id: 0,
    title: "",
    salePrice: {
      currency: "EUR",
      value: "",
    },
  },
  setReferencePriceModalInfo,
  referencePrices,
  setReferencePrices,
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: referencePriceModalInfo.title,
      salePrice: referencePriceModalInfo?.salePrice || {
        currency: "EUR",
        value: "",
      },
    },
    validationSchema: addReferencePricePartValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        if (referencePriceModalInfo.index + 1) {
          referencePrices[referencePriceModalInfo.index] = values;
          setReferencePrices(referencePrices);
        } else {
          referencePrices.push(values);
          setReferencePrices(referencePrices);
        }
        setIsModalOpen(false);
        setReferencePriceModalInfo({
          _id: 0,
          title: "",
          description: {
            currency: "EUR",
            value: "",
          },
        });
      }
    },
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value);
  };

  const handleChangeCurrency = (e) => {
    formik.setFieldValue(`${e.name}.currency`, e.value);
  };
  const handleChangeCurrencyInputValue = (e) => {
    formik.setFieldValue(`${e.name}.value`, e.value);
  };
  return (
    <div
      className={`add-reference-prices-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal add-reference-prices-modal`}>
        <ModalHeader
          title={`${
            referencePriceModalInfo._id
              ? languageTexts[danisikLanguage].edit
              : languageTexts[danisikLanguage].add
          }`}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content">
          <form
            onSubmit={formik.handleSubmit}
            className="flow-content tab-device-form"
          >
            <Input
              label={languageTexts[danisikLanguage].title}
              value={formik.values.title}
              handleChange={handleChange}
              name="title"
              showError={formik.touched.title && Boolean(formik.errors.title)}
              error={formik.touched.title && formik.errors.title}
            />
            <CurrencyInput
              label={languageTexts[danisikLanguage].salePrice}
              value={formik.values.salePrice.value}
              selectedCurrency={formik.values.salePrice.currency}
              handleChangeCurrency={handleChangeCurrency}
              handleChange={handleChangeCurrencyInputValue}
              name="salePrice"
              showError={
                formik.touched.salePrice?.value &&
                Boolean(formik.errors.salePrice?.value)
              }
              error={
                formik.touched.salePrice?.value &&
                formik.errors.salePrice?.value
              }
            />
            <FormButton title={languageTexts[danisikLanguage].save} />
          </form>
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
    </div>
  );
}

export default AddReferencePricePart;
