import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.scss";
import DropZone from "../DropZone";
import EditPencil from "assets/icons/EditPencil";
import DownloadFile from "assets/icons/DownloadFile";
import Check from "assets/icons/Check";
import Close from "assets/icons/Close";
import DeleteBin2 from "assets/icons/DeleteBin2";
import CustomAlert from "lib/customAlert";
import { uuidv4 } from "utils/generateUUID";
import SwiperComponent from "components/Swiper";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";
import { saveAs } from "file-saver";

let pos1 = 0,
  xPos = 0;
let mouseDown = false;
let startX, scrollLeft;
let dragElement;
let dragging = false;
let imgWidth = 160;
let gapBetweenImages = 7;
function getOffset(el) {
  if (el !== null) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  }
  return null;
}
let imgLengthInContainer;
let maxDragPosition = 0;

const sortByIndex = (arr) => {
  return arr.sort(function (a, b) {
    return a.order * 1 - b.order * 1;
  });
};

function DragNDropPreview({
  disabled,
  canEdit = true,
  images = [],
  handleOrder = () => {},
  modelId = "",
  handleUpdateFile = () => {},
  handleDeleteImage = () => {},
  handleDeleteImageFormik = () => {},
  setImages = () => {},
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [swiperModalOpen, setSwiperModalOpen] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  const [leftValues, setLeftValues] = useState([]);

  const imageContainerRef = useRef(null);
  useEffect(() => {
    for (let i = 0; i < imageContainerRef.current.children.length; i++) {
      let currentChild = imageContainerRef.current.children[i];
    }
  });

  const [imagesCopy, setImagesCopy] = useState([]);
  const editable = !imagesCopy.some((img) => img.preview) && imagesCopy.length;
  useEffect(() => {
    setImagesCopy(JSON.parse(JSON.stringify(images)));
  }, [images]);

  const [isEditing, setIsEditing] = useState(false);
  const [imagesHashMap, setImagesHashMap] = useState({}); //id will be key other values will be the value

  //relocate all images
  const relocateAllImages = useCallback(() => {
    let newLeftValues = [];
    for (let i = 0; i < imageContainerRef.current.children.length; i++) {
      let currentChild = imageContainerRef.current.children[i];
      currentChild.style.left = i * (imgWidth + gapBetweenImages) + 10 + "px";
      newLeftValues.push(i * (imgWidth + gapBetweenImages) + 10 + "px");
    }
    imgLengthInContainer = imageContainerRef.current.children.length - 1;
    maxDragPosition =
      (imgLengthInContainer - 1) * (imgWidth + gapBetweenImages) + 50;
    setLeftValues(newLeftValues);
  }, [imagesCopy]);

  const updateImagesHashMap = useCallback(() => {
    let imagesHashMapToUpdate = {};
    imagesCopy.forEach((img) => {
      imagesHashMapToUpdate[img._id] = { url: img.url, order: img.order };
    });
    setImagesHashMap(imagesHashMapToUpdate);
  }, [imagesCopy]);

  useEffect(() => {
    updateImagesHashMap();
    relocateAllImages();
  }, [updateImagesHashMap, relocateAllImages]);

  const handleDragStart = (e) => {
    if (canEdit && isEditing) {
      dragElement = e.target.parentElement; //set dragElement
      if (dragElement.attributes.getNamedItem("image-id")?.value) {
        dragElement.style.transition = "none"; //no transition for dragElement for smooth experience
        xPos = e.clientX;
        pos1 = 0;
        dragging = true;
        dragElement.style.zIndex = 10; //dragElement will be on top
      }
    }
  };

  const handleClickImg = (index) => {
    if (!isEditing) {
      setSwiperModalOpen(true);
      setInitialSlide(index);
    }
  };

  const elementDrag = (e) => {
    if (dragging) {
      let scrollAmount = 0; // the reason we defined scrollAmount is that if we scroll to the right drag element should come to the right by scroll amount
      //if dragElement is at the edge of right, scroll to the right by 10
      if (
        imageContainerRef.current.scrollLeft + 20 <=
          imageContainerRef.current.scrollWidth -
            imageContainerRef.current.clientWidth &&
        e.clientX + 50 >
          imageContainerRef.current.offsetWidth +
            getOffset(imageContainerRef.current).left
      ) {
        imageContainerRef.current.scrollLeft += 10;
        scrollAmount = 10;
      }
      //if dragElement is at the edge of left, scroll to the left by 10
      else if (
        imageContainerRef.current.scrollLeft !== 0 &&
        e.clientX - 50 < getOffset(imageContainerRef.current).left
      ) {
        imageContainerRef.current.scrollLeft -= 10;
        scrollAmount = -10;
      }
      e = e || window.event;
      pos1 = xPos - e.clientX;
      xPos = e.clientX;
      // we limit the drag element going for right. For example if there are 3 images then it can go (imgWidth+gapBetweenImages)*3+50 at most
      if (
        dragElement.offsetLeft + scrollAmount - pos1 >
        (imgLengthInContainer - 1) * (imgWidth + gapBetweenImages) + 50
      ) {
        dragElement.style.left = maxDragPosition + "px";
      } else {
        // otherwise place normally
        dragElement.style.left =
          dragElement.offsetLeft + scrollAmount - pos1 + "px";
      }
      handleOrderWhileDragging();
    }
  };
  const handleOrderWhileDragging = () => {
    let dragElementInArray =
      imagesHashMap[dragElement.attributes.getNamedItem("image-id").value];
    let dragElementCurrentX = getOffset(dragElement).left;
    let dragElementIndex = dragElementInArray.order;
    for (let i = 0; i < imageContainerRef.current.children.length - 1; i++) {
      let currentChild = imageContainerRef.current.children[i];
      if (!dragElement.isEqualNode(currentChild)) {
        let currentElementX = getOffset(currentChild).left;
        let currentElementInArray =
          imagesHashMap[currentChild.attributes.getNamedItem("image-id").value];
        let currentElementIndex = currentElementInArray.order;
        //if drag element pass next image, next image comes previous location and its order is reduced by and dragElement's order incremented by 1
        //else if is opposite
        if (
          dragElementIndex < currentElementIndex &&
          dragElementCurrentX > currentElementX
        ) {
          currentChild.style.left =
            currentChild.offsetLeft - (imgWidth + gapBetweenImages) + "px";
          imagesHashMap[currentChild.attributes.getNamedItem("image-id").value]
            .order--;
          imagesHashMap[dragElement.attributes.getNamedItem("image-id").value]
            .order++;
        } else if (
          dragElementIndex > currentElementIndex &&
          dragElementCurrentX < currentElementX
        ) {
          currentChild.style.left =
            currentChild.offsetLeft + (imgWidth + gapBetweenImages) + "px";
          imagesHashMap[currentChild.attributes.getNamedItem("image-id").value]
            .order++;
          imagesHashMap[dragElement.attributes.getNamedItem("image-id").value]
            .order--;
        }
      }
    }
  };

  //reset when drag end
  const handleDragEnd = () => {
    if (dragging) {
      dragging = false;
      dragElement.style.zIndex = 0;
      dragElement.style.transition = "all 0.2s";
      setImagesHashMap(JSON.parse(JSON.stringify(imagesHashMap)));
      handleSortImageArr();
    }
  };

  const handleTouchMoveStart = (e) => {
    if (canEdit && isEditing) {
      dragElement = e.target.parentElement; //set dragElement
      if (dragElement.attributes.getNamedItem("image-id")?.value) {
        dragElement.style.transition = "none"; //no transition for dragElement for smooth experience
        xPos = e.clientX;
        pos1 = 0;
        dragging = true;
        dragElement.style.zIndex = 10; //dragElement will be on top
      }
    }
  };
  const elementTouchMoveDrag = (e) => {
    if (dragging) {
      let scrollAmount = 0; // the reason we defined scrollAmount is that if we scroll to the right drag element should come to the right by scroll amount
      //if dragElement is at the edge of right, scroll to the right by 10
      if (
        imageContainerRef.current.scrollLeft + 20 <=
          imageContainerRef.current.scrollWidth -
            imageContainerRef.current.clientWidth &&
        e.touches[0].clientX + 50 >
          imageContainerRef.current.offsetWidth +
            getOffset(imageContainerRef.current).left
      ) {
        imageContainerRef.current.scrollLeft += 10;
        scrollAmount = 10;
      }
      //if dragElement is at the edge of left, scroll to the left by 10
      else if (
        imageContainerRef.current.scrollLeft !== 0 &&
        e.touches[0].clientX - 50 < getOffset(imageContainerRef.current).left
      ) {
        imageContainerRef.current.scrollLeft -= 10;
        scrollAmount = -10;
      }
      e = e || window.event;
      pos1 = xPos - e.touches[0].clientX;
      xPos = e.touches[0].clientX;
      // we limit the drag element going for right. For example if there are 3 images then it can go (imgWidth+gapBetweenImages)*3+50 at most
      if (
        dragElement.offsetLeft + scrollAmount - pos1 >
        (imgLengthInContainer - 1) * (imgWidth + gapBetweenImages) + 50
      ) {
        dragElement.style.left = maxDragPosition + "px";
      } else {
        // otherwise place normally
        dragElement.style.left =
          dragElement.offsetLeft + scrollAmount - pos1 + "px";
      }
      handleOrderWhileDragging();
    }
  };

  //sort image array by order and set
  const handleSortImageArr = () => {
    let imagesCopyNew = JSON.parse(JSON.stringify(imagesCopy));
    imagesCopyNew = imagesCopyNew.map((img) => {
      img.order = imagesHashMap[img._id].order;
      return img;
    });
    imagesCopyNew = sortByIndex(imagesCopyNew);
    setImagesCopy(imagesCopyNew);
  };

  const handleUpdateFiles = (acceptStatus) => {
    setIsEditing(false);
    if (acceptStatus) {
      //setUpdatedFiles(JSON.parse(JSON.stringify(images)));
      handleOrder(imagesCopy, modelId);
    } else {
      setImagesCopy(JSON.parse(JSON.stringify(images)));
    }
  };

  const handleDropzoneAddFile = (e) => {
    setImages([...images, ...e]);
  };

  const removeFile = async (file) => {
    if (file.public_id) {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].areYouSureToDelete
      );
      if (result) {
        handleDeleteImage(file.public_id);
      }
    } else {
      let newFiles = images.filter((img) => img._id !== file._id);
      newFiles = sortByIndex(newFiles);
      newFiles = newFiles.map((file, order) => {
        if (file) {
          file.order = order + 1;
        }

        return file;
      });
      handleDeleteImageFormik(file);
      setImages(newFiles);
    }
  };

  let startDragging = function (e) {
    if (!dragging) {
      imageContainerRef.current.style.cursor = "grabbing";
      mouseDown = true;
      startX = e.pageX - imageContainerRef.current.offsetLeft;
      scrollLeft = imageContainerRef.current.scrollLeft;
    }
  };
  let stopDragging = function (event) {
    mouseDown = false;
    imageContainerRef.current.style.cursor = "grab";
  };
  const handleMouseMoveImgContainer = (e) => {
    if (!dragging) {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - imageContainerRef.current.offsetLeft;
      const scroll = x - startX;
      imageContainerRef.current.scrollLeft = scrollLeft - scroll;
    }
  };

  const downloadAllImages = () => {
    images.map((file, index) => {
      if (file.url) {
        let newUrl = file.url + "?t=" + new Date().getTime();
        saveAs(newUrl, `${index + 1}.jpg`);
      }
      if (file.preview) {
        saveAs(file.preview, `${index + 1}.jpg`);
      }
    });
  };

  return (
    <div className="drag-and-drop-preview">
      {canEdit && editable ? (
        <div className="edit-side">
          {isEditing && !disabled ? (
            <>
              <div
                className="check-icon-container icon-container"
                onClick={() => handleUpdateFiles(true)}
              >
                <Check
                  width="2.4rem"
                  height="2.4rem"
                  fill="var(--clr-neutral-200)"
                />
              </div>
              <div
                className="close-icon-container icon-container"
                onClick={() => handleUpdateFiles(false)}
              >
                <Close
                  width="2.4rem"
                  height="2.4rem"
                  fill="var(--clr-neutral-200)"
                />
              </div>
            </>
          ) : (
            <>
              {!disabled ? (
                <div
                  className="edit-icon-container icon-container"
                  title="Edit image order"
                  onClick={() => setIsEditing(true)}
                >
                  <EditPencil />
                </div>
              ) : null}

              <div
                className="print-icon-container icon-container"
                title="Download all images"
                onClick={downloadAllImages}
              >
                <DownloadFile />
              </div>
            </>
          )}
        </div>
      ) : null}

      <div
        className={`image-container ${canEdit && isEditing ? "editing" : ""}`}
        style={{
          height: `${imgWidth + 20}px`,
        }}
        ref={imageContainerRef}
        onMouseMove={handleMouseMoveImgContainer}
        onMouseDown={startDragging}
        onMouseUp={stopDragging}
        onMouseLeave={stopDragging}
      >
        {imagesCopy.map((image, index) => (
          <div
            className={`img ${isEditing ? "editing" : ""}`}
            draggable
            image-id={image?._id}
            order={image?.order}
            key={uuidv4()}
            style={{ width: imgWidth, left: leftValues[index], top: "10px" }}
            onTouchStart={handleTouchMoveStart}
            onTouchMove={elementTouchMoveDrag}
            onTouchEnd={handleDragEnd}
            onTouchCancel={handleDragEnd}
            onMouseDown={handleDragStart}
            onMouseMove={elementDrag}
            onMouseUp={handleDragEnd}
            onDragStart={(event) => event.preventDefault()}
            onMouseLeave={handleDragEnd}
          >
            <img
              style={{ width: imgWidth }}
              src={image.url ? image.url : image.preview}
              alt={image?.order}
              image-id={image._id}
              onClick={() => handleClickImg(index)}
            />
            <div
              className={`${disabled ? "delete-icon-disabled" : "delete-icon"}`}
              onClick={() => removeFile(image)}
            >
              <DeleteBin2 />
            </div>
          </div>
        ))}
        {!disabled ? (
          <DropZone
            files={imagesCopy}
            setFiles={handleDropzoneAddFile}
            width={imgWidth}
            isEditing={isEditing}
          />
        ) : null}
      </div>
      <SwiperComponent
        files={images}
        setSwiperModalOpen={setSwiperModalOpen}
        swiperModalOpen={swiperModalOpen}
        initialSlide={initialSlide}
      />
    </div>
  );
}

export default DragNDropPreview;
