import React, { useEffect, useState } from "react";
import "./style.scss";
import { useFormik } from "formik";
import storageValidationSchema from "./storageValidationSchema";
import CustomAlert from "lib/customAlert";
import Input from "components/FormElements/Input";
import DeleteBin2 from "assets/icons/DeleteBin2";
import EditPencil from "assets/icons/EditPencil";
import AddCircle from "assets/icons/AddCircle";
import FormButton from "components/Buttons/FormButton";
import DeviceStorageService from "services/product/device/deviceStorageService";
import LoadingPage from "components/LoadingPage";
import AddDeviceLocation from "components/Modals/AddDeviceLocation";
import { toast } from "react-toastify";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";

const handleFormatDate = (date) => {
  if (date) {
    return new Date(date).toISOString().split("T")[0];
  }
  return "";
};
const deviceStorageService = new DeviceStorageService();
function Storage({ deviceId }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [isLoading, setIsLoading] = useState(false);
  const [deviceLocations, setDeviceLocations] = useState([]);
  const [deviceLocationModalInfo, setDeviceLocationModalInfo] = useState({
    _id: 0,
    title: "",
    description: "",
  });
  const [addDeviceLocationModalOpen, setAddDeviceLocationModalOpen] =
    useState(false);
  const formik = useFormik({
    initialValues: {
      _id: 0,
      inStockDate: "",
      outStockDate: "",
    },
    validationSchema: storageValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        setIsLoading(true);
        deviceStorageService
          .updateNormal(values._id, {
            inStockDate: values.inStockDate,
            outStockDate: values.outStockDate,
            deviceLocation: deviceLocations,
            deviceId,
          })
          .then(() =>
            toast.success(languageTexts[danisikLanguage].operationSuccessful)
          )
          .finally(() => setIsLoading(false));
      }
    },
  });

  useEffect(() => {
    setIsLoading(true);
    deviceStorageService
      .getAllByDeviceId(deviceId)
      .then((res) => {
        let storageValues = res.data.data;
        formik.setFieldValue("inStockDate", storageValues.inStockDate);
        formik.setFieldValue("outStockDate", storageValues.outStockDate);
        formik.setFieldValue("_id", storageValues._id);

        setDeviceLocations(storageValues.deviceLocation);
      })
      .finally(() => setIsLoading(false));
  }, [deviceId]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value);
  };

  const handleDeleteDeviceLocation = async (index) => {
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].OK
    );
    if (result) {
      const newDeviceLocation = [
        ...deviceLocations.slice(0, index),
        ...deviceLocations.slice(index + 1),
      ];
      setDeviceLocations(newDeviceLocation);
    }
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <div
      className="storage-container flow-content"
      onSubmit={formik.handleSubmit}
    >
      <Input
        type="date"
        label={languageTexts[danisikLanguage].inStockDate}
        value={handleFormatDate(formik.values.inStockDate)}
        handleChange={handleChange}
        name="inStockDate"
        showError={
          formik.touched.inStockDate && Boolean(formik.errors.inStockDate)
        }
        error={formik.touched.inStockDate && formik.errors.inStockDate}
      />
      <Input
        type="date"
        label={languageTexts[danisikLanguage].outStockDate}
        value={handleFormatDate(formik.values.outStockDate)}
        handleChange={handleChange}
        name="outStockDate"
        showError={
          formik.touched.outStockDate && Boolean(formik.errors.outStockDate)
        }
        error={formik.touched.outStockDate && formik.errors.outStockDate}
      />
      <div className="device-location-container">
        <div className="device-location-header">
          <h3 className="section-title">
            {languageTexts[danisikLanguage].productLocation}
          </h3>
          <div
            className="add-circle-container"
            onClick={() => {
              setDeviceLocationModalInfo({
                _id: 0,
                title: "",
                description: "",
              });
              setAddDeviceLocationModalOpen(true);
            }}
          >
            <AddCircle />
          </div>
        </div>
        <div className="device-location-body flow-content">
          {deviceLocations?.length
            ? deviceLocations.map((deviceLocation, index) => (
                <div className="device-location-item" key={index}>
                  <div className="device-location-info flow-content">
                    <Input
                      label="Title"
                      value={deviceLocation.title}
                      disabled={true}
                    />
                    <Input
                      label={languageTexts[danisikLanguage].description}
                      value={deviceLocation.description}
                      disabled={true}
                    />
                  </div>
                  <div className="button-container">
                    <button
                      className="delete"
                      type="button"
                      onClick={() => handleDeleteDeviceLocation(index)}
                    >
                      <DeleteBin2 width="2rem" height="2rem" />
                      {languageTexts[danisikLanguage].delete}
                    </button>
                    <button
                      className="edit"
                      type="button"
                      onClick={() => {
                        setDeviceLocationModalInfo({
                          _id: deviceLocation._id,
                          title: deviceLocation.title,
                          description: deviceLocation.description,
                          index,
                        });
                        setAddDeviceLocationModalOpen(true);
                      }}
                    >
                      <EditPencil width="1.8rem" height="1.8rem" />
                      {languageTexts[danisikLanguage].edit}
                    </button>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      <FormButton
        title={languageTexts[danisikLanguage].save}
        onClick={formik.handleSubmit}
      />
      <AddDeviceLocation
        setIsModalOpen={setAddDeviceLocationModalOpen}
        isModalOpen={addDeviceLocationModalOpen}
        deviceLocationModalInfo={deviceLocationModalInfo}
        setDeviceLocationModalInfo={setDeviceLocationModalInfo}
        deviceLocations={deviceLocations}
        setDeviceLocations={setDeviceLocations}
      />
    </div>
  );
}

export default Storage;
