import ArrowRight from "assets/icons/ArrowRight";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import React, { useRef, useState } from "react";
import tr from "assets/images/tr.png";
import en from "assets/images/en.png";
import ar from "assets/images/ar.png";
import de from "assets/images/de.png";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "redux/language/languageActions";
import languageTexts from "constants/languageTexts";

function ChangeLanguage() {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, function () {
    setIsDropdownOpen(false);
  });
  const handleChangeLanguage = (languageCode) => {
    dispatch(setLanguage(languageCode));
    setIsDropdownOpen(false);
  };
  return (
    <div className="setting-item-container" ref={wrapperRef}>
      <div className="setting-item" onClick={() => setIsDropdownOpen(true)}>
        <span>{languageTexts[danisikLanguage].changeLanguage}</span>
        <ArrowRight />
      </div>
      <div className={`setting-item-dropdown ${isDropdownOpen ? "open" : ""}`}>
        <span
          className="dropdown-item language"
          onClick={() => handleChangeLanguage("tr")}
        >
          <img src={tr} alt="TR Flag" />
          Türkçe
        </span>
        <span
          className="dropdown-item language"
          onClick={() => handleChangeLanguage("en")}
        >
          <img src={en} alt="TR Flag" />
          English
        </span>
        <span
          className="dropdown-item language"
          onClick={() => handleChangeLanguage("ar")}
        >
          <img src={ar} alt="TR Flag" />
          Arabic
        </span>
        <span
          className="dropdown-item language"
          onClick={() => handleChangeLanguage("de")}
        >
          <img src={de} alt="TR Flag" />
          Deutsch
        </span>
      </div>
    </div>
  );
}

export default ChangeLanguage;
