import * as Yup from "yup";
export default Yup.object().shape({
  salePrice: Yup.object(),
  serialNumber: Yup.string().required("required"),
  brand: Yup.object().shape({
    _id: Yup.string().required("Required"),
  }),
  model: Yup.object().shape({
    _id: Yup.string().required("Required"),
  }),
  dotmedCategory: Yup.object().shape({
    _id: Yup.string().required("Required"),
  }),
  productionYear: Yup.number()
    .min(1800, "Please enter valid year")
    .max(new Date().getFullYear() + 1, "Please enter valid year")
    .required("Production Year is required"),
  deviceInfo: Yup.string(),
  deviceStatus: Yup.object().shape({
    _id: Yup.string().required("Required"),
  }),

  physicalInfos: Yup.object().shape({
    width: Yup.number(),
    height: Yup.number(),
    size: Yup.number(),
    weight: Yup.number(),
  }),
});
