import "./style.scss";
import ModalHeader from "components/Modals/ModalHeader";
import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";
import WaitingForApprovalService from "services/admin/waitingForApprovalService";
import PrimaryButton from "components/Buttons/PrimaryButton";
import DeleteBin2 from "assets/icons/DeleteBin2";
import { toast } from "react-toastify";

const waitingForApprovalService = new WaitingForApprovalService();
function WaitingForApprovalModal({ isModalOpen = false, setIsModalOpen }) {
  const [loading, setLoading] = useState(false);
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [waitingForApprovals, setWaitingForApprovals] = useState([]);
  const getAllWaitingApprovals = useCallback(() => {
    waitingForApprovalService.getWaitingForApprovalss().then((res) => {
      setWaitingForApprovals(res.data.data.users);
    });
  }, []);

  useEffect(() => {
    getAllWaitingApprovals();
  }, [getAllWaitingApprovals]);

  const handleApprove = (id) => {
    setLoading(true);
    waitingForApprovalService
      .approveUser(id)
      .then(() => {
        toast.success(languageTexts[danisikLanguage].userApprovedSuccessfully);
        getAllWaitingApprovals();
      })
      .catch((err) => {
        toast.error(err.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDisapprove = (id) => {
    setLoading(true);
    waitingForApprovalService
      .disApproveUser(id)
      .then(() => {
        toast.success(
          languageTexts[danisikLanguage].userDisapprovedSuccessfully
        );
        getAllWaitingApprovals();
      })
      .catch((err) => {
        toast.error(err.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className={`waiting-for-approval-modal-container modal-container settings-modals ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal waiting-for-approval-modal`}>
        <ModalHeader
          title={languageTexts[danisikLanguage].waitingForApprovals}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content settings-modal-content">
          <div className="settings-modal-body">
            <div className="item-container">
              {waitingForApprovals.map((waitingForApproval) => (
                <div
                  className="waiting-for-approval-item"
                  key={waitingForApproval._id}
                >
                  <div className="first-part">
                    <span>{waitingForApproval.name}</span>
                    <span>{waitingForApproval.email}</span>
                  </div>
                  <div className="second-part">
                    <PrimaryButton
                      title={languageTexts[danisikLanguage].approveUser}
                      onClick={() => handleApprove(waitingForApproval._id)}
                      disabled={loading}
                    />
                    <div
                      className={`disapprove-icon-container icon-container ${
                        loading ? "disabled" : ""
                      }`}
                      title={languageTexts[danisikLanguage].disApproveUserTitle}
                      onClick={() => handleDisapprove(waitingForApproval._id)}
                    >
                      <DeleteBin2
                        fill="var(--clr-neutral-100)"
                        width="2.8rem"
                        height="2.8rem"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
    </div>
  );
}

export default WaitingForApprovalModal;
