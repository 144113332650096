import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.scss";

function ReactQuillWrapper({ value, label, name, handleChange, ...props }) {
  const onChange = (val) => {
    const e = { target: { name, value: val } };
    handleChange(e);
  };
  return (
    <div className="react-quill-wrapper">
      {label ? <span className="label-container">{label}</span> : null}
      <ReactQuill value={value} onChange={onChange} {...props} />
    </div>
  );
}

export default ReactQuillWrapper;
