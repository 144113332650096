import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import ModalHeader from "components/Modals/ModalHeader";
import SearchInput from "components/Search/SearchInput";
import Add from "assets/icons/Add";
import EditPencil from "assets/icons/EditPencil";
import CategoryAdd from "../CategoryAdd";
import CategoryService from "services/admin/category";
import LoadingPage from "components/LoadingPage";
import { debounce } from "utils/debounce";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";

const categoryService = new CategoryService();
function CategoryList({ isModalOpen = false, setIsModalOpen }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
  const [addCategoryModalInfo, setAddCategoryModalInfo] = useState({
    _id: 0,
    name: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const [categoryCurrentPage, setCategoryCurrentPage] = useState(1);
  const [categoryPageCount, setCategoryPageCount] = useState(1);
  const [categorySearchText, setCategorySearchText] = useState("");
  const [categorySearchTextForSearch, setCategorySearchTextForSearch] =
    useState("");

  const getAllCategories = useCallback(() => {
    setIsLoading(true);
    categoryService
      .getAll()
      .then((res) => {
        setCategories(res.data.data);
        setCategoryPageCount(res.data.totalPage ? res.data.totalPage : 1);
      })
      .finally(() => setIsLoading(false));
  }, []);
  useEffect(() => {
    getAllCategories();
  }, [getAllCategories]);

  const handleScrollDropdownDebounce = debounce((e) => {
    handleScrollDropdown(e);
  }, 350);

  const handleScrollBottomModel = () => {
    if (categoryPageCount > categoryCurrentPage) {
      setCategoryCurrentPage(categoryCurrentPage + 1);
    }
  };
  const handleScrollDropdown = (e) => {
    const isScrollAtTheBottom =
      e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 1;
    if (isScrollAtTheBottom) {
      handleScrollBottomModel();
    }
  };

  useEffect(() => {
    if (categoryCurrentPage > 1) {
      setIsLoading(true);
      categoryService
        .getAll(`?limit=15&page=${categoryCurrentPage}`)
        .then((res) => {
          let newCategories = [...categories, ...res.data.data];
          setCategories(newCategories);
        })
        .finally(() => setIsLoading(false));
    }
  }, [categoryCurrentPage]);
  useEffect(() => {
    categoryService
      .getAll(`?limit=15&search=${categorySearchText}`)
      .then((res) => {
        setCategories(res.data.data);
        setCategoryCurrentPage(1);
      });
  }, [categorySearchTextForSearch]);

  const handleChangeFilterInput = (e) => {
    setCategorySearchTextForSearch(e);
  };

  const handleChangeFilterInputDebounce = useCallback(
    debounce((val) => {
      handleChangeFilterInput(val);
    }, 350),
    []
  );

  useEffect(() => {
    handleChangeFilterInputDebounce(categorySearchText);
  }, [categorySearchText, handleChangeFilterInputDebounce]);
  return (
    <div
      className={`category-list-modal-container modal-container settings-modals ${
        isModalOpen ? "open" : ""
      }`}
    >
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <div className={`modal category-list-modal`}>
            <ModalHeader
              title={languageTexts[danisikLanguage].category}
              setIsModalOpen={setIsModalOpen}
            />
            <div className="modal-content settings-modal-content">
              <div className="modal-body-header">
                <div className="search-input-container">
                  <SearchInput
                    onChange={(e) => setCategorySearchText(e.target.value)}
                    value={categorySearchText}
                  />
                </div>
                <div
                  className="add-button-container"
                  onClick={() => {
                    setAddCategoryModalInfo({
                      name: "",
                      _id: 0,
                    });
                    setAddCategoryModalOpen(true);
                  }}
                >
                  <Add />
                </div>
              </div>
              <div
                className="settings-modal-body"
                onScroll={handleScrollDropdownDebounce}
              >
                <div className="item-container">
                  {categories.map((category) => (
                    <div className="item" key={category._id}>
                      <span>{category.name}</span>
                      <div
                        className="edit-icon-container"
                        onClick={() => {
                          setAddCategoryModalInfo({
                            name: category.name,
                            _id: category._id,
                          });
                          setAddCategoryModalOpen(true);
                        }}
                      >
                        <EditPencil />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className="modal-overlay"
              onClick={() => setIsModalOpen(false)}
            ></div>
          </div>
          <CategoryAdd
            setIsModalOpen={setAddCategoryModalOpen}
            isModalOpen={addCategoryModalOpen}
            addCategoryModalInfo={addCategoryModalInfo}
            getAllCategories={getAllCategories}
          />
        </>
      )}
    </div>
  );
}

export default CategoryList;
