import FactoryService from "services/factoryService";

export default class WaitingForApprovalService extends FactoryService {
  constructor() {
    super("user");
  }
  getWaitingForApprovalss() {
    return this.api.get(`${this.serviceName}/waitingForApproval`);
  }
  approveUser(id) {
    return this.api.post(`${this.serviceName}/approveUser/${id}`);
  }
  disApproveUser(id) {
    return this.api.delete(`${this.serviceName}/disApproveUser/${id}`);
  }
}
