import React from "react";
import "./style.scss";
import DownloadFile from "assets/icons/DownloadFile";

function ButtonWithIcon({
  title = "Download",
  backgroundColor = "var(--clr-blue)",
  icon = <DownloadFile />,
  onClick,
  ...props
}) {
  return (
    <button
      className="button-with-icon"
      style={{ backgroundColor: backgroundColor }}
      onClick={onClick}
      {...props}
    >
      {icon}
      {title}
    </button>
  );
}

export default ButtonWithIcon;
