import React from "react";
import "./style.scss";
import DragNDropPreview from "./DragNDropPreview";

function DragAndDropNew({
  images = [],
  handleOrder = () => {},
  modelId = "",
  disabled = false,
  handleUpdateFile = () => {},
  handleDeleteImage = () => {},
  handleDeleteImageFormik = () => {},
  setImages = () => {},
}) {
  return (
    <div className="drag-and-drop-container">
      <DragNDropPreview
        images={images}
        handleOrder={handleOrder}
        modelId={modelId}
        disabled={disabled}
        handleUpdateFile={handleUpdateFile}
        handleDeleteImage={handleDeleteImage}
        handleDeleteImageFormik={handleDeleteImageFormik}
        setImages={setImages}
      />
    </div>
  );
}

export default DragAndDropNew;
