import React, { useState } from "react";
import "./style.scss";
import danisikLogo from "assets/images/danisik_logo.png";
import Notification from "assets/icons/Notification";
import Add from "assets/icons/Add";
import Menu from "assets/icons/Menu";
import SearchInput from "components/Search/SearchInput";
import { Link, useLocation } from "react-router-dom";
import Search from "components/Search";
import NotificationsModal from "components/Modals/Notifications";
import AddProductModal from "components/Modals/AddProduct";
import { useEffect } from "react";

import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
import RecentOperationService from "services/admin/recentOperationService";

const locationMap = {
  "/": "Dashboard",
  "/get-stock": "Get Stock",
  "/devices": "Devices",
  "/parts": "Parts",
  "/settings": "Settings",
  "/inactives": "Inactives",
  "/inactive-parts": "inactiveParts",
};

const notificationService = new RecentOperationService();
function Header({ setSidePanelOpen }) {
  const user = useSelector((state) => state.danisikUser?.user);
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [unreadNotificationExists, setUnreadNotificationExists] =
    useState(false);
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    notificationService.isThereUnreadNotification().then((res) => {
      setUnreadNotificationExists(res.data.notification);
    });
  }, []);

  return (
    <div className="header">
      <div
        className="mobile-menu-icon-container"
        onClick={() => setSidePanelOpen(true)}
      >
        <Menu />
      </div>
      <div className="mobile-menu-title">
        <span>
          {languageTexts[danisikLanguage][locationMap[location.pathname]] ||
            locationMap[location.pathname]}
        </span>
      </div>
      <Link to={"/"} className="logo">
        <img src={danisikLogo} alt="company logo" />
      </Link>
      <div
        className="header-search-input"
        onClick={() => setIsSearchOpen(true)}
      >
        <SearchInput placeholder={languageTexts[danisikLanguage].search} />
      </div>
      <div className="app-name">
        <span>Medical Stock Management Tool</span>
      </div>
      {user && user.role !== "user" ? (
        <div
          className="notification"
          onClick={() => setIsNotificationsModalOpen(true)}
        >
          <span
            className={`${
              unreadNotificationExists ? "unread-notification-exists" : ""
            }`}
          ></span>
          <Notification />
        </div>
      ) : null}

      <div className="add-web" onClick={() => setIsAddProductModalOpen(true)}>
        <Add />
      </div>
      {isSearchOpen ? <Search setIsSearchOpen={setIsSearchOpen} /> : null}
      <NotificationsModal
        setIsModalOpen={setIsNotificationsModalOpen}
        isModalOpen={isNotificationsModalOpen}
      />
      <AddProductModal
        setIsModalOpen={setIsAddProductModalOpen}
        isModalOpen={isAddProductModalOpen}
      />
    </div>
  );
}

export default Header;
