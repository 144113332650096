import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import dotmed from "assets/images/dotmed.png";
import shopify from "assets/images/shopify.png";
import ebay from "assets/images/ebay.png";
import Euro from "assets/icons/Euro";
import Dollar from "assets/icons/Dollar";
import Pound from "assets/icons/Pound";
import TurkishLira from "assets/icons/TurkishLira";
import ECommerceService from "services/product/part/eCommerceService";
import LoadingPage from "components/LoadingPage";
import { toast } from "react-toastify";
import CustomAlert from "lib/customAlert.js";
import { dateFormatToDDMMYYYDayHHMMSS } from "utils/dateFormat";
import PrimaryButton from "components/Buttons/PrimaryButton";
import DeleteButton from "components/Buttons/DeleteButton";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";
import CurrencyInput from "components/FormElements/CurrencyInput";
import PartService from "services/product/part/part";

const currencies = {
  EUR: <Euro />,
  USD: <Dollar />,
  GBP: <Pound />,
  TRY: <TurkishLira />,
};

const eCommerceService = new ECommerceService();
const partService = new PartService();
function ECommerce({ partId }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const user = useSelector((state) => state.danisikUser?.user);
  const hasPermission =
    user && (user.role === "admin" || user.role === "buyer");
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState({
    currency: "EUR",
    value: "",
  });
  const [referencePrices, setReferencePrices] = useState([]);
  const [isShopifyModalOpen, setIsShopifyModalOpen] = useState(false);
  const [eCommerceId, setECommerceId] = useState(0);
  const [eCommerces, setECommerces] = useState({
    dotmed: null,
    shopify: null,
    ebay: null,
  });
  const getEcommerceInformations = useCallback(() => {
    if (hasPermission) {
      setLoading(true);
      eCommerceService
        .getECommerceByPartId(partId)
        .then((res) => {
          let eCommerceInfo = res.data.data;
          let eCommercesToSet = eCommerceInfo?.length
            ? eCommerceInfo[0].eCommerceSites
            : { dotmed: null, shopify: null, ebay: null };
          setECommerces(eCommercesToSet);
          setECommerceId(eCommerceInfo?.length ? eCommerceInfo[0]._id : 0);
        })
        .finally(() => setLoading(false));
    }
  }, [partId]);

  const getPart = useCallback(() => {
    setLoading(true);
    partService
      .getById(partId)
      .then((res) => {
        let partInfosData = res.data.data;
        setPrice(partInfosData.price);
        setReferencePrices(partInfosData.referencePrices);
      })
      .finally(() => setLoading(false));
  }, [partId]);

  useEffect(() => {
    getPart();
  }, [getPart]);

  useEffect(() => {
    getEcommerceInformations();
  }, [getEcommerceInformations]);

  const handleListInShopifyOpenModal = () => {
    setIsShopifyModalOpen(true);
  };

  const handleListInShopify = () => {
    setLoading(true);
    if (eCommerces.shopify && eCommerces.shopify.siteLink) {
      eCommerceService
        .updateShopify(eCommerceId, partId, price)
        .then((res) => {
          toast.success(languageTexts[danisikLanguage].listedSuccessfully);
          getEcommerceInformations();
        })
        .finally(() => setLoading(false));
    } else {
      eCommerceService
        .listInShopify(eCommerceId, partId, price)
        .then((res) => {
          toast.success(languageTexts[danisikLanguage].listedSuccessfully);
          getEcommerceInformations();
        })
        .finally(() => setLoading(false));
    }
  };

  const handleListIn = async (listIn, price) => {
    let functionToInvoke = eCommerceService.listInDotmed.bind(eCommerceService);
    switch (listIn) {
      case "shopify":
        functionToInvoke =
          eCommerceService.listInShopify.bind(eCommerceService);
        break;
      case "ebay":
        functionToInvoke = eCommerceService.listInEbay.bind(eCommerceService);
        break;
      case "dotmed":
        functionToInvoke = eCommerceService.listInDotmed.bind(eCommerceService);
        break;
      default:
        console.log("unhandled list option clicked");
        break;
    }
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].OK
    );
    if (result) {
      setLoading(true);
      functionToInvoke(eCommerceId, partId)
        .then((res) => {
          toast.success(languageTexts[danisikLanguage].listedSuccessfully);
          getEcommerceInformations();
        })
        .finally(() => setLoading(false));
    }
  };

  const updateInECommerce = async (listIn) => {
    let functionToInvoke = eCommerceService.updateDotmed.bind(eCommerceService);
    switch (listIn) {
      case "shopify":
        functionToInvoke =
          eCommerceService.updateShopify.bind(eCommerceService);
        break;
      case "dotmed":
        functionToInvoke = eCommerceService.updateDotmed.bind(eCommerceService);
        break;
      default:
        console.log("unhandled list option clicked");
        break;
    }
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].areYouSureToSyncProduct
    );
    if (result) {
      setLoading(true);
      functionToInvoke(eCommerceId, partId)
        .then((res) => {
          toast.success(languageTexts[danisikLanguage].operationSuccessful);
          getEcommerceInformations();
        })
        .finally(() => setLoading(false));
    }
  };
  const deleteInECommerce = async (listIn) => {
    let functionToInvoke =
      eCommerceService.deleteFromDotmed.bind(eCommerceService);
    switch (listIn) {
      case "shopify":
        functionToInvoke =
          eCommerceService.deleteFromShopify.bind(eCommerceService);
        break;
      case "dotmed":
        functionToInvoke =
          eCommerceService.deleteFromDotmed.bind(eCommerceService);
        break;
      default:
        console.log("unhandled list option clicked");
        break;
    }
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].OK
    );
    if (result) {
      setLoading(true);
      functionToInvoke(eCommerceId, partId)
        .then((res) => {
          toast.success(languageTexts[danisikLanguage].operationSuccessful);
          getEcommerceInformations();
        })
        .finally(() => setLoading(false));
    }
  };

  const handleChangeCurrency = (e) => {
    setPrice({ ...price, currency: e.value });
  };
  const handleChangeCurrencyInputValue = (e) => {
    setPrice({ ...price, value: e.value });
  };

  return loading ? (
    <LoadingPage />
  ) : hasPermission ? (
    <div className="e-commerce-container flow-content">
      <div className="e-commerce-item">
        <div className="e-commerce-img-container">
          <img src={dotmed} alt={"Dotmed logo"} />
        </div>
        <div className="e-commerce-info">
          {eCommerces.dotmed?.siteLink ? (
            <>
              <>
                <span className="e-commerce-title">
                  {languageTexts[danisikLanguage].inTheAdvertisementSince}{" "}
                  {dateFormatToDDMMYYYDayHHMMSS(eCommerces.dotmed.addDate)}
                </span>
                <span>
                  {languageTexts[danisikLanguage].link}:{" "}
                  <a
                    href={eCommerces.dotmed.siteLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {eCommerces.dotmed.siteLink}
                  </a>
                </span>
                <span className="price">
                  {languageTexts[danisikLanguage].price}:{" "}
                  {eCommerces.dotmed.price.value}
                  {currencies[eCommerces.dotmed.price.currency]}
                </span>
                <span className="listing-id">
                  {languageTexts[danisikLanguage].listingId}:{" "}
                  {eCommerces.dotmed.listingId}
                </span>
                <div className="btn-container">
                  <PrimaryButton
                    title={languageTexts[danisikLanguage].sync}
                    style={{ backgroundColor: "var(--clr-blue)" }}
                    onClick={() => updateInECommerce("dotmed")}
                  />
                  <DeleteButton
                    title={languageTexts[danisikLanguage].delete}
                    onClick={() => deleteInECommerce("dotmed")}
                  />
                </div>
              </>
            </>
          ) : (
            <>
              <span className="e-commerce-title">
                {languageTexts[danisikLanguage].notListedYet}
              </span>
              <button
                className="list-button"
                onClick={() => handleListIn("dotmed")}
              >
                {languageTexts[danisikLanguage].clickForList}
              </button>
            </>
          )}
        </div>
      </div>
      <div className="e-commerce-item">
        <div className="e-commerce-img-container">
          <img src={shopify} alt={"Shopify Logo"} />
        </div>
        <div className="e-commerce-info">
          {eCommerces.shopify?.siteLink ? (
            <>
              <>
                <span className="e-commerce-title">
                  {languageTexts[danisikLanguage].inTheAdvertisementSince}{" "}
                  {eCommerces.shopify.addDate}
                </span>
                <span>
                  {languageTexts[danisikLanguage].link}:{" "}
                  <a
                    href={eCommerces.shopify.siteLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {eCommerces.shopify.siteLink}
                  </a>
                </span>
                <span className="price">
                  {languageTexts[danisikLanguage].price}:{" "}
                  {eCommerces.shopify.price.value}
                  {currencies[eCommerces.shopify.price.currency]}
                </span>
                <span className="listing-id">
                  {languageTexts[danisikLanguage].listingId}:{" "}
                  {eCommerces.shopify.shopifyId}
                </span>
                <div className="btn-container">
                  <PrimaryButton
                    title={languageTexts[danisikLanguage].sync}
                    style={{ backgroundColor: "var(--clr-blue)" }}
                    onClick={() => handleListInShopifyOpenModal()}
                  />
                  <DeleteButton
                    title={languageTexts[danisikLanguage].delete}
                    onClick={() => deleteInECommerce("shopify")}
                  />
                </div>
              </>
            </>
          ) : (
            <>
              <span className="e-commerce-title">
                {languageTexts[danisikLanguage].notListedYet}
              </span>
              <button
                className="list-button"
                onClick={() => handleListInShopifyOpenModal()}
              >
                {languageTexts[danisikLanguage].clickForList}
              </button>
            </>
          )}
        </div>
      </div>
      <div
        id={`custom-alert-input-custom`}
        className={`${isShopifyModalOpen ? "open" : ""}`}
      >
        <div
          className="custom-alert-overlay-custom"
          onClick={() => setIsShopifyModalOpen(false)}
        ></div>
        <div id="dialogbox-custom">
          <div>
            <div id="dialogboxhead-custom">
              <h3>{languageTexts[danisikLanguage].listInShopify}</h3>
            </div>
            <div id="dialogboxbody-custom">
              {referencePrices.map((referencePrice) => (
                <div className="reference-price-container">
                  <span>{referencePrice.title}: </span>
                  <span>{referencePrice.salePrice.value}</span>
                  <span>{currencies[referencePrice.salePrice.currency]}</span>
                </div>
              ))}
              <CurrencyInput
                label={languageTexts[danisikLanguage].price}
                value={price.value}
                selectedCurrency={price.currency}
                handleChangeCurrency={handleChangeCurrency}
                handleChange={handleChangeCurrencyInputValue}
                name="price"
              />
            </div>
            <div id="dialogboxfoot-custom">
              <button
                id="dialogbox-cancel-button-custom"
                onClick={() => setIsShopifyModalOpen(false)}
              >
                {languageTexts[danisikLanguage].cancel}
              </button>
              <button
                id="dialogbox-ok-button-custom"
                onClick={() => handleListInShopify()}
              >
                {languageTexts[danisikLanguage].OK}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>{languageTexts[danisikLanguage].noPermissionToView}</div>
  );
}

export default ECommerce;
