import LoadingPage from "components/LoadingPage";
import React, { useEffect, useState } from "react";
import TableFilter from "./TableFilter";
import Table from "./Table";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getParts, setPage } from "redux/product/part/partActions";

function PartTable({ searchText, partId }) {
  const dispatch = useDispatch();
  const {
    parts,
    partInformationLoading,
    page,
    totalPage,
    filterOptions,
    sortBy,
  } = useSelector((state) => state.danisikProductPart);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [labelParam, setLabelParam] = useState(searchParams.get("label"));
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getParts(searchText));
  }, [page, filterOptions, sortBy, searchText]);

  useEffect(() => {
    if (labelParam) {
      navigate({
        search: `?${createSearchParams({
          page: page,
          label: labelParam,
        })}`,
      });
    } else {
      navigate({
        pathname: "",
        search: `?${createSearchParams({
          page: page,
        })}`,
      });
    }
  }, [page, navigate]);

  const next = () => {
    if (totalPage * 1 > page * 1) {
      dispatch(setPage(page * 1 + 1));
    }
  };
  const nextLast = () => {
    dispatch(setPage(totalPage * 1));
  };
  const previousLast = () => {
    dispatch(setPage(1));
  };
  const previous = () => {
    if (page * 1 > 1) {
      dispatch(setPage(page * 1 - 1));
    }
  };

  return partInformationLoading ? (
    <LoadingPage />
  ) : (
    <div>
      <TableFilter
        isModalOpen={isFilterModalOpen}
        setFilterModalOpen={setIsFilterModalOpen}
        next={next}
        nextLast={nextLast}
        previousLast={previousLast}
        previous={previous}
        leftArrowDisabled={page <= 1}
        rightArrowDisabled={totalPage * 1 <= page * 1}
      />
      <Table
        partArr={parts}
        sortBy={sortBy}
        partIdParam={partId}
        labelParam={labelParam}
      />
    </div>
  );
}

export default PartTable;
