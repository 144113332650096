import * as Yup from "yup";
export default Yup.object().shape({
  partNumber: Yup.string().required("Required"),
  partType: Yup.object().shape({
    _id: Yup.string().required("Part Type is required"),
  }),
  partName: Yup.string().required("Part name is required"),
  detailInformation: Yup.string(),
  brand: Yup.object().shape({
    _id: Yup.string().required("Brand is required"),
  }),
  model: Yup.object().shape({
    _id: Yup.string().required("Model is required"),
  }),
  category: Yup.object().shape({
    _id: Yup.string().required("Category is required"),
  }),
  // salePrice: Yup.object().shape({
  //   value: Yup.number("Enter Sale Price")
  //     .min(1, "Sale price must be greater than 0")
  //     .required("Sale Price is required"),
  //   currency: Yup.string()
  //     .oneOf(["EUR", "USD", "GBP", "TRY"])
  //     .required("Please select valid currency"),
  // }),
  // shippingCost: Yup.object().shape({
  //   value: Yup.number("Enter Shipping Cost")
  //     .min(1, "Shipping Cost must be greater than 0")
  //     .required("Shipping Cost is required"),
  //   currency: Yup.string()
  //     .oneOf(["EUR", "USD", "GBP", "TRY"])
  //     .required("Please select valid currency"),
  // }),
  // taxCost: Yup.object().shape({
  //   value: Yup.number("Enter Tax Cost")
  //     .min(1, "Tax cost must be greater than 0")
  //     .required("Tax Cost is required"),
  //   currency: Yup.string()
  //     .oneOf(["EUR", "USD", "GBP", "TRY"])
  //     .required("Please select valid currency"),
  // }),
  // otherCost: Yup.object().shape({
  //   value: Yup.number("Enter Other Cost")
  //     .min(1, "Other cost must be greater than 0")
  //     .required("Other Cost is required"),
  //   currency: Yup.string()
  //     .oneOf(["EUR", "USD", "GBP", "TRY"])
  //     .required("Please select valid currency"),
  // }),
  quantity: Yup.number(),
  productionYear: Yup.number()
    .min(1800, "Please enter valid year")
    .max(new Date().getFullYear() + 1, "Please enter valid year"),
  status: Yup.object().shape({
    _id: Yup.string("Enter Status").required("Status is required"),
  }),
  deviceSerialNumber: Yup.string(),
  deviceBrand: Yup.object().shape({
    _id: Yup.string("Enter Brand"),
  }),
  deviceModel: Yup.object().shape({
    _id: Yup.string("Enter Model"),
  }),
  deviceCategory: Yup.object().shape({
    _id: Yup.string("Enter Category"),
  }),
  deviceProductionYear: Yup.number()
    .min(1800, "Please enter valid year")
    .max(new Date().getFullYear() + 1, "Please enter valid year"),
});
