import React, { useState } from "react";
import "../style.scss";
import User from "assets/icons/User";
import InputWithIcon from "components/FormElements/InputWithIcon";

import danisikLogo from "assets/images/danisik_logo.png";
import bernerLogo from "assets/images/berner_logo.svg";
import Lock from "assets/icons/Lock";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { useFormik } from "formik";
import registerValidationSchema from "./registerValidationSchema";
import CustomAlert from "lib/customAlert.js";
import UserService from "services/userService";
import { useDispatch, useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";
import { signIn } from "redux/user/userActions";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const userService = new UserService();
function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      role: "user",
    },
    validationSchema: registerValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        setLoading(true);
        userService
          .signUp(values)
          .then((res) => {
            toast.success(languageTexts[danisikLanguage].registerApproveText);
            navigate("/login");
          })
          .catch((err) => {
            toast.error(err.data.message);
          })
          .finally(() => setLoading(false));
      }
    },
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value);
  };
  return (
    <div className="register-page auth-page">
      <div className="customer-logo">
        <img src={danisikLogo} alt="company logo" />
      </div>
      <div className="register-page-content auth-page-content">
        <div className="content-title">
          {languageTexts[danisikLanguage].register}
        </div>
        <form
          className="register-page-form auth-page-form"
          onSubmit={formik.handleSubmit}
        >
          <InputWithIcon
            icon={<User />}
            label={languageTexts[danisikLanguage].name}
            type="name"
            value={formik.values.name}
            name="name"
            handleChange={handleChange}
            showError={formik.touched.name && Boolean(formik.errors.name)}
            error={formik.touched.name && formik.errors.name}
          />
          <InputWithIcon
            icon={<User />}
            label={languageTexts[danisikLanguage].email}
            type="email"
            value={formik.values.email}
            name="email"
            handleChange={handleChange}
            showError={formik.touched.email && Boolean(formik.errors.email)}
            error={formik.touched.email && formik.errors.email}
          />
          <InputWithIcon
            icon={<Lock />}
            label={languageTexts[danisikLanguage].password}
            type="password"
            value={formik.values.password}
            name="password"
            handleChange={handleChange}
            showError={
              formik.touched.password && Boolean(formik.errors.password)
            }
            error={formik.touched.password && formik.errors.password}
          />
          <InputWithIcon
            icon={<Lock />}
            label={languageTexts[danisikLanguage].passwordConfirm}
            type="password"
            value={formik.values.passwordConfirm}
            name="passwordConfirm"
            handleChange={handleChange}
            showError={
              formik.touched.passwordConfirm &&
              Boolean(formik.errors.passwordConfirm)
            }
            error={
              formik.touched.passwordConfirm && formik.errors.passwordConfirm
            }
          />
          <PrimaryButton
            title={languageTexts[danisikLanguage].register}
            type="submit"
            backgroundColor="var(--clr-blue)"
            loading={loading}
          />
          <Link to={"/login"}>{languageTexts[danisikLanguage].login}</Link>
        </form>
      </div>
      <div className="footer">
        <img
          src={bernerLogo}
          alt="Developers' logo"
          className="developers-logo"
        />
        <div className="footer-text">
          <span>2023</span>
          <span>All Right Reserved</span>
        </div>
      </div>
    </div>
  );
}

export default Register;
