import Device from "assets/icons/Device";
import Home from "assets/icons/Home";
import Inactive from "assets/icons/Inactive";
import PdfSheet from "assets/icons/PdfSheet";
import Parts from "assets/icons/Parts";
import User from "assets/icons/User";
import React, { useEffect, useState } from "react";
import "./style.scss";
import Settings from "assets/icons/Settings";
import bernerLogo from "assets/images/berner_logo.svg";
import Logout from "assets/icons/Logout";
import Close from "assets/icons/Close";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomAlert from "lib/customAlert";
import languageTexts from "constants/languageTexts";

function SidePanel({ sidePanelOpen, setSidePanelOpen }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [selectedLink, setSelectedLink] = useState(window.location.pathname);
  const user = useSelector((state) => state.danisikUser?.user);
  const location = useLocation();
  useEffect(() => {
    setSelectedLink(window.location.pathname);
  }, []);

  useEffect(() => {
    setSelectedLink(location.pathname);
  }, [location.pathname]);

  const handleLogout = async () => {
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].youAreAboutToLogout,
      languageTexts[danisikLanguage].areYouSureToLogout
    );
    if (result) {
      localStorage.removeItem("danisik-token");
      window.location.reload();
    }
  };

  return (
    <div className={`side-panel ${sidePanelOpen ? "open" : "closed"}`}>
      <div className="user-info">
        <User />
        <span className="user-info-text">
          {languageTexts[danisikLanguage].welcome} {user?.name}
        </span>
      </div>
      <div className="side-panel-items">
        {user && user.role !== "user" ? (
          <Link
            onClick={() => setSidePanelOpen(false)}
            to={"/"}
            className={`side-panel-item ${
              selectedLink === "/" || !selectedLink ? "active" : ""
            }`}
          >
            <Home />
            {languageTexts[danisikLanguage].dashboard}
          </Link>
        ) : null}

        <Link
          onClick={() => setSidePanelOpen(false)}
          to={"/devices"}
          className={`side-panel-item ${
            selectedLink === "/devices" ? "active" : ""
          }`}
        >
          <Device />
          {languageTexts[danisikLanguage].devices}
        </Link>
        <Link
          onClick={() => setSidePanelOpen(false)}
          to={"/parts"}
          className={`side-panel-item ${
            selectedLink === "/parts" ? "active" : ""
          }`}
        >
          <Parts />
          {languageTexts[danisikLanguage].parts}
        </Link>
        <Link
          onClick={() => setSidePanelOpen(false)}
          to={"/inactives"}
          className={`side-panel-item ${
            selectedLink === "/inactives" ? "active" : ""
          }`}
        >
          <Inactive />
          {languageTexts[danisikLanguage].inactiveItems}
        </Link>
        <Link
          onClick={() => setSidePanelOpen(false)}
          to={"/inactive-parts"}
          className={`side-panel-item ${
            selectedLink === "/inactive-parts" ? "active" : ""
          }`}
        >
          <Inactive />
          {languageTexts[danisikLanguage].inactiveParts}
        </Link>
        {user && user.role !== "user" ? (
          <Link
            onClick={() => setSidePanelOpen(false)}
            to={"/get-stock"}
            className={`side-panel-item ${
              selectedLink === "/get-stock" ? "active" : ""
            }`}
          >
            <PdfSheet />
            {languageTexts[danisikLanguage].getStock}
          </Link>
        ) : null}

        <Link
          onClick={() => setSidePanelOpen(false)}
          to={"/settings"}
          className={`side-panel-item ${
            selectedLink === "/settings" ? "active" : ""
          }`}
        >
          <Settings />
          {languageTexts[danisikLanguage].settings}
        </Link>
      </div>
      <div className="side-panel-bottom">
        <div className="berner-logo">
          <img src={bernerLogo} alt="Developer's logo" />
        </div>
        <div className="logout" onClick={handleLogout}>
          <Logout />
          {languageTexts[danisikLanguage].logout}
        </div>
      </div>
      <div
        className="close-icon-container"
        onClick={() => setSidePanelOpen(false)}
      >
        <Close />
      </div>
    </div>
  );
}

export default SidePanel;
