import React from "react";
import "./style.scss";

function Checkbox({ label = "", checked = true, onChange, name }) {
  return (
    <label className="checkbox-container">
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      {label}
    </label>
  );
}

export default Checkbox;
