import Download from "assets/icons/Download";
import React from "react";
import "./style.scss";

function DownloadButton({
  title = "Download",
  backgroundColor = "",
  onClick,
  ...props
}) {
  return (
    <button
      className="download-button"
      style={{ backgroundColor: backgroundColor }}
      onClick={onClick}
      {...props}
    >
      <Download />
      {title}
    </button>
  );
}

export default DownloadButton;
