import DevelopersInfo from "components/Settings/DevelopersInfo";
import SettingItemsContainer from "components/Settings/SettingItemsContainer";
import React from "react";
import "./style.scss";

function Settings() {
  return (
    <div className="settings">
      <SettingItemsContainer />
      <DevelopersInfo />
    </div>
  );
}

export default Settings;
