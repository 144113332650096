import React, { useEffect, useState } from "react";
import "./style.scss";
import { useFormik } from "formik";
import CustomAlert from "lib/customAlert";
import Input from "components/FormElements/Input";
import FormButton from "components/Buttons/FormButton";
import deviceValidationSchema from "./deviceValidationSchema";
import BrandModelOptions from "components/BrandModelOptions";
import CategoryOptions from "components/CategoryOptions";
import PartDeviceService from "services/product/part/partDeviceService";
import LoadingPage from "components/LoadingPage";
import { toast } from "react-toastify";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
import DotmedCategoryOptions from "components/DotmedCategoryOptions";
import InputWithQrCodeNew from "components/FormElements/InputWithQrCodeNew";
import BrandModelOptionsNew from "components/BrandModelOptionsNew";
import DotmedCategoryOptionsNew from "components/DotmedCategoryOptionsNew";

const brandOptions = [
  { name: "Siemens", value: "_45132154" },
  { name: "X-Ray", value: "_45132155" },
  { name: "Bosch", value: "_45132154" },
  { name: "Arcelik", value: "_45132154" },
  { name: "Siemens", value: "_45132154" },
  { name: "X-Ray", value: "_45132155" },
  { name: "Bosch", value: "_45132154" },
  { name: "Arcelik", value: "_45132154" },
  { name: "Siemens", value: "_45132154" },
  { name: "X-Ray", value: "_45132155" },
  { name: "Bosch", value: "_45132154" },
];

const partDeviceService = new PartDeviceService();
function Device({ partId }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [isLoading, setIsLoading] = useState(false);
  const [partDeviceInfos, setPartDeviceInfos] = useState(null);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: partDeviceInfos?._id || 0,
      serialNumber: partDeviceInfos?.serialNumber || "",
      brand: partDeviceInfos?.brandId || {
        name: "",
        _id: "",
      },
      model: partDeviceInfos?.modelId || {
        name: "",
        _id: "",
      },
      category: partDeviceInfos?.categoryId || {
        name: "",
        _id: "",
      },
      productionYear: partDeviceInfos?.productionYear || "",
    },
    validationSchema: deviceValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        setIsLoading(true);
        partDeviceService
          .updateNormal(values._id, {
            serialNumber: values.serialNumber,
            brandId: values?.brand?._id,
            modelId: values?.model?._id,
            categoryId: values?.category?._id,
            productionYear: values?.productionYear,
            partId: partId,
          })
          .then((res) => {
            toast.success(languageTexts[danisikLanguage].operationSuccessful);
          })
          .finally(() => setIsLoading(false));
      }
    },
  });

  useEffect(() => {
    setIsLoading(true);
    partDeviceService
      .getDeviceByPartId(partId)
      .then((res) => {
        setPartDeviceInfos(res.data.data);
      })
      .finally(() => setIsLoading(false));
  }, [partId]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value);
  };
  return isLoading ? (
    <LoadingPage />
  ) : (
    <form
      className="storage-container flow-content"
      onSubmit={formik.handleSubmit}
    >
      <InputWithQrCodeNew
        label={languageTexts[danisikLanguage].serialNumber}
        value={formik.values.serialNumber}
        handleChange={handleChange}
        name="serialNumber"
        showError={
          formik.touched.serialNumber && Boolean(formik.errors.serialNumber)
        }
        error={formik.touched.serialNumber && formik.errors.serialNumber}
      />
      <BrandModelOptionsNew
        brandLabel={languageTexts[danisikLanguage].brand}
        modelLabel={languageTexts[danisikLanguage].model}
        brandValue={formik.values.brand}
        modelValue={formik.values.model}
        showErrorBrand={
          formik.touched.brand?._id && Boolean(formik.errors.brand?._id)
        }
        errorBrand={formik.touched.brand?._id && formik.errors.brand?._id}
        showErrorModel={
          formik.touched.model?._id && Boolean(formik.errors.model?._id)
        }
        errorModel={formik.touched.model?._id && formik.errors.model?._id}
        handleChangeBrand={handleChange}
        handleChangeModel={handleChange}
      />
      <DotmedCategoryOptionsNew
        label={"*" + languageTexts[danisikLanguage].category}
        name="category"
        handleChange={handleChange}
        value={formik.values.category}
        showError={
          formik.touched.category?._id && Boolean(formik.errors.category?._id)
        }
        error={formik.touched.category?._id && formik.errors.category?._id}
      />

      <Input
        type="number"
        label={languageTexts[danisikLanguage].productionYear}
        value={formik.values.productionYear}
        handleChange={handleChange}
        name="productionYear"
        showError={
          formik.touched.productionYear && Boolean(formik.errors.productionYear)
        }
        error={formik.touched.productionYear && formik.errors.productionYear}
      />
      <FormButton title={languageTexts[danisikLanguage].save} />
    </form>
  );
}

export default Device;
