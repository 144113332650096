import React from "react";
import * as constants from "constants/constants";
import { useSelector } from "react-redux";

function ThemeProvider({ children }) {
  const theme = useSelector((state) => state.danisikTheme?.theme);
  let themeClassName =
    theme === constants.DARK_THEME ? "theme--dark" : "theme--light";
  if (theme) return <div className={themeClassName}>{children}</div>;
}

export default ThemeProvider;
