import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import SelectOption from "components/FormElements/SelectOption";
import Input from "components/FormElements/Input";
import AddCircle from "assets/icons/AddCircle";
import DeleteBin2 from "assets/icons/DeleteBin2";
import EditPencil from "assets/icons/EditPencil";
import FormButton from "components/Buttons/FormButton";
import AddPackaging from "components/Modals/AddPackaging";
import PackagingTypeService from "services/product/device/packagingTypeService";
import PackagingService from "services/product/device/packagingService";
import LoadingPage from "components/LoadingPage";
import CurrencyInput from "components/FormElements/CurrencyInput";
import CustomAlert from "lib/customAlert";
import { toast } from "react-toastify";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";

const packagingOptions = [
  { name: "Ambalaj", value: "64305d48e76d5b02ec45efd0" },
  { name: "Kutu", value: "56498aas" },
];

const formatPackaging = (packaging) => {
  let obj = {};
  obj.packagingTypeId = packaging.packagingTypeId?._id;
  obj.physicalInfos = packaging.physicalInfos;
  obj.packagingCost = {
    value: packaging.packagingCost ? packaging.packagingCost.value : null,
    currency: packaging.packagingCost
      ? packaging.packagingCost.currency
      : "EUR",
  };
  obj.deviceId = packaging.deviceId;
  if (packaging._id) {
    obj._id = packaging._id;
  }
  return obj;
};

const packagingService = new PackagingService();
function Packaging({ deviceId }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [loading, setLoading] = useState(false);
  const [packagings, setPackagings] = useState([]);
  const [addPackagingModalOpen, setAddPackagingModalOpen] = useState(false);
  const [packagingModalInfo, setPackagingModalInfo] = useState({
    _id: 0,
    packagingType: {
      _id: 0,
      name: "",
    },
    physicalInfos: {
      width: 0,
      height: 0,
      size: 0,
      weight: 0,
    },
    packagingCost: {
      value: 0,
      currency: "EUR",
    },
    deviceId: 0,
  });
  const getPackagingWithDeviceId = useCallback(() => {
    setLoading(true);
    packagingService
      .getAllPackagingWithDeviceId(deviceId)
      .then((res) => {
        setPackagings(res.data.data);
      })
      .finally(() => setLoading(false));
  }, [deviceId]);

  useEffect(() => {
    getPackagingWithDeviceId();
  }, [getPackagingWithDeviceId]);

  const handleDeletePackaging = async (index) => {
    if (packagings[index]._id) {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        setLoading(true);
        packagingService
          .delete(packagings[index]._id)
          .then((res) => {
            getPackagingWithDeviceId();
            toast.success(languageTexts[danisikLanguage].operationSuccessful);
          })
          .finally(() => setLoading(false));
      }
    } else {
      const newPackagings = [
        ...packagings.slice(0, index),
        ...packagings.slice(index + 1),
      ];
      setPackagings(newPackagings);
    }
  };

  const updatePackaging = async () => {
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].OK
    );
    if (result) {
      let packagingsReqArr = [];
      packagings.forEach((packaging) => {
        packagingsReqArr.push(formatPackaging(packaging));
      });
      setLoading(true);
      packagingService
        .add({ packaging: packagingsReqArr })
        .then(() => {
          toast.success(languageTexts[danisikLanguage].operationSuccessful);
        })
        .finally(() => setLoading(false));
    }
  };
  return loading ? (
    <LoadingPage />
  ) : (
    <div className="packaging-container flow-content">
      <div className="packaging-header">
        <h3 className="section-title">
          {languageTexts[danisikLanguage].packaging}
        </h3>
        <div
          className="add-circle-container"
          onClick={() => {
            setPackagingModalInfo({
              _id: 0,
              packagingType: {
                _id: "",
                name: "",
              },
              physicalInfos: {
                width: 0,
                height: 0,
                size: 0,
                weight: 0,
              },
              packagingCost: {
                value: 0,
                currency: "EUR",
              },
              deviceId: deviceId,
            });
            setAddPackagingModalOpen(true);
          }}
        >
          <AddCircle />
        </div>
      </div>
      <div className="packaging-body flow-content">
        {packagings.map((packagingType, index) => (
          <div className="packaging-item" key={index}>
            <div className="packaging-info flow-content">
              <Input
                label={languageTexts[danisikLanguage].packagingType}
                value={packagingType.packagingTypeId?.name}
                disabled={true}
              />
              <CurrencyInput
                label={languageTexts[danisikLanguage].packagingCost}
                value={packagingType.packagingCost?.value}
                selectedCurrency={packagingType.packagingCost?.currency}
                disabled={true}
              />
              <Input
                label={languageTexts[danisikLanguage].width}
                value={packagingType.physicalInfos?.width}
                disabled={true}
              />
              <Input
                label={languageTexts[danisikLanguage].height}
                value={packagingType.physicalInfos?.height}
                disabled={true}
              />
              <Input
                label={languageTexts[danisikLanguage].size}
                value={packagingType.physicalInfos?.size}
                disabled={true}
              />
              <Input
                label={languageTexts[danisikLanguage].weight}
                value={packagingType.physicalInfos?.weight}
                disabled={true}
              />
            </div>
            <div className="button-container">
              <button
                className="delete"
                type="button"
                onClick={() => handleDeletePackaging(index)}
              >
                <DeleteBin2 width="2rem" height="2rem" />
                {languageTexts[danisikLanguage].delete}
              </button>
              <button
                className="edit"
                type="button"
                onClick={() => {
                  setPackagingModalInfo({
                    _id: packagingType._id,
                    packagingType: packagingType.packagingTypeId,
                    physicalInfos: packagingType.physicalInfos,
                    packagingCost: { value: 0, currency: "EUR" }, // packagingType.packagingCost,
                    deviceId: deviceId,
                    index: index,
                  });
                  setAddPackagingModalOpen(true);
                }}
              >
                <EditPencil width="1.8rem" height="1.8rem" />
                {languageTexts[danisikLanguage].edit}
              </button>
            </div>
          </div>
        ))}
      </div>
      <FormButton
        title={languageTexts[danisikLanguage].save}
        onClick={updatePackaging}
      />
      <AddPackaging
        setIsModalOpen={setAddPackagingModalOpen}
        isModalOpen={addPackagingModalOpen}
        packagingModalInfo={packagingModalInfo}
        setPackagingModalInfo={setPackagingModalInfo}
        packagingInfos={packagings}
        setPackagingInfos={setPackagings}
      />
    </div>
  );
}

export default Packaging;
