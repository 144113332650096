import React, { useEffect, useState } from "react";
import "./style.scss";
import { useFormik } from "formik";
import CustomAlert from "lib/customAlert";
import Input from "components/FormElements/Input";
import FormButton from "components/Buttons/FormButton";
import PartStorageService from "services/product/part/partStorageService";
import LoadingPage from "components/LoadingPage";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";
import AddStorage from "components/Modals/AddStorage";
import AddCircle from "assets/icons/AddCircle";
import DeleteBin2 from "assets/icons/DeleteBin2";
import EditPencil from "assets/icons/EditPencil";

const partStorageService = new PartStorageService();
function Storage({ partId }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [storages, setStorages] = useState([]);
  const [storageModalInfo, setStorageModalInfo] = useState({
    _id: 0,
    title: "",
    description: "",
  });
  const [addStorageModalOpen, setAddStorageModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(0);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    // validationSchema: storageValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        setIsLoading(true);
        partStorageService
          .updateNormal(id, {
            partId: partId,
            storages: storages,
          })
          .then((res) => {
            toast.success(languageTexts[danisikLanguage].operationSuccessful);
          })
          .finally(() => setIsLoading(false));
      }
    },
  });

  useEffect(() => {
    setIsLoading(true);
    partStorageService
      .getPartStorageByPartId(partId)
      .then((res) => {
        setId(res.data.data._id);
        formik.values._id = res.data.data._id;
        if (Array.isArray(res.data?.data?.storages)) {
          setStorages(res.data.data.storages);
        }
      })
      .finally(() => setIsLoading(false));
  }, [partId]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value);
  };

  const handleDeleteStorage = async (index) => {
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].OK
    );
    if (result) {
      const newStorages = [
        ...storages.slice(0, index),
        ...storages.slice(index + 1),
      ];
      setStorages(newStorages);
    }
  };
  return isLoading ? (
    <LoadingPage />
  ) : (
    <div>
      <form
        className="storage-container flow-content"
        onSubmit={formik.handleSubmit}
      >
        <div className="storages flow-content">
          <div className="storages-header">
            <h3 className="section-title">
              {languageTexts[danisikLanguage].storeInfo}
            </h3>
            <div
              className="add-icon"
              onClick={() => {
                setStorageModalInfo({
                  storageDefinition: "",
                  shelfNumber: "",
                  boxNumber: "",
                  _id: 0,
                });
                setAddStorageModalOpen(true);
              }}
            >
              <AddCircle />
            </div>
          </div>
          {storages?.length
            ? storages.map((value, index) => (
                <div className="storage flow-content" key={index}>
                  <Input
                    label={languageTexts[danisikLanguage].storageDefinition}
                    value={value.storageDefinition}
                    disabled={true}
                  />
                  <Input
                    label={languageTexts[danisikLanguage].shelfNumber}
                    value={value.shelfNumber}
                    disabled={true}
                  />
                  <Input
                    label={languageTexts[danisikLanguage].boxNumber}
                    value={value.boxNumber}
                    disabled={true}
                  />
                  <div className="button-container">
                    <button
                      className="delete"
                      type="button"
                      onClick={() => handleDeleteStorage(index)}
                    >
                      <DeleteBin2 width="2rem" height="2rem" />
                      {languageTexts[danisikLanguage].delete}
                    </button>
                    <button
                      className="edit"
                      type="button"
                      onClick={() => {
                        setStorageModalInfo({
                          storageDefinition: value.storageDefinition,
                          shelfNumber: value.shelfNumber,
                          boxNumber: value.boxNumber,
                          _id: value._id,
                          index: index,
                        });
                        setAddStorageModalOpen(true);
                      }}
                    >
                      <EditPencil width="1.8rem" height="1.8rem" />
                      {languageTexts[danisikLanguage].edit}
                    </button>
                  </div>
                </div>
              ))
            : null}
        </div>
        <FormButton title={languageTexts[danisikLanguage].save} />
      </form>
      <AddStorage
        setIsModalOpen={setAddStorageModalOpen}
        isModalOpen={addStorageModalOpen}
        partId={partId}
        storageModalInfo={storageModalInfo}
        setStorageModalInfo={setStorageModalInfo}
        storages={storages || []}
        setStorages={setStorages}
      />
    </div>
  );
}

export default Storage;
