import WaitingForApprovalModal from "components/Modals/Admin/WaitingForApprovalModal";
import languageTexts from "constants/languageTexts";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

function WaitingForApprovals() {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [waitingForApprovalModalOpen, setWaitingForApprovalModalOpen] =
    useState(false);
  const wrapperRef = useRef(null);
  return (
    <div className="setting-item-container" ref={wrapperRef}>
      <div
        className="setting-item"
        onClick={() => setWaitingForApprovalModalOpen(true)}
      >
        <span>{languageTexts[danisikLanguage].waitingForApprovals}</span>
      </div>
      <WaitingForApprovalModal
        setIsModalOpen={setWaitingForApprovalModalOpen}
        isModalOpen={waitingForApprovalModalOpen}
      />
    </div>
  );
}

export default WaitingForApprovals;
