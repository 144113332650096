import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  RadialLinearScale,
} from "chart.js";
import { Bar, Pie, PolarArea } from "react-chartjs-2";
import DashboardService from "services/admin/dashboardService";
import LoadingPage from "components/LoadingPage";
import ChartDataLabels from "chartjs-plugin-datalabels";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
import { ROLES } from "constants/userRoles";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  RadialLinearScale
);

const backgroundColors = [
  "#0080FF",
  "#FF5858",
  "#6555C1",
  "#71FF65",
  "rgb(255, 205, 86)",
];

const options = {
  plugins: {
    datalabels: {
      formatter: (value, ctx) => {
        let sum = 0;
        let dataArr = ctx.chart.data.datasets[0].data;
        dataArr.forEach((data) => {
          sum += data;
        });
        let percentage = ((value * 100) / sum).toFixed(2) + "%";
        return percentage;
      },
      color: "#fff",
    },
    tooltip: {
      enabled: true,
    },
    labels: {
      render: "percentage",
      precision: 2,
    },
    legend: {
      position: "top",
    },
  },
};

const getDistributionOptions = (data, labelItem) => {
  const labels = data.map((item) => item[labelItem]);
  const datasets = [
    {
      label: "Accessory",
      data: data.map((item) => item.Accessory),
      backgroundColor: "#0080FF", // Customize color
    },
    {
      label: "Device",
      data: data.map((item) => item.Device),
      backgroundColor: "#FF5858", // Customize color
    },
    {
      label: "Packaging",
      data: data.map((item) => item.Packaging),
      backgroundColor: "#6555C1", // Customize color
    },
    {
      label: "Storage",
      data: data.map((item) => item.Storage),
      backgroundColor: "#71FF65", // Customize color
    },
    // Add additional datasets for other categories
  ];
  return { labels, datasets };
};

const getPolarChartOptions = (data) => {
  const labels = Object.keys(data);
  const datasets = [
    {
      label: "# of Items",
      data: Object.values(data),
      backgroundColor: [
        "rgba(255, 99, 132, 0.5)",
        "rgba(54, 162, 235, 0.5)",
        "rgba(255, 206, 86, 0.5)",
        "rgba(75, 192, 192, 0.5)",
        "rgba(153, 102, 255, 0.5)",
        "rgba(255, 159, 64, 0.5)",
      ],
      borderWidth: 1,
    },
  ];
  return { labels, datasets };
};

const barChartOptions = {
  responsive: true,
  title: {
    display: true,
    text: "Daily Inventory Breakdown",
  },
  tooltips: {
    enabled: true,
    mode: "single",
  },
  // scales: {
  //   xAxes: [
  //     {
  //       stacked: true, // Stack bars by day (optional)
  //       title: {
  //         display: true,
  //         text: "Day",
  //       },
  //     },
  //   ],
  //   yAxes: [
  //     {
  //       stacked: true, // Stack bars by category (optional)
  //       title: {
  //         display: true,
  //         text: "Quantity",
  //       },
  //     },
  //   ],
  // },
};

const dashboardService = new DashboardService();
function GraphicalInfos() {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [isLoading, setIsLoading] = useState(false);
  const [brandDataOptions, setBrandDataOptions] = useState(null);
  const [modelDataOptions, setModelDataOptions] = useState(null);
  const [categoryDataOptions, setCategoryDataOptions] = useState(null);
  const [weeklyModelDistribution, setWeeklyModelDistribution] = useState(null);
  const [monthlyModeDistribution, setMonthlyModeDistribution] = useState(null);
  const [yearlyModelDistribution, setYearlyModelDistribution] = useState(null);
  const [userDistributions, setUserDistributions] = useState(null);
  const [roleDistribution, setRoleDistribution] = useState(null);
  const [eCommerceDistribution, setECommerceDistribution] = useState(null);

  const getChartOptions = useCallback((datas, label, name) => {
    if (datas?.length) {
      let labels = [];
      let dataArr = [];

      datas.forEach((data) => {
        labels.push(data[name]);
        dataArr.push(data.percentage * 1);
      });
      let dataOptions = {
        labels,
        datasets: [
          {
            label: label,
            data: dataArr,
            backgroundColor: backgroundColors.slice(0, datas.length),
            borderWidth: 1,
          },
        ],
      };
      return dataOptions;
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dashboardService
      .getTopModels()
      .then((res) => {
        setBrandDataOptions(
          getChartOptions(
            res.data.topFiveBrands,
            languageTexts[danisikLanguage].topBrands,
            "brandName"
          )
        );
        setModelDataOptions(
          getChartOptions(
            res.data.topFiveModels,
            languageTexts[danisikLanguage].topModels,
            "modelName"
          )
        );
        setCategoryDataOptions(
          getChartOptions(
            res.data.topFiveCategories,
            languageTexts[danisikLanguage].topCategories,
            "categoryName"
          )
        );
      })
      .finally(() => setIsLoading(false));
  }, [getChartOptions]);
  useEffect(() => {
    dashboardService.getYearlyModelDistribution().then((res) => {
      setYearlyModelDistribution(res.data);
    });
  }, []);
  useEffect(() => {
    dashboardService.getMonthlyModelDistribution().then((res) => {
      setMonthlyModeDistribution(res.data);
    });
  }, []);
  useEffect(() => {
    dashboardService.getWeeklyModelDistribution().then((res) => {
      setWeeklyModelDistribution(res.data);
    });
  }, []);
  useEffect(() => {
    dashboardService.getECommercesSalesCount().then((res) => {
      setECommerceDistribution(res.data);
    });
  }, []);
  useEffect(() => {
    dashboardService.getUsersDistribution().then((res) => {
      let newRoleDistributionArr = res.data.roleDistribution.map((role) => {
        role.name = ROLES[role.name];
        return role;
      });
      setRoleDistribution(
        getChartOptions(
          newRoleDistributionArr,
          languageTexts[danisikLanguage].roleDistribution,
          "name"
        )
      );
      setUserDistributions(
        getChartOptions(
          res.data.userDistribution,
          languageTexts[danisikLanguage].userDistribution,
          "userType"
        )
      );
    });
  }, []);

  return (
    <div className="graphical-infos">
      {brandDataOptions ? (
        <div className="top-brands graphical-infos-item">
          <h3>{languageTexts[danisikLanguage].topBrands}</h3>
          <Pie data={brandDataOptions} options={options} />
        </div>
      ) : null}
      {modelDataOptions ? (
        <div className="top-models graphical-infos-item">
          <h3>{languageTexts[danisikLanguage].topModels}</h3>
          <Pie data={modelDataOptions} options={options} />
        </div>
      ) : null}
      {categoryDataOptions ? (
        <div className="top-categories graphical-infos-item">
          <h3>{languageTexts[danisikLanguage].topCategories}</h3>
          <Pie data={categoryDataOptions} options={options} />
        </div>
      ) : null}
      {weeklyModelDistribution ? (
        <div className="weekly-distribution graphical-infos-item">
          <h3>{languageTexts[danisikLanguage].weeklyModelDistribution}</h3>
          <Bar
            data={getDistributionOptions(weeklyModelDistribution, "Day")}
            options={barChartOptions}
          />
        </div>
      ) : null}
      {monthlyModeDistribution ? (
        <div className="monthly-distribution graphical-infos-item">
          <h3>{languageTexts[danisikLanguage].monthlyModelDistribution}</h3>
          <Bar
            data={getDistributionOptions(monthlyModeDistribution, "Day")}
            options={barChartOptions}
          />
        </div>
      ) : null}
      {yearlyModelDistribution ? (
        <div className="yearly-distribution graphical-infos-item">
          <h3>{languageTexts[danisikLanguage].yearlyModelDistribution}</h3>
          <Bar
            data={getDistributionOptions(yearlyModelDistribution, "Month")}
            options={barChartOptions}
          />
        </div>
      ) : null}
      {eCommerceDistribution ? (
        <div className="e-commerce-distribution graphical-infos-item">
          <h3>{languageTexts[danisikLanguage].eCommerceDistribution}</h3>
          <PolarArea data={getPolarChartOptions(eCommerceDistribution)} />
        </div>
      ) : null}
      {userDistributions ? (
        <div className="user-distribution graphical-infos-item">
          <h3>{languageTexts[danisikLanguage].userDistribution}</h3>
          <Pie data={userDistributions} options={options} />
        </div>
      ) : null}
      {roleDistribution ? (
        <div className="role-distribution graphical-infos-item">
          <h3>{languageTexts[danisikLanguage].roleDistribution}</h3>
          <Pie data={roleDistribution} options={options} />
        </div>
      ) : null}
    </div>
  );
}

export default GraphicalInfos;
