import BrandService from "services/admin/brandService";
import { BrandActionTypes } from "./brandActionTypes";

export const setBrands = (user) => {
  const brandService = new BrandService();
  if (user && user.role === "admin") {
    return async (dispatch) => {
      try {
        dispatch({ type: BrandActionTypes.SET_BRANDS });

        // Make your API request
        const response = await brandService.getAll(
          "?limit=999999999999&sort=name"
        );
        dispatch({
          type: BrandActionTypes.SET_BRANDS_SUCCESSFUL,
          payload: response.data.data,
        });
      } catch (error) {
        dispatch({ type: BrandActionTypes.SET_BRANDS_ERROR, payload: error });
      }
    };
  } else {
    return async (dispatch) => {
      dispatch({
        type: BrandActionTypes.SET_BRANDS_SUCCESSFUL,
        payload: [],
      });
    };
  }
};
