const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
function pad(n) {
  return n < 10 ? "0" + n : n;
}
export const dateFormatToDDMMYYYDayHHMMSS = (dateStr) => {
  const date = new Date(dateStr);
  const yyyy = date.getFullYear();
  const mm = date.getMonth() + 1; // Months start at 0!
  const dd = date.getDate();
  const day = weekday[date.getDay()];
  const hour = date.getHours();
  const minute = date.getMinutes();
  const seconds = date.getSeconds();
  return `${pad(dd)}.${pad(mm)}.${yyyy} ${day} ${pad(hour)}:${pad(
    minute
  )}:${pad(seconds)}`;
};

export const dateFormatToDDMMYYYDayHHMMSSDash = (dateStr) => {
  const date = new Date(dateStr);
  const yyyy = date.getFullYear();
  const mm = date.getMonth() + 1; // Months start at 0!
  const dd = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const seconds = date.getSeconds();
  return `${pad(dd)}-${pad(mm)}-${yyyy}-${pad(hour)}-${pad(minute)}-${pad(
    seconds
  )}`;
};
