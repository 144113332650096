import Close from "assets/icons/Close";
import DeviceTable from "components/DeviceTable";
import React, { useCallback, useEffect, useState } from "react";
import SearchInput from "./SearchInput";
import "./style.scss";
import DeviceIcon from "assets/icons/Device";
import PartsIcon from "assets/icons/Parts";
import PartTable from "components/PartTable";
import { debounce } from "utils/debounce";
import languageTexts from "constants/languageTexts";
import { useDispatch, useSelector } from "react-redux";
import { getDevices } from "redux/product/device/deviceActions";
import { getParts } from "redux/product/part/partActions";

function Search({ setIsSearchOpen }) {
  const dispatch = useDispatch();
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [activeTab, setActiveTab] = useState("DEVICE");
  const [searchText, setSearchText] = useState("");
  const [searchTextToSend, setSearchTextToSend] = useState("");
  const handleChangeSearchTextDebounce = useCallback(
    debounce((text) => {
      setSearchTextToSend(text);
    }, 350),
    []
  );
  useEffect(() => {
    handleChangeSearchTextDebounce(searchText);
  }, [searchText]);

  const handleCloseSearch = () => {
    setIsSearchOpen(false);
  };
  useEffect(() => {
    return () => {
      dispatch(getDevices());
      dispatch(getParts());
    };
  }, []);

  return (
    <div className="search-container flow-content">
      <div className="search-input-container">
        <SearchInput
          placeholder={languageTexts[danisikLanguage].search}
          autoFocus={true}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className="device-part-container">
        <div
          className={`tab-item not-completed ${
            activeTab === "DEVICE" ? "active" : ""
          }`}
          onClick={() => setActiveTab("DEVICE")}
        >
          <div className="svg-icon-container">
            <DeviceIcon />
          </div>

          <span>{languageTexts[danisikLanguage].deviceInfos}</span>
        </div>
        <div
          className={`tab-item not-completed ${
            activeTab === "PART" ? "active" : ""
          }`}
          onClick={() => setActiveTab("PART")}
        >
          <div className="svg-icon-container">
            <PartsIcon />
          </div>

          <span>Part</span>
        </div>
      </div>
      <div className="search-content">
        {activeTab === "DEVICE" ? (
          <DeviceTable searchText={searchTextToSend} />
        ) : (
          <PartTable searchText={searchTextToSend} />
        )}
      </div>
      <div className="close-icon-container" onClick={() => handleCloseSearch()}>
        <Close width="3rem" height="3rem" />
      </div>
    </div>
  );
}

export default Search;
