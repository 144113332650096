import React from "react";
import AddAndEditFieldsItem from "../AddAndEditFieldsItem";
import ChangeLanguage from "../ChangeLanguage";
import UserManagementItem from "../UserManagementItem";
import "./style.scss";
import RecentOperations from "../RecentOperations";
import { useSelector } from "react-redux";
import DefaultInformationsItem from "../DefaultInformationsItem";
import WaitingForApprovals from "../WaitingForApprovals";

function SettingItemsContainer() {
  const user = useSelector((state) => state.danisikUser?.user);
  return (
    <div className="setting-items-container">
      {user && user.role !== "user" ? <AddAndEditFieldsItem /> : null}
      {user && user.role === "admin" ? <UserManagementItem /> : null}
      <ChangeLanguage />
      {user && user.role !== "user" ? <RecentOperations /> : null}
      {/*user && user.role === "admin" ? <DefaultInformationsItem /> : null*/}
      {user && user.role === "admin" ? <WaitingForApprovals /> : null}
      {/* <div style={{ margin: "2rem" }}>
        <ToggleTheme />
      </div> */}
    </div>
  );
}

export default SettingItemsContainer;
