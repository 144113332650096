import React, { useEffect, useState } from "react";
import "./style.scss";
import { useSelector } from "react-redux";
import SelectOptionInstantSearch from "components/FormElements/SelectOptionInstantSearch";

let categoryLimit = 15;
function DotmedCategoryOptionsNew({
  label = "*Category",
  name = "category",
  handleChange = () => {},
  value = "",
  showError,
  error,
  isModalOpen = true,
}) {
  const categoryInformation = useSelector((state) => state.danisikCategories);

  const user = useSelector((state) => state.danisikUser?.user);
  const [categories, setCategories] = useState([]);
  let categoryPageCount = categoryInformation.categories.length / categoryLimit;
  const [categoryCurrentPage, setCategoryCurrentPage] = useState(1);
  const [categorySearchText, setCategorySearchText] = useState("");

  useEffect(() => {
    setCategories(categoryInformation.categories.slice(0, categoryLimit));
    setCategoryCurrentPage(1);
  }, [categoryInformation.categories]);

  const handleScrollBottom = () => {
    if (categoryPageCount > categoryCurrentPage) {
      setCategoryCurrentPage(categoryCurrentPage + 1);
    }
  };

  useEffect(() => {
    if (categoryCurrentPage > 1) {
      let newCategories = categoryInformation.categories
        .filter((category) =>
          category.name.toLowerCase().includes(categorySearchText.toLowerCase())
        )
        .slice(0, categoryCurrentPage * categoryLimit);
      setCategories(newCategories);
    }
  }, [categoryCurrentPage]);
  useEffect(() => {
    if (
      isModalOpen &&
      user &&
      (user?.role === "admin" || user?.role === "store")
    ) {
      let newCategories = categoryInformation.categories
        .filter((category) =>
          category.name.toLowerCase().includes(categorySearchText.toLowerCase())
        )
        .slice(0, categoryLimit);
      setCategories(newCategories);
      setCategoryCurrentPage(1);
    }
  }, [categorySearchText, isModalOpen]);

  const handleChangeFilterCategoryInput = (val) => {
    setCategorySearchText(val);
  };
  return (
    <SelectOptionInstantSearch
      options={categories}
      label={label}
      name={name}
      handleChange={handleChange}
      value={value}
      showError={showError}
      error={error}
      handleScrollBottom={handleScrollBottom}
      filterInput={categorySearchText}
      handleChangeFilterInput={handleChangeFilterCategoryInput}
      disabled={!(user?.role === "admin" || user?.role === "store")}
    />
  );
}

export default DotmedCategoryOptionsNew;
