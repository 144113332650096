import Dollar from "assets/icons/Dollar";
import Euro from "assets/icons/Euro";
import Pound from "assets/icons/Pound";
import TurkishLira from "assets/icons/TurkishLira";
import LoadingPage from "components/LoadingPage";
import React, { useEffect, useState } from "react";
import "./style.scss";
import DashboardService from "services/admin/dashboardService";
import { currencyFormat } from "utils/numberHelperFunctions";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";

const currencies = {
  EUR: <Euro />,
  USD: <Dollar />,
  GBP: <Pound />,
  TRY: <TurkishLira />,
};
const dasboardService = new DashboardService();
function NumericStatistics() {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [totalPrices, setTotalPrices] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    dasboardService
      .getTotalPrices()
      .then((res) => {
        setTotalPrices(res.data);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className="numeric-statistics">
      <div className="numeric-statistic">
        <span className="title">
          {languageTexts[danisikLanguage].totalSalePrice}
        </span>
        <span className="value">
          {currencyFormat(totalPrices?.totalSalePrice?.totalValue)}
          {currencies["EUR"]}
        </span>
      </div>
      <div className="numeric-statistic">
        <span className="title">
          {languageTexts[danisikLanguage].totalShippingCost}
        </span>
        <span className="value">
          {currencyFormat(totalPrices?.totalShippingCost?.totalValue)}
          {currencies["EUR"]}
        </span>
      </div>
      <div className="numeric-statistic">
        <span className="title">
          {languageTexts[danisikLanguage].totalTaxCost}
        </span>
        <span className="value">
          {currencyFormat(totalPrices?.totalTaxCost?.totalValue)}
          {currencies["EUR"]}
        </span>
      </div>
      <div className="numeric-statistic">
        <span className="title">
          {languageTexts[danisikLanguage].totalOtherCost}
        </span>
        <span className="value">
          {currencyFormat(totalPrices?.totalOtherCost?.totalValue)}
          {currencies["EUR"]}
        </span>
      </div>
    </div>
  );
}

export default NumericStatistics;
