import ErrorMessages from "components/ErrorMessages";
import React, { useState } from "react";
import "./style.scss";

function InputWithIcon({
  icon,
  label = "",
  value = "",
  name = "",
  handleChange,
  type = "text",
  error = "",
  showError = false,
  ...props
}) {
  const [focused, setFocused] = useState(false);
  return (
    <div>
      <label className="input-with-icon">
        <span
          className={`label-text ${
            value.length || focused ? "label-small" : ""
          }`}
        >
          {label}
        </span>
        <div className="icon-container">{icon}</div>
        <input
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={value}
          name={name}
          onChange={handleChange}
          type={type}
          {...props}
        />
      </label>
      {showError && error.length ? <ErrorMessages error={error} /> : null}
    </div>
  );
}

export default InputWithIcon;
