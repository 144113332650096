export const ROLES_OPTIONS = [
  {
    name: "User",
    _id: "user",
  },
  {
    name: "Seller",
    _id: "seller",
  },
  {
    name: "Store",
    _id: "store",
  },
  {
    name: "Buyer",
    _id: "buyer",
  },
  {
    name: "Admin",
    _id: "admin",
  },
];

export const ROLES = {
  user: "User",
  seller: "Seller",
  store: "Store",
  buyer: "Buyer",
  admin: "Admin",
};
