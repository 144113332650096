import React, { useEffect, useState } from "react";
import TableFilter from "./TableFilter";
import Table from "./Table";

import DeviceService from "services/product/device/deviceService";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import LoadingPage from "components/LoadingPage";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDevices, setPageDevice } from "redux/product/device/deviceActions";
// const deviceArr = [
//   {
//     _id: "6434699f5ce5d23fe458eb5f",
//     deviceId: "6434699f5ce5d23fe458eb5f",
//     productionYear: 2023,
//     serialNumber: "",
//     stockNumber: 10000105,
//     brandId: { _id: "642b2b5a18494842e4244c8f", name: "Marka 1" },
//     categoryId: { _id: "642b3a2eb7743e7a2c27aaf9", name: "Category 1" },
//     modelId: { _id: "642b35e31d0ac2443870f6e1", name: "Model 3" },
//     deviceStatus: "Unknown",
//     images: [
//       {
//         order: 1,
//         public_id:
//           "8172c7d4-9362-4484-a45e-24315a41207c-bernersoftware_golden_dog._digital_drawing_cartoon_sticker_Ghib_9b9f5266-d22e-43e3-9649-8542ead4fc69.png",
//         url: "https://testbucketforberkay.s3.amazonaws.com/8172c7d4-9362-4484-a45e-24315a41207c-bernersoftware_golden_dog._digital_drawing_cartoon_sticker_Ghib_9b9f5266-d22e-43e3-9649-8542ead4fc69.png",
//         _id: "64346a11c9d3c89950b89c32",
//       },
//     ],
//   },
// ];

function DeviceTable({ searchText, deviceId }) {
  const dispatch = useDispatch();
  const {
    devices,
    deviceError,
    deviceInformationLoading,
    page,
    totalPage,
    totalCount,
    limit,
    filterOptions,
    sortBy,
  } = useSelector((state) => state.danisikProductDevice);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [labelParam, setLabelParam] = useState(searchParams.get("label"));
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const getDeviceWithFilters = useCallback(() => {
    dispatch(getDevices(searchText));
  }, [page, filterOptions, sortBy, searchText]);

  useEffect(() => {
    getDeviceWithFilters();
  }, [getDeviceWithFilters]);

  useEffect(() => {
    if (labelParam) {
      navigate({
        search: `?${createSearchParams({
          page: page,
          label: labelParam,
        })}`,
      });
    } else {
      navigate({
        search: `?${createSearchParams({
          page: page,
        })}`,
      });
    }
  }, [page, navigate]);

  const next = () => {
    if (totalPage * 1 > page * 1) {
      dispatch(setPageDevice(page * 1 + 1));
    }
  };
  const nextLast = () => {
    dispatch(setPageDevice(totalPage * 1));
  };
  const previousLast = () => {
    dispatch(setPageDevice(1));
  };
  const previous = () => {
    if (page * 1 > 1) {
      dispatch(setPageDevice(page * 1 - 1));
    }
  };

  return (
    <div>
      <TableFilter
        isModalOpen={isFilterModalOpen}
        setFilterModalOpen={setIsFilterModalOpen}
        next={next}
        nextLast={nextLast}
        previousLast={previousLast}
        previous={previous}
        leftArrowDisabled={page <= 1}
        rightArrowDisabled={totalPage * 1 <= page * 1}
        limit={limit}
        page={page}
        totalCount={totalCount}
      />
      <Table
        deviceArr={devices}
        deviceIdParam={deviceId}
        labelParam={labelParam}
      />
    </div>
  );
}

export default DeviceTable;
