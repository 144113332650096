import Close from "assets/icons/Close";
import React, { useEffect, useRef } from "react";
import "./style.scss";

function hasChildWithClassNames(element, classNames) {
  var classList = classNames.split(" "); // Split the class names into an array

  // Check if all class names exist in at least one child element
  return classList.every(function (className) {
    return element.querySelector("." + className) !== null;
  });
}
function hasParentWithClass(element, classNames) {
  var classList = classNames.split(" "); // Split the class names into an array
  return classList.every(function (className) {
    return element.querySelector("." + className) !== null;
  });
}

function ModalHeader({ title = "Add Product", setIsModalOpen, subTitle = "" }) {
  const modalHeaderRef = useRef(null);
  const handleClose = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        let currentModal = modalHeaderRef.current.parentElement.parentElement;
        let thisModalHasChildModalThatIsOpen = hasChildWithClassNames(
          currentModal,
          "modal-container open"
        );
        if (!thisModalHasChildModalThatIsOpen) {
          handleClose();
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);
  return (
    <div className="modal-header" ref={modalHeaderRef}>
      <div className="title-container">
        <h3 className="modal-title">{title}</h3>
        {subTitle ? <h4>{subTitle}</h4> : null}
      </div>

      <div className="modal-close" onClick={handleClose}>
        <Close fill="var(--clr-neutral-200)" width="4rem" height="4rem" />
      </div>
    </div>
  );
}

export default ModalHeader;
