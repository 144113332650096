import Input from "components/FormElements/Input";
import { useFormik } from "formik";
import CustomAlert from "lib/customAlert";
import React, { useState } from "react";
import FormButton from "components/Buttons/FormButton";
import PrimaryButton from "components/Buttons/PrimaryButton";
import SelectOption from "components/FormElements/SelectOption";
import { partTypeOptions } from "constants/partTypes";
import { statusInfoOptions } from "constants/statusInfos";
import CurrencyInput from "components/FormElements/CurrencyInput";
import BrandModelOptions from "components/BrandModelOptions";
import CategoryOptions from "components/CategoryOptions";
import ProductService from "services/product/productService";
import { toast } from "react-toastify";
import languageTexts from "constants/languageTexts";
import { useDispatch, useSelector } from "react-redux";
import AddNewPartValidationSchema from "./AddNewPartValidationSchema";
import DotmedCategoryOptions from "components/DotmedCategoryOptions";
import { getParts } from "redux/product/part/partActions";
import BrandModelOptionsNew from "components/BrandModelOptionsNew";
import DotmedCategoryOptionsNew from "components/DotmedCategoryOptionsNew";
import AccessoryService from "services/product/device/accessoryService";

const brandOptions = [
  { name: "Siemens", value: "_45132154" },
  { name: "X-Ray", value: "_45132155" },
  { name: "Bosch", value: "_45132154" },
  { name: "Arcelik", value: "_45132154" },
  { name: "Siemens", value: "_45132154" },
  { name: "X-Ray", value: "_45132155" },
  { name: "Bosch", value: "_45132154" },
  { name: "Arcelik", value: "_45132154" },
  { name: "Siemens", value: "_45132154" },
  { name: "X-Ray", value: "_45132155" },
  { name: "Bosch", value: "_45132154" },
];

const getFormattedPartValuesFormData = (
  partValues,
  accessuarModalInfoFromConvert
) => {
  let formData = new FormData();
  formData.append("partNumber", partValues.partNumber);
  formData.append("partType", partValues.partType?._id);
  formData.append("partName", partValues.partName);
  formData.append("detailInformation", partValues.detailInformation);
  formData.append("brandId", partValues.brand?._id);
  formData.append("categoryId", partValues.category?._id);
  formData.append("dotmedCategoryId", partValues.category?._id);
  formData.append("quantity", partValues.quantity);
  formData.append("productionYear", partValues.productionYear);
  formData.append("serialNumber", partValues.serialNumber);
  formData.append("salePrice[currency]", partValues.salePrice?.currency);
  formData.append("salePrice[value]", partValues.salePrice?.value);
  formData.append("shippingCost[currency]", partValues.shippingCost?.currency);
  formData.append("shippingCost[value]", partValues.shippingCost?.value);
  formData.append("taxCost[currency]", partValues.taxCost?.currency);
  formData.append("taxCost[value]", partValues.taxCost?.value);
  formData.append("otherCost[currency]", partValues.otherCost?.currency);
  formData.append("otherCost[value]", partValues.otherCost?.value);
  formData.append("partSerialNumber", partValues.partSerialNumber);
  formData.append("partDeviceBrandId", partValues.deviceBrand?._id);
  formData.append("partDeviceModelId", partValues.deviceModel?._id);
  formData.append("partCategoryId", partValues.deviceCategory?._id);
  formData.append("partProductionYear", partValues.deviceProductionYear);
  for (let i = 0; i < accessuarModalInfoFromConvert.images.length; i++) {
    formData.append("images", accessuarModalInfoFromConvert.images[i]);
  }
  return formData;
};
const getFormattedPartValues = (partValues) => {
  return {
    partNumber: partValues.partNumber,
    partType: partValues.partType?._id,
    partName: partValues.partName,
    detailInfo: partValues.detailInformation,
    brandId: partValues.brand?._id,
    modelId: partValues.model?._id,
    categoryId: partValues.category?._id,
    quantity: partValues.quantity,
    productionYear: partValues.productionYear,
    serialNumber: partValues.serialNumber,
    status: partValues.status?._id,
    salePrice: {
      currency: partValues.salePrice?.currency,
      value: partValues.salePrice?.value ? partValues.salePrice.value : null,
    },
    shippingCost: {
      currency: partValues.shippingCost?.currency,
      value: partValues.shippingCost?.value
        ? partValues.shippingCost.value
        : null,
    },
    taxCost: {
      currency: partValues.taxCost?.currency,
      value: partValues.taxCost?.value ? partValues.taxCost.value : null,
    },
    otherCost: {
      currency: partValues.otherCost?.currency,
      value: partValues.otherCost?.value ? partValues.otherCost.value : null,
    },
    partSerialNumber: partValues.deviceSerialNumber,
    partDeviceBrandId: partValues.deviceBrand?._id,
    partDeviceModelId: partValues.deviceModel?._id,
    partCategoryId: partValues.deviceCategory?._id,
    partProductionYear: partValues.deviceProductionYear,
  };
};

const productService = new ProductService();
function AddNewPart({
  setAddNewPart,
  partNumber,
  accessuarModalInfoFromConvert,
  isOpenedFromConvert,
}) {
  const dispatch = useDispatch();
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      partNumber: partNumber,
      partType: {
        name: "",
        _id: "",
      },
      partName: "",
      detailInformation: "",
      brand: {
        name: "",
        _id: "",
      },
      model: {
        name: "",
        _id: "",
      },
      category: {
        name: "",
        _id: "",
      },

      quantity: "",
      serialNumber: accessuarModalInfoFromConvert?.serialNumber || "",
      productionYear: "",
      status: {
        name: "",
        _id: "",
      },
      salePrice: {
        currency: "EUR",
        value: "",
      },
      shippingCost: {
        currency: "EUR",
        value: "",
      },
      taxCost: {
        currency: "EUR",
        value: "",
      },
      otherCost: {
        currency: "EUR",
        value: "",
      },
      deviceSerialNumber: "",
      deviceBrand: {
        name: "",
        _id: "",
      },
      deviceModel: {
        name: "",
        _id: "",
      },
      deviceCategory: {
        name: "",
        _id: "",
      },
      deviceProductionYear: "",
    },
    validationSchema: AddNewPartValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        setIsLoading(true);
        if (isOpenedFromConvert) {
          const accessoryService = new AccessoryService();
          let data = getFormattedPartValuesFormData(
            values,
            accessuarModalInfoFromConvert
          );
          accessoryService
            .convertAccessuar(accessuarModalInfoFromConvert?._id, data)
            .then((res) => {
              toast.success(languageTexts[danisikLanguage].operationSuccessful);
              formik.resetForm();
              dispatch(getParts());
            })
            .finally(() => setIsLoading(false));
        } else {
          productService
            .createPart(getFormattedPartValues(values))
            .then((res) => {
              toast.success(languageTexts[danisikLanguage].operationSuccessful);
              formik.resetForm();
              dispatch(getParts());
            })
            .finally(() => setIsLoading(false));
        }
      }
    },
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "brand") {
      formik.setFieldValue("deviceBrand", value);
    } else if (name === "category") {
      formik.setFieldValue("deviceCategory", value);
    } else if (name === "model") {
      formik.setFieldValue("deviceModel", value);
    } else if (name === "productionYear") {
      formik.setFieldValue("deviceProductionYear", value);
    }
    formik.setFieldValue(name, value);
  };
  const handleChangeCurrency = (e) => {
    formik.setFieldValue(`${e.name}.currency`, e.value);
  };
  const handleChangeCurrencyInputValue = (e) => {
    formik.setFieldValue(`${e.name}.value`, e.value);
  };

  return (
    <div className="flow-content">
      <form onSubmit={formik.handleSubmit} className="flow-content">
        <Input
          label={"*" + languageTexts[danisikLanguage].partNumber}
          value={formik.values.partNumber}
          handleChange={handleChange}
          name="partNumber"
          disabled={true}
          showError={
            formik.touched.partNumber && Boolean(formik.errors.partNumber)
          }
          error={formik.touched.partNumber && formik.errors.partNumber}
        />
        <SelectOption
          options={partTypeOptions}
          label={"*" + languageTexts[danisikLanguage].partType}
          name="partType"
          handleChange={handleChange}
          value={formik.values.partType}
          showError={
            formik.touched.partType?._id && Boolean(formik.errors.partType?._id)
          }
          error={formik.touched.partType?._id && formik.errors.partType?._id}
        />
        <Input
          label={"*" + languageTexts[danisikLanguage].partName}
          value={formik.values.partName}
          handleChange={handleChange}
          name="partName"
          showError={formik.touched.partName && Boolean(formik.errors.partName)}
          error={formik.touched.partName && formik.errors.partName}
        />
        <Input
          label={"*" + languageTexts[danisikLanguage].detailInformation}
          value={formik.values.detailInformation}
          handleChange={handleChange}
          name="detailInformation"
          showError={
            formik.touched.detailInformation &&
            Boolean(formik.errors.detailInformation)
          }
          error={
            formik.touched.detailInformation && formik.errors.detailInformation
          }
        />
        <BrandModelOptionsNew
          brandLabel={languageTexts[danisikLanguage].brand}
          modelLabel={languageTexts[danisikLanguage].model}
          brandValue={formik.values.brand}
          modelValue={formik.values.model}
          showErrorBrand={
            formik.touched.brand?._id && Boolean(formik.errors.brand?._id)
          }
          errorBrand={formik.touched.brand?._id && formik.errors.brand?._id}
          showErrorModel={
            formik.touched.model?._id && Boolean(formik.errors.model?._id)
          }
          errorModel={formik.touched.model?._id && formik.errors.model?._id}
          handleChangeBrand={handleChange}
          handleChangeModel={handleChange}
        />
        <DotmedCategoryOptionsNew
          options={brandOptions}
          label={"*" + languageTexts[danisikLanguage].category}
          name="category"
          handleChange={handleChange}
          value={formik.values.category}
          showError={
            formik.touched.category?._id && Boolean(formik.errors.category?._id)
          }
          error={formik.touched.category?._id && formik.errors.category?._id}
        />
        <Input
          label={languageTexts[danisikLanguage].quantity}
          type="number"
          value={formik.values.quantity}
          handleChange={handleChange}
          name="quantity"
          showError={formik.touched.quantity && Boolean(formik.errors.quantity)}
          error={formik.touched.quantity && formik.errors.quantity}
        />
        <Input
          label={languageTexts[danisikLanguage].serialNumber}
          value={formik.values.serialNumber}
          handleChange={handleChange}
          name="serialNumber"
          showError={
            formik.touched.serialNumber && Boolean(formik.errors.serialNumber)
          }
          error={formik.touched.serialNumber && formik.errors.serialNumber}
        />
        <Input
          type="number"
          label={languageTexts[danisikLanguage].productionYear}
          value={formik.values.productionYear}
          handleChange={handleChange}
          name="productionYear"
          showError={
            formik.touched.productionYear &&
            Boolean(formik.errors.productionYear)
          }
          error={formik.touched.productionYear && formik.errors.productionYear}
        />
        <SelectOption
          options={statusInfoOptions}
          label={languageTexts[danisikLanguage].status}
          name="status"
          handleChange={handleChange}
          value={formik.values.status}
          showError={
            formik.touched.status?._id && Boolean(formik.errors.status?._id)
          }
          error={formik.touched.status?._id && formik.errors.status?._id}
        />
        <CurrencyInput
          label={languageTexts[danisikLanguage].purchasePrice}
          value={formik.values.salePrice.value}
          selectedCurrency={formik.values.salePrice.currency}
          handleChangeCurrency={handleChangeCurrency}
          handleChange={handleChangeCurrencyInputValue}
          name="salePrice"
          showError={
            formik.touched.salePrice?.value &&
            Boolean(formik.errors.salePrice?.value)
          }
          error={
            formik.touched.salePrice?.value && formik.errors.salePrice?.value
          }
        />
        <CurrencyInput
          label={languageTexts[danisikLanguage].shippingExpenses}
          value={formik.values.shippingCost.value}
          selectedCurrency={formik.values.shippingCost.currency}
          handleChangeCurrency={handleChangeCurrency}
          handleChange={handleChangeCurrencyInputValue}
          name="shippingCost"
          showError={
            formik.touched.shippingCost?.value &&
            Boolean(formik.errors.shippingCost?.value)
          }
          error={
            formik.touched.shippingCost?.value &&
            formik.errors.shippingCost?.value
          }
        />
        <CurrencyInput
          label={languageTexts[danisikLanguage].taxExpenses}
          value={formik.values.taxCost.value}
          selectedCurrency={formik.values.taxCost.currency}
          handleChangeCurrency={handleChangeCurrency}
          handleChange={handleChangeCurrencyInputValue}
          name="taxCost"
          showError={
            formik.touched.taxCost?.value &&
            Boolean(formik.errors.taxCost?.value)
          }
          error={formik.touched.taxCost?.value && formik.errors.taxCost?.value}
        />
        <CurrencyInput
          label={languageTexts[danisikLanguage].otherExpenses}
          value={formik.values.otherCost.value}
          selectedCurrency={formik.values.otherCost.currency}
          handleChangeCurrency={handleChangeCurrency}
          handleChange={handleChangeCurrencyInputValue}
          name="otherCost"
          showError={
            formik.touched.otherCost?.value &&
            Boolean(formik.errors.otherCost?.value)
          }
          error={
            formik.touched.otherCost?.value && formik.errors.otherCost?.value
          }
        />
        <div className="flow-content">
          <h3>
            {languageTexts[danisikLanguage].deviceInformationThatPartBelongsTo}
          </h3>
          <Input
            label={languageTexts[danisikLanguage].serialNumber}
            value={formik.values.deviceSerialNumber}
            handleChange={handleChange}
            name="deviceSerialNumber"
            showError={
              formik.touched.deviceSerialNumber &&
              Boolean(formik.errors.deviceSerialNumber)
            }
            error={
              formik.touched.deviceSerialNumber &&
              formik.errors.deviceSerialNumber
            }
          />
          <BrandModelOptionsNew
            brandLabel={languageTexts[danisikLanguage].brand}
            modelLabel={languageTexts[danisikLanguage].model}
            brandValue={formik.values.deviceBrand}
            modelValue={formik.values.deviceModel}
            brandName="deviceBrand"
            modelName="deviceModel"
            showErrorBrand={
              formik.touched.deviceBrand?._id &&
              Boolean(formik.errors.deviceBrand?._id)
            }
            errorBrand={
              formik.touched.deviceBrand?._id && formik.errors.deviceBrand?._id
            }
            showErrorModel={
              formik.touched.deviceModel?._id &&
              Boolean(formik.errors.deviceModel?._id)
            }
            errorModel={
              formik.touched.deviceModel?._id && formik.errors.deviceModel?._id
            }
            handleChangeBrand={handleChange}
            handleChangeModel={handleChange}
          />
          <DotmedCategoryOptionsNew
            options={brandOptions}
            label={languageTexts[danisikLanguage].category}
            name="deviceCategory"
            handleChange={handleChange}
            value={formik.values.deviceCategory}
            showError={
              formik.touched.deviceCategory?._id &&
              Boolean(formik.errors.deviceCategory?._id)
            }
            error={
              formik.touched.deviceCategory?._id &&
              formik.errors.deviceCategory?._id
            }
          />
          <Input
            label={languageTexts[danisikLanguage].productionYear}
            value={formik.values.deviceProductionYear}
            handleChange={handleChange}
            name="deviceProductionYear"
            showError={
              formik.touched.deviceProductionYear &&
              Boolean(formik.errors.deviceProductionYear)
            }
            error={
              formik.touched.deviceProductionYear &&
              formik.errors.deviceProductionYear
            }
          />
        </div>
        <FormButton
          title={languageTexts[danisikLanguage].save}
          isLoading={isLoading}
        />
      </form>
      <PrimaryButton
        title={languageTexts[danisikLanguage].cancel}
        onClick={() => setAddNewPart(false)}
        backgroundColor="var(--clr-blue)"
      />
    </div>
  );
}

export default AddNewPart;
