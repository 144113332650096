import FactoryService from "services/factoryService";

export default class DeviceStorageService extends FactoryService {
  constructor() {
    super("storage");
  }
  getAllByDeviceId(deviceId) {
    return this.api.get(
      `/${this.serviceName}/getAllStoragesWithDeviceId/${deviceId}`
    );
  }
}
