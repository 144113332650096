import Save from "assets/icons/Save";
import Loader from "components/Loader";
import React from "react";
import "./style.scss";
function FormButton({ title = "", isLoading = false, onClick, ...props }) {
  return (
    <button
      className="form-button"
      onClick={onClick}
      type="submit"
      disabled={isLoading}
      {...props}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Save />
          {title}
        </>
      )}
    </button>
  );
}

export default FormButton;
