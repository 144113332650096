import * as Yup from "yup";
export default Yup.object().shape({
  serialNumber: Yup.string().required("Serial number is required"),
  brand: Yup.object().shape({
    _id: Yup.string().required("Brand is required"),
  }),
  model: Yup.object().shape({
    _id: Yup.string().required("Model is required"),
  }),
  productionYear: Yup.number()
    .min(1800, "Please enter valid year")
    .max(new Date().getFullYear() + 1, "Please enter valid year")
    .required("Production Year is required"),
  dotmedCategory: Yup.object().shape({
    _id: Yup.string().required("Category is required"),
  }),
  supplyInstitution: Yup.string().required("Supply Institution is required"),
  salePrice: Yup.object().shape({
    value: Yup.number("Enter Sale Price")
      .min(1, "Sale price must be greater than 0")
      .required("Sale Price is required"),
    currency: Yup.string()
      .oneOf(["EUR", "USD", "GBP", "TRY"])
      .required("Please select valid currency"),
  }),
  // shippingCost: Yup.object().shape({
  //   value: Yup.number("Enter Sale Price")
  //     .min(1, "Shipping cost must be greater than 0")
  //     .required("Shipping cost is required"),
  //   currency: Yup.string()
  //     .oneOf(["EUR", "USD", "GBP", "TRY"])
  //     .required("Please select valid currency"),
  // }),

  // taxCost: Yup.object().shape({
  //   value: Yup.number("")
  //     .min(1, "Tax cost must be greater than 0")
  //     .required("Tax cost is required"),
  //   currency: Yup.string()
  //     .oneOf(["EUR", "USD", "GBP", "TRY"])
  //     .required("Please select valid currency"),
  // }),
  // otherCost: Yup.object().shape({
  //   value: Yup.number("").required("Other cost is required"),
  //   currency: Yup.string()
  //     .oneOf(["EUR", "USD", "GBP", "TRY"])
  //     .required("Please select valid currency"),
  // }),
});
