import React, { useEffect, useState } from "react";
import "../style.scss";
import User from "assets/icons/User";
import InputWithIcon from "components/FormElements/InputWithIcon";

import danisikLogo from "assets/images/danisik_logo.png";
import bernerLogo from "assets/images/berner_logo.svg";
import Lock from "assets/icons/Lock";
import PrimaryButton from "components/Buttons/PrimaryButton";
import Checkbox from "components/FormElements/Checkbox";
import { useFormik } from "formik";
import loginValidationSchema from "./loginValidationSchema";
import CustomAlert from "lib/customAlert.js";
import UserService from "services/userService";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "redux/user/userActions";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import languageTexts from "constants/languageTexts";
import ChangeLanguage from "components/Settings/ChangeLanguage";

const userService = new UserService();
function Login() {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(true);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let token = localStorage.getItem("danisik-token");
    if (token) {
      navigate("/");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      userService
        .login(values)
        .then((res) => {
          dispatch(signIn(res.data.data.user, res.data.token));
          navigate("/");
        })
        .catch((err) => {
          toast.error(err.data.message);
        })
        .finally(() => setLoading(false));
    },
  });
  const handleRememberMeCheckStatus = (e) => {
    setRememberMe(e.target.checked);
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value);
  };
  return (
    <div className="login-page auth-page">
      <div className="customer-logo">
        <img src={danisikLogo} alt="company logo" />
      </div>
      <div className="login-page-content auth-page-content">
        <div className="content-title">
          {languageTexts[danisikLanguage].login}
        </div>
        <form
          className="login-page-form auth-page-form"
          onSubmit={formik.handleSubmit}
        >
          <InputWithIcon
            icon={<User />}
            label={languageTexts[danisikLanguage].email}
            type="email"
            value={formik.values.email}
            name="email"
            handleChange={handleChange}
            showError={formik.touched.email && Boolean(formik.errors.email)}
            error={formik.touched.email && formik.errors.email}
          />
          <InputWithIcon
            icon={<Lock />}
            label={languageTexts[danisikLanguage].password}
            type="password"
            value={formik.values.password}
            name="password"
            handleChange={handleChange}
            showError={
              formik.touched.password && Boolean(formik.errors.password)
            }
            error={formik.touched.password && formik.errors.password}
          />
          {/* <Checkbox
            label="Remember me"
            onChange={handleRememberMeCheckStatus}
            checked={rememberMe}
          /> */}
          <PrimaryButton
            onClick={() => console.log("handle click")}
            title={languageTexts[danisikLanguage].login}
            type="submit"
            backgroundColor="var(--clr-blue)"
            loading={loading}
          />
          <div className="setting-items-container">
            <ChangeLanguage />
          </div>
          <Link to={"/register"}>
            {languageTexts[danisikLanguage].register}
          </Link>
        </form>
      </div>
      <div className="footer">
        <img
          src={bernerLogo}
          alt="Developers' logo"
          className="developers-logo"
        />
        <div className="footer-text">
          <span>2023</span>
          <span>All Right Reserved</span>
        </div>
      </div>
    </div>
  );
}

export default Login;
