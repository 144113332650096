import "./style.scss";
import Calendar from "assets/icons/Calendar";
import ModalHeader from "components/Modals/ModalHeader";
import SearchInput from "components/Search/SearchInput";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { debounce } from "utils/debounce";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { dateFormatToDDMMYYYDayHHMMSS } from "utils/dateFormat";
import RecentOperationService from "services/admin/recentOperationService";
import languageTexts from "constants/languageTexts";

const recentOperationsDummy = [
  {
    isRead: false,
    _id: "64b7ab94f15ad7496c9b568c",
    title: "BuyUpdated",
    description: "Suleyman Akilli updated Buy id = 64b3b20071050c2150ab3150",
    userName: "Suleyman Akilli",
    date: "2023-07-19T09:23:32.337Z",
    priority: 2,
  },
  {
    isRead: true,
    _id: "64b7a3c5b8384732ec1f3bb8",
    title: "BrandSaved",
    description: "Suleyman Akilli added to Brand id = 64b7a3c4b8384732ec1f3bb6",
    userName: "Suleyman Akilli",
    date: "2023-07-19T08:50:13.024Z",
    priority: 1,
  },
  {
    isRead: true,
    _id: "64b6d595185ddc43f4441b3b",
    title: "PartProductSaved",
    description: "beko added to PartProduct id = 64b6d594185ddc43f4441b22",
    userName: "beko",
    date: "2023-07-18T18:10:29.929Z",
    priority: 1,
  },
];

const setQuery = (startDate, endDate, searchText) => {
  let query = "";

  if (startDate) {
    startDate = new Date(startDate);
    startDate = `${startDate.getFullYear()}-${
      startDate.getMonth() + 1
    }-${startDate.getDate()}`;
    query += "minDate=" + startDate;
  }
  if (endDate) {
    if (query) {
      query += "&";
    }
    endDate = new Date(endDate);
    endDate = `${endDate.getFullYear()}-${
      endDate.getMonth() + 1
    }-${endDate.getDate()}`;
    query += "maxDate=" + endDate;
  }
  if (searchText) {
    if (query) {
      query += "&";
    }
    query += `search=${searchText}`;
  }
  return query;
};

const recentOperationService = new RecentOperationService();
function RecentOperationsModal({ isModalOpen = false, setIsModalOpen }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [recentOperations, setRecentOperations] = useState([]);
  const [recentOperationCurrentPage, setRecentOperationCurrentPage] =
    useState(1);
  const [recentOperationPageCount, setRecentOperationsPageCount] = useState(1);
  const [recentOperationSearchText, setRecentOperationSearchText] =
    useState("");
  const [recentOperationFilter, setRecentOperationFilter] = useState({
    startDate: new Date(),
    endDate: null,
    searchText: "",
  });

  const [datepickerOpen, setDatepickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getAllRecentOperations = useCallback((query) => {
    recentOperationService
      .getAll(`?limit=15&page=${recentOperationCurrentPage}&${query}`)
      .then((res) => {
        setRecentOperations(res.data.data);
        setRecentOperationsPageCount(
          res.data.totalPage ? res.data.totalPage : 1
        );
      });
  }, []);

  useEffect(() => {
    getAllRecentOperations();
  }, [getAllRecentOperations]);
  const handleScrollDropdownDebounce = debounce((e) => {
    handleScrollDropdown(e);
  }, 350);

  const handleScrollBottomRecentOperaiton = () => {
    if (recentOperationPageCount > recentOperationCurrentPage) {
      setRecentOperationCurrentPage(recentOperationCurrentPage + 1);
    }
  };
  const handleScrollDropdown = (e) => {
    const isScrollAtTheBottom =
      e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 1;
    if (isScrollAtTheBottom) {
      handleScrollBottomRecentOperaiton();
    }
  };

  useEffect(() => {
    if (recentOperationCurrentPage > 1) {
      recentOperationService
        .getAll(`?limit=15&page=${recentOperationCurrentPage}`)
        .then((res) => {
          let newRecentOperations = [...recentOperations, ...res.data.data];
          setRecentOperations(newRecentOperations);
        });
    }
  }, [recentOperationCurrentPage]);
  useEffect(() => {
    let query = setQuery(
      recentOperationFilter.startDate,
      recentOperationFilter.endDate,
      recentOperationFilter.searchText
    );
    getAllRecentOperations(query);
  }, [recentOperationFilter]);

  const handleChangeFilterInput = (searchText, startDate, endDate) => {
    setRecentOperationFilter({ searchText, startDate, endDate });
  };

  const handleChangeFilterInputDebounce = useCallback(
    debounce((searchText, startDate, endDate) => {
      handleChangeFilterInput(searchText, startDate, endDate);
    }, 350),
    []
  );

  useEffect(() => {
    handleChangeFilterInputDebounce(
      recentOperationSearchText,
      startDate,
      endDate
    );
  }, [
    recentOperationSearchText,
    startDate,
    endDate,
    handleChangeFilterInputDebounce,
  ]);

  return (
    <div
      className={`recent-operation-list-modal-container modal-container settings-modals ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal recent-operation-list-modal`}>
        <ModalHeader
          title={languageTexts[danisikLanguage].recentOperations}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content settings-modal-content">
          <div className="modal-body-header">
            <div className="search-input-container">
              <div className="search-input">
                <SearchInput
                  onChange={(e) => setRecentOperationSearchText(e.target.value)}
                  value={recentOperationSearchText}
                />
              </div>

              <div className="calendar-container">
                <Calendar
                  fill="var(--clr-neutral-100)"
                  onClick={() => setDatepickerOpen(!datepickerOpen)}
                />
                {datepickerOpen ? (
                  <div className="date-picker-container">
                    <DatePicker
                      selected={startDate}
                      onChange={onChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      inline
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div
            className="settings-modal-body"
            onScroll={handleScrollDropdownDebounce}
          >
            <div className="item-container">
              {recentOperations.map((recentOperation) => (
                <div
                  key={recentOperation._id}
                  className="recent-operation-item"
                >
                  <span>{recentOperation.description}</span>
                  <span>
                    {dateFormatToDDMMYYYDayHHMMSS(recentOperation.date)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
    </div>
  );
}

export default RecentOperationsModal;
