import PartTableInactive from "components/PartTableInactive";
import React from "react";

function InactiveParts() {
  return (
    <div>
      <PartTableInactive />
    </div>
  );
}

export default InactiveParts;
