import FormButton from "components/Buttons/FormButton";
import Input from "components/FormElements/Input";
import { useFormik } from "formik";
import CustomAlert from "lib/customAlert.js";
import React from "react";
import "./style.scss";
import ModalHeader from "components/Modals/ModalHeader";
import operationsMadeValidationSchema from "./operationsMadeValidationSchema";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";

function AddOperationsMade({
  isModalOpen = false,
  setIsModalOpen,
  operationsMadeModalInfo = {
    description: "",
    timeSpend: "",
  },
  setOperationsMadeModalInfo,
  operationsMade,
  setOperationsMade,
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: operationsMadeModalInfo.description,
      timeSpend: operationsMadeModalInfo.timeSpend,
    },
    validationSchema: operationsMadeValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        if (operationsMadeModalInfo.index + 1) {
          operationsMade[operationsMadeModalInfo.index] = values;
          setOperationsMade(operationsMade);
        } else {
          operationsMade.push(values);
          setOperationsMade(operationsMade);
        }
        setIsModalOpen(false);
        setOperationsMadeModalInfo({
          material: "",
          cost: {
            value: 0,
            currency: "EUR",
          },
        });
      }
    },
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    formik.setFieldValue(name, value);
  };
  return (
    <div
      className={`add-operations-made-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal add-operations-made-modal`}>
        <ModalHeader
          title={`${
            operationsMadeModalInfo.index + 1
              ? languageTexts[danisikLanguage].edit
              : languageTexts[danisikLanguage].add
          }`}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content">
          <form
            onSubmit={formik.handleSubmit}
            className="flow-content tab-device-form"
          >
            <Input
              label={languageTexts[danisikLanguage].description}
              value={formik.values.description}
              handleChange={handleChange}
              name="description"
              showError={
                formik.touched.description && Boolean(formik.errors.description)
              }
              error={formik.touched.description && formik.errors.description}
            />
            <Input
              label={languageTexts[danisikLanguage].hourSpent}
              value={formik.values.timeSpend}
              handleChange={handleChange}
              name="timeSpend"
              showError={
                formik.touched.timeSpend && Boolean(formik.errors.timeSpend)
              }
              error={formik.touched.timeSpend && formik.errors.timeSpend}
            />

            <FormButton title={languageTexts[danisikLanguage].save} />
          </form>
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
    </div>
  );
}

export default AddOperationsMade;
