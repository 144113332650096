import FormButton from "components/Buttons/FormButton";
import Input from "components/FormElements/Input";
import { useFormik } from "formik";
import CustomAlert from "lib/customAlert.js";
import React, { useState } from "react";
import ModalHeader from "../ModalHeader";
import "./style.scss";
import DragAndDrop from "components/DragAndDrop";
import SelectOption from "components/FormElements/SelectOption";
import { statusInfoOptions, statusInfos } from "constants/statusInfos";
import BrandModelOptions from "components/BrandModelOptions";
import AccessoryService from "services/product/device/accessoryService";
import { toast } from "react-toastify";
import LoadingPage from "components/LoadingPage";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
import accessuarValidationSchema from "./accessuarValidationSchema";
import InputWithQrCodeNew from "components/FormElements/InputWithQrCodeNew";
import DeviceIcon from "assets/icons/Device";
import PartsIcon from "assets/icons/Parts";
import { useNavigate } from "react-router-dom";
import DragAndDropNew from "components/DragAndDropNew";
import BrandModelOptionsNew from "components/BrandModelOptionsNew";
import AddProduct from "../AddProduct";

let accessuarService = new AccessoryService();
function AddAccessuars({
  isModalOpen = false,
  setIsModalOpen,
  getAccessories,
  accessuarModalInfo = {
    _id: 0,
    serialNumber: "",
    status: {
      name: "",
      _id: "",
    },
    definition: "",
    images: [],
    brand: {
      name: "",
      _id: "",
    },
    model: {
      name: "",
      _id: "",
    },
    deviceId: 0,
    stockNumber: 0,
  },
  setAccessuarModalInfo,
  accessuars,
  setAccessuars,
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [loading, setLoading] = useState(false);
  const [isConvertModalOpen, setIsConvertModalOpen] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: accessuarModalInfo._id || 0,
      serialNumber: accessuarModalInfo.serialNumber,
      status: {
        name: statusInfos[accessuarModalInfo.status] || "",
        _id: accessuarModalInfo.status || "",
      },
      definition: accessuarModalInfo.definition,
      brand: accessuarModalInfo.brand,
      model: accessuarModalInfo.model,
      images: accessuarModalInfo.images,
      deviceId: accessuarModalInfo.deviceId,
      stockNumber: accessuarModalInfo.stockNumber,
    },
    validationSchema: accessuarValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        let newImages = values.images.map((img) => {
          img.newAdded = false;
          return img;
        });
        let formattedValues = {
          _id: values._id,
          serialNumber: values.serialNumber,
          status: values.status?._id,
          definition: values.definition,
          brandId: values.brand,
          modelId: values.model,
          images: newImages,
          deviceId: values.deviceId,
          stockNumber: values.stockNumber,
        };
        if (accessuarModalInfo.index + 1) {
          accessuars[accessuarModalInfo.index] = formattedValues;
          setAccessuars(accessuars);
        } else {
          accessuars.push(formattedValues);
          setAccessuars(accessuars);
        }
        setIsModalOpen(false);
        setAccessuarModalInfo({
          _id: 0,
          serialNumber: "",
          status: {
            name: "",
            _id: "",
          },
          definition: "",
          images: [],
          brand: {
            name: "",
            _id: "",
          },
          model: {
            name: "",
            _id: "",
          },
          deviceId: 0,
          stockNumber: 0,
        });
      }
    },
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value);
  };

  const handleUpdateFile = (images) => {
    let newImages = [...formik.values.images, ...images];
    formik.setFieldValue("images", newImages);
  };
  const handleDeleteImageFormik = (file) => {
    let newFiles = JSON.parse(JSON.stringify(formik.values.images));
    newFiles = newFiles.filter((img) => img._id !== file._id);
    formik.setFieldValue("images", newFiles);
  };
  const handleOrderImages = (images, modelId) => {
    accessuarService
      .orderImages({ modelId: modelId, images: images })
      .then((res) => {
        toast.success(
          languageTexts[danisikLanguage].imagesAreOrderedSuccessfully
        );
      });
  };
  const handleDeleteImage = (publicId) => {
    setLoading(true);
    accessuarService
      .handleDeleteImg(formik.values._id, publicId)
      .then((res) => {
        setIsModalOpen(false);
        setAccessuarModalInfo({
          _id: 0,
          serialNumber: "",
          status: {
            name: "",
            _id: "",
          },
          definition: "",
          images: [],
          brand: {
            name: "",
            _id: "",
          },
          model: {
            name: "",
            _id: "",
          },
          deviceId: 0,
          stockNumber: 0,
        });
        getAccessories();
        toast.success(languageTexts[danisikLanguage].succesfullyDeleted);
      })
      .finally(() => setLoading(false));
  };

  const transferAccessory = async (accessuarId, stockNumber) => {
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].OK
    );
    if (result) {
      setLoading(true);
      accessuarService
        .transferAccessuary(accessuarId, stockNumber)
        .then((res) => {
          getAccessories();
          toast.success(languageTexts[danisikLanguage].operationSuccessful);
          setIsModalOpen(false);
        })
        .finally(() => setLoading(false));
    }
  };
  const convertToDevice = async (accessuarId) => {
    setIsConvertModalOpen(true);
  };

  const convertToPart = async (accessuarId) => {
    setIsConvertModalOpen(true);
  };

  const handleCloseModal = () => {
    formik.setFieldValue("_id", accessuarModalInfo.serialNumber);
    formik.setFieldValue("serialNumber", accessuarModalInfo.serialNumber);
    formik.setFieldValue("status", {
      name: statusInfos[accessuarModalInfo.status] || "",
      _id: accessuarModalInfo.status || "",
    });
    formik.setFieldValue("definition", accessuarModalInfo.definition);
    formik.setFieldValue("brand", accessuarModalInfo.brand);
    formik.setFieldValue("model", accessuarModalInfo.model);
    formik.setFieldValue("images", accessuarModalInfo.images);
    formik.setFieldValue("deviceId", accessuarModalInfo.deviceId);
    formik.setFieldValue("stockNumber", accessuarModalInfo.stockNumber);
    setIsModalOpen(false);
  };
  return loading ? (
    <LoadingPage />
  ) : (
    <div
      className={`add-accessuar-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal add-accessuar-modal`}>
        <ModalHeader
          title={`${
            accessuarModalInfo.index + 1
              ? languageTexts[danisikLanguage].edit
              : languageTexts[danisikLanguage].add
          }`}
          setIsModalOpen={handleCloseModal}
        />
        <div className="modal-content">
          <form
            onSubmit={formik.handleSubmit}
            className="flow-content tab-device-form"
          >
            <DragAndDropNew
              images={formik.values.images}
              setImages={(images) => {
                formik.setFieldValue("images", images);
              }}
              // handleUpdateFile={handleUpdateFile}
              handleDeleteImageFormik={handleDeleteImageFormik}
              handleDeleteImage={handleDeleteImage}
              handleOrder={handleOrderImages}
              modelId={formik.values._id}
            />
            <InputWithQrCodeNew
              label={languageTexts[danisikLanguage].serialNumber}
              value={formik.values.serialNumber}
              handleChange={handleChange}
              name="serialNumber"
              showError={
                formik.touched.serialNumber &&
                Boolean(formik.errors.serialNumber)
              }
              error={formik.touched.serialNumber && formik.errors.serialNumber}
            />

            <BrandModelOptionsNew
              isModalOpen={isModalOpen}
              brandLabel={languageTexts[danisikLanguage].brand}
              modelLabel={languageTexts[danisikLanguage].model}
              brandValue={formik.values.brand}
              modelValue={formik.values.model}
              showErrorBrand={
                formik.touched.brand?._id && Boolean(formik.errors.brand?._id)
              }
              errorBrand={formik.touched.brand?._id && formik.errors.brand?._id}
              showErrorModel={
                formik.touched.model?._id && Boolean(formik.errors.model?._id)
              }
              errorModel={formik.touched.model?._id && formik.errors.model?._id}
              handleChangeBrand={handleChange}
              handleChangeModel={handleChange}
            />

            <SelectOption
              label={languageTexts[danisikLanguage].status}
              options={statusInfoOptions}
              value={formik.values.status}
              handleChange={handleChange}
              name="status"
              showError={
                formik.touched.status?._id && Boolean(formik.errors.status?._id)
              }
              error={formik.touched.status?._id && formik.errors.status?._id}
            />
            <Input
              label={languageTexts[danisikLanguage].accessuarDefinition}
              value={formik.values.definition}
              handleChange={handleChange}
              name="definition"
              showError={
                formik.touched.definition && Boolean(formik.errors.definition)
              }
              error={formik.touched.definition && formik.errors.definition}
            />
            {accessuarModalInfo._id ? (
              <>
                <h3>{languageTexts[danisikLanguage].transferAccessory}</h3>
                <div className="transfer-accessory">
                  <Input
                    label={languageTexts[danisikLanguage].stockNumber}
                    value={formik.values.stockNumber}
                    handleChange={handleChange}
                    name="stockNumber"
                    showError={
                      formik.touched.stockNumber &&
                      Boolean(formik.errors.stockNumber)
                    }
                    error={
                      formik.touched.stockNumber && formik.errors.stockNumber
                    }
                  />
                  <button
                    className="accessuar-transfer-item"
                    onClick={() =>
                      transferAccessory(
                        accessuarModalInfo._id,
                        formik.values.stockNumber
                      )
                    }
                  >
                    {languageTexts[danisikLanguage].transferAccessory}
                  </button>
                </div>
                <div className="accessuar-transfer-operations">
                  <div
                    className="accessuar-transfer-item"
                    onClick={() => convertToDevice(accessuarModalInfo._id)}
                  >
                    <DeviceIcon />
                    {languageTexts[danisikLanguage].convertToDevice}
                  </div>
                  <div
                    className="accessuar-transfer-item"
                    onClick={() => convertToPart(accessuarModalInfo._id)}
                  >
                    <PartsIcon />
                    {languageTexts[danisikLanguage].convertToPart}
                  </div>
                </div>
              </>
            ) : null}

            <FormButton title={languageTexts[danisikLanguage].save} />
          </form>
        </div>
        <div className="modal-overlay" onClick={handleCloseModal}></div>
      </div>
      <AddProduct
        isModalOpen={isConvertModalOpen}
        setIsModalOpen={setIsConvertModalOpen}
        isOpenedFromConvert={true}
        accessuarModalInfo={accessuarModalInfo}
      />
    </div>
  );
}

export default AddAccessuars;
