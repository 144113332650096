import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import userReducer from "./user/userReducer";
import languageReducer from "redux/language/languageReducer";
import staticTextsReducer from "redux/staticTexts/staticTextsReducer";
import themeReducer from "./theme/themeReducer";
import deviceReducer from "./product/device/deviceReducer";
import partReducer from "./product/part/partReducer";
import brandReducer from "./product/brand/brandReducer";
import categoryReducer from "./product/category/categoryReducer";
import modelReducer from "./product/model/modelReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "danisikLanguages", "danisikStaticTexts", "theme"],
};

export const rootReducer = combineReducers({
  danisikUser: userReducer,
  danisikLanguage: languageReducer,
  danisikStaticTexts: staticTextsReducer,
  danisikTheme: themeReducer,
  danisikProductDevice: deviceReducer,
  danisikProductPart: partReducer,
  danisikBrands: brandReducer,
  danisikCategories: categoryReducer,
  danisikModels: modelReducer,
});

export default persistReducer(persistConfig, rootReducer);
