import FormButton from "components/Buttons/FormButton";
import Input from "components/FormElements/Input";
import { useFormik } from "formik";
import CustomAlert from "lib/customAlert.js";
import React from "react";
import ModalHeader from "../ModalHeader";
import "./style.scss";
import techincalDetailValidationSchema from "./techincalDetailValidationSchema";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";

function AddTechnicalDetails({
  isModalOpen = false,
  setIsModalOpen,
  deviceId,
  technicalDetailModalInfo = { _id: 2, title: "", description: "" },
  setTechnicalDetailModalInfo,
  technicalDetails,
  setTechnicalDetails,
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: technicalDetailModalInfo.title,
      description: technicalDetailModalInfo.description,
    },
    validationSchema: techincalDetailValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        if (technicalDetailModalInfo.index + 1) {
          technicalDetails[technicalDetailModalInfo.index] = values;
          setTechnicalDetails(technicalDetails);
        } else {
          technicalDetails.push(values);
          setTechnicalDetails(technicalDetails);
        }
        setIsModalOpen(false);
        setTechnicalDetailModalInfo({ _id: 0, title: "", description: "" });
      }
    },
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value);
  };
  return (
    <div
      className={`add-technical-details-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal add-technical-details-modal`}>
        <ModalHeader
          title={`${
            technicalDetailModalInfo._id
              ? languageTexts[danisikLanguage].edit
              : languageTexts[danisikLanguage].add
          }`}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content">
          <form
            onSubmit={formik.handleSubmit}
            className="flow-content tab-device-form"
          >
            <Input
              label={languageTexts[danisikLanguage].title}
              value={formik.values.title}
              handleChange={handleChange}
              name="title"
              showError={formik.touched.title && Boolean(formik.errors.title)}
              error={formik.touched.title && formik.errors.title}
            />
            <Input
              label={languageTexts[danisikLanguage].description}
              value={formik.values.description}
              handleChange={handleChange}
              name="description"
              showError={
                formik.touched.description && Boolean(formik.errors.description)
              }
              error={formik.touched.description && formik.errors.description}
            />
            <FormButton title={languageTexts[danisikLanguage].save} />
          </form>
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
    </div>
  );
}

export default AddTechnicalDetails;
