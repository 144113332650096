import FactoryService from "services/factoryService";

export default class CategoryService extends FactoryService {
  constructor() {
    super("category");
  }
  getDotmedCategory(query = "") {
    return this.api.get(`dotmedcategory${query}`);
  }
}
