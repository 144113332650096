import React, { useEffect, useState } from "react";
import "./style.scss";
import SelectOption from "components/FormElements/SelectOption";
import PackagingTypeService from "services/product/device/packagingTypeService";

const packagingTypeService = new PackagingTypeService();
function PackagingOptions({
  label = "*Packaging Type",
  name = "packagingType",
  handleChange = () => {},
  value = "",
  showError,
  error,
}) {
  const [packagingOptions, setPackagingOptions] = useState([]);
  const [packagingCurrentPage, setPackagingCurrentPage] = useState(1);
  const [packagingPageCount, setPackagingPageCount] = useState(1);
  const [packagingOptionSearchText, setPackagingOptionSearchText] =
    useState("");

  const handleScrollBottom = () => {
    if (packagingPageCount > packagingCurrentPage) {
      setPackagingCurrentPage(packagingCurrentPage + 1);
    }
  };
  useEffect(() => {
    packagingTypeService
      .getAll(`?limit=15&page=${packagingCurrentPage}`)
      .then((res) => {
        setPackagingOptions(res.data.data);
        setPackagingPageCount(res.data.totalPage ? res.data.totalPage : 1);
      });
  }, []);
  useEffect(() => {
    if (packagingCurrentPage > 1) {
      packagingTypeService
        .getAll(`?limit=15&page=${packagingCurrentPage}`)
        .then((res) => {
          let newPackagingOptions = [...packagingOptions, ...res.data.data];
          setPackagingOptions(newPackagingOptions);
        });
    }
  }, [packagingCurrentPage]);

  const handleChangeFilterCategoryInput = (val) => {
    setPackagingOptionSearchText(val);
  };
  return (
    <SelectOption
      options={packagingOptions}
      label={label}
      name={name}
      handleChange={handleChange}
      value={value}
      showError={showError}
      error={error}
      handleScrollBottom={handleScrollBottom}
      filterInput={packagingOptionSearchText}
      handleChangeFilterInput={handleChangeFilterCategoryInput}
    />
  );
}

export default PackagingOptions;
