import FormButton from "components/Buttons/FormButton";
import ModalHeader from "components/Modals/ModalHeader";
import { useFormik } from "formik";
import CustomAlert from "lib/customAlert";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import UserService from "services/userService";
import userChangePasswordValidationSchema from "./userChangePasswordValidationSchema";
import Input from "components/FormElements/Input";
import languageTexts from "constants/languageTexts";

function UserChangePassword({ id, isModalOpen, setIsModalOpen }) {
  const [loading, setLoading] = useState(false);
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    validationSchema: userChangePasswordValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        let userService = new UserService();
        setLoading(true);
        userService
          .changePassword(id, values)
          .then((res) => {
            toast.success(languageTexts[danisikLanguage].operationSuccessful);
          })
          .finally(() => {
            setLoading(false);
            setIsModalOpen(false);
          });
      }
    },
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value);
  };
  return (
    <div
      className={`user-change-password-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal user-change-password-modal`}>
        <ModalHeader
          title={`Change Password`}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content">
          <form onSubmit={formik.handleSubmit} className="flow-content">
            <Input
              label={"Password"}
              value={formik.values.password}
              handleChange={handleChange}
              name="password"
              type="password"
              showError={
                formik.touched.password && Boolean(formik.errors.password)
              }
              error={formik.touched.password && formik.errors.password}
            />
            <Input
              label={"Password Confirm"}
              value={formik.values.passwordConfirm}
              handleChange={handleChange}
              name="passwordConfirm"
              type="password"
              showError={
                formik.touched.passwordConfirm &&
                Boolean(formik.errors.passwordConfirm)
              }
              error={
                formik.touched.passwordConfirm && formik.errors.passwordConfirm
              }
            />
            <FormButton
              title={languageTexts[danisikLanguage].save}
              isLoading={loading}
            />
          </form>
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
    </div>
  );
}

export default UserChangePassword;
