import React from "react";

function CameraLine({
  fill = "rgba(222, 222, 222, 1)",
  width = "1.4rem",
  height = "1.4rem",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={fill}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M9 3h6l2 2h4a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4l2-2zm3 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12zm0-2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
    </svg>
  );
}

export default CameraLine;
