import React from "react";

function Settings({
  fill = "var(--clr-neutral-200)",
  width = "2.4rem",
  height = "2.4rem",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 14"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 14H7V16H0V14ZM0 7H9V9H0V7ZM0 0H20V2H0V0ZM18.674 9.025L19.83 8.634L20.83 10.366L19.914 11.171C20.0293 11.7177 20.0293 12.2823 19.914 12.829L20.83 13.634L19.83 15.366L18.674 14.975C18.264 15.345 17.776 15.63 17.239 15.805L17 17H15L14.76 15.804C14.2293 15.6306 13.7408 15.3478 13.326 14.974L12.17 15.366L11.17 13.634L12.086 12.829C11.9707 12.2823 11.9707 11.7177 12.086 11.171L11.17 10.366L12.17 8.634L13.326 9.025C13.736 8.655 14.224 8.37 14.761 8.195L15 7H17L17.24 8.196C17.776 8.37 18.264 8.656 18.674 9.026V9.025ZM16 13C16.2652 13 16.5196 12.8946 16.7071 12.7071C16.8946 12.5196 17 12.2652 17 12C17 11.7348 16.8946 11.4804 16.7071 11.2929C16.5196 11.1054 16.2652 11 16 11C15.7348 11 15.4804 11.1054 15.2929 11.2929C15.1054 11.4804 15 11.7348 15 12C15 12.2652 15.1054 12.5196 15.2929 12.7071C15.4804 12.8946 15.7348 13 16 13Z"
        fill="#DEDEDE"
      />
    </svg>
  );
}

export default Settings;
