import Filter from "assets/icons/Filter";
import Unfilter from "assets/icons/Unfilter";
import React from "react";
import "./style.scss";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";

function FilterButton({ filtered = false, ...props }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  return (
    <button
      className={`filter-btn ${filtered ? "clear-filter" : ""}`}
      {...props}
    >
      {filtered ? <Unfilter /> : <Filter />}
      {filtered
        ? languageTexts[danisikLanguage].clear
        : languageTexts[danisikLanguage].filter}
    </button>
  );
}

export default FilterButton;
