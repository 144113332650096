import FormButton from "components/Buttons/FormButton";
import Input from "components/FormElements/Input";
import { useFormik } from "formik";
import CustomAlert from "lib/customAlert.js";
import React from "react";
import "./style.scss";
import CurrencyInput from "components/FormElements/CurrencyInput";
import materialsUsedValidationSchema from "./materialsUsedValidationSchema";
import ModalHeader from "components/Modals/ModalHeader";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";

function AddMaterialsUsed({
  isModalOpen = false,
  setIsModalOpen,
  materialsUsedModalInfo = {
    material: "",
    cost: {
      value: "",
      currency: "EUR",
    },
  },
  setMaterialsUsedModalInfo,
  materialsUsed,
  setMaterialsUsed,
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      material: materialsUsedModalInfo.material,
      cost: materialsUsedModalInfo.cost,
    },
    validationSchema: materialsUsedValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        if (materialsUsedModalInfo.index + 1) {
          materialsUsed[materialsUsedModalInfo.index] = values;
          setMaterialsUsed(materialsUsed);
        } else {
          materialsUsed.push(values);
          setMaterialsUsed(materialsUsed);
        }
        setIsModalOpen(false);
        setMaterialsUsedModalInfo({
          material: "",
          cost: {
            value: 0,
            currency: "EUR",
          },
        });
      }
    },
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    formik.setFieldValue(name, value);
  };
  const handleChangeCurrency = (e) => {
    formik.setFieldValue(`${e.name}.currency`, e.value);
  };
  const handleChangeCurrencyInputValue = (e) => {
    formik.setFieldValue(`${e.name}.value`, e.value);
  };
  return (
    <div
      className={`add-materials-used-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal add-materials-used-modal`}>
        <ModalHeader
          title={`${
            materialsUsedModalInfo.index + 1
              ? languageTexts[danisikLanguage].edit
              : languageTexts[danisikLanguage].add
          }`}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content">
          <form
            onSubmit={formik.handleSubmit}
            className="flow-content tab-device-form"
          >
            <Input
              label={"Material"}
              value={formik.values.material}
              handleChange={handleChange}
              name="material"
              showError={
                formik.touched.material && Boolean(formik.errors.material)
              }
              error={formik.touched.material && formik.errors.material}
            />
            <CurrencyInput
              label={"*" + languageTexts[danisikLanguage].cost}
              value={formik.values.cost.value}
              selectedCurrency={formik.values.cost.currency}
              handleChangeCurrency={handleChangeCurrency}
              handleChange={handleChangeCurrencyInputValue}
              name="cost"
              showError={
                formik.touched.cost?.value && Boolean(formik.errors.cost?.value)
              }
              error={formik.touched.cost?.value && formik.errors.cost?.value}
            />

            <FormButton title={languageTexts[danisikLanguage].save} />
          </form>
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
    </div>
  );
}

export default AddMaterialsUsed;
