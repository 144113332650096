import React from "react";
import "./style.scss";
import PartTable from "components/PartTable";
import { useParams } from "react-router-dom";

function Parts() {
  const { partId } = useParams();
  return (
    <div>
      <PartTable partId={partId} />
    </div>
  );
}

export default Parts;
