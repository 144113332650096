import { store } from "../../store";
import PartService from "services/product/part/part";
import { PartActionTypes } from "./partActionTypes";

const setQueryFromFilters = (filter, sortBy, searchText) => {
  let query = "";
  if (filter.brand._id) {
    query += `brandId=${filter.brand._id}`;
  }
  if (filter.model._id) {
    if (query) {
      query += "&";
    }
    query += `modelId=${filter.model._id}`;
  }
  if (filter.category._id) {
    if (query) {
      query += "&";
    }
    query += `dotmedCategoryId=${filter.category._id}`;
  }
  if (filter.productionYear) {
    if (query) {
      query += "&";
    }
    query += `productionYear=${filter.productionYear}`;
  }
  if (searchText) {
    query += `&search=${searchText}`;
  }
  if (sortBy) {
    if (query) {
      query += "&";
    }
    query += `sortBy=${sortBy.name}&sort=${sortBy.sortType}`;
  }
  if (query) {
    return "&" + query;
  }
  return "";
};

export const getParts = (searchText) => {
  const partService = new PartService();
  let partStore = store.getState().danisikProductPart;
  let filterQuery = setQueryFromFilters(
    partStore.filterOptions,
    partStore.sortBy,
    searchText
  );
  let query = `?active=true&limit=${partStore.limit}&page=${partStore.page}${filterQuery}`;
  return async (dispatch) => {
    try {
      dispatch({ type: PartActionTypes.GET_PARTS });

      // Make your API request
      const response = await partService.getAllPartsWithFilters(query);
      dispatch({
        type: PartActionTypes.GET_PARTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: PartActionTypes.GET_PARTS_ERROR, payload: error });
    }
  };
};
export const getInactiveParts = (searchText) => {
  const partService = new PartService();
  let partStore = store.getState().danisikProductPart;
  let filterQuery = setQueryFromFilters(
    partStore.filterOptions,
    partStore.sortBy,
    searchText
  );
  let query = `?active=false&limit=${partStore.limit}&page=${partStore.page}${filterQuery}`;
  return async (dispatch) => {
    try {
      dispatch({ type: PartActionTypes.GET_PARTS });

      // Make your API request
      const response = await partService.getAllPartsWithFilters(query);
      dispatch({
        type: PartActionTypes.GET_PARTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: PartActionTypes.GET_PARTS_ERROR, payload: error });
    }
  };
};
export const setPage = (page) => ({
  type: PartActionTypes.SET_PAGE,
  payload: page,
});
export const setFilterOptionsPart = (filterOptions) => ({
  type: PartActionTypes.SET_FILTER_OPTIONS_PART,
  payload: filterOptions,
});
export const setSortBy = (sortBy) => ({
  type: PartActionTypes.SET_SORT_BY,
  payload: sortBy,
});
export const setSearchText = (searchText) => ({
  type: PartActionTypes.SET_SEARCH_TEXT,
  payload: searchText,
});
