import React from "react";

function Lock({
  fill = "var(--clr-neutral-200)",
  width = "2.4rem",
  height = "2.4rem",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 21"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 7H17C17.2652 7 17.5196 7.10536 17.7071 7.29289C17.8946 7.48043 18 7.73478 18 8V20C18 20.2652 17.8946 20.5196 17.7071 20.7071C17.5196 20.8946 17.2652 21 17 21H1C0.734784 21 0.48043 20.8946 0.292893 20.7071C0.105357 20.5196 0 20.2652 0 20V8C0 7.73478 0.105357 7.48043 0.292893 7.29289C0.48043 7.10536 0.734784 7 1 7H3V6C3 4.4087 3.63214 2.88258 4.75736 1.75736C5.88258 0.632141 7.4087 0 9 0C10.5913 0 12.1174 0.632141 13.2426 1.75736C14.3679 2.88258 15 4.4087 15 6V7ZM8 14.732V17H10V14.732C10.3813 14.5119 10.6793 14.1721 10.8478 13.7653C11.0162 13.3586 11.0458 12.9076 10.9319 12.4823C10.8179 12.057 10.5668 11.6813 10.2175 11.4132C9.86823 11.1452 9.44027 10.9999 9 10.9999C8.55973 10.9999 8.13177 11.1452 7.78248 11.4132C7.43319 11.6813 7.1821 12.057 7.06815 12.4823C6.9542 12.9076 6.98376 13.3586 7.15224 13.7653C7.32072 14.1721 7.61872 14.5119 8 14.732ZM13 7V6C13 4.93913 12.5786 3.92172 11.8284 3.17157C11.0783 2.42143 10.0609 2 9 2C7.93913 2 6.92172 2.42143 6.17157 3.17157C5.42143 3.92172 5 4.93913 5 6V7H13Z"
        fill="#DEDEDE"
      />
    </svg>
  );
}

export default Lock;
