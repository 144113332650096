import { StaticTextsActionTypes } from "./staticTextsActionTypes";

const INITIAL_STATE = {
  staticTexts: {},
};

const staticTextsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StaticTextsActionTypes.SET_STATIC_TEXTS:
      return {
        ...state,
        staticTexts: action.payload,
      };
    default:
      return state;
  }
};

export default staticTextsReducer;
