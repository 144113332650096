import FormButton from "components/Buttons/FormButton";
import CurrencyInput from "components/FormElements/CurrencyInput";
import { useFormik } from "formik";
import CustomAlert from "lib/customAlert.js";
import React, { useEffect, useState } from "react";
import purchaseValidationSchema from "./purchaseValidationSchema";
import "./style.scss";
import LoadingPage from "components/LoadingPage";
import PartBuyService from "services/product/part/buyService";
import { toast } from "react-toastify";
import Euro from "assets/icons/Euro";
import Dollar from "assets/icons/Dollar";
import Pound from "assets/icons/Pound";
import TurkishLira from "assets/icons/TurkishLira";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
import { useCallback } from "react";

const handleTotalPrice = (purchaseInfos) => {
  let price = 0;
  if (purchaseInfos.salePrice.value * 1) {
    price += purchaseInfos.salePrice.value * 1;
  }
  if (purchaseInfos.shippingCost.value * 1) {
    price += purchaseInfos.shippingCost.value * 1;
  }
  if (purchaseInfos.taxCost.value * 1) {
    price += purchaseInfos.taxCost.value * 1;
  }
  if (purchaseInfos.otherCost.value * 1) {
    price += purchaseInfos.otherCost.value * 1;
  }
  return {
    currency: purchaseInfos.salePrice.currency,
    value: price.toFixed(2),
  };
};

const currencies = {
  EUR: <Euro />,
  USD: <Dollar />,
  GBP: <Pound />,
  TRY: <TurkishLira />,
};

const partBuyService = new PartBuyService();
function Purchase({ partId }) {
  const user = useSelector((state) => state.danisikUser?.user);
  const hasPermission =
    user && (user.role === "admin" || user.role === "buyer");
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [loading, setLoading] = useState(false);
  const [purchaseInfos, setPurchaseInfos] = useState(null);
  const [totalPrice, setTotalPrice] = useState({ currency: "", value: 0 });

  const getParts = useCallback(() => {
    if (hasPermission) {
      setLoading(true);
      partBuyService
        .getById(partId)
        .then((res) => {
          setPurchaseInfos(res.data.data);
          setTotalPrice(handleTotalPrice(res.data.data));
        })
        .finally(() => setLoading(false));
    }
  }, [partId]);

  const formik = useFormik({
    initialValues: {
      salePrice: purchaseInfos?.salePrice || {
        currency: "EUR",
        value: "",
      },
      shippingCost: purchaseInfos?.shippingCost || {
        currency: "EUR",
        value: "",
      },
      taxCost: purchaseInfos?.taxCost || {
        currency: "EUR",
        value: "",
      },
      otherCost: purchaseInfos?.otherCost || {
        currency: "EUR",
        value: "",
      },
    },
    validationSchema: purchaseValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        setLoading(true);
        partBuyService
          .updateNormal(partId, {
            ...formik.values,
            salePrice: {
              currency: formik.values.salePrice.currency,
              value: formik.values.salePrice.value
                ? formik.values.salePrice.value
                : null,
            },
            shippingCost: {
              currency: formik.values.shippingCost.currency,
              value: formik.values.shippingCost.value
                ? formik.values.shippingCost.value
                : null,
            },
            taxCost: {
              currency: formik.values.taxCost.currency,
              value: formik.values.taxCost.value
                ? formik.values.taxCost.value
                : null,
            },
            otherCost: {
              currency: formik.values.otherCost.currency,
              value: formik.values.otherCost.value
                ? formik.values.otherCost.value
                : null,
            },
          })
          .then(() => {
            toast.success(languageTexts[danisikLanguage].succesfullyUpdated);
            getParts();
          })
          .finally(() => setLoading(false));
      }
    },
  });

  useEffect(() => {
    getParts();
  }, [getParts]);
  const handleChangeCurrency = (e) => {
    formik.setFieldValue(`${e.name}.currency`, e.value);
  };
  const handleChangeCurrencyInputValue = (e) => {
    formik.setFieldValue(`${e.name}.value`, e.value);
  };
  return loading ? (
    <LoadingPage />
  ) : hasPermission ? (
    <form
      onSubmit={formik.handleSubmit}
      className="flow-content modal-form-padding purchase-tab"
    >
      <CurrencyInput
        label={languageTexts[danisikLanguage].purchasePrice}
        value={formik.values.salePrice.value}
        selectedCurrency={formik.values.salePrice.currency}
        handleChangeCurrency={handleChangeCurrency}
        handleChange={handleChangeCurrencyInputValue}
        name="salePrice"
        showError={
          formik.touched.salePrice?.value &&
          Boolean(formik.errors.salePrice?.value)
        }
        error={
          formik.touched.salePrice?.value && formik.errors.salePrice?.value
        }
      />
      <CurrencyInput
        label={languageTexts[danisikLanguage].shippingExpenses}
        value={formik.values.shippingCost.value}
        selectedCurrency={formik.values.shippingCost.currency}
        handleChangeCurrency={handleChangeCurrency}
        handleChange={handleChangeCurrencyInputValue}
        name="shippingCost"
        showError={
          formik.touched.shippingCost?.value &&
          Boolean(formik.errors.shippingCost?.value)
        }
        error={
          formik.touched.shippingCost?.value &&
          formik.errors.shippingCost?.value
        }
      />
      <CurrencyInput
        label={languageTexts[danisikLanguage].taxExpenses}
        value={formik.values.taxCost.value}
        selectedCurrency={formik.values.taxCost.currency}
        handleChangeCurrency={handleChangeCurrency}
        handleChange={handleChangeCurrencyInputValue}
        name="taxCost"
        showError={
          formik.touched.taxCost?.value && Boolean(formik.errors.taxCost?.value)
        }
        error={formik.touched.taxCost?.value && formik.errors.taxCost?.value}
      />
      <CurrencyInput
        label={languageTexts[danisikLanguage].otherExpenses}
        value={formik.values.otherCost.value}
        selectedCurrency={formik.values.otherCost.currency}
        handleChangeCurrency={handleChangeCurrency}
        handleChange={handleChangeCurrencyInputValue}
        name="otherCost"
        showError={
          formik.touched.otherCost?.value &&
          Boolean(formik.errors.otherCost?.value)
        }
        error={
          formik.touched.otherCost?.value && formik.errors.otherCost?.value
        }
      />
      <div className="total-price-container">
        <span className="total-price-title">
          {languageTexts[danisikLanguage].totalPrice}
        </span>
        <span className="total-price-content">
          {totalPrice.value} {currencies[totalPrice.currency]}
        </span>
      </div>
      <FormButton title={languageTexts[danisikLanguage].save} />
    </form>
  ) : (
    <div>{languageTexts[danisikLanguage].noPermissionToView}</div>
  );
}

export default Purchase;
