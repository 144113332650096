import React, { useEffect, useState } from "react";
import "./style.scss";
import { useFormik } from "formik";
import modelValidationSchema from "./modelValidationSchema";
import CustomAlert from "lib/customAlert";
import FormButton from "components/Buttons/FormButton";
import Input from "components/FormElements/Input";
import ModalHeader from "components/Modals/ModalHeader";
import SelectOption from "components/FormElements/SelectOption";
import BrandService from "services/admin/brandService";
import ModelService from "services/admin/modelService";
import { toast } from "react-toastify";
import DeleteButton from "components/Buttons/DeleteButton";
import languageTexts from "constants/languageTexts";
import { useDispatch, useSelector } from "react-redux";
import { setModels } from "redux/product/model/modelActions";
import SelectOptionInstantSearch from "components/FormElements/SelectOptionInstantSearch";

const brandLimit = 15;
function ModelAdd({
  isModalOpen = false,
  setIsModalOpen,
  addModelModalInfo = {
    _id: 2,
    name: "",
    brandId: {
      _id: "",
      name: "",
    },
  },
}) {
  const dispatch = useDispatch();
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const user = useSelector((state) => state.danisikUser?.user);
  const [brandSearchText, setBrandSearchText] = useState("");
  const brandInformation = useSelector((state) => state.danisikBrands);
  const [brandCurrentPage, setBrandCurrentPage] = useState(1);
  let brandPageCount = brandInformation.brands.length / brandLimit;

  const [brands, setBrands] = useState([]);
  useEffect(() => {
    if (isModalOpen) {
      let brandService = new BrandService();
      brandService.getAll().then((res) => {
        setBrands(res.data.data);
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    setBrands(brandInformation.brands.slice(0, brandLimit));
    // setBrandCurrentPage(1);
  }, [brandInformation.brands]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: addModelModalInfo._id,
      name: addModelModalInfo.name,
      brand: addModelModalInfo.brandId,
    },
    validationSchema: modelValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        let modelService = new ModelService();
        if (values._id) {
          modelService
            .updateNormal(values._id, {
              brandId: values.brand._id,
              name: values.name,
            })
            .then(() => {
              toast.success(languageTexts[danisikLanguage].succesfullyUpdated);
              dispatch(setModels(user));
              setIsModalOpen(false);
            });
        } else {
          modelService
            .add({
              brandId: values.brand._id,
              name: values.name,
            })
            .then(() => {
              toast.success(languageTexts[danisikLanguage].operationSuccessful);
              dispatch(setModels(user));
              setIsModalOpen(false);
            });
        }
      }
    },
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    formik.setFieldValue(name, value);
  };

  const handleDelete = async () => {
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].OK
    );
    if (result) {
      let modelService = new ModelService();
      modelService.delete(formik.values._id).then(() => {
        toast.success(languageTexts[danisikLanguage].succesfullyDeleted);
        dispatch(setModels(user));
        setIsModalOpen(false);
      });
    }
  };

  const handleChangeFilterBrandInput = (val) => {
    setBrandSearchText(val);
  };

  const handleScrollBottomBrand = () => {
    if (brandPageCount > brandCurrentPage) {
      setBrandCurrentPage(brandCurrentPage + 1);
    }
  };
  useEffect(() => {
    if (brandCurrentPage > 1) {
      setBrands(
        brandInformation.brands.slice(0, brandCurrentPage * brandLimit)
      );
    }
  }, [brandCurrentPage]);
  useEffect(() => {
    if (
      isModalOpen &&
      user &&
      (user?.role === "admin" || user?.role === "store")
    ) {
      let newBrands = brandInformation.brands
        .filter((brand) =>
          brand.name.toLowerCase().includes(brandSearchText.toLowerCase())
        )
        .slice(0, brandLimit);
      setBrands(newBrands);
      setBrandCurrentPage(1);
    }
  }, [brandSearchText, isModalOpen]);
  return (
    <div
      className={`add-model-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal add-model-modal`}>
        <ModalHeader
          title={`${
            addModelModalInfo._id
              ? languageTexts[danisikLanguage].edit
              : languageTexts[danisikLanguage].add
          }`}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content">
          <form onSubmit={formik.handleSubmit} className="flow-content">
            <SelectOptionInstantSearch
              label={languageTexts[danisikLanguage].brand}
              name="brand"
              value={formik.values.brand}
              options={brands}
              handleChange={handleChange}
              error={formik.touched.brand?._id && formik.errors.brand?._id}
              showError={
                formik.touched.brand?._id && Boolean(formik.errors.brand?._id)
              }
              handleScrollBottom={handleScrollBottomBrand}
              filterInput={brandSearchText}
              handleChangeFilterInput={handleChangeFilterBrandInput}
            />
            <Input
              label={languageTexts[danisikLanguage].model}
              value={formik.values.name}
              handleChange={handleChange}
              name="name"
              showError={formik.touched.name && Boolean(formik.errors.name)}
              error={formik.touched.name && formik.errors.name}
            />
            <FormButton title={languageTexts[danisikLanguage].save} />
            {formik.values._id ? (
              <DeleteButton onClick={() => handleDelete()} />
            ) : null}
          </form>
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
    </div>
  );
}

export default ModelAdd;
