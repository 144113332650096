import React from "react";
import SearchIcon from "assets/icons/Search";
import "./style.scss";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
function SearchInput({
  placeholder = "Type for search...",
  type = "text",
  onKeyUp,
  value,
  onChange,
  ...props
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  return (
    <div className="search-input">
      <div className="search-icon-container">
        <SearchIcon />
      </div>
      <input
        placeholder={
          placeholder || languageTexts[danisikLanguage].typeForSearch
        }
        value={value}
        onKeyUp={onKeyUp}
        onChange={onChange}
        type={type}
        {...props}
      />
    </div>
  );
}

export default SearchInput;
