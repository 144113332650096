import ArrowLeftCircle from "assets/icons/ArrowLeftCircle";
import ArrowRightCircle from "assets/icons/ArrowRightCircle";
import FilterButton from "components/Buttons/FilterButton";
import FilterModal from "components/Modals/Filter";
import React, { useEffect, useState } from "react";
import "./style.scss";
import NextSkipRight from "assets/icons/NextSkipRight";
import NextSkipLeft from "assets/icons/NextSkipLeft";

function TableFilter({
  setFilterModalOpen,
  isModalOpen,
  setFilterOptions,
  filterOptions,
  previous = () => {},
  next = () => {},
  nextLast = () => {},
  previousLast = () => {},
  leftArrowDisabled = false,
  rightArrowDisabled = true,
  limit = 50,
  page = 1,
  totalCount,
}) {
  const [isFiltered, setIsFiltered] = useState(false);
  useEffect(() => {
    setIsFiltered(
      filterOptions.brand._id ||
        filterOptions.model._id ||
        filterOptions.category._id ||
        filterOptions.productionYear
    );
  }, [filterOptions]);

  const handleClickFilter = () => {
    if (isFiltered) {
      setFilterOptions({
        brand: {
          name: "",
          _id: "",
        },
        model: {
          name: "",
          _id: "",
        },
        category: {
          name: "",
          _id: "",
        },
        production: "",
      });
    } else {
      setFilterModalOpen(true);
    }
  };

  return (
    <div className="table-filter">
      <div className="filter-options">
        {filterOptions.brand._id ? (
          <span className="filter-option">{filterOptions.brand.name}</span>
        ) : null}
        {filterOptions.model._id ? (
          <span className="filter-option">{filterOptions.model.name}</span>
        ) : null}
        {filterOptions.category._id ? (
          <span className="filter-option">{filterOptions.category.name}</span>
        ) : null}
        {filterOptions.productionYear ? (
          <span className="filter-option">{filterOptions.productionYear}</span>
        ) : null}
      </div>
      <FilterButton onClick={handleClickFilter} filtered={isFiltered} />
      <div className="filter-btn-text">
        <NextSkipLeft
          cursor={leftArrowDisabled ? "" : "pointer"}
          fill={
            leftArrowDisabled
              ? "var(--clr-neutral-800)"
              : "var(--clr-neutral-200)"
          }
          onClick={previousLast}
        />
        <ArrowLeftCircle
          cursor={leftArrowDisabled ? "" : "pointer"}
          fill={
            leftArrowDisabled
              ? "var(--clr-neutral-800)"
              : "var(--clr-neutral-200)"
          }
          onClick={previous}
        />
        <span>
          {page * 1 * limit * 1 - limit * 1 + 1}-
          {totalCount < limit
            ? page * limit - limit + totalCount
            : page * limit}
        </span>
        <ArrowRightCircle
          fill={
            rightArrowDisabled
              ? "var(--clr-neutral-800)"
              : "var(--clr-neutral-200)"
          }
          cursor={rightArrowDisabled ? "" : "pointer"}
          onClick={next}
        />
        <NextSkipRight
          onClick={nextLast}
          fill={
            rightArrowDisabled
              ? "var(--clr-neutral-800)"
              : "var(--clr-neutral-200)"
          }
          cursor={rightArrowDisabled ? "" : "pointer"}
        />
      </div>
      <FilterModal
        setFilterOptions={setFilterOptions}
        setIsModalOpen={setFilterModalOpen}
        isModalOpen={isModalOpen}
        filterOptions={filterOptions}
      />
    </div>
  );
}

export default TableFilter;
