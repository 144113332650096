import React from "react";
import "./style.scss";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";

function CustomAlert() {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  return (
    <div id="custom-alert">
      <div className="custom-alert-overlay"></div>
      <div id="dialogbox">
        <div>
          <div id="dialogboxhead"></div>
          <div id="dialogboxbody"></div>
          <div id="dialogboxfoot">
            <button id="dialogbox-cancel-button">
              {languageTexts[danisikLanguage].cancel}
            </button>
            <button id="dialogbox-ok-button">
              {languageTexts[danisikLanguage].OK}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomAlert;
