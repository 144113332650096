import CategoryService from "services/admin/category";
import { CategoryActionTypes } from "./categoryActionTypes";

export const setCategories = (user) => {
  const categoryService = new CategoryService();
  if (user && user.role === "admin") {
    return async (dispatch) => {
      try {
        dispatch({ type: CategoryActionTypes.SET_CATEGORIES });

        // Make your API request
        const response = await categoryService.getDotmedCategory(
          "?limit=999999999999&sort=name"
        );
        dispatch({
          type: CategoryActionTypes.SET_CATEGORIES_SUCCESSFUL,
          payload: response.data.data,
        });
      } catch (error) {
        dispatch({
          type: CategoryActionTypes.SET_CATEGORIES_ERROR,
          payload: error,
        });
      }
    };
  } else {
    return async (dispatch) => {
      dispatch({
        type: CategoryActionTypes.SET_CATEGORIES_SUCCESSFUL,
        payload: [],
      });
    };
  }
};
