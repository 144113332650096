import React from "react";

function TurkishLira({ width = "2rem", height = "2rem", fill = "none" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="10" fill="var(--clr-neutral-200)" />
      <path
        d="M6.82 15V5.2H9.06V13.166H9.578C10.6513 13.166 11.4447 12.8907 11.958 12.34C12.4807 11.78 12.742 11.0053 12.742 10.016H15.01C15.0193 11.0427 14.8 11.9293 14.352 12.676C13.904 13.4133 13.2367 13.9873 12.35 14.398C11.4727 14.7993 10.3807 15 9.074 15H6.82ZM5.42 12.354V11.136L12.462 7.426V8.644L5.42 12.354ZM5.42 10.352V9.148L12.462 5.438V6.642L5.42 10.352Z"
        fill="var(--background-color-dark)"
      />
    </svg>
  );
}

export default TurkishLira;
