import React from "react";

function Download({
  width = "2rem",
  height = "1.8rem",
  fill = "var(--clr-neutral-100)",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16ZM12 6H17L10 13L3 6H8V0H12V6Z" />
    </svg>
  );
}

export default Download;
