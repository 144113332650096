export const statusInfoOptions = [
  { name: "Damaged", _id: "damaged" },
  { name: "Working", _id: "working" },
  { name: "Hard Damaged", _id: "hardDamaged" },
  { name: "Unkown", _id: "unknown" },
  { name: "Waiting for Repair", _id: "waitingForRepair" },
];

export const statusInfos = {
  damaged: "Damaged",
  working: "Working",
  hardDamaged: "Hard Damaged",
  unknown: "Unkown",
  waitingForRepair: "Waiting for Repair",
};
