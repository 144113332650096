import React, { useEffect, useState } from "react";
import "./style.scss";
import RecentOperationService from "services/admin/recentOperationService";
import { debounce } from "utils/debounce";
import LoadingPage from "components/LoadingPage";
import { truncate } from "utils/stringFunctions";
import { dateFormatToDDMMYYYDayHHMMSS } from "utils/dateFormat";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";

const recentOperationService = new RecentOperationService();
function RecentOperation() {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [isLoading, setIsLoading] = useState(false);
  const [recentOperations, setRecentOperations] = useState([]);
  const [recentOperationCurrentPage, setRecentOperationCurrentPage] =
    useState(1);
  const [recentOperationPageCount, setRecentOperationPageCount] = useState(1);

  useEffect(() => {
    setIsLoading(true);
    recentOperationService
      .getAll(`?limit=15&page=${recentOperationCurrentPage}`)
      .then((res) => {
        setRecentOperations(res.data.data);
        setRecentOperationPageCount(
          res.data.totalPage ? res.data.totalPage : 1
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleScrollDropdownDebounce = debounce((e) => {
    handleScrollDropdown(e);
  }, 350);

  const handleScrollBottom = () => {
    if (recentOperationPageCount > recentOperationCurrentPage) {
      setRecentOperationCurrentPage(recentOperationCurrentPage + 1);
    }
  };
  const handleScrollDropdown = (e) => {
    const isScrollAtTheBottom =
      e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 1;
    if (isScrollAtTheBottom) {
      handleScrollBottom();
    }
  };

  useEffect(() => {
    if (recentOperationCurrentPage > 1) {
      recentOperationService
        .getAll(`?limit=15&page=${recentOperationCurrentPage}`)
        .then((res) => {
          let newRecentOperationd = [...recentOperations, ...res.data.data];
          setRecentOperations(newRecentOperationd);
        });
    }
  }, [recentOperationCurrentPage]);

  useEffect(() => {
    recentOperationService.getAll();
  }, []);

  return (
    <div className="recent-operations-container">
      <h3 className="recent-operations-title">
        {languageTexts[danisikLanguage].recentOperations}
      </h3>

      <div className="recent-operations-header">
        <span className="description">
          {languageTexts[danisikLanguage].description}
        </span>
        <span className="time">{languageTexts[danisikLanguage].time}</span>
      </div>
      <div
        className="recent-operation-item-container"
        onScroll={handleScrollDropdownDebounce}
      >
        {recentOperations.map((recentOperation) => (
          <div key={recentOperation._id} className="recent-operation-item">
            <div className="description">
              <span className="recent-operation-item-title">
                {recentOperation.title}{" "}
              </span>
              <span
                className="recent-operation-item-description"
                title={recentOperation.description}
              >
                {truncate(recentOperation.description, 80)}
              </span>
            </div>
            <div className="time">
              {dateFormatToDDMMYYYDayHHMMSS(recentOperation.date)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RecentOperation;
