export const debounce = function (fn, interval) {
  let _fn = fn,
    timer;

  return function () {
    let args = arguments,
      _self = this;

    clearTimeout(timer);
    timer = setTimeout(function () {
      _fn.apply(_self, args);
    }, interval || 350);
  };
};

//usage :

// const handleSearchPartNumber = useCallback(
//     debounce((value) => {
//       "debounce works";
//     }, 2500),
//     []
//   );
