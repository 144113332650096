import * as Yup from "yup";
export default Yup.object().shape({
  material: Yup.string("").required("Material is required"),
  cost: Yup.object().shape({
    value: Yup.number("")
      .min(1, "Value must be greater than 0")
      .required("Value is required"),
    currency: Yup.string()
      .oneOf(["EUR", "USD", "GBP", "TRY"])
      .required("Please select valid currency"),
  }),
});
