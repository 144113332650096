import api from "lib/api";

export default class FactoryService {
  api = api;
  serviceName;
  constructor(serviceName) {
    this.serviceName = serviceName;
  }
  getAll(query = "") {
    return api.get(`${this.serviceName}${query}`);
  }
  getById(id) {
    return api.get(`${this.serviceName}/${id}`);
  }

  addFormData(data) {
    return api.post(this.serviceName, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  add(data) {
    return api.post(this.serviceName, data);
  }
  update(id, data) {
    return api.put(`${this.serviceName}/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  updateNormal(id, data) {
    return api.put(`${this.serviceName}/${id}`, data);
  }
  delete(id) {
    return api.delete(`${this.serviceName}/${id}`);
  }
}
