import CameraLine from "assets/icons/CameraLine";
import React, { useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import "./style.scss";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";
const baseStyle = {
  height: "100%",
  position: "relative",
  border: "1px dashed rgba(222, 222, 222, 1)",
  borderRadius: "1.2rem",
};
const focusedStyle = {
  border: "1px solid #0080ff",
};
const acceptStyle = {
  border: "1px solid #0080ff",
};

const rejectStyle = {
  border: "1px solid #ff5858",
};
function DropZone({ files, addFiles, width, isEditing }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      disabled: isEditing,
      accept: {
        "image/*": [],
      },
      onDrop: (acceptedFiles) => {
        let lastIndex = files.length;
        addFiles(
          acceptedFiles.map((file, index) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              index: lastIndex + index,
              _id: Date.now() + index,
              order: lastIndex + index + 1,
              newAdded: true,
            })
          )
        );
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  return (
    <div className="dropzone-container" style={{ width: width }}>
      <div
        {...getRootProps({
          style,
          className: "dropzone",
        })}
      >
        <input {...getInputProps()} />

        <div className="dropzone-content">
          <CameraLine />
          <span>{languageTexts[danisikLanguage].addPhoto}</span>
        </div>
      </div>
    </div>
  );
}

export default DropZone;
