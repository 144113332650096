import { UserActionTypes } from "./userActionTypes";

const INITIAL_STATE = {
  user: null,
};

const setUser = (user, token) => {
  if (!user) {
    localStorage.setItem("danisik-token", "");
    return null;
  }
  localStorage.setItem("danisik-token", token);
  return user;
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SIGN_IN:
      return {
        ...state,
        user: setUser(action.payload.user, action.payload.token),
      };
    default:
      return state;
  }
};

export default userReducer;
