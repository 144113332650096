import FactoryService from "services/factoryService";

export default class ModelService extends FactoryService {
  constructor() {
    super("model");
  }
  getAllByBrandId(brandId, query) {
    return this.api.get(`${this.serviceName}/withBrand/${brandId}${query}`);
  }
}
