import React from "react";

function NextSkipLeft({
  fill = "var(--clr-neutral-200)",
  width = "2.4rem",
  height = "2.4rem",
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={fill}
      {...props}
    >
      <path d="M7 18V6H9V18H7ZM11 12 17 6V18L11 12Z"></path>
    </svg>
  );
}

export default NextSkipLeft;
