import React from "react";

function ArrowRight2({
  fill = "var(--clr-neutral-200)",
  width = "0.4rem",
  height = "0.8rem",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 4 8"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 4L0 8V0L4 4Z" />
    </svg>
  );
}

export default ArrowRight2;
