import { DeviceActionTypes } from "./deviceActionTypes";

const INITIAL_STATE = {
  devices: [],
  deviceError: null,
  deviceInformationLoading: false,
  searchText: "",
  page: 1,
  totalPage: 1,
  totalCount: null,
  limit: 50,
  filterOptions: {
    brand: {
      name: "",
      _id: "",
    },
    model: {
      name: "",
      _id: "",
    },
    category: {
      name: "",
      _id: "",
    },
    productionYear: "",
  },
  sortBy: "",
};

const deviceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DeviceActionTypes.GET_DEVICES:
      return {
        ...state,
        deviceError: null,
        deviceInformationLoading: true,
      };
    case DeviceActionTypes.GET_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.payload.data,
        totalPage: action.payload.totalPage,
        totalCount: action.payload.totalCount,
        deviceError: null,
        deviceInformationLoading: false,
      };
    case DeviceActionTypes.GET_DEVICES_ERROR:
      return {
        ...state,
        devices: [],
        totalPage: 1,
        totalCount: null,
        deviceError: action.payload,
        deviceInformationLoading: false,
      };
    case DeviceActionTypes.SET_FILTER_OPTIONS_DEVICE:
      return {
        ...state,
        page: 1,
        filterOptions: { ...action.payload },
      };
    case DeviceActionTypes.SET_PAGE_DEVICE:
      return {
        ...state,
        page: action.payload,
      };
    case DeviceActionTypes.SET_SEARCH_TEXT_DEVICE:
      return {
        ...state,
        searchText: action.payload,
      };
    case DeviceActionTypes.SET_SORT_BY_DEVICE:
      return {
        ...state,
        sortBy: action.payload,
      };
    default:
      return state;
  }
};

export default deviceReducer;
