import React from "react";
import "./style.scss";
import bernerLogo from "assets/images/berner_logo.svg";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";

function DevelopersInfo() {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  return (
    <div className="developers-info">
      <img src={bernerLogo} alt="Developers'" />
      <h3 className="app-name">Medical Stock Management Tool</h3>
      <span className="app-version">v1.0.0</span>
      <a href="mailto:bernersoftware@gmail.com" className="contact-with-devs">
        {languageTexts[danisikLanguage].contactWithDevelopers}
      </a>
    </div>
  );
}

export default DevelopersInfo;
