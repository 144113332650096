import React from "react";
import "./style.scss";
import DeleteBin from "assets/icons/DeleteBin";

function DeleteButton({ title = "Delete", onClick, ...props }) {
  return (
    <button
      className="delete-button"
      type="button"
      onClick={onClick}
      {...props}
    >
      <DeleteBin width="2rem" height="2rem" />
      {title}
    </button>
  );
}

export default DeleteButton;
