import FormButton from "components/Buttons/FormButton";
import Input from "components/FormElements/Input";
import { useFormik } from "formik";
import CustomAlert from "lib/customAlert.js";
import React from "react";
import ModalHeader from "../ModalHeader";
import "./style.scss";
import PackagingOptions from "components/PackagingOptions";
import CurrencyInput from "components/FormElements/CurrencyInput";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
import packagingValidationSchema from "./packagingValidationSchema";

function AddPackaging({
  isModalOpen = false,
  setIsModalOpen,
  packagingModalInfo = {
    _id: 0,
    packagingType: {
      _id: "",
      name: "",
    },
    physicalInfos: {
      width: 0,
      height: 0,
      size: 0,
      weight: 0,
    },
    packagingCost: {
      value: 0,
      currency: "EUR",
    },
    deviceId: 0,
  },
  setPackagingModalInfo,
  packagingInfos,
  setPackagingInfos,
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: packagingModalInfo._id || "",
      packagingType: {
        _id: packagingModalInfo.packagingType?._id,
        name: packagingModalInfo.packagingType?.name,
      },
      physicalInfos: {
        width: packagingModalInfo.physicalInfos?.width || 0,
        height: packagingModalInfo.physicalInfos?.height || 0,
        size: packagingModalInfo.physicalInfos?.size || 0,
        weight: packagingModalInfo.physicalInfos?.weight || 0,
      },
      packagingCost: {
        value: packagingModalInfo.packagingCost?.value || 0,
        currency: packagingModalInfo.packagingCost?.currency || "EUR",
      },
      deviceId: packagingModalInfo.deviceId || 0,
    },
    validationSchema: packagingValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        let formattedValues = {
          _id: values._id,
          packagingCost: values.packagingCost,
          packagingTypeId: values.packagingType,
          physicalInfos: values.physicalInfos,
          deviceId: values.deviceId,
        };
        if (packagingModalInfo.index + 1) {
          packagingInfos[packagingModalInfo.index] = formattedValues;
          setPackagingInfos(packagingInfos);
        } else {
          packagingInfos.push(formattedValues);
          setPackagingInfos(packagingInfos);
        }
        setIsModalOpen(false);
        setPackagingModalInfo({
          _id: 0,
          serialNumber: "",
          status: {
            name: "",
            _id: "",
          },
          definition: "",
          images: [],
          brand: {
            name: "",
            _id: "",
          },
          model: {
            name: "",
            _id: "",
          },
          deviceId: 0,
        });
      }
    },
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    formik.setFieldValue(name, value);
  };

  const handleChangeCurrency = (e) => {
    formik.setFieldValue(`${e.name}.currency`, e.value);
  };
  const handleChangeCurrencyInputValue = (e) => {
    formik.setFieldValue(`${e.name}.value`, e.value);
  };
  return (
    <div
      className={`add-accessuar-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal add-accessuar-modal`}>
        <ModalHeader
          title={`${
            packagingModalInfo.index + 1
              ? languageTexts[danisikLanguage].edit
              : languageTexts[danisikLanguage].add
          }`}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content">
          <form
            onSubmit={formik.handleSubmit}
            className="flow-content tab-device-form"
          >
            <PackagingOptions
              handleChange={handleChange}
              value={formik.values.packagingType}
              showError={
                formik.touched.packagingType?._id &&
                Boolean(formik.errors.packagingType?._id)
              }
              error={
                formik.touched.packagingType?._id &&
                formik.errors.packagingType?._id
              }
            />
            <CurrencyInput
              label={"*Packaging Cost"}
              value={formik.values.packagingCost.value}
              selectedCurrency={formik.values.packagingCost.currency}
              handleChangeCurrency={handleChangeCurrency}
              handleChange={handleChangeCurrencyInputValue}
              name="packagingCost"
              showError={
                formik.touched.packagingCost?.value &&
                Boolean(formik.errors.packagingCost?.value)
              }
              error={
                formik.touched.packagingCost?.value &&
                formik.errors.packagingCost?.value
              }
            />
            <Input
              label={languageTexts[danisikLanguage].width}
              value={formik.values.physicalInfos?.width}
              handleChange={handleChange}
              name="physicalInfos.width"
              showError={
                formik.touched.physicalInfos?.width &&
                Boolean(formik.errors.physicalInfos?.width)
              }
              error={
                formik.touched.physicalInfos?.width &&
                formik.errors.physicalInfos?.width
              }
            />
            <Input
              label={languageTexts[danisikLanguage].height}
              value={formik.values.physicalInfos?.height}
              handleChange={handleChange}
              name="physicalInfos.height"
              showError={
                formik.touched.physicalInfos?.height &&
                Boolean(formik.errors.physicalInfos?.height)
              }
              error={
                formik.touched.physicalInfos?.height &&
                formik.errors.physicalInfos?.height
              }
            />
            <Input
              label={languageTexts[danisikLanguage].size}
              value={formik.values.physicalInfos?.size}
              handleChange={handleChange}
              name="physicalInfos.size"
              showError={
                formik.touched.physicalInfos?.size &&
                Boolean(formik.errors.physicalInfos?.size)
              }
              error={
                formik.touched.physicalInfos?.size &&
                formik.errors.physicalInfos?.size
              }
            />
            <Input
              label={languageTexts[danisikLanguage].weight}
              value={formik.values.physicalInfos?.weight}
              handleChange={handleChange}
              name="physicalInfos.weight"
              showError={
                formik.touched.physicalInfos?.weight &&
                Boolean(formik.errors.physicalInfos?.weight)
              }
              error={
                formik.touched.physicalInfos?.weight &&
                formik.errors.physicalInfos?.weight
              }
            />

            <FormButton title={languageTexts[danisikLanguage].save} />
          </form>
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
    </div>
  );
}

export default AddPackaging;
