import Euro from "assets/icons/Euro";
import HomeGear from "assets/icons/HomeGear";
import MoreVertical from "assets/icons/MoreVertical";
import React, { useCallback, useRef, useState } from "react";
import "./style.scss";
import * as partTabs from "constants/partTabs";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import PartsIcon from "assets/icons/Parts";
import DeviceIcon from "assets/icons/Device";
import { useEffect } from "react";
import PartService from "services/product/part/part";
import languageTexts from "constants/languageTexts";
import { useDispatch, useSelector } from "react-redux";
import { truncate } from "utils/stringFunctions";
import { toast } from "react-toastify";
import LoadingPage from "components/LoadingPage";
import CustomAlert from "lib/customAlert";
import PartDymo from "components/Dymo/PartDymo";
import { Link } from "react-router-dom";
import { getInactiveParts, getParts } from "redux/product/part/partActions";

const partService = new PartService();
function Tab({ setActiveTab, activeTab, partId, partActiveStatus }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const tabContainerRef = useRef(null);
  const [moreOptionsVisible, setMoreOptionsVisible] = useState(false);

  const handleCloseOptions = useCallback(() => {
    setMoreOptionsVisible(false);
  }, []);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, handleCloseOptions);

  const [completionStatusInfo, setCompletionStatusInfo] = useState(null);
  useEffect(() => {
    partService.completionStatus(partId).then((res) => {
      setCompletionStatusInfo(res.data?.data[0]);
    });
  }, [partId]);

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const handleDeletePart = async () => {
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].OK
    );
    if (result) {
      setLoading(true);
      partService
        .delete(partId)
        .then(() => {
          toast.success(languageTexts[danisikLanguage].succesfullyDeleted);
          dispatch(getParts());
        })
        .finally(() => setLoading(false));
    }
  };

  const handleChangeActiveStatus = () => {
    setLoading(true);
    partService
      .changeActiveStatus(partId)
      .then(() => {
        toast.success(languageTexts[danisikLanguage].operationSuccessful);
        if (partActiveStatus) {
          dispatch(getParts());
        } else {
          dispatch(getInactiveParts());
        }
      })
      .finally(() => setLoading(false));
  };

  return loading ? (
    <LoadingPage />
  ) : (
    <div className="part-modal-tab">
      <div className="tab-items" ref={tabContainerRef}>
        <div
          className={`tab-item ${
            !completionStatusInfo?.buy ? "not-completed" : ""
          } ${activeTab === partTabs.PURCHASE ? "active" : ""}`}
          onClick={() => setActiveTab(partTabs.PURCHASE)}
        >
          <div className="svg-icon-container">
            <Euro />
          </div>

          <span title={languageTexts[danisikLanguage].purchasePrice}>
            {truncate(languageTexts[danisikLanguage].purchasePrice, 10)}
          </span>
        </div>
        <div
          className={`tab-item ${
            !completionStatusInfo?.part ? "not-completed" : ""
          } ${activeTab === partTabs.PART ? "active" : ""}`}
          onClick={() => handleActiveTab(partTabs.PART)}
        >
          <div className="svg-icon-container">
            <PartsIcon />
          </div>

          <span title="Part">{truncate("Part", 10)} </span>
        </div>
        <div
          className={`tab-item ${
            !completionStatusInfo?.storage ? "not-completed" : ""
          } ${activeTab === partTabs.STORAGE ? "active" : ""}`}
          onClick={() => handleActiveTab(partTabs.STORAGE)}
        >
          <div className="svg-icon-container">
            <HomeGear />
          </div>

          <span title={languageTexts[danisikLanguage].storeInfo}>
            {truncate(languageTexts[danisikLanguage].storeInfo, 10)}
          </span>
        </div>
        <div
          className={`tab-item ${
            !completionStatusInfo?.device ? "not-completed" : ""
          } ${activeTab === partTabs.DEVICE ? "active" : ""}`}
          onClick={() => handleActiveTab(partTabs.DEVICE)}
        >
          <div className="svg-icon-container">
            <DeviceIcon />
          </div>

          <span title={languageTexts[danisikLanguage].deviceInfos}>
            {truncate(languageTexts[danisikLanguage].deviceInfos, 10)}
          </span>
        </div>
        <div
          className={`tab-item not-completed ${
            activeTab === partTabs.ECOMMERCE ? "active" : ""
          }`}
          onClick={() => handleActiveTab(partTabs.ECOMMERCE)}
        >
          <div className="svg-icon-container">
            <Euro />
          </div>

          <span title={languageTexts[danisikLanguage].eCommerce}>
            {truncate(languageTexts[danisikLanguage].eCommerce, 10)}{" "}
          </span>
        </div>
      </div>
      <div className="more-options">
        <div className="more-icon-contanier" ref={wrapperRef}>
          <MoreVertical
            onClick={() => {
              setMoreOptionsVisible(true);
            }}
          />
          <div className={`options ${moreOptionsVisible ? "visible" : ""}`}>
            <PartDymo partId={partId} />
            <span onClick={handleChangeActiveStatus}>
              {partActiveStatus !== null
                ? partActiveStatus
                  ? languageTexts[danisikLanguage].makeInactive
                  : languageTexts[danisikLanguage].makeActive
                : languageTexts[danisikLanguage].toggleActive}
            </span>
            <Link
              to={`/product-detail/part/${partId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {languageTexts[danisikLanguage].partAd}
            </Link>
            <span className="red-text" onClick={handleDeletePart}>
              {languageTexts[danisikLanguage].delete}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tab;
