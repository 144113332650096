export default {
  tr: {
    imagesAddedSuccessfully: "Resimler başarıyla eklendi",
    loading: "Yükleniyor",
    imagesDeletedSuccessfully: "Resimler başarıyla silindi",
    succesfullyDeleted: "Başarıyla silindi",
    update: "Güncelle",
    category: "Kategori",
    cancel: "İptal Et",
    brandList: "Marka Listesi",
    role: "Rol",
    password: "Şifre",
    passwordConfirm: "Tekrar Şifre",
    delete: "Sil",
    supplyingInstitution: "Tedarik Edilen Kurum",
    shippingExpenses: "Nakliye Giderleri",
    areYouSureToDelete: "Silmek istediğinize emin misiniz?",
    stockNumber: "Stok Numarası",
    technicalDetails: "Teknik Detaylar",
    softwareInfos: "Yazılım bilgileri",
    description: "Açıklama",
    damageImages: "Hasar fotoğrafları",
    printLabel: "Etiket Çıkart",
    packaging: "Paketleme",
    productDamageList: "Ürün Hasar Listesi",
    languages: "Diller",
    deleteProduct: "Cihaz Sil",
    deviceStatus: "Cihaz Durumu",
    devicePdfDownload: "Cihaz Bilgileri PDF indir",
    adminOperations: "Admin işlemleri",
    packagingType: "Paketleme Türü",
    userList: "Kullanıcı Listesi",
    areYouSure: "Emin misiniz?",
    succesfullyUpdated: "Başarıyla güncellendi",
    select: "Seçiniz",
    purchasePrice: "Satın Alma Bedeli",
    salePrice: "Satış Fiyatı",
    taxExpenses: "Vergi Giderleri",
    width: "En (cm)",
    accessuarySuccessfullyDeleted: "Aksesuar başarıyla silindi",
    outStockDate: "Stoktan çıkış tarihi",
    inStockDate: "Stoğa giriş tarihi",
    weight: "Ağırlık (kg)",
    storeInfo: "Depolama bilgileri",
    productImage: "Ürün Fotoğrafı",
    isRepaired: "Tamir edildi mi?",
    buyInfos: "Satın Alma Bilgileri",
    operationDescription: "Yapılan İşlem Açıklaması",
    productCost: "Ürün maaliyeti",
    newImagesAfterRepair: "Ürünün tamirden sonraki yeni fotoğrafları",
    productUsed: "Kullanılan ürün",
    firstSelectLanguage: "Önce Dil Seçiniz",
    operations: "İşlemler",
    name: "İsim",
    language: "Dil",
    deviceRepairPdfDownload: "Cihaz Tamir Bilgileri PDF indir",
    user: "Kullanıcı",
    waitingForRepair: "Onarım Bekliyor",
    damaged: "Hasarlı",
    accessuarDefinition: "Aksesuar Tanımı",
    working: "Çalışıyor",
    unexpectedErrorOccured: "Beklenmedik bir hata oluştu",
    buy: "Satın Alma",
    productList: "Ürün listesi",
    brand: "Marka",
    logout: "Çıkış yap",
    email: "Email",
    succesfullyAdded: "Başarıyla eklendi",
    modelList: "Model Listesi",
    accessuaryInformations: "Aksesuar Bilgileri",
    otherExpenses: "Diğer Giderler",
    deviceInfos: "Cihaz Bilgileri",
    status: "Durum bilgisi",
    otherInfos: "Diğer bilgiler",
    productSerialNumber: "Ürün Seri Numarası",
    operationsMade: "Yapılan işlemler",
    productsUsedForRepair: "Onarımda Kullanılan Malzemeler",
    staticTexts: "Statik Textler",
    emptyFieldCannotBeSaved: "Boş alan kaydedilemez",
    totalExpenses: "Toplam Giderler",
    addProduct: "Ürün ekle",
    releaseImages: "Fotoğrafları buraya sürükleyin",
    dropImages: "Fotoğrafları bırakın",
    noOption: "Seçenek Yok",
    model: "Model",
    login: "Giriş Yap",
    operationSuccessful: "İşlem başarılı",
    search: "Arama yap",
    add: "Ekle",
    packagingTypeList: "Paketleme Türü Listesi",
    categoryList: "Kategori Listesi",
    productionYear: "Üretim Yılı",
    serialNumber: "Seri numarası",
    languageList: "Dil Listesi",
    userName: "Kullanıcı ismi",
    save: "Kaydet",
    deviceImages: "Ürün fotoğrafları",
    hardwareInfos: "Donanım bilgileri",
    height: "Boy (cm)",
    physicalInfos: "Fiziksel Bilgiler",
    size: "Yükseklik (cm)",
    damageStatus: "Hasar Durum Bilgileri",
    deviceStore: "Cihaz Depoplama",
    productLocation: "Cihazın Lokasyonu",
    packagingCost: "Paketleme maaliyeti",
    hourSpent: "Harcanan Zaman (saat)",
    repairInfos: "Tamir Bilgileri Gir",
    repair: "Tamir ve Onarım",
    languageCode: "Dil Kodu",
    admin: "Admin",
    seller: "Satıcı",
    store: "Depo Görevlisi",
    satinalmaci: "Satın Almaci",
    hardDamaged: "Ağır Hasarlı",
    addToStock: "Stoğa ekle",
    deleteFromStock: "Stoktan düş",
    inactiveItems: "Aktif olmayan ürünler",
    sortSuccessful:
      "Resim sıralaması başarılı. Aktif olması için sayfayı yenileyin",
    productListPdf: "Ürün listesi pdf",
    customizePdf: "Pdf Özelleştir",
    eCommerceSites: "E Ticaret Siteleri",
    listingNumberRequired: "Listeleme numarası alanı zorunludur",
    listingNumber: "Listeleme numarası",
    siteLink: "İlan Linki",
    eCommerceCorporate: "E Ticaret Şirketi",
    addDateToSite: "Siteye Eklenme Tarihi",
    price: "Fiyat",
    downloadImages: "Resimleri İndir",
    edit: "Düzenle",
    changePassword: "Şifre Değiştir",
    roleChangeSuccessful: "Rol başarıyla değiştirildi",
    aboutToDeleteUserAreYouSure:
      "Bu kullanıcıyı silmek üzeresiniz. Devam etmek istiyor musunuz?",
    saleDescription: "Satış Açıklaması",
    saleDescriptionShopify: "Shopify Satış Açıklaması",
    saleDescriptionDotmed: "Dotmed Satış Açıklaması",
    OK: "Tamam",
    topBrands: "Popüler Markalar",
    topModels: "Popüler Modeller",
    topCategories: "Popüler Kategoriler",
    totalSalePrice: "Toplam Satış Fiyatı",
    totalShippingCost: "Toplam Nakliye Maliyeti",
    totalTaxCost: "Toplam Vergi Maliyeti",
    totalOtherCost: "Toplam Diğer Giderler",
    recentOperations: "Son İşlemler",
    year: "Sene",
    firstAddImageThenOrderError: "Önce resim ekleyin sonra sıralayın",
    addPhoto: "Fotoğraf Ekle",
    youAreAboutToLogout: "Çıkış yapmak üzeresin",
    areYouSureToLogout: "Çıkış yapmak istediğinize emin misiniz?",
    welcome: "Hoşgeldiniz",
    dashboard: "Ana Sayfa",
    devices: "Cihazlar",
    parts: "Parçalar",
    getStock: "Stok",
    settings: "Ayarlar",
    imagesAreOrderedSuccessfully: "Resimler başarıyla sıralandı",
    transferAccessory: "Aksesuarı transfer et",
    convertToDevice: "Cihaza çevir",
    convertToPart: "Parçaya çevir",
    title: "Başlık",
    areYouSureToAddNewDevice: "Yeni cihaz eklemek istediğinize emin misiniz?",
    deviceCreated: "Cihaz oluşturuldu",
    partNumber: "Parça numarası (P/N)",
    partType: "Parça tipi",
    partName: "Parça ismi (Açıklama)",
    detailInformation: "Detay Bilgileri",
    quantity: "Miktar",
    deviceInformationThatPartBelongsTo: "Parçanın ait olduğu cihaz",
    partsFound: "Parça bulundu",
    reset: "Sıfırla",
    inTheAdvertisementSince: "Den beri ilanda",
    link: "Link",
    listingId: "Listeleme id",
    notListedYet: "Henüz listelenmedi",
    clickForList: "Listelemek için tıkla",
    noPermissionToView: "Sayfayı görüntülemeye yetkiniz yok",
    deviceAd: "Cihaz Reklamı",
    deviceInfoPDF: "Cihaz Bilgileri PDF",
    technicalAndManufacturerImages: "Teknik - Üretici Resimleri",
    listedSuccessfully: "Cihaz başarıyla listelendi",
    sync: "Eşitle",
    storageDefinition: "Depolama Tanımı",
    shelfNumber: "Raf Numarası",
    boxNumber: "Kutu Numarası",
    eCommerce: "E-Ticaret",
    partAd: "Parça Reklamı",
    type: "Tip",
    typeForSearch: "Arama yapın...",
    addAndEditFields: "Alan Ekleyin ve Güncelleyin",
    changeLanguage: "Dil Değiştir",
    contactWithDevelopers: "Geliştiriciler ile İletişime Geç",
    editECommerce: "E-Ticaret Düzenle",
    userManagement: "Kullanıcı Yönetimi",
    download: "İndir",
    getStockPdf: "Stok PDF",
    notFound: "Bulunamadı",
    condition: "Durum",
    yes: "Evet",
    inStock: "Stokta",
    date: "Tarih",
    sellerInfo: "Satıcı bilgisi",
    buyThisItem: "Ürünü Satın Al",
    location: "Konum",
    noProductDataInfo: "Cihaz bilgisi bulunamadı",
    downloadPDF: "PDF indir",
    areYouSureToListProduct: "Ürünü listelemek istediğnize emin misiniz?",
    areYouSureToSyncProduct: "Eşitleme yapmak istediğinize emin misiniz?",
    minAccessuarNeededError: "En az 1 aksesuar gerekli",
    minMaterialNeededError:
      "En az 1 adet kullanılan malzeme ve yapılan işlemler eklenmelidir",
    clear: "Clear",
    filter: "Filter",
    Dashboard: "Ana sayfa",
    Devices: "Cihazlar",
    Parts: "Parçalar",
    Inactives: "Aktif Olmayan Cihazlar",
    "Get Stock": "Stok",
    Settings: "Ayarlar",
    damage: "Hasar",
    repaired: "Tamir edildi",
    initialPhotos: "Başlangıç fotoğrafları",
    materialsUsedForRepair: "Tamir için kullanılan malzemeler",
    material: "Materyal",
    cost: "Maaliyet",
    makeInactive: "Inaktif yap",
    makeActive: "Aktif yap",
    toggleActive: "Aktiflik durumunu değiştir",
    inactiveParts: "Aktif Olmayan Parçalar",
    listingIds: "Listeleme Numaraları",
    time: "Zaman",
    notifications: "Bildirimler",
    weeklyModelDistribution: "Haftalık Model Dağılımları",
    monthlyModelDistribution: "Aylık Model Dağılımları",
    yearlyModelDistribution: "Senelik Model Dağılımları",
    eCommerceDistribution: "E Ticaret Dağılımları",
    userDistribution: "Kullanıcı Dağılımları",
    roleDistribution: "Rol Dağılımları",
    deviceName: "Cihaz İsmi",
    referencePrices: "Referans Fiyatlar",
    siteName: "Site ismi",
    listInShopify: "Shopify'da listele",
    defaultInformations: "Standart Bilgier",
    defaultCurrencyInfo: "Standart döviz",
    register: "Kayıt ol",
    pdfLink: "PDF'i gör",
    updatePdfLink: "PDF linkini güncelle",
    createPdfLink: "PDF linki oluştur",
    noPdfLinkYet: "Henüz PDF linki yok",
    repair: "Tamir Et",
    goBackToDamageInfo: "Hasar Bilgilerine Dön",
    waitingForApprovals: "Onay Bekleyenler",
    userApprovedSuccessfully: "Kullanıcı onaylandı",
    userDisapprovedSuccessfully: "Kullanıcı silindi",
    registerApproveText:
      "Başarıyla kayıt oldunuz. Admin onayından sonra sisteme giriş yapabilirsiniz.",
    approveUser: "Onayla",
  },
  de: {
    unexpectedErrorOccured: "Ein unerwarteter Fehler ist aufgetreten",
    buy: "Einkauf",
    brand: "Marke",
    succesfullyUpdated: "Erfolgreich aktualisiert",
    email: "E-Mail",
    password: "Passwort",
    select: "Wählen Sie",
    languageList: "Sprachenliste",
    purchasePrice: "Einkaufspreis",
    salePrice: "Verkaufspreis",
    areYouSureToDelete: "Sind Sie sicher, dass Sie es löschen möchten?",
    stockNumber: "Bestandsnummer",
    deviceImages: "Produktfotos",
    productSerialNumber: "Gerät-Seriennummer",
    storeInfo: "Lagerinformationen",
    packagingCost: "Verpackungskosten",
    totalExpenses: "Gesamtkosten",
    deleteProduct: "Produkt löschen",
    operations: "Aktionen",
    languageCode: "Sprachcode",
    deviceRepairPdfDownload: "Informationen zur Produktreparatur PDF-Download",
    taxExpenses: "Steuerkosten",
    outStockDate: "Ausgangsdatum Lager",
    noOption: "Keine Option",
    packagingType: "Verpackungsart",
    login: "Anmeldung",
    category: "Kategorie",
    brandList: "Markenliste",
    succesfullyAdded: "Erfolgreich hinzugefügt",
    userName: "Benutzername",
    otherInfos: "Andere Informationen",
    weight: "Gewicht (kg)",
    isRepaired: "Wurde es repariert?",
    newImagesAfterRepair: "Fotos des Produkts nach der Reparatur",
    firstSelectLanguage: "Wählen Sie zuerst die Sprache aus",
    addProduct: "Produkt hinzufügen",
    deviceStatus: "Produktstatus",
    operationsMade: "Durchgeführte Aktionen",
    inStockDate: "Eintrittsdatum Lager",
    accessuarDefinition: "Beschreibung des Zubehörs",
    imagesDeletedSuccessfully: "Fotos erfolgreich gelöscht",
    loading: "Lädt",
    releaseImages: "Fotos hierhin ziehen",
    model: "Model",
    userList: "Benutzerliste",
    areYouSure: "Sind Sie sich sicher?",
    add: "Hinzufügen",
    cancel: "Abbrechen",
    packagingTypeList: "Liste der Verpackungsarten",
    modelList: "Modelliste",
    role: "Rolle",
    serialNumber: "Seriennummer",
    shippingExpenses: "Transportkosten",
    otherExpenses: "Sonstige Kosten",
    accessuaryInformations: "Zubehörinformationen",
    save: "Speichern",
    technicalDetails: "Technische Details",
    softwareInfos: "Software-Informationen",
    height: "Länge (cm)",
    size: "Höhe (cm)",
    deviceInfos: "Produktinformationen",
    width: "Breite (cm)",
    accessuarySuccessfullyDeleted: "Zubehör erfolgreich gelöscht",
    status: "Statusinformationen",
    productDamageList: "Produktschadensliste",
    productCost: "Produktkosten",
    hourSpent: "Zeitaufwand (Stunden)",
    repair: "Reparatur und Service",
    productUsed: "Benutztes Produkt",
    productsUsedForRepair: "Bei dem Service benutzte Materialien",
    name: "Name",
    adminOperations: "Admin Aktionen",
    damageImages: "Schadensfotos",
    emptyFieldCannotBeSaved: "Leere Fläche, kann nicht gespeichert werden",
    dropImages: "Fotos hinterlassen",
    imagesAddedSuccessfully: "Fotos erfolgreich hinzugefügt",
    productList: "Produktliste",
    logout: "Ausloggen",
    succesfullyDeleted: "Erfolgreich gelöscht",
    operationSuccessful: "Aktion erfolgreich",
    update: "Aktualisieren",
    search: "Suchen",
    categoryList: "Kategorieliste",
    passwordConfirm: "Passwort erneut eingeben",
    delete: "Löschen",
    productionYear: "Baujahr",
    hardwareInfos: "Hardware-Informationen",
    description: "Erklärung",
    productLocation: "Produktstandort",
    productImage: "Produktfotos",
    printLabel: "Etikett ausdrucken",
    packaging: "Verpackung",
    buyInfos: "Einkaufsinformationen",
    repairInfos: "Geben Sie die Reparaturinformationen ein",
    languages: "Sprachen",
    staticTexts: "Statische Texte",
    language: "Sprache",
    devicePdfDownload: "Produktinformationen PDF-Download",
    physicalInfos: "Physische Details",
    damageStatus: "Details zum Schadensstatus",
    deviceStore: "Gerätelagerung",
    operationDescription: "Erläuterung der durchgeführten Aktionen",
    supplyingInstitution: "Versorgungsfirma",
    user: "Benutzer",
    seller: "Verkäufer",
    store: "Lagerarbeiter",
    satinalmaci: "Verkaufspersonal",
    admin: "Administrator",
    waitingForRepair: "anstehende Reparatur",
    hardDamaged: "Schwer beschädigt",
    damaged: "Beschädigt",
    working: "Funktionsfähig",
    addToStock: "zum Vorrat hinzufügen",
    deleteFromStock: "vom Vorrat entfernen",
    inactiveItems: "Inaktive Produkte",
    sortSuccessful:
      "Bilderreihe erfolgreich geändert. Aktualisieren Sie die Seite um es zu aktivieren.",
    productListPdf: "Geräteliste PDF",
    customizePdf: "Ändern der PDF ",
    eCommerceSites: "E-Commerce Webseiten",
    listingNumberRequired:
      "Das Feld für die Sortierungsnummer ist erforderlich.",
    listingNumber: "Sortierungsnummer",
    siteLink: "Vergabelink",
    eCommerceCorporate: "E-Commerce Firma",
    addDateToSite: "Datum des Hinzufügen zur Webseite",
    price: "Preis",
    downloadImages: "Bilder herunterladen",
    edit: "Ordnen",
    changePassword: "Passwort ändern",
    roleChangeSuccessful: "Rolle wurde erfolgreich geändert",
    aboutToDeleteUserAreYouSure:
      "Sie sind dabei den Benutzer zu löschen. Möchten Sie fortfahren?",
    saleDescription: "Verkaufsbeschreibung",
    saleDescriptionShopify: "Shopify Verkaufsbeschreibung",
    saleDescriptionDotmed: "Dotmed Verkaufsbeschreibung",
    OK: "OK",
    topBrands: "Top Marken",
    topModels: "Topmodelle",
    topCategories: "Top Kategorien",
    totalSalePrice: "Gesamtverkaufspreis",
    totalShippingCost: "Gesamte Versandkosten",
    totalTaxCost: "Gesamte Steuerkosten",
    totalOtherCost: "Gesamte sonstige Kosten",
    recentOperations: "Letzte Operationen",
    year: "Jahr",
    firstAddImageThenOrderError: "Zuerst Bild hinzufügen, dann bestellen",
    addPhoto: "Foto hinzufügen",
    youAreAboutToLogout: "Sie sind dabei, sich abzumelden",
    areYouSureToLogout: "Sind Sie sicher, dass Sie sich abmelden möchten",
    welcome: "Willkommen",
    dashboard: "Dashboard",
    devices: "Geräte",
    parts: "Teile",
    getStock: "Bestand erhalten",
    settings: "Einstellungen",
    imagesAreOrderedSuccessfully: "Bilder wurden erfolgreich bestellt",
    transferAccessory: "Zubehör übertragen",
    convertToDevice: "In Gerät umwandeln",
    convertToPart: "In Teil umwandeln",
    title: "Titel",
    areYouSureToAddNewDevice:
      "Sind Sie sicher, ein neues Gerät hinzufügen möchten",
    deviceCreated: "Gerät erstellt",
    partNumber: "Teilenummer (P/N)",
    partType: "Teileart",
    partName: "Teilname (Definition)",
    detailInformation: "Detailinformation",
    quantity: "Menge",
    deviceInformationThatPartBelongsTo:
      "Geräteinformationen, zu dem das Teil gehört",
    partsFound: "Gefundene Teile",
    reset: "Zurücksetzen",
    inTheAdvertisementSince: "In der Werbung seit",
    link: "Link",
    listingId: "Auflistungs-ID",
    notListedYet: "Noch nicht aufgelistet",
    clickForList: "Klicken Sie für die Liste",
    noPermissionToView: "Sie haben keine Berechtigung zum Anzeigen",
    deviceAd: "Geräteanzeige",
    deviceInfoPDF: "Geräteinformationen PDF",
    technicalAndManufacturerImages: "Technische - Herstellerbilder",
    listedSuccessfully: "Produkt erfolgreich aufgelistet",
    sync: "Synchronisieren",
    storageDefinition: "Speicherdefinition",
    shelfNumber: "Regalnummer",
    boxNumber: "Kistennummer",
    eCommerce: "E-Commerce",
    partAd: "Teileanzeige",
    type: "Typ",
    typeForSearch: "Typ für die Suche...",
    addAndEditFields: "Felder hinzufügen und bearbeiten",
    changeLanguage: "Sprache ändern",
    contactWithDevelopers: "Kontakt zu den Entwicklern",
    editECommerce: "E-Commerce bearbeiten",
    userManagement: "Benutzerverwaltung",
    download: "Herunterladen",
    getStockPdf: "Bestand als PDF erhalten",
    notFound: "Nicht gefunden",
    condition: "Zustand",
    yes: "Ja",
    inStock: "Auf Lager",
    date: "Datum",
    sellerInfo: "Verkäuferinformationen",
    buyThisItem: "Diesen Artikel kaufen",
    location: "Ort",
    noProductDataInfo: "Keine Produktinformationen verfügbar",
    downloadPDF: "PDF herunterladen",
    areYouSureToListProduct: "Sind Sie sicher, dieses Produkt aufzulisten",
    areYouSureToSyncProduct:
      "Sind Sie sicher, dieses Produkt zu synchronisieren",
    minMaterialNeededError:
      "Mindestens 1 Material und durchgeführte Operationen müssen hinzugefügt werden",
    clear: "Klar",
    filter: "Filter",
    Dashboard: "Armaturenbrett",
    Devices: "Geräte",
    Parts: "Teile",
    Inactives: "Inaktive",
    "Get Stock": "Holen Sie sich Lagerbestände",
    Settings: "Einstellungen",
    damage: "Schaden",
    repaired: "Repariert",
    initialPhotos: "Anfangsfotos",
    materialsUsedForRepair: "Für die Reparatur verwendete Materialien",
    material: "Material",
    cost: "Kosten",
    makeInactive: "Inaktivieren",
    makeActive: "Aktivieren",
    toggleActive: "Aktivitätsstatus umschalten",
    inactiveParts: "Inaktive Teile",
    listingIds: "Auflistungs-IDs",
    minAccessuarNeededError: "Mindestens 1 Zubehörteil erforderlich",
    time: "Zeit",
    notifications: "Benachrichtigungen",
    weeklyModelDistribution: "Wöchentliche Modellverteilung",
    monthlyModelDistribution: "Monatliche Modellverteilung",
    yearlyModelDistribution: "Jährliche Modellverteilung",
    eCommerceDistribution: "E-Commerce-Verteilung",
    userDistribution: "Benutzerverteilung",
    roleDistribution: "Rollenverteilung",
    deviceName: "Gerätename",
    referencePrices: "Referenzpreise",
    siteName: "Site-Name",
    listInShopify: "Liste in Shopify",
    defaultInformations: "Standardinformationen",
    defaultCurrencyInfo: "Standardwährung",
    register: "Registrieren",
    pdfLink: "PDF anzeigen",
    updatePdfLink: "PDF-Link aktualisieren",
    createPdfLink: "PDF-Link erstellen",
    noPdfLinkYet: "Es gibt noch keinen PDF-Link",
    repair: "Repariere es",
    goBackToDamageInfo: "Zurück zur Schadensinformation",
    waitingForApprovals: "Warten auf Genehmigungen",
    userApprovedSuccessfully: "Benutzer erfolgreich genehmigt",
    userDisapprovedSuccessfully: "Benutzer erfolgreich gelöscht",
    disApproveUserTitle: "Benutzer löschen",
    registerApproveText:
      "Sie haben sich erfolgreich registriert. Nach der Genehmigung durch den Administrator können Sie sich beim System anmelden.",
    approveUser: "Genehmigen",
  },
  en: {
    operationSuccessful: "Process was successfull",
    update: "Update",
    category: "Category",
    categoryList: "Category List",
    modelList: "Type List",
    delete: "Delete",
    userName: "User name",
    technicalDetails: "Technical Details",
    hardwareInfos: "Hardware Information",
    damageStatus: "Damage Status Information",
    weight: "Weight (kg)",
    deviceStatus: "Device Status",
    devicePdfDownload: "Device Information - Download PDF",
    dropImages: "Leave photos",
    passwordConfirm: "Re-enter password",
    inStockDate: "Entry Date - Storage",
    damageImages: "Damage photos",
    productList: "Product list",
    loading: "Loading",
    releaseImages: "Drag the photos here",
    brand: "Brand",
    model: "Type",
    packagingType: "Packaging Options",
    login: "Login",
    areYouSure: "Are you sure?",
    productionYear: "Year of production",
    purchasePrice: "Purchase price",
    salePrice: "Sale price",
    shippingExpenses: "Shipping Cost",
    otherExpenses: "Other expenses",
    save: "Save",
    softwareInfos: "Software Information",
    size: "Hight (cm)",
    productLocation: "Device Location",
    productSerialNumber: "Product Serial Number",
    storeInfo: "Storage information",
    packagingCost: "Packaging cost",
    packaging: "Packaging",
    repair: "Repair and Maintenance",
    newImagesAfterRepair: "Photos of the device after repair",
    outStockDate: "Departure Date - Storage",
    firstSelectLanguage: "Choose language first",
    productUsed: "Product used",
    languages: "Languages",
    addProduct: "Add product",
    deleteProduct: "Delete device",
    language: "Language",
    deviceRepairPdfDownload: "Device Repair Information - Download PDF",
    supplyingInstitution: "Supply company",
    repairInfos: "Enter repair informations",
    operations: "Procedures",
    imagesAddedSuccessfully: "Images successfully added",
    unexpectedErrorOccured: "Unexpected Error Occured",
    userList: "User List",
    succesfullyDeleted: "Successfully deleted",
    succesfullyUpdated: "Successfully updated",
    add: "Add",
    search: "Search",
    cancel: "Cancel",
    packagingTypeList: "Packaging Type List",
    select: "Choose",
    imagesDeletedSuccessfully: "Images successfully deleted",
    logout: "Log out",
    brandList: "Brand List",
    succesfullyAdded: "Successfully added",
    email: "E-mail",
    role: "Role",
    password: "Password",
    areYouSureToDelete: "Are you sure you want to delete it?",
    deviceImages: "Product Photos",
    taxExpenses: "Tax Expenses",
    width: "Width (cm)",
    status: "Status information",
    isRepaired: "Has it been repaired?",
    productImage: "Product photo",
    printLabel: "Print label",
    buyInfos: "Purchase Information",
    emptyFieldCannotBeSaved: "Empty space - cannot be saved",
    totalExpenses: "Total Expenses",
    languageCode: "Language Code",
    noOption: "No option",
    accessuaryInformations: "Accessory Information",
    deviceStore: "Device Storage",
    operationsMade: "Procedures done",
    operationDescription: "Description of procedures done",
    serialNumber: "Serial number",
    languageList: "Language List",
    stockNumber: "Stock Number",
    height: "Length (cm)",
    physicalInfos: "Physical Information",
    deviceInfos: "Product Information",
    description: "Description",
    otherInfos: "Other informations",
    productCost: "Product cost",
    hourSpent: "Time spend (hour)",
    productsUsedForRepair: "Products used in repair",
    staticTexts: "Static Texts",
    name: "Name",
    buy: "Purchase",
    adminOperations: "Admin operations",
    accessuarySuccessfullyDeleted: "Accessory deleted successfully",
    productDamageList: "Product Damage List",
    accessuarDefinition: "Accessory Description",
    user: "User",
    seller: "Seller",
    store: "Warehouse Staff",
    satinalmaci: "Purchasing Agent",
    admin: "Admin",
    waitingForRepair: "Awaiting Repair",
    hardDamaged: "Heavily Damaged",
    damaged: "Damaged",
    working: "Working",
    addToStock: "Add to stock",
    deleteFromStock: "Take off stock",
    inactiveItems: "Inactive products",
    productListPdf: "Device List PDF",
    customizePdf: "Edit PDF",
    sortSuccessful:
      "Image order successfully changed, refresh the page to activate it.",
    eCommerceSites: "E-commerce webpages",
    listingNumberRequired: "Listing number field is mandatory",
    listingNumber: "Listing number",
    siteLink: "Publication link",
    eCommerceCorporate: "E-commerce company",
    addDateToSite: "Date added to the webpage",
    price: "Price",
    downloadImages: "Download images",
    edit: "Arrange",
    changePassword: "Change password",
    roleChangeSuccessful: "Role successfully changed",
    aboutToDeleteUserAreYouSure:
      "You are about to delete this user. Do you wish to continue?",
    saleDescription: "Sale Description",
    saleDescriptionShopify: "Sale Description for Shopify",
    saleDescriptionDotmed: "Sale Description for Dotmed",
    time: "Time",
    OK: "OK",
    topBrands: "Top Brands",
    topModels: "Top Models",
    topCategories: "Top Categories",
    totalSalePrice: "Total Sale Price",
    totalShippingCost: "Total Shipping Cost",
    totalTaxCost: "Total Tax Cost",
    totalOtherCost: "Total Other Cost",
    recentOperations: "Recent Operations",
    year: "Year",
    firstAddImageThenOrderError: "First add image then order",
    addPhoto: "Add Photo",
    youAreAboutToLogout: "You are about to logout",
    areYouSureToLogout: "Are you sure to logout",
    welcome: "Welcome",
    dashboard: "Dashboard",
    devices: "Devices",
    parts: "Parts",
    getStock: "Get Stock",
    settings: "Settings",
    imagesAreOrderedSuccessfully: "Images are ordered successully",
    transferAccessory: "Transfer Accessory",
    convertToDevice: "Conver to Device",
    convertToPart: "Convert to Part",
    title: "Title",
    areYouSureToAddNewDevice: "Are you sure to add new device",
    deviceCreated: "Device created",
    partNumber: "Part Number (P/N)",
    partType: "Part Type",
    partName: "Part Name (Definition)",
    detailInformation: "Detail Information",
    quantity: "Quantity",
    deviceInformationThatPartBelongsTo: "Device information part belongs to",
    partsFound: "Parts Found",
    reset: "Reset",
    inTheAdvertisementSince: "In The Advertisement Since",
    link: "Link",
    listingId: "Listing id",
    notListedYet: "Not listed yet",
    clickForList: "Click for list",
    noPermissionToView: "You don't have permission to view",
    deviceAd: "Device Ad",
    deviceInfoPDF: "Device Info PDF",
    technicalAndManufacturerImages: "Technical - Manufacturer Images",
    listedSuccessfully: "Product listed succesfully",
    sync: "Sync",
    storageDefinition: "Storage Definition",
    shelfNumber: "Shelf Number",
    boxNumber: "Box Number",
    eCommerce: "E-Commerce",
    partAd: "Part Ad",
    type: "Type",
    typeForSearch: "Type for search...",
    addAndEditFields: "Add and Edit Fields",
    changeLanguage: "Change Language",
    contactWithDevelopers: "Contact with developers",
    editECommerce: "Edit E-Commerce",
    userManagement: "User Management",
    download: "Download",
    getStockPdf: "Get Stock PDF",
    notFound: "Not Found",
    condition: "Condition",
    yes: "Yes",
    inStock: "In Stock",
    date: "Date",
    sellerInfo: "Seller Info",
    buyThisItem: "Buy This Item",
    location: "Location",
    noProductDataInfo: "No Product Data Info",
    downloadPDF: "Download PDF",
    areYouSureToListProduct: "Are you sure to list this product",
    areYouSureToSyncProduct: "Are you sure to sync this product",
    minAccessuarNeededError: "At least 1 accessuar needed",
    minMaterialNeededError:
      "At least 1 materials used and operations made must be added",
    clear: "Clear",
    filter: "Filter",
    Dashboard: "Dashboard",
    Devices: "Devices",
    Parts: "Parts",
    Inactives: "Inactives",
    "Get Stock": "Get Stock",
    Settings: "Settings",
    damage: "Damage",
    repaired: "Repaired",
    initialPhotos: "Initial Photos",
    materialsUsedForRepair: "Materials used for repair",
    material: "Material",
    cost: "Cost",
    makeInactive: "Make Inactive",
    makeActive: "Make Active",
    toggleActive: "Toggle Active Status",
    inactiveParts: "Inactive Parts",
    listingIds: "Listing IDs",
    notifications: "Notifications",
    weeklyModelDistribution: "Weekly Model Distribution",
    monthlyModelDistribution: "Monthly Model Distribution",
    yearlyModelDistribution: "Yearly Model Distribution",
    eCommerceDistribution: "E-Commerce Distribution",
    userDistribution: "User Distribution",
    roleDistribution: "Role Distribution",
    deviceName: "Device Name",
    referencePrices: "Reference Prices",
    siteName: "Site Name",
    listInShopify: "List in Shopify",
    defaultInformations: "Default Informations",
    defaultCurrencyInfo: "Default Currency",
    register: "Register",
    pdfLink: "Show PDF",
    updatePdfLink: "Update PDF link",
    createPdfLink: "Create PDF link",
    noPdfLinkYet: "There is no PDF link yet",
    repair: "Repair",
    goBackToDamageInfo: "Return to Damage Information",
    waitingForApprovals: "Waiting for approvals",
    userApprovedSuccessfully: "User approved sucessfully",
    userDisapprovedSuccessfully: "User deleted sucessfully",
    disApproveUserTitle: "Delete user",
    registerApproveText:
      "You have registered successfully. You can log in to the system after admin approval.",
    approveUser: "Approve",
  },
  ar: {
    imagesAddedSuccessfully: "تمت إضافة الصور بنجاح",
    brand: "الماركة",
    productionYear: "سنة الصنع",
    taxExpenses: "المصاريف الضريبية",
    height: "(الطول (سم",
    productCost: "تكلفة المنتج",
    totalExpenses: "المصروفات الكلية",
    deviceRepairPdfDownload: "PDF معلومات إصلاح الجهاز انزل ",
    brandList: " قائمة الماركة",
    packagingTypeList: "قائمة نوع التعبئة",
    password: "كَلِمَةُ المُرُورِ",
    passwordConfirm: "كلمة مرور مرة أخرى",
    softwareInfos: "معلومات البرمجية",
    outStockDate: "تاريخ الخروج من الذخيرة",
    printLabel: "اخرج اتيكــــيت",
    repair: "الإصلاح",
    deviceStatus: "حالة الجهاز",
    adminOperations: "عمليات الإدارة",
    areYouSure: "هل انتم متأكدون؟",
    succesfullyDeleted: "تم الحذف بنجاح",
    succesfullyUpdated: "تم التحديث بنجاح",
    operationSuccessful: "عملية ناجحة",
    add: "أضف",
    succesfullyAdded: "تمت الاضافة بنجاح",
    shippingExpenses: "تكاليف الشحن",
    areYouSureToDelete: "هل أنت متأكد أنك تريد حذف؟",
    otherExpenses: "مصاريف أخرى",
    technicalDetails: "تفاصيل تقنية",
    status: "معلومات الوضع",
    description: "توضيح",
    otherInfos: "معلومات أخرى",
    hourSpent: "(الوقت المنقضي (ساعات",
    deleteProduct: "حذف الجهاز",
    name: "اسم",
    language: "اللغة",
    languageCode: "رمز اللغة",
    addProduct: "إضافة منتج",
    loading: "جاري التحميل",
    noOption: "لا توجد خيارات",
    productList: "قائمة المنتوجات",
    logout: "تسجيل الخروج",
    update: "تحديث إلى",
    category: "صِنْف",
    role: "دَوْرٌ",
    purchasePrice: "سعر الشراء",
    salePrice: "سعر البيع",
    supplyingInstitution: "مؤسسة التوريد",
    accessuaryInformations: "معلومات الملحقات",
    inStockDate: "تاريخ الدخول الى الذخيرة",
    productLocation: "موقع الجهاز",
    productDamageList: "قائمة الخسارة المنتج",
    productUsed: "المنتج المستخدم",
    accessuarDefinition: "وصف الملحق",
    buy: "شراء",
    dropImages: "اترك الصور",
    unexpectedErrorOccured: "حدث خطأ غير متوقع",
    model: "نموذج",
    packagingType: "نوع التغليف",
    save: "يحفظ",
    stockNumber: "رقم المخزون",
    physicalInfos: "المعلومات المادية",
    weight: "(الوزن (كجم",
    packagingCost: "تكلفة التغليف",
    languages: "اللغات",
    staticTexts: "نصوص ثابتة",
    firstSelectLanguage: "اختر اللغة أولا",
    devicePdfDownload: "PDF معلومات الجهاز انزل ",
    operations: "المعاملات",
    releaseImages: "اسحب الصور هنا",
    userList: "قائمة المستخدمين",
    login: "تسجيل الدخول",
    categoryList: "قائمة الصنف",
    modelList: "قائمة النموذج",
    languageList: "قائمة اللغات",
    userName: "إسم المستخدم",
    hardwareInfos: "معلومات الأجهزة",
    accessuarySuccessfullyDeleted: "تم حذف الملحقات بنجاح",
    packaging: "التعبئة",
    operationDescription: "وصف المعاملة المنجزة",
    deviceStore: "تخزين الجهاز",
    imagesDeletedSuccessfully: "تم حذف الصور بنجاح",
    email: "البريد الإلكتروني",
    select: "اختار",
    delete: "حذف",
    serialNumber: "الرقم التسلسلي",
    deviceImages: "صور المنتج",
    size: "(الارتفاع (سم",
    deviceInfos: "معلومات الجهاز",
    width: "(العرض (سم",
    damageStatus: "معلومات وضع الضرر",
    productSerialNumber: "الرقم التسلسلي للمنتج",
    storeInfo: "معلومات التخزين",
    productImage: "صورة المنتج",
    operationsMade: "المعاملات التي تمت\\n",
    buyInfos: "معلومات الشراء\\n",
    newImagesAfterRepair: "صور جديدة للمنتج بعد الإصلاح",
    productsUsedForRepair: "المواد المستعملة في الإصلاح",
    cancel: "اِبْطَال",
    damageImages: "صور الخسارة",
    isRepaired: "ا لُئِِمَ",
    repairInfos: "أدخل معلومات الإصلاح",
    emptyFieldCannotBeSaved: "لا يمكن حفظ المساحة الخالية",
    search: "بَحْث عَن",
    seller: "البائع",
    user: "المستعمل",
    store: "عامل مستودع",
    satinalmaci: "مندوب مبيعات",
    admin: "المشرف",
    waitingForRepair: "انتظر الاصلاح",
    hardDamaged: "شديد التلف",
    damaged: "متضرر",
    working: "يعمل",
    deleteFromStock: "اخرج من المخزون",
    addToStock: "أضف إلى المخزون",
    inactiveItems: "المنتجات غير النشطة",
    productListPdf: "جداول الأجهزة",
    customizePdf: "PDF تحرير ",
    sortSuccessful: "تم فرز الصور بنجاح. قم بتحديث الصفحة لتكون نشطة",
    eCommerceSites: "صفحات التجارة الإلكترونية",
    listingNumberRequired: "حقل رقم القائمة مطلوب",
    listingNumber: "رقم القائمة",
    siteLink: "ارتباط إعلان",
    eCommerceCorporate: "شركة التجارة الإلكترونية",
    addDateToSite: "تاريخ الإضافة إلى الصفحة",
    price: "السعر",
    downloadImages: "تنزيل الصور",
    edit: "تصحيح",
    changePassword: "تغيير كلمة السر",
    roleChangeSuccessful: "تم تغيير الدور بنجاح",
    aboutToDeleteUserAreYouSure:
      "\\nأنت على وشك حذف هذا المستخدم. هل تريد أن تكمل؟",
    saleDescription: "وصف البيع",
    saleDescriptionShopify: "Shopify وصف البيع",
    saleDescriptionDotmed: "وصف البيع Dotmed",
    OK: "نعم",
    topBrands: "ارقى الماركات",
    topModels: "أفضل النماذج",
    topCategories: "أفضل الفئات",
    totalSalePrice: "إجمالي سعر البيع",
    totalShippingCost: "إجمالي تكلفة الشحن",
    totalTaxCost: "إجمالي تكلفة الضريبة",
    totalOtherCost: "إجمالي التكلفة الأخرى",
    recentOperations: "العمليات الأخيرة",
    year: "السنة",
    firstAddImageThenOrderError: "أضف الصورة أولاً ثم قم بالطلب",
    addPhoto: "أضف صورة",
    youAreAboutToLogout: "أنت على وشك تسجيل الخروج",
    areYouSureToLogout: "هل أنت متأكد من رغبتك في تسجيل الخروج؟",
    welcome: "مرحبًا",
    dashboard: "لوحة القيادة",
    devices: "الأجهزة",
    parts: "القطع",
    getStock: "الحصول على المخزون",
    settings: "الإعدادات",
    imagesAreOrderedSuccessfully: "تم طلب الصور بنجاح",
    transferAccessory: "نقل الإكسسوار",
    convertToDevice: "تحويل إلى جهاز",
    convertToPart: "تحويل إلى قطعة",
    title: "العنوان",
    areYouSureToAddNewDevice: "هل أنت متأكد من رغبتك في إضافة جهاز جديد",
    deviceCreated: "تم إنشاء الجهاز",
    partNumber: "رقم الجزء (P/N)",
    partType: "نوع الجزء",
    partName: "اسم الجزء (التعريف)",
    detailInformation: "معلومات مفصلة",
    quantity: "الكمية",
    deviceInformationThatPartBelongsTo: "معلومات الجهاز التي ينتمي إليها الجزء",
    partsFound: "الأجزاء الموجودة",
    reset: "إعادة تعيين",
    inTheAdvertisementSince: "في الإعلان منذ",
    link: "الرابط",
    listingId: "معرف القائمة",
    notListedYet: "لم يتم إدراجه بعد",
    clickForList: "انقر للعرض",
    noPermissionToView: "لا تمتلك الإذن للعرض",
    deviceAd: "إعلان الجهاز",
    deviceInfoPDF: "معلومات الجهاز بصيغة PDF",
    technicalAndManufacturerImages: "الصور التقنية والصانع",
    listedSuccessfully: "تمت إدراج المنتج بنجاح",
    sync: "مزامنة",
    storageDefinition: "تعريف التخزين",
    shelfNumber: "رقم الرف",
    boxNumber: "رقم الصندوق",
    eCommerce: "التجارة الإلكترونية",
    partAd: "إعلان الجزء",
    type: "النوع",
    typeForSearch: "النوع للبحث...",
    addAndEditFields: "إضافة وتحرير الحقول",
    changeLanguage: "تغيير اللغة",
    contactWithDevelopers: "التواصل مع المطورين",
    editECommerce: "تحرير التجارة الإلكترونية",
    userManagement: "إدارة المستخدمين",
    download: "تحميل",
    getStockPdf: "الحصول على ملف PDF للمخزون",
    notFound: "غير موجود",
    condition: "الحالة",
    yes: "نعم",
    inStock: "متوفر",
    date: "التاريخ",
    sellerInfo: "معلومات البائع",
    buyThisItem: "شراء هذا العنصر",
    location: "الموقع",
    noProductDataInfo: "لا توجد معلومات عن المنتج",
    downloadPDF: "تحميل ملف PDF",
    areYouSureToListProduct: "هل أنت متأكد من رغبتك في إدراج هذا المنتج",
    areYouSureToSyncProduct: "هل أنت متأكد من رغبتك في مزامنة هذا المنتج",
    minMaterialNeededError:
      "يجب أن يتم إضافة على الأقل مادة واحدة وعملية واحدة",
    clear: "واضح",
    filter: "منقي",
    Dashboard: "لوحة القيادة",
    Devices: "الأجهزة",
    Parts: "القطع",
    Inactives: "غير نشط",
    "Get Stock": "احصل على المخزون",
    Settings: "إعدادات",
    damage: "ضرر",
    repaired: "تم إصلاحه",
    initialPhotos: "صور البداية",
    materialsUsedForRepair: "المواد المستخدمة في الإصلاح",
    material: "مادة",
    cost: "تكلفة",
    makeInactive: "جعل غير نشط",
    makeActive: "جعل نشط",
    toggleActive: "تبديل الحالة النشطة",
    inactiveParts: "الأجزاء غير النشطة",
    listingIds: "معرفات القوائم",
    minAccessuarNeededError: "مطلوب ملحق واحد على الأقل",
    time: "وقت",
    notifications: "إشعارات",
    weeklyModelDistribution: "توزيع النموذج الأسبوعي",
    monthlyModelDistribution: "توزيع النموذج الشهري",
    yearlyModelDistribution: "توزيع النموذج السنوي",
    eCommerceDistribution: "توزيع التجارة الإلكترونية",
    userDistribution: "توزيع المستخدمين",
    roleDistribution: "توزيع الأدوار",
    deviceName: "اسم الجهاز",
    referencePrices: "الأسعار المرجعية",
    siteName: "اسم الموقع",
    listInShopify: "القائمة في Shopify",
    defaultInformations: "المعلومات الافتراضية",
    defaultCurrencyInfo: "العملة القياسية",
    register: "يسجل",
    pdfLink: "عرض قوات الدفاع الشعبي",
    updatePdfLink: "تحديث رابط PDF",
    createPdfLink: "إنشاء رابط PDF",
    noPdfLinkYet: "لا يوجد رابط PDF بعد",
    repair: "اصلحه",
    goBackToDamageInfo: "العودة إلى معلومات الضرر",
    waitingForApprovals: "في انتظار الموافقات",
    userApprovedSuccessfully: "تمت الموافقة على المستخدم بنجاح",
    userDisapprovedSuccessfully: "تم حذف المستخدم بنجاح",
    disApproveUserTitle: "حذف المستخدم",
    registerApproveText:
      "لقد قمت بالتسجيل بنجاح. بعد موافقة المشرف يمكنك تسجيل الدخول إلى النظام.",
    approveUser: "يعتمد",
  },
};
