import FactoryService from "services/factoryService";

export default class UserService extends FactoryService {
  constructor() {
    super("user");
  }

  signUp(data) {
    return this.api.post(`${this.serviceName}/signup`, data);
  }

  login(data) {
    return this.api.post(`${this.serviceName}/login`, data);
  }
  getCurrentUser() {
    return this.api.get(`${this.serviceName}/getCurrentUser`);
  }
  getActiveUsers(query = "") {
    return this.api.get(`${this.serviceName}/allUsers${query}`);
  }
  updateUser(userId, data) {
    return this.api.patch(`${this.serviceName}/updateUser/${userId}`, data);
  }
  deleteUser(userId) {
    return this.api.delete(`${this.serviceName}/deleteUser/${userId}`);
  }
  createUser(data) {
    return this.api.post(`${this.serviceName}/createUser`, data);
  }
  changePassword(id, data) {
    return this.api.patch(`${this.serviceName}/updateMyPassword/${id}`, data);
  }
}
