import Printer from "assets/icons/Printer";
import languageTexts from "constants/languageTexts";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { generateXmlDymoAccessuar } from "utils/dymo/accessuarDymo";

function AccessuarDymo({
  definition,
  stockNumber,
  brand,
  model,
  productionYear,
  link,
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);

  const dymo = window.dymo;
  const printLabel = () => {
    let printers = dymo.label?.framework?.getPrinters();
    if (!printers?.length) {
      alert("No DYMO printers are installed. Install DYMO printers.");
      return;
    }
    let selectedPrinter = printers[0];
    let xml = generateXmlDymoAccessuar(
      definition,
      stockNumber,
      brand,
      model,
      productionYear,
      link
    );
    dymo.label.framework.openLabelXml(xml).print(selectedPrinter.name);
  };

  return (
    <button className="print-label" onClick={printLabel}>
      <Printer />
      {languageTexts[danisikLanguage].printLabel}
    </button>
  );
}

export default AccessuarDymo;
