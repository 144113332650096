import FactoryService from "services/factoryService";

export default class AccessoryService extends FactoryService {
  constructor() {
    super("accessory");
  }
  getAllByDeviceId(deviceId) {
    return this.api.get(
      `/${this.serviceName}/getAllAccessuarsWithDeviceId/${deviceId}`
    );
  }
  orderImages(values) {
    return this.api.patch(`${this.serviceName}/imagesArrayOrderUpdate`, values);
  }
  handleDeleteImg(modelId, publicId) {
    return this.api.delete(`${this.serviceName}/${modelId}/images/${publicId}`);
  }
  // convertAccessuarToPart(accessoryId) {
  //   return this.api.post(
  //     `${this.serviceName}/convertAccessory/${accessoryId}`,
  //     { part: true }
  //   );
  // }
  // convertAccessuarToDevice(accessoryId) {
  //   return this.api.post(
  //     `${this.serviceName}/convertAccessory/${accessoryId}`,
  //     { device: true }
  //   );
  // }
  convertAccessuar(accessoryId, data) {
    return this.api.post(
      `${this.serviceName}/convertAccessory/${accessoryId}`,
      data
    );
  }

  transferAccessuary(accessuarId, stockNumber) {
    return this.api.post(`accessory/transferAccessory/${accessuarId}`, {
      stockNumber,
    });
  }
}
