import ArrowLeftCircle from "assets/icons/ArrowLeftCircle";
import ArrowRightCircle from "assets/icons/ArrowRightCircle";
import React, { useState } from "react";
import { useSwiper } from "swiper/react";

function SwiperCustomButtons() {
  const swiper = useSwiper();
  const [slideProgress, setSlideProgress] = useState(0);

  swiper.on("slideChange", (e) => {
    setSlideProgress(e.progress);
  });
  return (
    <>
      <div
        onClick={() => swiper.slidePrev()}
        className={`swiper-prev ${slideProgress === 0 ? "disabled" : ""}`}
      >
        <ArrowLeftCircle />
      </div>
      <div
        onClick={() => swiper.slideNext()}
        className={`swiper-next ${slideProgress === 1 ? "disabled" : ""}`}
      >
        <ArrowRightCircle />
      </div>
    </>
  );
}

export default SwiperCustomButtons;
