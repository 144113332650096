import React, { useEffect, useState } from "react";
import TableFilter from "./TableFilter";
import Table from "./Table";

import DeviceService from "services/product/device/deviceService";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import LoadingPage from "components/LoadingPage";
import { useCallback } from "react";
const setQueryFromFilters = (filter, sortBy) => {
  let query = "";
  if (filter.brand._id) {
    query += `brandId=${filter.brand._id}`;
  }
  if (filter.model._id) {
    if (query) {
      query += "&";
    }
    query += `modelId=${filter.model._id}`;
  }
  if (filter.category._id) {
    if (query) {
      query += "&";
    }
    query += `categoryId=${filter.category._id}`;
  }
  if (filter.productionYear) {
    if (query) {
      query += "&";
    }
    query += `productionYear=${filter.productionYear}`;
  }
  if (sortBy) {
    if (query) {
      query += "&";
    }
    query += `sortBy=${sortBy.name}&sort=${sortBy.sortType}`;
  }
  if (query) {
    return "&" + query;
  }
  return "";
};
const limit = 50;
let deviceService = new DeviceService();
function DeviceTableInactive() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") * 1 : 1
  );
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [totalCount, setTotalCount] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [deviceArr, setDeviceArr] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    brand: {
      name: "",
      _id: "",
    },
    model: {
      name: "",
      _id: "",
    },
    category: {
      name: "",
      _id: "",
    },
    production: "",
  });
  const getDeviceWithFilters = useCallback(() => {
    let query = setQueryFromFilters(filterOptions, sortBy);
    setLoading(true);
    deviceService
      .getAllDeviceWithFilters(
        `?active=false&limit=${limit}&page=${page}${query}`
      )
      .then((res) => {
        setTotalPage(res.data.totalPage);
        setTotalCount(res.data.totalCount);
        setDeviceArr(res.data.data);
      })
      .finally(() => setLoading(false));
  }, [page, filterOptions, sortBy]);
  useEffect(() => {
    getDeviceWithFilters();
  }, [getDeviceWithFilters]);

  useEffect(() => {
    navigate({
      search: `?${createSearchParams({
        page: page,
      })}`,
    });
  }, [page, navigate]);

  const next = () => {
    if (totalPage * 1 > page * 1) {
      setPage(page * 1 + 1);
    }
  };
  const nextLast = () => {
    setPage(totalPage * 1);
  };
  const previousLast = () => {
    setPage(1);
  };
  const previous = () => {
    if (page * 1 > 1) {
      setPage(page * 1 - 1);
    }
  };

  return loading ? (
    <LoadingPage />
  ) : (
    <div>
      <TableFilter
        isModalOpen={isFilterModalOpen}
        setFilterModalOpen={setIsFilterModalOpen}
        setFilterOptions={setFilterOptions}
        filterOptions={filterOptions}
        next={next}
        previous={previous}
        nextLast={nextLast}
        previousLast={previousLast}
        leftArrowDisabled={page <= 1}
        rightArrowDisabled={totalPage * 1 <= page * 1}
        limit={limit}
        page={page}
        totalCount={totalCount}
      />
      <Table
        filterOptions={filterOptions}
        deviceArr={deviceArr}
        sortBy={sortBy}
        setSortBy={setSortBy}
        getDeviceWithFilters={getDeviceWithFilters}
      />
    </div>
  );
}

export default DeviceTableInactive;
