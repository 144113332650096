import RecentOperationsModal from "components/Modals/Admin/RecentOperationsModal";
import languageTexts from "constants/languageTexts";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

function RecentOperations() {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [isRecentOperationsModalOpen, setIsRecentOperationsModalOpen] =
    useState(false);
  const wrapperRef = useRef(null);
  return (
    <div className="setting-item-container" ref={wrapperRef}>
      <div
        className="setting-item"
        onClick={() => setIsRecentOperationsModalOpen(true)}
      >
        <span>{languageTexts[danisikLanguage].recentOperations}</span>
      </div>
      <RecentOperationsModal
        setIsModalOpen={setIsRecentOperationsModalOpen}
        isModalOpen={isRecentOperationsModalOpen}
      />
    </div>
  );
}

export default RecentOperations;
