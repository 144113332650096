import { LanguageActionTypes } from "./languageActionTypes";

// const includedLanguages = ["tr", "en", "de", "ar"];
// const handleLanguageCode = () => {
//   let langCode = navigator.language || navigator.userLanguage;
//   if (langCode) {
//     if (includedLanguages.includes(langCode)) {
//       return langCode;
//     }
//   }
//   return "tr";
// };
const getLanguage = () => {
  const includedLanguages = ["tr", "en", "de", "ar"];
  let languageInLocalStorage = localStorage.getItem("danisik-language");
  if (
    languageInLocalStorage &&
    includedLanguages.includes(languageInLocalStorage)
  ) {
    return languageInLocalStorage;
  }
  return "de";
};
const INITIAL_STATE = getLanguage();

const languageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LanguageActionTypes.SET_LANGUAGE:
      localStorage.setItem("danisik-language", action.payload);
      return action.payload;
    default:
      return state;
  }
};

export default languageReducer;
