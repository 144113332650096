import FactoryService from "services/factoryService";

export default class DeviceService extends FactoryService {
  constructor() {
    super("device");
  }
  getAllDeviceWithFilters(query) {
    return this.api.get(`${this.serviceName}/getAllDeviceWithFilters${query}`);
  }
  changeActiveStatus(id) {
    return this.api.patch(`${this.serviceName}/activeOrDeActiveDevice/${id}`);
  }
  handleDeleteImg(deviceId, publicId) {
    return this.api.delete(
      `${this.serviceName}/${deviceId}/images/${publicId}`
    );
  }
  orderImages(values) {
    return this.api.patch(`${this.serviceName}/imagesArrayOrderUpdate`, values);
  }
  completionStatus(id) {
    return this.api.get(
      `deviceCompletionStatusRouter/getAllDeviceFieldsCompletionStatusWithDeviceId/${id}`
    );
  }
}
