import React from "react";

function Notification({
  fill = "var(--clr-neutral-200)",
  width = "2.6rem",
  height = "2.7rem",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 27"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23 19.25H25.5V21.75H0.5V19.25H3V10.5C3 7.84784 4.05357 5.3043 5.92893 3.42893C7.8043 1.55357 10.3478 0.5 13 0.5C15.6522 0.5 18.1957 1.55357 20.0711 3.42893C21.9464 5.3043 23 7.84784 23 10.5V19.25ZM9.25 24.25H16.75V26.75H9.25V24.25Z" />
    </svg>
  );
}

export default Notification;
