import { ModelActionTypes } from "./modelActionTypes";
import ModelService from "services/admin/modelService";

function formatModels(models) {
  const result = {};

  // Iterate through the input data
  models.forEach((item) => {
    const { _id, active, name, brandId } = item;
    if (brandId) {
      // Extract relevant information

      // Create an object for the current item
      const modelObject = {
        _id,
        active,
        name,
      };

      // Get the brandId and use it as a key in the result object
      const key = brandId._id;

      // Check if the key already exists in the result object
      if (!result[key]) {
        // If not, create a new array for the key
        result[key] = [modelObject];
      } else {
        // If yes, push the current model object to the existing array
        result[key].push(modelObject);
      }
    }
  });
  return result;
}
export const setModels = (user) => {
  const modelService = new ModelService();
  if (user && user.role === "admin") {
    return async (dispatch) => {
      try {
        dispatch({ type: ModelActionTypes.SET_MODELS });

        // Make your API request
        const response = await modelService.getAll(
          "?limit=999999999999&sort=name"
        );
        let allModels = response.data.data;
        let formattedModels = formatModels(allModels);
        dispatch({
          type: ModelActionTypes.SET_MODELS_SUCCESSFUL,
          payload: { formattedModels, allModels },
        });
      } catch (error) {
        dispatch({ type: ModelActionTypes.SET_MODELS_ERROR, payload: error });
      }
    };
  } else {
    return async (dispatch) => {
      dispatch({
        type: ModelActionTypes.SET_MODELS_SUCCESSFUL,
        payload: { formattedModels: [], allModels: [] },
      });
    };
  }
};
