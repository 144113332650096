import React, { useEffect, useState } from "react";
import "./style.scss";
import ModalHeader from "components/Modals/ModalHeader";
import Checkbox from "components/FormElements/Checkbox";
import { truncate } from "utils/stringFunctions";
import PdfService from "services/product/device/pdfService";
import DeviceService from "services/product/device/deviceService";
import AccessoryService from "services/product/device/accessoryService";
import LoadingPage from "components/LoadingPage";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { dateFormatToDDMMYYYDayHHMMSSDash } from "utils/dateFormat";
import { Link } from "react-router-dom";
import FormButton from "components/Buttons/FormButton";

const pdfService = new PdfService();
const deviceService = new DeviceService();
const accessoryService = new AccessoryService();
function DeviceInfoPdf({ isModalOpen = false, setIsModalOpen, deviceId }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [technicalDetails, setTechnicalDetails] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pdfDeviceInfo, setPdfDeviceInfo] = useState({
    serialNumber: true,
    brandId: true,
    modelId: true,
    productionYear: true,
    dotmedCategoryId: true,
    deviceInfo: true,
    images: true,
    physicalInfos: true,
    packagingInfos: true,
    deviceLocations: true,
  });
  const [accessoriesInPdf, setAccessoriesInPdf] = useState([]);
  const [technicalDetailsInPdf, setTechnicalDetailsInPdf] = useState([]);
  const [pdfName, setPdfName] = useState("");
  const [pdfLink, setPdfLink] = useState("");
  const handleChangePdfDeviceInfo = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    setPdfDeviceInfo({ ...pdfDeviceInfo, [name]: value });
  };
  const handleChangePdfArray = (e, id, arr, setArr) => {
    let newArr = [...arr];
    if (e.target.checked) {
      if (!newArr.includes(id)) {
        newArr.push(id);
      }
    } else {
      if (newArr.includes(id)) {
        newArr.splice(newArr.indexOf(id), 1);
      }
    }
    setArr(newArr);
  };
  useEffect(() => {
    async function fetchData() {
      let [deviceInfo, accessoryInfo] = await Promise.all([
        deviceService.getById(deviceId),
        accessoryService.getAllByDeviceId(deviceId),
      ]);
      try {
        setLoading(true);
        const deviceInfoResult = deviceInfo.data.data;
        setPdfLink(deviceInfoResult?.pdf?.url);
        setPdfName(
          `${deviceInfoResult.stockNumber}-${deviceInfoResult.serialNumber}`
        );
        let technicalDetails = deviceInfoResult.technicalDetails;
        setTechnicalDetails(technicalDetails);
        let accessories = accessoryInfo.data.data;
        setAccessories(accessories);
      } catch (error) {
        console.log("error : ", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [deviceId, isModalOpen]);

  const handleDownloadPdf = () => {
    let pdfInfos = {
      id: deviceId,
      deviceInfo: pdfDeviceInfo,
      accessory:
        accessoriesInPdf &&
        accessoriesInPdf.length &&
        accessoriesInPdf[0] !== null
          ? accessoriesInPdf
          : [],
      technicalDetails:
        technicalDetailsInPdf &&
        technicalDetailsInPdf.length &&
        technicalDetailsInPdf[0] !== null
          ? technicalDetailsInPdf
          : [],
    };
    setLoading(true);
    pdfService
      .downloadPdf(pdfInfos)
      .then((res) => {
        setPdfLink(res.data?.pdfUrl);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  return (
    <div
      className={`device-info-pdf-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal device-modal `}>
        <ModalHeader
          title={languageTexts[danisikLanguage].deviceInfoPDF}
          setIsModalOpen={setIsModalOpen}
        />

        <div className="modal-content flow-content device-info-pdf-modal-content">
          <div>
            <h3 className="pdf-info-subtitle">
              {languageTexts[danisikLanguage].deviceInfos}
            </h3>
            <div className="flow-content">
              <div className="pdf-info-item">
                <span>Serial Number</span>
                <Checkbox
                  checked={pdfDeviceInfo.serialNumber}
                  onChange={handleChangePdfDeviceInfo}
                  name={"serialNumber"}
                />
              </div>
              <div className="pdf-info-item">
                <span>{languageTexts[danisikLanguage].brand}</span>
                <Checkbox
                  checked={pdfDeviceInfo.brandId}
                  onChange={handleChangePdfDeviceInfo}
                  name={"brandId"}
                />
              </div>
              <div className="pdf-info-item">
                <span>{languageTexts[danisikLanguage].model}</span>
                <Checkbox
                  checked={pdfDeviceInfo.modelId}
                  onChange={handleChangePdfDeviceInfo}
                  name={"modelId"}
                />
              </div>
              <div className="pdf-info-item">
                <span>{languageTexts[danisikLanguage].productionYear}</span>
                <Checkbox
                  checked={pdfDeviceInfo.productionYear}
                  onChange={handleChangePdfDeviceInfo}
                  name={"productionYear"}
                />
              </div>
              <div className="pdf-info-item">
                <span>{languageTexts[danisikLanguage].category}</span>
                <Checkbox
                  checked={pdfDeviceInfo.dotmedCategoryId}
                  onChange={handleChangePdfDeviceInfo}
                  name={"dotmedCategoryId"}
                />
              </div>
              <div className="pdf-info-item">
                <span>{languageTexts[danisikLanguage].deviceInfos}</span>
                <Checkbox
                  checked={pdfDeviceInfo.deviceInfo}
                  onChange={handleChangePdfDeviceInfo}
                  name={"deviceInfo"}
                />
              </div>
              <div className="pdf-info-item">
                <span>Images</span>
                <Checkbox
                  checked={pdfDeviceInfo.images}
                  onChange={handleChangePdfDeviceInfo}
                  name={"images"}
                />
              </div>
              <div className="pdf-info-item">
                <span>{languageTexts[danisikLanguage].physicalInfos}</span>
                <Checkbox
                  checked={pdfDeviceInfo.physicalInfos}
                  onChange={handleChangePdfDeviceInfo}
                  name={"physicalInfos"}
                />
              </div>
              <div className="pdf-info-item">
                <span>{languageTexts[danisikLanguage].packaging}</span>
                <Checkbox
                  checked={pdfDeviceInfo.packagingInfos}
                  onChange={handleChangePdfDeviceInfo}
                  name={"packagingInfos"}
                />
              </div>
              <div className="pdf-info-item">
                <span>Device Locations</span>
                <Checkbox
                  checked={pdfDeviceInfo.deviceLocations}
                  onChange={handleChangePdfDeviceInfo}
                  name={"deviceLocations"}
                />
              </div>
            </div>
          </div>
          {accessories.length ? (
            <div>
              <h3 className="pdf-info-subtitle">Accessories</h3>
              <div className="flow-content">
                {accessories.map((accessory) => (
                  <div className="pdf-info-item" key={accessory._id}>
                    <span
                      title={`${accessory.serialNumber} ${accessory.definition}`}
                    >
                      {accessory.serialNumber}{" "}
                      {truncate(accessory.definition, 35)}
                    </span>
                    <Checkbox
                      checked={accessoriesInPdf.includes(accessory._id)}
                      onChange={(e) =>
                        handleChangePdfArray(
                          e,
                          accessory._id,
                          accessoriesInPdf,
                          setAccessoriesInPdf
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {technicalDetails.length ? (
            <div>
              <h3 className="pdf-info-subtitle">Technical Details</h3>
              <div className="flow-content">
                {technicalDetails.map((technicalDetail) => (
                  <div className="pdf-info-item" key={technicalDetail._id}>
                    <span
                      title={`${technicalDetail.title} ${technicalDetail.description}`}
                    >
                      {truncate(
                        `${technicalDetail.title} ${technicalDetail.description}`,
                        45
                      )}
                    </span>
                    <Checkbox
                      checked={technicalDetailsInPdf.includes(
                        technicalDetail._id
                      )}
                      onChange={(e) =>
                        handleChangePdfArray(
                          e,
                          technicalDetail._id,
                          technicalDetailsInPdf,
                          setTechnicalDetailsInPdf
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          <div className="link-container">
            {pdfLink ? (
              <Link to={pdfLink}>{languageTexts[danisikLanguage].pdfLink}</Link>
            ) : (
              <span>{languageTexts[danisikLanguage].noPdfLinkYet}</span>
            )}
          </div>

          <FormButton
            onClick={handleDownloadPdf}
            title={
              pdfLink
                ? languageTexts[danisikLanguage].updatePdfLink
                : languageTexts[danisikLanguage].createPdfLink
            }
          />
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
      {loading && <LoadingPage />}
    </div>
  );
}

export default DeviceInfoPdf;
