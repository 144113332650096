import Dollar from "assets/icons/Dollar";
import Euro from "assets/icons/Euro";
import Pound from "assets/icons/Pound";
import TurkishLira from "assets/icons/TurkishLira";
import ErrorMessages from "components/ErrorMessages";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import React, { useCallback, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import "./style.scss";

const currencies = {
  EUR: { name: "Euro", value: "EUR", icon: <Euro /> },
  USD: { name: "Dollar", value: "USD", icon: <Dollar /> },
  GBP: { name: "Pound", value: "GBP", icon: <Pound /> },
  TRY: { name: "Turkish Lira", value: "TRY", icon: <TurkishLira /> },
};

function CurrencyInput({
  value,
  name = "",
  selectedCurrency = "EUR",
  handleChange,
  handleChangeCurrency,
  disabled,
  error = "",
  showError = false,
  label = "",
  ...props
}) {
  selectedCurrency = selectedCurrency ? selectedCurrency : "EUR";
  const [showCurrencyOptions, setShowCurrencyOptions] = useState(false);
  const [focused, setFocused] = useState(false);

  const handleCloseOptions = useCallback(() => {
    setShowCurrencyOptions(false);
  }, []);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, handleCloseOptions);

  const onChangeCurrency = (currency) => {
    setShowCurrencyOptions(false);
    handleChangeCurrency({ value: currency, name: name });
  };
  const onChangeValue = (e) => {
    let elemToReturn = { value: e.value, name: name };
    handleChange(elemToReturn);
  };

  return (
    <div>
      <div className="currency-input-container">
        <label className="currency-input">
          <span
            className={`label-text ${
              (value && value > 0) || value?.toString().length || focused
                ? "label-small"
                : ""
            }`}
          >
            {label}
          </span>
          <NumericFormat
            decimalSeparator=","
            thousandSeparator="."
            variant="outlined"
            valueIsNumericString={true}
            value={value}
            decimalScale={2}
            onValueChange={onChangeValue}
            disabled={disabled}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            {...props}
          />
          <div className="currencies">
            <button
              onClick={() => setShowCurrencyOptions(!showCurrencyOptions)}
              type="button"
            >
              {currencies[selectedCurrency].icon}
            </button>
          </div>
        </label>
        <div
          className={`options ${showCurrencyOptions ? "show" : ""}`}
          ref={wrapperRef}
        >
          {Object.keys(currencies).map((currency, index) => (
            <div
              key={index}
              className="flex"
              onClick={() => onChangeCurrency(currency)}
            >
              {currencies[currency].icon}
              <span>{currencies[currency].name}</span>
            </div>
          ))}
        </div>
      </div>

      {showError && error.length ? <ErrorMessages error={error} /> : null}
    </div>
  );
}

export default CurrencyInput;
