import AddCircle from "assets/icons/AddCircle";
import DeleteBin2 from "assets/icons/DeleteBin2";
import EditPencil from "assets/icons/EditPencil";
import FormButton from "components/Buttons/FormButton";
import DragAndDrop from "components/DragAndDrop";
import CurrencyInput from "components/FormElements/CurrencyInput";
import Input from "components/FormElements/Input";
import SelectOption from "components/FormElements/SelectOption";
import AddTechnicalDetails from "components/Modals/AddTechnicalDetails";
import { useFormik } from "formik";
import CustomAlert from "lib/customAlert.js";
import React, { useCallback, useEffect, useState } from "react";
import deviceValidationSchema from "./deviceValidationSchema";
import "./style.scss";
import DeviceService from "services/product/device/deviceService";
import LoadingPage from "components/LoadingPage";
import { toast } from "react-toastify";
import { statusInfoOptions, statusInfos } from "constants/statusInfos";
import CategoryOptions from "components/CategoryOptions";
import languageTexts from "constants/languageTexts";
import { useDispatch, useSelector } from "react-redux";
import DragAndDropNew from "components/DragAndDropNew";
import { getDevices } from "redux/product/device/deviceActions";
import BrandModelOptionsNew from "components/BrandModelOptionsNew";
import DotmedCategoryOptionsNew from "components/DotmedCategoryOptionsNew";
import ReactQuillWrapper from "components/FormElements/ReactQuillWrapper";

const handleFormatDevice = (deviceInfos, technicalDetails) => {
  let formData = new FormData();
  formData.append("salePrice[currency]", deviceInfos.salePrice?.currency);
  formData.append("salePrice[value]", deviceInfos.salePrice?.value);
  formData.append("productionYear", deviceInfos.productionYear);
  formData.append("serialNumber", deviceInfos.serialNumber);
  formData.append("brandId", deviceInfos.brand?._id);
  formData.append("modelId", deviceInfos.model?._id);
  formData.append("categoryId", deviceInfos.category?._id);
  formData.append("dotmedCategoryId", deviceInfos.dotmedCategory?._id);
  formData.append("deviceInfo", deviceInfos.deviceInfo);
  formData.append("deviceStatus", deviceInfos.deviceStatus?._id);
  formData.append("saleDescriptionShopify", deviceInfos.saleDescriptionShopify);
  formData.append("saleDescriptionDotmed", deviceInfos.saleDescriptionDotmed);
  deviceInfos.images.forEach((image) => {
    formData.append("images", image);
  });
  formData.append("physicalInfos[width]", deviceInfos.physicalInfos?.width);
  formData.append("physicalInfos[height]", deviceInfos.physicalInfos?.height);
  formData.append("physicalInfos[size]", deviceInfos.physicalInfos?.size);
  formData.append("physicalInfos[weight]", deviceInfos.physicalInfos?.weight);

  for (let i = 0; i < technicalDetails.length; i++) {
    formData.append(`technicalDetails[${i}][title]`, technicalDetails[i].title);
    formData.append(
      `technicalDetails[${i}][description]`,
      technicalDetails[i].description
    );
  }
  return formData;
};
const deviceService = new DeviceService();
function Device({ deviceId }) {
  const dispatch = useDispatch();
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [addTechincalDetailsModalOpen, setAddTechincalDetailsModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [technicalDetails, setTechnicalDetails] = useState([]);
  const [technicalDetailModalInfo, setTechnicalDetailModalInfo] = useState({
    _id: 0,
    title: "",
    description: "",
  });
  const [deviceInfos, setDeviceInfos] = useState(null);

  const getDevice = useCallback(() => {
    setLoading(true);
    deviceService
      .getById(deviceId)
      .then((res) => {
        setDeviceInfos(res.data.data);
        setTechnicalDetails(res.data.data.technicalDetails);
      })
      .finally(() => setLoading(false));
  }, [deviceId]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      images: deviceInfos?.images || [],
      salePrice: deviceInfos?.salePrice || {
        currency: "EUR",
        value: "",
      },
      serialNumber: deviceInfos?.serialNumber || "",
      saleDescriptionShopify: deviceInfos?.saleDescriptionShopify || "",
      saleDescriptionDotmed: deviceInfos?.saleDescriptionDotmed || "",
      brand: deviceInfos?.brandId || {
        name: "",
        _id: "",
      },
      model: deviceInfos?.modelId || {
        name: "",
        _id: "",
      },
      productionYear: deviceInfos?.productionYear || "",
      category: deviceInfos?.categoryId || {
        name: "",
        _id: "",
      },
      dotmedCategory: deviceInfos?.dotmedCategoryId || {
        name: "",
        _id: "",
      },
      deviceInfo: deviceInfos?.deviceInfo || "",
      physicalInfos: {
        width: deviceInfos?.physicalInfos?.width || "",
        height: deviceInfos?.physicalInfos?.height || "",
        size: deviceInfos?.physicalInfos?.size || "",
        weight: deviceInfos?.physicalInfos?.weight || "",
      },
      deviceStatus: {
        name: statusInfos[deviceInfos?.deviceStatus] || "",
        _id: deviceInfos?.deviceStatus || "",
      },
    },
    validationSchema: deviceValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        setLoading(true);
        deviceService
          .update(deviceId, handleFormatDevice(values, technicalDetails))
          .then(() => {
            toast.success(languageTexts[danisikLanguage].succesfullyUpdated);
            getDevice();
            dispatch(getDevices());
          })
          .finally(() => setLoading(false));
      }
    },
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    formik.setFieldValue(name, value);
  };
  const handleChangeCurrency = (e) => {
    formik.setFieldValue(`${e.name}.currency`, e.value);
  };
  const handleChangeCurrencyInputValue = (e) => {
    formik.setFieldValue(`${e.name}.value`, e.value);
  };

  const handleDeleteTechnicalDetail = async (index) => {
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].OK
    );
    if (result) {
      const newTechnicalDetails = [
        ...technicalDetails.slice(0, index),
        ...technicalDetails.slice(index + 1),
      ];
      setTechnicalDetails(newTechnicalDetails);
    }
  };
  const handleDeleteImageFormik = (file) => {
    let newFiles = JSON.parse(JSON.stringify(formik.values.images));
    newFiles = newFiles.filter((img) => img._id !== file._id);
    formik.setFieldValue("images", newFiles);
  };

  const handleDeleteImage = (publicId) => {
    setLoading(true);
    deviceService
      .handleDeleteImg(deviceId, publicId)
      .then(() => {
        toast.success(languageTexts[danisikLanguage].succesfullyDeleted);
        getDevice();
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getDevice();
  }, [getDevice]);

  const handleOrderImages = (images, modelId) => {
    formik.setFieldValue("images", images);
    deviceService
      .orderImages({
        modelId: modelId,
        images: images,
      })
      .then((res) => {
        toast.success(
          languageTexts[danisikLanguage].imagesAreOrderedSuccessfully
        );
      });
  };
  return loading ? (
    <LoadingPage />
  ) : (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="flow-content tab-device-form"
      >
        <DragAndDropNew
          images={formik.values?.images}
          setImages={(images) => {
            formik.setFieldValue("images", images);
          }}
          // handleUpdateFile={handleUpdateFile}
          handleDeleteImageFormik={handleDeleteImageFormik}
          handleDeleteImage={handleDeleteImage}
          handleOrder={handleOrderImages}
          modelId={deviceId}
        />
        <div className="modal-form-padding flow-content">
          <CurrencyInput
            label={languageTexts[danisikLanguage].salePrice}
            value={formik.values.salePrice.value}
            selectedCurrency={formik.values.salePrice.currency}
            handleChangeCurrency={handleChangeCurrency}
            handleChange={handleChangeCurrencyInputValue}
            name="salePrice"
            showError={
              formik.touched.salePrice?.value &&
              Boolean(formik.errors.salePrice?.value)
            }
            error={
              formik.touched.salePrice?.value && formik.errors.salePrice?.value
            }
          />
          <Input
            label={"Serial Number"}
            value={formik.values.serialNumber}
            handleChange={handleChange}
            name="serialNumber"
            showError={
              formik.touched.serialNumber && Boolean(formik.errors.serialNumber)
            }
            error={formik.touched.serialNumber && formik.errors.serialNumber}
          />
          <BrandModelOptionsNew
            brandLabel={languageTexts[danisikLanguage].brand}
            modelLabel={languageTexts[danisikLanguage].model}
            brandValue={formik.values.brand}
            modelValue={formik.values.model}
            showErrorBrand={
              formik.touched.brand?._id && Boolean(formik.errors.brand?._id)
            }
            errorBrand={formik.touched.brand?._id && formik.errors.brand?._id}
            showErrorModel={
              formik.touched.model?._id && Boolean(formik.errors.model?._id)
            }
            errorModel={formik.touched.model?._id && formik.errors.model?._id}
            handleChangeBrand={handleChange}
            handleChangeModel={handleChange}
          />

          <Input
            type="number"
            max={new Date().getFullYear()}
            label={languageTexts[danisikLanguage].productionYear}
            value={formik.values.productionYear}
            handleChange={handleChange}
            name="productionYear"
            showError={
              formik.touched.productionYear &&
              Boolean(formik.errors.productionYear)
            }
            error={
              formik.touched.productionYear && formik.errors.productionYear
            }
          />
          <CategoryOptions
            label={languageTexts[danisikLanguage].category + "(Deprecated)"}
            name="category"
            handleChange={handleChange}
            value={formik.values.category}
            showError={
              formik.touched.category?._id &&
              Boolean(formik.errors.category?._id)
            }
            error={formik.touched.category?._id && formik.errors.category?._id}
          />
          <DotmedCategoryOptionsNew
            label={"*" + languageTexts[danisikLanguage].category + " Dotmed"}
            name="dotmedCategory"
            handleChange={handleChange}
            value={formik.values.dotmedCategory}
            showError={
              formik.touched.dotmedCategory?._id &&
              Boolean(formik.errors.dotmedCategory?._id)
            }
            error={
              formik.touched.dotmedCategory?._id &&
              formik.errors.dotmedCategory?._id
            }
          />
          <Input
            label={languageTexts[danisikLanguage].deviceInfos}
            value={formik.values.deviceInfo}
            handleChange={handleChange}
            name="deviceInfo"
            showError={
              formik.touched.deviceInfo && Boolean(formik.errors.deviceInfo)
            }
            error={formik.touched.deviceInfo && formik.errors.deviceInfo}
          />
          <SelectOption
            options={statusInfoOptions}
            label={"*" + languageTexts[danisikLanguage].deviceStatus}
            name="deviceStatus"
            handleChange={handleChange}
            value={formik.values.deviceStatus}
            showError={
              formik.touched.deviceStatus && Boolean(formik.errors.deviceStatus)
            }
            error={formik.touched.deviceStatus && formik.errors.deviceStatus}
          />
          <div className="physical-info flow-content">
            <h3 className="section-title">
              {languageTexts[danisikLanguage].physicalInfos}
            </h3>
            <Input
              type="number"
              label={languageTexts[danisikLanguage].width}
              value={formik.values.physicalInfos?.width}
              handleChange={handleChange}
              name="physicalInfos.width"
              showError={
                formik.touched.physicalInfos?.width &&
                Boolean(formik.errors.physicalInfos?.width)
              }
              error={
                formik.touched.physicalInfos?.width &&
                formik.errors.physicalInfos?.width
              }
            />
            <Input
              type="number"
              label={languageTexts[danisikLanguage].height}
              value={formik.values.physicalInfos?.height}
              handleChange={handleChange}
              name="physicalInfos.height"
              showError={
                formik.touched.physicalInfos?.height &&
                Boolean(formik.errors.physicalInfos?.height)
              }
              error={
                formik.touched.physicalInfos?.height &&
                formik.errors.physicalInfos?.height
              }
            />
            <Input
              type="number"
              label={languageTexts[danisikLanguage].size}
              value={formik.values.physicalInfos?.size}
              handleChange={handleChange}
              name="physicalInfos.size"
              showError={
                formik.touched.physicalInfos?.size &&
                Boolean(formik.errors.physicalInfos?.size)
              }
              error={
                formik.touched.physicalInfos?.size &&
                formik.errors.physicalInfos?.size
              }
            />
            <Input
              type="number"
              label={languageTexts[danisikLanguage].weight}
              value={formik.values.physicalInfos?.weight}
              handleChange={handleChange}
              name="physicalInfos.weight"
              showError={
                formik.touched.physicalInfos?.weight &&
                Boolean(formik.errors.physicalInfos?.weight)
              }
              error={
                formik.touched.physicalInfos?.weight &&
                formik.errors.physicalInfos?.weight
              }
            />
          </div>
          <div className="technical-details flow-content">
            <div className="technical-details-header">
              <h3 className="section-title">
                {languageTexts[danisikLanguage].technicalDetails}
              </h3>
              <div
                className="add-icon"
                onClick={() => {
                  setTechnicalDetailModalInfo({
                    title: "",
                    description: "",
                    _id: 0,
                  });
                  setAddTechincalDetailsModalOpen(true);
                }}
              >
                <AddCircle />
              </div>
            </div>
            {technicalDetails.map((value, index) => (
              <div className="technical-detail flow-content" key={index}>
                <Input
                  label={languageTexts[danisikLanguage].title}
                  value={value.title}
                  disabled={true}
                />
                <Input
                  label={languageTexts[danisikLanguage].description}
                  value={value.description}
                  disabled={true}
                />
                <div className="button-container">
                  <button
                    className="delete"
                    type="button"
                    onClick={() => handleDeleteTechnicalDetail(index)}
                  >
                    <DeleteBin2 width="2rem" height="2rem" />
                    {languageTexts[danisikLanguage].delete}
                  </button>
                  <button
                    className="edit"
                    type="button"
                    onClick={() => {
                      setTechnicalDetailModalInfo({
                        title: value.title,
                        description: value.description,
                        _id: value._id,
                        index: index,
                      });
                      setAddTechincalDetailsModalOpen(true);
                    }}
                  >
                    <EditPencil width="1.8rem" height="1.8rem" />
                    {languageTexts[danisikLanguage].edit}
                  </button>
                </div>
              </div>
            ))}
          </div>
          <ReactQuillWrapper
            value={formik.values.saleDescriptionDotmed}
            label={languageTexts[danisikLanguage].saleDescriptionDotmed}
            name="saleDescriptionDotmed"
            handleChange={handleChange}
          />
          <ReactQuillWrapper
            value={formik.values.saleDescriptionShopify}
            label={languageTexts[danisikLanguage].saleDescriptionShopify}
            name="saleDescriptionShopify"
            handleChange={handleChange}
          />
          <FormButton title={languageTexts[danisikLanguage].save} />
        </div>
      </form>
      <AddTechnicalDetails
        setIsModalOpen={setAddTechincalDetailsModalOpen}
        isModalOpen={addTechincalDetailsModalOpen}
        deviceId={deviceId}
        technicalDetailModalInfo={technicalDetailModalInfo}
        setTechnicalDetailModalInfo={setTechnicalDetailModalInfo}
        technicalDetails={technicalDetails}
        setTechnicalDetails={setTechnicalDetails}
      />
    </div>
  );
}

export default Device;
