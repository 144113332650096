import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import { truncate } from "utils/stringFunctions";
import AddButton from "components/Buttons/AddButton";
import LoadingPage from "components/LoadingPage";
import DamageService from "services/product/device/damageService";
import AddDamage from "components/Modals/Damage/AddDamage";
import Repair from "components/Modals/Damage/Repair";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";

const damageService = new DamageService();
function Damage({ deviceId }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [loading, setLoading] = useState(false);
  const [damages, setDamages] = useState([]);
  const [isDamageModalOpen, setIsDamageModalOpen] = useState(false);
  const [isRepairModalOpen, setIsRepairModalOpen] = useState(false);
  const [repairModalInfos, setRepairModalInfos] = useState(null);
  const getDamages = useCallback(() => {
    setLoading(true);
    damageService
      .getAllByDeviceId(deviceId)
      .then((res) => {
        setDamages(res.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [deviceId]);
  useEffect(() => {
    getDamages();
  }, [getDamages]);

  return loading ? (
    <LoadingPage />
  ) : (
    <div className="damage-container flow-content">
      <h3 className="damage-title">
        {languageTexts[danisikLanguage].productDamageList}
      </h3>
      <div className="damage-list flow-content">
        {damages.map((damage) => (
          <div
            className="damage-item"
            key={damage._id}
            onClick={() => {
              setRepairModalInfos(damage);
              setIsRepairModalOpen(true);
            }}
          >
            <div className="damage-img-container">
              <img src={damage.images[0]?.url} alt="" />
            </div>
            <div className="damage-info-container">
              <span className="device-serial-number">
                {languageTexts[danisikLanguage].serialNumber}:{" "}
                {damage?.serialNumber}
              </span>
              <span>
                {languageTexts[danisikLanguage].repaired}:{" "}
                {damage.isRepaired
                  ? languageTexts[danisikLanguage].yes
                  : languageTexts[danisikLanguage].no}
              </span>
              <span title={damage.description}>
                {languageTexts[danisikLanguage].description}:{" "}
                {truncate(damage.description, 50)}
              </span>
            </div>
            <div className="damage-status-container">
              <div
                className={`device-status-${damage.deviceSituationInfo} `}
              ></div>
            </div>
          </div>
        ))}
      </div>
      <AddButton onClick={() => setIsDamageModalOpen(true)} />
      <AddDamage
        isModalOpen={isDamageModalOpen}
        setIsModalOpen={setIsDamageModalOpen}
        deviceId={deviceId}
        getDamages={getDamages}
      />
      <Repair
        isModalOpen={isRepairModalOpen}
        setIsModalOpen={setIsRepairModalOpen}
        setRepairModalInfos={setRepairModalInfos}
        repairModalInfos={repairModalInfos}
        getDamages={getDamages}
        deviceId={deviceId}
      />
    </div>
  );
}

export default Damage;
