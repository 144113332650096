import { useFormik } from "formik";
import CustomAlert from "lib/customAlert.js";
import React from "react";
import ModalHeader from "components/Modals/ModalHeader";
import Input from "components/FormElements/Input";
import FormButton from "components/Buttons/FormButton";
import categoryValidationSchema from "./categoryValidationSchema";
import CategoryService from "services/admin/category";
import { toast } from "react-toastify";
import DeleteButton from "components/Buttons/DeleteButton";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";

function CategoryAdd({
  isModalOpen = false,
  setIsModalOpen,
  addCategoryModalInfo = { _id: 2, name: "" },
  getAllCategories,
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: addCategoryModalInfo._id,
      name: addCategoryModalInfo.name,
    },
    validationSchema: categoryValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        let categoryService = new CategoryService();
        if (values._id) {
          categoryService
            .updateNormal(values._id, {
              name: values.name,
            })
            .then(() => {
              toast.success(languageTexts[danisikLanguage].succesfullyUpdated);
              getAllCategories();
              setIsModalOpen(false);
            });
        } else {
          categoryService
            .add({
              name: values.name,
            })
            .then(() => {
              toast.success(languageTexts[danisikLanguage].operationSuccessful);
              getAllCategories();
              setIsModalOpen(false);
            });
        }
      }
    },
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    formik.setFieldValue(name, value);
  };
  const handleDelete = async () => {
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].OK
    );
    if (result) {
      let categoryService = new CategoryService();
      categoryService.delete(formik.values._id).then(() => {
        toast.success(languageTexts[danisikLanguage].succesfullyDeleted);
        getAllCategories();
        setIsModalOpen(false);
      });
    }
  };
  return (
    <div
      className={`add-category-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal add-category-modal`}>
        <ModalHeader
          title={`${
            addCategoryModalInfo._id
              ? languageTexts[danisikLanguage].edit
              : languageTexts[danisikLanguage].add
          }`}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content">
          <form onSubmit={formik.handleSubmit} className="flow-content">
            <Input
              label={languageTexts[danisikLanguage].category}
              value={formik.values.name}
              handleChange={handleChange}
              name="name"
              showError={formik.touched.name && Boolean(formik.errors.name)}
              error={formik.touched.name && formik.errors.name}
            />
            <FormButton title={languageTexts[danisikLanguage].save} />
            {formik.values._id ? (
              <DeleteButton onClick={() => handleDelete()} />
            ) : null}
          </form>
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
    </div>
  );
}

export default CategoryAdd;
