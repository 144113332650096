import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import ModalHeader from "components/Modals/ModalHeader";
import SearchInput from "components/Search/SearchInput";
import Add from "assets/icons/Add";
import EditPencil from "assets/icons/EditPencil";
import PackagingOptionAdd from "../PackagingOptionsAdd";
import PackagingTypeService from "services/product/device/packagingTypeService";
import LoadingPage from "components/LoadingPage";
import { debounce } from "utils/debounce";
import languageTexts from "constants/languageTexts";

let packagingTypeService = new PackagingTypeService();
function PackagingOptionsList({ isModalOpen = false, setIsModalOpen }) {
  const [addPackagingOptionsModalOpen, setAddPackagingOptionsModalOpen] =
    useState(false);
  const [addPackagingOptionModalInfo, setAddaPackagingOptionsModalInfo] =
    useState({
      _id: 0,
      name: "",
    });
  const [isLoading, setIsLoading] = useState(false);
  const [packagingOptions, setPackagingOptions] = useState([]);

  const [packagingOptionCurrentPage, setPackagingOptionCurrentPage] =
    useState(1);
  const [packagingOptionPageCount, setPackagingOptionPageCount] = useState(1);
  const [packagingOptionSearchText, setPackagingOptionSearchText] =
    useState("");
  const [
    packagingOptionSearchTextForSearch,
    setPackagingOptionSearchTextForSearch,
  ] = useState("");

  const getAllPackagingTypes = useCallback(() => {
    setIsLoading(true);
    packagingTypeService
      .getAll()
      .then((res) => {
        setPackagingOptions(res.data.data);
        setPackagingOptionPageCount(
          res.data.totalPage ? res.data.totalPage : 1
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getAllPackagingTypes();
  }, [getAllPackagingTypes]);

  const handleScrollDropdownDebounce = debounce((e) => {
    handleScrollDropdown(e);
  }, 350);

  const handleScrollBottomModel = () => {
    if (packagingOptionPageCount > packagingOptionCurrentPage) {
      setPackagingOptionCurrentPage(packagingOptionCurrentPage + 1);
    }
  };
  const handleScrollDropdown = (e) => {
    const isScrollAtTheBottom =
      e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 1;
    if (isScrollAtTheBottom) {
      handleScrollBottomModel();
    }
  };

  useEffect(() => {
    if (packagingOptionCurrentPage > 1) {
      setIsLoading(true);
      packagingTypeService
        .getAll(`?limit=15&page=${packagingOptionCurrentPage}`)
        .then((res) => {
          let newPackagingOptions = [...packagingOptions, ...res.data.data];
          setPackagingOptions(newPackagingOptions);
        })
        .finally(() => setIsLoading(false));
    }
  }, [packagingOptionCurrentPage]);
  useEffect(() => {
    packagingTypeService
      .getAll(`?limit=15&search=${packagingOptionSearchText}`)
      .then((res) => {
        setPackagingOptions(res.data.data);
        setPackagingOptionCurrentPage(1);
      });
  }, [packagingOptionSearchTextForSearch]);

  const handleChangeFilterInput = (e) => {
    setPackagingOptionSearchTextForSearch(e);
  };

  const handleChangeFilterInputDebounce = useCallback(
    debounce((val) => {
      handleChangeFilterInput(val);
    }, 350),
    []
  );

  useEffect(() => {
    handleChangeFilterInputDebounce(packagingOptionSearchText);
  }, [packagingOptionSearchText, handleChangeFilterInputDebounce]);
  return (
    <div
      className={`packaging-option-list-modal-container modal-container settings-modals ${
        isModalOpen ? "open" : ""
      }`}
    >
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <div className={`modal packaging-option-list-modal`}>
            <ModalHeader
              title={"Packaging Option"}
              setIsModalOpen={setIsModalOpen}
            />
            <div className="modal-content settings-modal-content">
              <div className="modal-body-header">
                <div className="search-input-container">
                  <SearchInput
                    onChange={(e) =>
                      setPackagingOptionSearchText(e.target.value)
                    }
                    value={packagingOptionSearchText}
                  />
                </div>
                <div
                  className="add-button-container"
                  onClick={() => {
                    setAddaPackagingOptionsModalInfo({
                      name: "",
                      _id: 0,
                    });
                    setAddPackagingOptionsModalOpen(true);
                  }}
                >
                  <Add />
                </div>
              </div>
              <div
                className="settings-modal-body"
                onScroll={handleScrollDropdownDebounce}
              >
                <div className="item-container">
                  {packagingOptions.map((packagingOption) => (
                    <div className="item" key={packagingOption._id}>
                      <span>{packagingOption.name}</span>
                      <div
                        className="edit-icon-container"
                        onClick={() => {
                          setAddaPackagingOptionsModalInfo({
                            name: packagingOption.name,
                            _id: packagingOption._id,
                          });
                          setAddPackagingOptionsModalOpen(true);
                        }}
                      >
                        <EditPencil />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className="modal-overlay"
              onClick={() => setIsModalOpen(false)}
            ></div>
          </div>
          <PackagingOptionAdd
            setIsModalOpen={setAddPackagingOptionsModalOpen}
            isModalOpen={addPackagingOptionsModalOpen}
            addPackagingOptionModalInfo={addPackagingOptionModalInfo}
            getAllPackagingTypes={getAllPackagingTypes}
          />
        </>
      )}
    </div>
  );
}

export default PackagingOptionsList;
