import React, { useEffect } from "react";
import "./style.scss";
import dotmed from "assets/images/dotmed.png";
import shopify from "assets/images/shopify.png";
import ebay from "assets/images/ebay.png";
import Euro from "assets/icons/Euro";
import Dollar from "assets/icons/Dollar";
import Pound from "assets/icons/Pound";
import TurkishLira from "assets/icons/TurkishLira";
import ECommerceService from "services/product/device/eCommerceService";
import { toast } from "react-toastify";
import { useState } from "react";
import CustomAlert from "lib/customAlert";
import { useCallback } from "react";
import LoadingPage from "components/LoadingPage";
import { dateFormatToDDMMYYYDayHHMMSS } from "utils/dateFormat";
import PrimaryButton from "components/Buttons/PrimaryButton";
import DeleteButton from "components/Buttons/DeleteButton";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";

const currencies = {
  EUR: <Euro />,
  USD: <Dollar />,
  GBP: <Pound />,
  TRY: <TurkishLira />,
};

const eCommerceService = new ECommerceService();

function ECommerce({ deviceId }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [loading, setLoading] = useState(false);
  const [eCommerceId, setECommerceId] = useState(0);
  const user = useSelector((state) => state.danisikUser?.user);
  const hasPermission =
    user && (user.role === "admin" || user.role === "buyer");
  const [eCommerces, setECommerces] = useState({
    dotmed: null,
    shopify: null,
    ebay: null,
  });
  const getEcommerceInformations = useCallback(() => {
    setLoading(true);
    eCommerceService
      .getECommerceByDeviceId(deviceId)
      .then((res) => {
        let eCommerceInfo = res.data.data;
        let eCommercesToSet = eCommerceInfo?.length
          ? eCommerceInfo[0].eCommerceSites
          : { dotmed: null, shopify: null, ebay: null };
        setECommerces(eCommercesToSet);
        setECommerceId(eCommerceInfo?.length ? eCommerceInfo[0]._id : 0);
      })
      .finally(() => setLoading(false));
  }, [deviceId]);

  useEffect(() => {
    getEcommerceInformations();
  }, [getEcommerceInformations]);

  const handleListIn = async (listIn) => {
    let functionToInvoke = eCommerceService.listInDotmed.bind(eCommerceService);
    switch (listIn) {
      case "shopify":
        functionToInvoke =
          eCommerceService.listInShopify.bind(eCommerceService);
        break;
      case "ebay":
        functionToInvoke = eCommerceService.listInEbay.bind(eCommerceService);
        break;
      case "dotmed":
        functionToInvoke = eCommerceService.listInDotmed.bind(eCommerceService);
        break;
      default:
        console.log("unhandled list option clicked");
        break;
    }
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].areYouSureToListProduct
    );
    if (result) {
      setLoading(true);
      functionToInvoke(eCommerceId, deviceId)
        .then((res) => {
          toast.success(languageTexts[danisikLanguage].listedSuccessfully);
          getEcommerceInformations();
        })
        .finally(() => setLoading(false));
    }
  };

  const updateInECommerce = async (listIn) => {
    let functionToInvoke = eCommerceService.updateDotmed.bind(eCommerceService);
    switch (listIn) {
      case "shopify":
        functionToInvoke =
          eCommerceService.updateShopify.bind(eCommerceService);
        break;
      case "dotmed":
        functionToInvoke = eCommerceService.updateDotmed.bind(eCommerceService);
        break;
      default:
        console.log("unhandled list option clicked");
        break;
    }
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].areYouSureToSyncProduct
    );
    if (result) {
      setLoading(true);
      functionToInvoke(eCommerceId, deviceId)
        .then((res) => {
          toast.success(languageTexts[danisikLanguage].operationSuccessful);
          getEcommerceInformations();
        })
        .finally(() => setLoading(false));
    }
  };
  const deleteInECommerce = async (listIn) => {
    let functionToInvoke =
      eCommerceService.deleteFromDotmed.bind(eCommerceService);
    switch (listIn) {
      case "shopify":
        functionToInvoke =
          eCommerceService.deleteFromShopify.bind(eCommerceService);
        break;
      case "dotmed":
        functionToInvoke =
          eCommerceService.deleteFromDotmed.bind(eCommerceService);
        break;
      default:
        console.log("unhandled list option clicked");
        break;
    }
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].OK
    );
    if (result) {
      setLoading(true);
      functionToInvoke(eCommerceId, deviceId)
        .then((res) => {
          toast.success(languageTexts[danisikLanguage].operationSuccessful);
          getEcommerceInformations();
        })
        .finally(() => setLoading(false));
    }
  };

  return loading ? (
    <LoadingPage />
  ) : hasPermission ? (
    <div className="e-commerce-container flow-content">
      <div className="e-commerce-item">
        <div className="e-commerce-img-container">
          <img src={dotmed} alt={"Dotmed logo"} />
        </div>
        <div className="e-commerce-info">
          {eCommerces.dotmed?.siteLink ? (
            <>
              <>
                <span className="e-commerce-title">
                  {languageTexts[danisikLanguage].inTheAdvertisementSince}{" "}
                  {dateFormatToDDMMYYYDayHHMMSS(eCommerces.dotmed.addDate)}
                </span>
                <span>
                  {languageTexts[danisikLanguage].link}:{" "}
                  <a
                    href={eCommerces.dotmed.siteLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {eCommerces.dotmed.siteLink}
                  </a>
                </span>
                <span className="price">
                  {languageTexts[danisikLanguage].price}:{" "}
                  {eCommerces.dotmed.price.value}
                  {currencies[eCommerces.dotmed.price.currency]}
                </span>
                <span className="listing-id">
                  {languageTexts[danisikLanguage].listingId}:{" "}
                  {eCommerces.dotmed.listingId}
                </span>
                <div className="btn-container">
                  <PrimaryButton
                    title={languageTexts[danisikLanguage].sync}
                    style={{ backgroundColor: "var(--clr-blue)" }}
                    onClick={() => updateInECommerce("dotmed")}
                  />
                  <DeleteButton
                    title={languageTexts[danisikLanguage].delete}
                    onClick={() => deleteInECommerce("dotmed")}
                  />
                </div>
              </>
            </>
          ) : (
            <>
              <span className="e-commerce-title">
                {languageTexts[danisikLanguage].notListedYet}
              </span>
              <button
                className="list-button"
                onClick={() => handleListIn("dotmed")}
              >
                {languageTexts[danisikLanguage].clickForList}
              </button>
            </>
          )}
        </div>
      </div>
      <div className="e-commerce-item">
        <div className="e-commerce-img-container">
          <img src={shopify} alt={"Shopify Logo"} />
        </div>
        <div className="e-commerce-info">
          {eCommerces.shopify?.siteLink ? (
            <>
              <>
                <span className="e-commerce-title">
                  {languageTexts[danisikLanguage].inTheAdvertisementSince}{" "}
                  {eCommerces.shopify.addDate}
                </span>
                <span>
                  {languageTexts[danisikLanguage].link}:{" "}
                  <a
                    href={eCommerces.shopify.siteLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {eCommerces.shopify.siteLink}
                  </a>
                </span>
                <span className="price">
                  {languageTexts[danisikLanguage].price}:{" "}
                  {eCommerces.shopify.price.value}
                  {currencies[eCommerces.shopify.price.currency]}
                </span>
                <span className="listing-id">
                  {languageTexts[danisikLanguage].listingId}:{" "}
                  {eCommerces.shopify.shopifyId}
                </span>
                <div className="btn-container">
                  <PrimaryButton
                    title={languageTexts[danisikLanguage].sync}
                    style={{ backgroundColor: "var(--clr-blue)" }}
                    onClick={() => updateInECommerce("shopify")}
                  />
                  <DeleteButton
                    title={languageTexts[danisikLanguage].delete}
                    onClick={() => deleteInECommerce("shopify")}
                  />
                </div>
              </>
            </>
          ) : (
            <>
              <span className="e-commerce-title">
                {languageTexts[danisikLanguage].notListedYet}
              </span>
              <button
                className="list-button"
                onClick={() => handleListIn("shopify")}
              >
                {languageTexts[danisikLanguage].clickForList}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div>{languageTexts[danisikLanguage].noPermissionToView}</div>
  );
}

export default ECommerce;
