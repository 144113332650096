import FactoryService from "services/factoryService";

export default class PartService extends FactoryService {
  constructor() {
    super("part");
  }
  findByPartNumber(partNumber) {
    return this.api.get(
      `${this.serviceName}/findByPartNumber?partNumber=${partNumber}`
    );
  }
  handleDeletePartImg(modelId, publicId) {
    return this.api.delete(
      `${this.serviceName}/images/${modelId}/images/${publicId}`
    );
  }
  handleDeleteTechnicalAndManufacturerImg(modelId, publicId) {
    return this.api.delete(
      `${this.serviceName}/deletePartImageTechnicalAndManufacturerImages/${modelId}/images/${publicId}`
    );
  }
  orderImages(values) {
    return this.api.patch(`${this.serviceName}/imagesArrayOrderUpdate`, values);
  }
  orderImagesTechnicalAndManufucturerImages(values) {
    return this.api.patch(
      `${this.serviceName}/technicalAndManufacturerImagesArrayOrderUpdate`,
      values
    );
  }
  completionStatus(id) {
    return this.api.get(
      `partFieldsCompletionStatus/getAllPartFieldsCompletionStatusWithPartId/${id}`
    );
  }
  getAllPartsWithFilters(query) {
    return this.api.get(`${this.serviceName}/getAllPartWithFilters${query}`);
  }
  changeActiveStatus(id) {
    return this.api.patch(`${this.serviceName}/activeOrDeActivePart/${id}`);
  }
}
