import React from "react";

function Inactive({
  fill = "var(--clr-neutral-200)",
  width = "2.4rem",
  height = "2.4rem",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 14"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7H19V17.004C19 17.554 18.555 18 18.007 18H1.993C1.86238 17.9999 1.73307 17.974 1.61246 17.9239C1.49184 17.8737 1.3823 17.8003 1.29007 17.7078C1.19785 17.6153 1.12477 17.5055 1.07499 17.3848C1.02522 17.264 0.999737 17.1346 1 17.004V7ZM7 9V11H13V9H7ZM0 1C0 0.448 0.455 0 0.992 0H19.008C19.556 0 20 0.444 20 1V5H0V1Z"
        fill="#DEDEDE"
      />
    </svg>
  );
}

export default Inactive;
