import DeviceService from "services/product/device/deviceService";
import { store } from "../../store";
import { DeviceActionTypes } from "./deviceActionTypes";

const setQueryFromFilters = (filter, sortBy, searchText) => {
  let query = "";
  if (filter.brand._id) {
    query += `brandId=${filter.brand._id}`;
  }
  if (filter.model._id) {
    if (query) {
      query += "&";
    }
    query += `modelId=${filter.model._id}`;
  }
  if (filter.category._id) {
    if (query) {
      query += "&";
    }
    query += `dotmedCategoryId=${filter.category._id}`;
  }
  if (filter.productionYear) {
    if (query) {
      query += "&";
    }
    query += `productionYear=${filter.productionYear}`;
  }
  if (searchText) {
    query += `&search=${searchText}`;
  }
  if (sortBy) {
    if (query) {
      query += "&";
    }
    query += `sortBy=${sortBy.name}&sort=${sortBy.sortType}`;
  }
  if (query) {
    return "&" + query;
  }
  return "";
};

export const getDevices = (searchText) => {
  const deviceService = new DeviceService();
  let deviceStore = store.getState().danisikProductDevice;
  let filterQuery = setQueryFromFilters(
    deviceStore.filterOptions,
    deviceStore.sortBy,
    searchText
  );
  let query = `?active=true&limit=${deviceStore.limit}&page=${deviceStore.page}${filterQuery}`;
  return async (dispatch) => {
    try {
      dispatch({ type: DeviceActionTypes.GET_DEVICES });

      // Make your API request
      const response = await deviceService.getAllDeviceWithFilters(query);
      dispatch({
        type: DeviceActionTypes.GET_DEVICES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: DeviceActionTypes.GET_DEVICES_ERROR, payload: error });
    }
  };
};
export const setPageDevice = (page) => ({
  type: DeviceActionTypes.SET_PAGE_DEVICE,
  payload: page,
});
export const setFilterOptionsDevice = (filterOptions) => ({
  type: DeviceActionTypes.SET_FILTER_OPTIONS_DEVICE,
  payload: filterOptions,
});
export const setSortByDevice = (sortBy) => ({
  type: DeviceActionTypes.SET_SORT_BY_DEVICE,
  payload: sortBy,
});
export const setSearchTextDevice = (searchText) => ({
  type: DeviceActionTypes.SET_SEARCH_TEXT_DEVICE,
  payload: searchText,
});

// SET_PAGE: "SET_PAGE",
//   GET_PAGE: "GET_PAGE",
//   SET_FILTER_OPTIONS: "SET_FILTER_OPTIONS",
//   GET_FILTER_OPTIONS: "GET_FILTER_OPTIONS",
//   SET_SORT_BY: "SET_SORT_BY",
//   GET_SORT_BY: "GET_SORT_BY",
//   SET_SEARCH_TEXT: "SET_SEARCH_TEXT",
//   GET_SEARCH_TEXT: "GET_SEARCH_TEXT",
