import FactoryService from "services/factoryService";

export default class ProductService extends FactoryService {
  constructor() {
    super("product");
  }

  createDevice(data) {
    return this.api.post(`${this.serviceName}/deviceProduct`, data);
  }
  createPart(data) {
    return this.api.post(`${this.serviceName}/partProduct`, data);
  }
}
