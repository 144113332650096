import FactoryService from "services/factoryService";

export default class DamageService extends FactoryService {
  constructor() {
    super("damageAndRepair");
  }
  getAllByDeviceId(deviceId) {
    return this.api.get(
      `/${this.serviceName}/getAllDamageAndRepairWithDeviceId/${deviceId}`
    );
  }
  addDamage(data) {
    return this.api.post(`${this.serviceName}/damage`, data);
  }
  setRepair(id, data) {
    return this.api.put(`${this.serviceName}/repair/${id}`, data);
  }
  setDamage(id, data) {
    return this.api.put(`${this.serviceName}/damage/${id}`, data);
  }
  handleDeleteDamageImg(modelId, publicId) {
    return this.api.delete(
      `${this.serviceName}/damage/${modelId}/images/${publicId}`
    );
  }
  handleDeleteRepairImg(modelId, publicId) {
    return this.api.delete(
      `${this.serviceName}/repair/${modelId}/images/${publicId}`
    );
  }

  orderImagesDamage(values) {
    return this.api.patch(
      `${this.serviceName}/damage/imagesArrayOrderUpdate`,
      values
    );
  }
  orderImagesRepair(values) {
    return this.api.patch(
      `${this.serviceName}/repair/imagesArrayOrderUpdate`,
      values
    );
  }
}
