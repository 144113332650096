import Input from "components/FormElements/Input";
import React, { useEffect, useState } from "react";
import "./style.scss";
import DragAndDrop from "components/DragAndDrop";
import ModalHeader from "components/Modals/ModalHeader";
import CustomAlert from "lib/customAlert";
import DeleteBin2 from "assets/icons/DeleteBin2";
import EditPencil from "assets/icons/EditPencil";
import AddCircle from "assets/icons/AddCircle";
import CurrencyInput from "components/FormElements/CurrencyInput";
import FormButton from "components/Buttons/FormButton";
import { useFormik } from "formik";
import repairValidationSchema from "./repairValidationSchema";
import AddMaterialsUsed from "../AddMaterialsUsed";
import AddOperationsMade from "../AddOperationsMade";
import DamageService from "services/product/device/damageService";
import LoadingPage from "components/LoadingPage";
import { toast } from "react-toastify";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";
import DragAndDropNew from "components/DragAndDropNew";
import PrimaryButton from "components/Buttons/PrimaryButton";

const handleFormatrepair = (images, materialsUsed, operationsMade) => {
  let formData = new FormData();
  if (images.length) {
    images.forEach((image) => {
      console.log("image worked");
      formData.append("images", image);
    });
  }

  for (let i = 0; i < materialsUsed.length; i++) {
    formData.append(
      `materialsUsedForRepair[${i}][material]`,
      materialsUsed[i]?.material
    );
    formData.append(
      `materialsUsedForRepair[${i}][cost][value]`,
      materialsUsed[i]?.cost?.value
    );
    formData.append(
      `materialsUsedForRepair[${i}][cost][currency]`,
      materialsUsed[i]?.cost?.currency
    );
  }
  for (let i = 0; i < operationsMade.length; i++) {
    formData.append(
      `operationsMade[${i}][description]`,
      operationsMade[i]?.description
    );
    formData.append(
      `operationsMade[${i}][timeSpend]`,
      operationsMade[i]?.timeSpend
    );
  }
  return formData;
};
const handleFormatDamage = (desciption, images, deviceId) => {
  let formData = new FormData();
  formData.append("description", desciption);
  formData.append("deviceId", deviceId);
  if (images.length) {
    images.forEach((image) => {
      formData.append("images", image);
    });
  }
  return formData;
};
const damageService = new DamageService();
function Repair({
  deviceId,
  isModalOpen,
  setIsModalOpen,
  repairModalInfos,
  setRepairModalInfos,
  getDamages,
}) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [isLoading, setIsLoading] = useState(false);
  const [operationsMade, setOperationsMade] = useState([]);
  const [materialsUsed, setMaterialsUsed] = useState([]);
  const [materialsUsedModalInfo, setMaterialsUsedModalInfo] = useState({
    material: "",
    cost: {
      value: "",
      currency: "EUR",
    },
  });
  const [addMaterialsUsedModalOpen, setAddMaterialsUsedModalOpen] =
    useState(false);

  const [operationsMadeModalInfo, setOperationsMadeModalInfo] = useState({
    description: "",
    timeSpend: "",
  });
  const [addOperationsMadeModalOpen, setOperationsMadeModalOpen] =
    useState(false);
  const [isRepairing, setIsRepairing] = useState(false);
  const handleDeleteOperationsMade = async (index) => {
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].OK
    );
    if (result) {
      const newOperationsMade = [
        ...operationsMade.slice(0, index),
        ...operationsMade.slice(index + 1),
      ];
      setOperationsMade(newOperationsMade);
    }
  };
  const handleDeleteMaterialsUsed = async (index) => {
    let customAlert = new CustomAlert();
    let result = await customAlert.alert(
      languageTexts[danisikLanguage].areYouSure,
      languageTexts[danisikLanguage].OK
    );
    if (result) {
      const newMaterialsUsed = [
        ...materialsUsed.slice(0, index),
        ...materialsUsed.slice(index + 1),
      ];
      setMaterialsUsed(newMaterialsUsed);
    }
  };
  useEffect(() => {
    setOperationsMade(repairModalInfos?.repair?.operationsMade || []);
    setMaterialsUsed(repairModalInfos?.repair?.materialsUsedForRepair || []);
  }, [repairModalInfos]);
  const formikDamage = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: repairModalInfos?._id || 0,
      description: repairModalInfos?.description || "",
      images: repairModalInfos?.images || [],
    },
    validationSchema: repairValidationSchema,
    onSubmit: async (values) => {
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        setIsLoading(true);
        damageService
          .setDamage(
            values._id,
            handleFormatDamage(values.description, values.images, deviceId)
          )
          .then((res) => {
            toast.success(languageTexts[danisikLanguage].operationSuccessful);
            setIsModalOpen(false);
            getDamages();
          })
          .finally(() => setIsLoading(false));
      }
    },
  });
  const formikRepair = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: repairModalInfos?._id || 0,
      images: repairModalInfos?.repair?.images || [],
      materialsUsedForRepair:
        repairModalInfos?.repair?.materialsUsedForRepair || [],
      operationsMade: repairModalInfos?.repair?.operationsMade || [],
    },
    onSubmit: async (values) => {
      console.log("values : ", values);
      if (!materialsUsed.length || !operationsMade.length) {
        toast.error(languageTexts[danisikLanguage].minMaterialNeededError);
        return;
      }
      let customAlert = new CustomAlert();
      let result = await customAlert.alert(
        languageTexts[danisikLanguage].areYouSure,
        languageTexts[danisikLanguage].OK
      );
      if (result) {
        setIsLoading(true);
        let repairData = handleFormatrepair(
          values.images || [],
          materialsUsed,
          operationsMade
        );
        damageService
          .setRepair(values._id, repairData)
          .then((res) => {
            toast.success(languageTexts[danisikLanguage].operationSuccessful);
            setIsModalOpen(false);
            getDamages();
          })
          .finally(() => setIsLoading(false));
        damageService.setRepair(values._id, repairData);
      }
    },
  });

  const handleChangeDamage = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    formikDamage.setFieldValue(name, value);
  };

  const handleOrderDamage = (images, modelId) => {
    setIsLoading(true);
    damageService
      .orderImagesDamage({ modelId: modelId, images: images })
      .then((res) => {
        toast.success(
          languageTexts[danisikLanguage].imagesAreOrderedSuccessfully
        );
      })
      .finally(() => setIsLoading(false));
  };
  const handleOrderRepair = (images, modelId) => {
    setIsLoading(true);
    damageService
      .orderImagesRepair({ modelId: modelId, images: images })
      .then((res) => {
        toast.success(
          languageTexts[danisikLanguage].imagesAreOrderedSuccessfully
        );
      })
      .finally(() => setIsLoading(false));
  };
  const handleDeleteDamageImage = (publicId) => {
    setIsLoading(true);
    damageService
      .handleDeleteDamageImg(formikDamage.values._id, publicId)
      .then((res) => {
        toast.success(languageTexts[danisikLanguage].succesfullyDeleted);
        setIsModalOpen(false);
        getDamages();
      })
      .finally(() => setIsLoading(false));
  };
  const handleDeleteRepairImage = (publicId) => {
    setIsLoading(true);
    damageService
      .handleDeleteRepairImg(formikRepair.values._id, publicId)
      .then((res) => {
        toast.success(languageTexts[danisikLanguage].succesfullyDeleted);
        setIsModalOpen(false);
        getDamages();
      })
      .finally(() => setIsLoading(false));
  };
  const handleDeleteDamageImageFormik = (file) => {
    let newFiles = JSON.parse(JSON.stringify(formikDamage.values.images));
    newFiles = newFiles.filter((img) => img._id !== file._id);
    formikDamage.setFieldValue("images", newFiles);
  };
  const handleDeleteRepairImageFormik = (file) => {
    let newFiles = JSON.parse(JSON.stringify(formikRepair.values.images));
    newFiles = newFiles.filter((img) => img._id !== file._id);
    formikRepair.setFieldValue("images", newFiles);
  };
  return isLoading ? (
    <LoadingPage />
  ) : (
    <div
      className={`add-repair-modal-container modal-container ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal add-repair-modal`}>
        <ModalHeader
          title={languageTexts[danisikLanguage].repair}
          setIsModalOpen={setIsModalOpen}
        />
        {!isRepairing ? (
          <form
            className="modal-content flow-content"
            onSubmit={formikDamage.handleSubmit}
          >
            <h3>{languageTexts[danisikLanguage].initialPhotos}</h3>
            <DragAndDropNew
              images={formikDamage.values.images}
              setImages={(images) => {
                formikDamage.setFieldValue("images", images);
              }}
              // handleUpdateFile={handleUpdateFile}
              handleDeleteImageFormik={handleDeleteDamageImageFormik}
              handleDeleteImage={handleDeleteDamageImage}
              handleOrder={handleOrderDamage}
              modelId={formikDamage.values._id}
            />
            <Input
              label={"*" + languageTexts[danisikLanguage].description}
              value={formikDamage.values.description}
              handleChange={handleChangeDamage}
              name="description"
              showError={
                formikDamage.touched.description &&
                Boolean(formikDamage.errors.description)
              }
              error={
                formikDamage.touched.description &&
                formikDamage.errors.description
              }
            />
            <FormButton
              title={languageTexts[danisikLanguage].save}
              type="submit"
            />
            <PrimaryButton
              title={languageTexts[danisikLanguage].repair}
              type="button"
              backgroundColor="var(--clr-blue)"
              onClick={() => setIsRepairing(true)}
            />
          </form>
        ) : (
          <form
            className="modal-content flow-content"
            onSubmit={formikRepair.handleSubmit}
          >
            <h3>{languageTexts[danisikLanguage].newImagesAfterRepair}</h3>
            <DragAndDropNew
              images={formikRepair.values.images}
              setImages={(images) => {
                console.log("images : ", images);
                formikRepair.setFieldValue("images", images);
              }}
              // handleUpdateFile={handleUpdateFile}
              handleDeleteImageFormik={handleDeleteRepairImageFormik}
              handleDeleteImage={handleDeleteRepairImage}
              handleOrder={handleOrderRepair}
              modelId={formikRepair.values._id}
            />
            <div className="operations-made repairs-item flow-content">
              <div className="operations-made-header repairs-item-header">
                <h3 className="section-title">
                  {languageTexts[danisikLanguage].operationsMade}
                </h3>
                <div
                  className="add-icon"
                  onClick={() => {
                    setOperationsMadeModalInfo({
                      description: "",
                      timeSpend: "",
                    });
                    setOperationsMadeModalOpen(true);
                  }}
                >
                  <AddCircle />
                </div>
              </div>
              {operationsMade.map((value, index) => (
                <div
                  className="operation-made repair-item flow-content"
                  key={index}
                >
                  <Input
                    label={languageTexts[danisikLanguage].description}
                    value={value.description}
                    disabled={true}
                  />
                  <Input
                    label={languageTexts[danisikLanguage].hourSpent}
                    value={value.timeSpend}
                    disabled={true}
                  />
                  <div className="button-container">
                    <button
                      className="delete"
                      type="button"
                      onClick={() => handleDeleteOperationsMade(index)}
                    >
                      <DeleteBin2 width="2rem" height="2rem" />
                      {languageTexts[danisikLanguage].delete}
                    </button>
                    <button
                      className="edit"
                      type="button"
                      onClick={() => {
                        setOperationsMadeModalInfo({
                          description: value.description,
                          timeSpend: value.timeSpend,
                          index: index,
                        });
                        setOperationsMadeModalOpen(true);
                      }}
                    >
                      <EditPencil width="1.8rem" height="1.8rem" />
                      {languageTexts[danisikLanguage].edit}
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="materials-used repairs-item flow-content">
              <div className="materials-used-header repairs-item-header">
                <h3 className="section-title">
                  {languageTexts[danisikLanguage].materialsUsedForRepair}
                </h3>
                <div
                  className="add-icon"
                  onClick={() => {
                    setMaterialsUsedModalInfo({
                      _id: 0,
                      material: "",
                      cost: {
                        value: "",
                        currency: "EUR",
                      },
                    });
                    setAddMaterialsUsedModalOpen(true);
                  }}
                >
                  <AddCircle />
                </div>
              </div>
              {materialsUsed.map((value, index) => (
                <div
                  className="material-used repair-item flow-content"
                  key={index}
                >
                  <Input
                    label={languageTexts[danisikLanguage].material}
                    value={value.material}
                    disabled={true}
                  />
                  <CurrencyInput
                    disabled={true}
                    value={value.cost.value}
                    selectedCurrency={value.cost.currency}
                  />

                  <div className="button-container">
                    <button
                      className="delete"
                      type="button"
                      onClick={() => handleDeleteMaterialsUsed(index)}
                    >
                      <DeleteBin2 width="2rem" height="2rem" />
                      {languageTexts[danisikLanguage].delete}
                    </button>
                    <button
                      className="edit"
                      type="button"
                      onClick={() => {
                        setMaterialsUsedModalInfo({
                          material: value.material,
                          cost: value.cost,
                          index: index,
                        });
                        setAddMaterialsUsedModalOpen(true);
                      }}
                    >
                      <EditPencil width="1.8rem" height="1.8rem" />
                      {languageTexts[danisikLanguage].edit}
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <FormButton
              title={languageTexts[danisikLanguage].save}
              type="submit"
            />
            <PrimaryButton
              title={languageTexts[danisikLanguage].goBackToDamageInfo}
              type="button"
              onClick={() => setIsRepairing(false)}
              backgroundColor="var(--clr-blue)"
            />
          </form>
        )}

        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
      <AddMaterialsUsed
        setIsModalOpen={setAddMaterialsUsedModalOpen}
        isModalOpen={addMaterialsUsedModalOpen}
        materialsUsedModalInfo={materialsUsedModalInfo}
        setMaterialsUsedModalInfo={setMaterialsUsedModalInfo}
        materialsUsed={materialsUsed}
        setMaterialsUsed={setMaterialsUsed}
      />
      <AddOperationsMade
        setIsModalOpen={setOperationsMadeModalOpen}
        isModalOpen={addOperationsMadeModalOpen}
        operationsMade={operationsMade}
        setOperationsMade={setOperationsMade}
        setOperationsMadeModalInfo={setOperationsMadeModalInfo}
        operationsMadeModalInfo={operationsMadeModalInfo}
      />
    </div>
  );
}

export default Repair;
