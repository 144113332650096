import ErrorMessages from "components/ErrorMessages";
import React, { useState } from "react";
import "./style.scss";

function Input({
  label,
  value = "",
  name = "",
  handleChange = () => {},
  type = "text",
  showError = false,
  error = [],
  ...props
}) {
  const [focused, setFocused] = useState(false);

  const onInput = (e) => {
    if (type === "number") {
      e.target.value = e.target.value.replace(/\D/g, "");
    }
  };
  return (
    <div>
      <label className="form-input">
        <span
          className={`label-text ${
            value?.toString()?.length || focused ? "label-small" : ""
          } ${type}`}
        >
          {label}
        </span>
        <input
          name={name}
          onInput={onInput}
          onChange={handleChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={value}
          type={type}
          onWheel={() => document.activeElement.blur()}
          {...props}
        />
      </label>
      {showError && error.length ? <ErrorMessages error={error} /> : null}
    </div>
  );
}

export default Input;
