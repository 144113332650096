import * as Yup from "yup";
export default Yup.object().shape({
  // salePrice: Yup.object().shape({
  //   value: Yup.number("Enter Purchase Price")
  //     .min(1, "Value must be greater than 0")
  //     .required("Required"),
  //   currency: Yup.string()
  //     .oneOf(["EUR", "USD", "GBP", "TRY"])
  //     .required("Please select valid currency"),
  // }),
  // shippingCost: Yup.object().shape({
  //   value: Yup.number("Enter Shipping Cost")
  //     .min(1, "Value must be greater than 0")
  //     .required("Required"),
  //   currency: Yup.string()
  //     .oneOf(["EUR", "USD", "GBP", "TRY"])
  //     .required("Please select valid currency"),
  // }),
  // taxCost: Yup.object().shape({
  //   value: Yup.number("Enter Tax Cost")
  //     .min(1, "Value must be greater than 0")
  //     .required("Required"),
  //   currency: Yup.string()
  //     .oneOf(["EUR", "USD", "GBP", "TRY"])
  //     .required("Please select valid currency"),
  // }),
  // otherCost: Yup.object().shape({
  //   value: Yup.number("Enter Other Cost").min(
  //     1,
  //     "Value must be greater than 0"
  //   ),
  //   currency: Yup.string()
  //     .oneOf(["EUR", "USD", "GBP", "TRY"])
  //     .required("Please select valid currency"),
  // }),
});
