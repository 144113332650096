import FactoryService from "services/factoryService";

export default class PartDeviceService extends FactoryService {
  constructor() {
    super("partDevice");
  }
  getDeviceByPartId(partId) {
    return this.api.get(
      `${this.serviceName}/getAllPartDeviceWithPartId/${partId}`
    );
  }
}
