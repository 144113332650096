import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import ModalHeader from "components/Modals/ModalHeader";
import SearchInput from "components/Search/SearchInput";
import Add from "assets/icons/Add";
import EditPencil from "assets/icons/EditPencil";
import UserAdd from "../UserAdd";
import LoadingPage from "components/LoadingPage";
import { truncate } from "utils/stringFunctions";
import { debounce } from "utils/debounce";
import UserService from "services/userService";
import { ROLES } from "constants/userRoles";
import languageTexts from "constants/languageTexts";
import { useSelector } from "react-redux";

const userService = new UserService();
function UserList({ isModalOpen = false, setIsModalOpen }) {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [addUserModalInfo, setAddUserModalInfo] = useState({
    _id: 0,
    name: "",
  });
  const [users, setUsers] = useState([]);
  const [userCurrentPage, setUserCurrentPage] = useState(1);
  const [userPageCount, setUserPageCount] = useState(1);
  const [userSearchText, setBrandSearchText] = useState("");
  const [userSearchTextForSearch, setBrandSearchTextForSearch] = useState("");

  const getAllUsers = useCallback(() => {
    setUserCurrentPage(1);
    userService
      .getActiveUsers(`?isActive=true&limit=15&page=${1}`)
      .then((res) => {
        setUsers(res.data.data);
        setUserPageCount(res.data.totalPage ? res.data.totalPage : 1);
      });
  }, []);

  useEffect(() => {
    userService
      .getActiveUsers(
        `?isActive=true&limit=15&search=${userSearchTextForSearch}`
      )
      .then((res) => {
        setUsers(res.data.data);
        setUserCurrentPage(1);
      });
  }, [userSearchTextForSearch]);

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  const handleScrollDropdownDebounce = debounce((e) => {
    handleScrollDropdown(e);
  }, 350);

  const handleScrollBottomBrand = () => {
    if (userPageCount > userCurrentPage) {
      setUserCurrentPage(userCurrentPage + 1);
    }
  };
  const handleScrollDropdown = (e) => {
    const isScrollAtTheBottom =
      e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 1;
    if (isScrollAtTheBottom) {
      handleScrollBottomBrand();
    }
  };

  useEffect(() => {
    if (userCurrentPage > 1) {
      userService
        .getActiveUsers(`?isActive=true&limit=15&page=${userCurrentPage}`)
        .then((res) => {
          let newUsers = [...users, ...res.data.data];
          setUsers(newUsers);
        });
    }
  }, [userCurrentPage]);
  useEffect(() => {
    userService
      .getActiveUsers(
        `?isActive=true&limit=15&search=${userSearchText}&page=${userCurrentPage}`
      )
      .then((res) => {
        setUsers(res.data.data);
        setUserCurrentPage(1);
      });
  }, [userSearchTextForSearch]);

  const handleChangeFilterInput = (e) => {
    setBrandSearchTextForSearch(e);
  };

  const handleChangeFilterInputDebounce = useCallback(
    debounce((val) => {
      handleChangeFilterInput(val);
    }, 350),
    []
  );

  useEffect(() => {
    handleChangeFilterInputDebounce(userSearchText);
  }, [userSearchText, handleChangeFilterInputDebounce]);

  return (
    <div
      className={`user-list-modal-container modal-container settings-modals ${
        isModalOpen ? "open" : ""
      }`}
    >
      <div className={`modal user-list-modal`}>
        <ModalHeader
          title={languageTexts[danisikLanguage].edit}
          setIsModalOpen={setIsModalOpen}
        />
        <div className="modal-content settings-modal-content">
          <div className="modal-body-header">
            <div className="search-input-container">
              <SearchInput
                onChange={(e) => setBrandSearchText(e.target.value)}
                value={userSearchText}
              />
            </div>
          </div>
          <div
            className="settings-modal-body"
            onScroll={handleScrollDropdownDebounce}
          >
            <div className="item-container">
              {users.map((user) => (
                <div className="item" key={user._id}>
                  <span title={user.name}>
                    {user.name} - {user.email} - {ROLES[user.role]}
                  </span>
                  <div
                    className="edit-icon-container"
                    onClick={() => {
                      setAddUserModalInfo({
                        name: user.name,
                        email: user.email,
                        roleId: user.role,
                        _id: user._id,
                      });
                      setAddUserModalOpen(true);
                    }}
                  >
                    <EditPencil />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className="modal-overlay"
          onClick={() => setIsModalOpen(false)}
        ></div>
      </div>
      <UserAdd
        setIsModalOpen={setAddUserModalOpen}
        isModalOpen={addUserModalOpen}
        addUserModalInfo={addUserModalInfo}
        getAllUsers={getAllUsers}
      />
    </div>
  );
}

export default UserList;
