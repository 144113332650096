import FactoryService from "services/factoryService";

export default class AdvertiseService extends FactoryService {
  deviceAdvertise(deviceId) {
    return this.api.get(`device/advertiseDevice/${deviceId}`);
  }
  partAdvertise(partId) {
    return this.api.get(`part/advertise/${partId}`);
  }
}
