import React from "react";
import "./style.scss";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";

function NotFound() {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  return (
    <div className="not-found">{languageTexts[danisikLanguage].notFound}</div>
  );
}

export default NotFound;
