import { ModelActionTypes } from "./modelActionTypes";

const INITIAL_STATE = {
  models: [],
  modelsNotFormatted: [],
  modelLoading: false,
  modelError: null,
};

const modelReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ModelActionTypes.SET_MODELS:
      return {
        ...state,
        modelLoading: true,
      };
    case ModelActionTypes.SET_MODELS_SUCCESSFUL:
      return {
        ...state,
        models: action.payload.formattedModels,
        modelsNotFormatted: action.payload.allModels,
        modelLoading: false,
      };
    case ModelActionTypes.SET_MODELS_ERROR:
      return {
        ...state,
        modelError: action.payload,
        modelLoading: false,
      };
    default:
      return state;
  }
};

export default modelReducer;
