import React, { useEffect, useState } from "react";
import "./style.scss";
import DownloadButton from "components/Buttons/DownloadButton";
import PreviewPDF from "components/PreviewPDF";
import PdfService from "services/product/device/pdfService";
import LoadingPage from "components/LoadingPage";
import { useSelector } from "react-redux";
import languageTexts from "constants/languageTexts";
import { saveAs } from "file-saver";

const pdfService = new PdfService();
function GetStock() {
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const user = useSelector((state) => state.danisikUser?.user);
  const [pdfFile, setPdfFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const downloadPDF = () => {
    fetch(pdfFile)
      .then((response) => {
        response.blob().then((blob) => {
          saveAs(blob, "stock-list.pdf");
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    setIsLoading(true);
    pdfService
      .getStockPdf()
      .then((res) => {
        var file = new Blob([res.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        setPdfFile(fileURL);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return user && user.role === "user" ? (
    <span>{languageTexts[danisikLanguage].noPermissionToView}</span>
  ) : isLoading ? (
    <LoadingPage />
  ) : (
    <div className="get-stock">
      <h2 className="page-title">
        {languageTexts[danisikLanguage].getStockPdf}
      </h2>
      {pdfFile ? (
        <>
          <PreviewPDF pdfFile={pdfFile} />
          <DownloadButton onClick={downloadPDF} />
        </>
      ) : null}
    </div>
  );
}

export default GetStock;
